import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import axios from 'axios';
import createStore from '1_reduxs/store';
import {
  refreshTokensThunk,
  setLoginThunk,
} from '1_reduxs/actions/loginAction';
import { ScrollToTop } from '5_components';
import './custom.scss'; // Import custom SCSS file
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { datadogRum } from '@datadog/browser-rum';

if (process.env.REACT_APP_USE_DATADOG_RUM === 'true') {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_RUM_APP_ID,
    clientToken: process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN,
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: process.env.REACT_APP_DATADOG_RUM_SITE,
    service: process.env.REACT_APP_DATADOG_RUM_SERVICE,
    env: process.env.REACT_APP_DATADOG_RUM_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: process.env.REACT_APP_DATADOG_RUM_VERSION,
    sessionSampleRate: Number(
      process.env.REACT_APP_DATADOG_RUM_SESSION_SAMPLE_RATE,
    ),
    sessionReplaySampleRate: Number(
      process.env.REACT_APP_DATADOG_RUM_REPLAY_SAMPLE_RATE,
    ),
    trackUserInteractions:
      process.env.REACT_APP_DATADOG_RUM_TRACK_USER_INTERACTIONS === 'true',
    trackResources:
      process.env.REACT_APP_DATADOG_RUM_TRACK_RESOURCES === 'true',
    trackLongTasks:
      process.env.REACT_APP_DATADOG_RUM_TRACKLONG_TASKS === 'true',
    defaultPrivacyLevel:
      process.env.REACT_APP_DATADOG_RUM_DEFAULT_PRIVACY_LEVEL,
    allowedTracingUrls: [
      process.env.REACT_APP_BASE_URL,
      (url) => url.startsWith(process.env.REACT_APP_BASE_URL),
    ],
  });
}

const store = createStore({});

// direct access to redux store.
const { dispatch } = store;

const axiosRetryClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const axiosRefreshInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

axios.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem('token');
    try {
      if (token) {
        config.headers.Authorization = `jwt ${token}`;
      }
      return config;
    } catch (err) {
      console.error('[axios request config error]' + err);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

let authTokenRequest = null;

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    if (error.response && error.response.status) {
      const UNAUTHORIZED = 401;
      const { status, data } = error.response;
      const config = error.config;

      // token 자동 갱신 및 재시도 로직
      if (
        // token 갱신 사용 시에만 실행
        process.env.REACT_APP_USE_REFRESH_TOKEN === 'true' &&
        status === UNAUTHORIZED &&
        !config.url.includes('/testing/refresh_token/') && // token 갱신 요청 시에는 실행하지 않음
        !config.__isRetryRequest // 재시도 플래그가 없을 때만 실행
      ) {
        const refreshToken = sessionStorage.getItem('refreshToken');
        const username = sessionStorage.getItem('username');

        if (data.detail === 'Signature has expired.') {
          // access token 만료 시
          if (!authTokenRequest) {
            // 현재 token 갱신 요청이 실행 중이지 않을 때만 실행
            authTokenRequest = dispatch(
              refreshTokensThunk({
                username,
                refresh_token: refreshToken,
              }),
            );
          }

          try {
            const rtRes = await authTokenRequest; // token 갱신 요청 결과 대기

            if (rtRes.code === 200) {
              // token 갱신 성공 시 기존 요청 재시도
              config.headers.Authorization = `jwt ${rtRes.token}`; // 새로 받은 token으로 헤더 설정
              config.__isRetryRequest = true; // 재시도 플래그 설정
              authTokenRequest = null; // 다음 token 갱신 요청을 위해 초기화
              return axiosRetryClient.request(config); // 원래의 Axios 호출을 재시도(중복 호출을 막기 위해 별개의 client 사용)
            } else {
              console.error('refresh token error', rtRes);
            }
          } catch (refreshError) {
            // pass
            console.error('refresh token error', refreshError);
          }
        }
      }
    }

    // token 갱신 실패 또는 token 갱신 안 할 경우 로그아웃 처리
    if (sessionStorage.getItem('username') !== null) {
      // 현재 시점에서 로그아웃 여부 체크
      // 아직 로그인된 상태면 로그아웃 처리
      await dispatch(
        setLoginThunk({
          username: null,
          token: null,
          expire: null,
          refreshToken: null,
          logged: false,
        }),
      );

      // 로그아웃 처리 후 에러 메시지 출력
      alert(
        "You've been logged out. This may have been caused by another account logged in at the same time. Please use one account at a time. ",
      );
    } // else: 이미 다른 API 호출 등으로 로그아웃된 상태이므로 alert 띄우지 않고 reject 처리만 함

    return Promise.reject(error);
  },
);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <ScrollToTop />
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
reportWebVitals();
