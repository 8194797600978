import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { BiUserCircle } from 'react-icons/bi';
import { CgTimer } from 'react-icons/cg';
import { useInterval } from 'react-use';
import { coreItem } from '0_variables/coreItem';
import {
  refreshLicenseInfo,
  setLoginThunk,
} from '1_reduxs/actions/loginAction';
import { openVersionModal } from '1_reduxs/reducers/modalReducer';
import { AuthCheck } from '2_services/loginApi';
import {
  dashboard as MEDIA_dashboard,
  blueDashboard as MEDIA_blueDashboard,
  upload as MEDIA_upload,
  blueUpload as MEDIA_blueUpload,
  view as MEDIA_view,
  blueView as MEDIA_blueView,
  analysis as MEDIA_analysis,
  blueAnalysis as MEDIA_blueAnalysis,
  setting as MEDIA_setting,
  blueSetting as MEDIA_blueSetting,
  logout as MEDIA_logout,
} from '6_media';
import BrtnxLogo from '6_media/svg/BrtnxLogo';
import { MenuButton } from './components';
import styles from './Menu.module.scss';

export default function Menu() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { product: productName } = useParams();
  const username = sessionStorage.getItem('username');
  const pageName = location.pathname.split('/')[2];
  const productCoreItem = coreItem[productName];
  const { CTN_Mode, Cloud_Mode } = productCoreItem;
  const selectedFileID = useSelector((state) => state.control.selectedFileID);
  const productLicenseState = useSelector(
    (state) => state.license[productName],
  );
  const endDate = productLicenseState.until;
  const remainCounts = productLicenseState.count;

  const menuItems = [
    {
      enabled: true,
      name: 'Dashboard',
      unactiveIcon: MEDIA_dashboard,
      push: `/${productName}/dashboard`,
      activeIcon: MEDIA_blueDashboard,
      callBack: () => undefined,
    },
    {
      enabled: true,
      name: 'Upload',
      unactiveIcon: MEDIA_upload,
      push: `/${productName}/upload`,
      activeIcon: MEDIA_blueUpload,
      callBack: () => undefined,
    },
    {
      enabled: selectedFileID !== null,
      name: 'View',
      unactiveIcon: MEDIA_view,
      push: `/${productName}/view/${selectedFileID}`,
      activeIcon: MEDIA_blueView,
      callBack: () => undefined,
    },
  ];

  if (CTN_Mode === false) {
    menuItems.push(
      {
        enabled: selectedFileID !== null,
        name: 'Analysis',
        unactiveIcon: MEDIA_analysis,
        push: `/${productName}/analysis/${selectedFileID}`,
        activeIcon: MEDIA_blueAnalysis,
        callBack: () => undefined,
      },
      {
        enabled: true,
        name: 'Setting',
        unactiveIcon: MEDIA_setting,
        push: `/${productName}/setting`,
        activeIcon: MEDIA_blueSetting,
        callBack: () => undefined,
      },
    );
  }

  const logoutData = {
    username: null,
    token: null,
    logged: false,
  };

  if (process.env.REACT_APP_USE_REFRESH_TOKEN === 'true') {
    logoutData.refreshToken = null;
    logoutData.expire = null;
  }

  const logoutMenu = {
    enabled: true,
    name: 'Log out',
    unactiveIcon: MEDIA_logout,
    push: '/signin',
    callBack: () => dispatch(setLoginThunk(logoutData)),
  };

  // token validation, license check
  useInterval(() => {
    if (sessionStorage.getItem('token') !== null) {
      AuthCheck();

      // update license Info
      dispatch(refreshLicenseInfo(productName));
    }
  }, 1000 * 30);

  useEffect(() => {
    const { valid, isInitailized } = productLicenseState;

    if (isInitailized && valid === false) {
      // url을 직접 치고 들어왔을때, 라이센스가 없는 경우
      alert('A product license is required to use this service.');
      history.goBack(); // move back
    }
  }, [Cloud_Mode, history, productLicenseState, productName]);

  return (
    <div
      className={`${styles['container']} ${
        pageName === 'report' ? styles.hidden : ''
      }`}
    >
      <div className={`${styles['up-items']}`}>
        <div className={`${styles['logo-container']}`}>
          <div
            className={`${styles['logo-style']}`}
            onClick={() => {
              dispatch(openVersionModal({ isOn: true }));
            }}
          >
            <BrtnxLogo />
          </div>
        </div>

        {menuItems.map((item, idx) => (
          <MenuButton key={`main-menu-${idx}`} item={item} />
        ))}
      </div>

      <div className={`${styles['down-items']}`}>
        <div className={`${styles['license-info']}`}>
          <div>
            <BiUserCircle size={'1vw'} strokeWidth={'0px'} />
            {(() => {
              if (CTN_Mode || remainCounts === 'inf') {
                return <span>{username}</span>;
              } else {
                return <span>{username + ' (' + remainCounts + ')'}</span>;
              }
            })()}
          </div>
          {endDate.trim() !== 'inf' && (
            <div>
              <CgTimer size={'1vw'} strokeWidth={'0px'} />
              <span>{endDate}</span>
            </div>
          )}
        </div>

        <MenuButton item={logoutMenu} />
      </div>
    </div>
  );
}
