import axios from 'axios';
import { axiosRefreshInstance } from 'index';

export function Signin(data) {
  return axios.post('testing/one_user_login/', data);
}

export function RefreshToken(data) {
  if (process.env.REACT_APP_USE_REFRESH_TOKEN !== 'true') {
    return;
  }

  return axiosRefreshInstance.post('testing/refresh_token/', data);
}

export function AuthCheck() {
  return axios.get('testing/auth_test/').catch(function () {
    console.log('auth check error');
  });
}

export function Registration(data) {
  return axios.post('rest-auth/registration/', data);
}

// not used
// export function TokenVerification(data) {
//   return axios.post('api/token/verify/', data);
// }

export function getAuthJWT(data) {
  // return axios.post(IPinUSE+'api/token/', data);
  return axios.post('testing/one_user_login/', data);
}

export function ResetPassword(Auth, data) {
  return axios.post('testing/forgot/', data, { headers: Auth });
}

export function ActivateUser(Auth, data) {
  return axios.post('testing/activate_user/', data, {
    headers: Auth,
  });
}
