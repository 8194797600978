import {unitVolume} from './unitVolume';
export const subRegions = (()=>{
  return {
    amyloid:(()=>{
      const arr = [
        {static: true, primaryKey:  0,  identifier:  0, belongToForeignKey:   0,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"AAL3",            belongTo: "Global",           fullname: "Global",                        varname:{Left:"Global",                               Right:"Global"                              }},
        {static: true, primaryKey:  1,  identifier:  1, belongToForeignKey:   1,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal",                       varname:{Left:"Frontal_L",                            Right:"Frontal_R"                           }},
        {static: true, primaryKey:  2,  identifier:  2, belongToForeignKey:   1,  volumeForeignKey:  0, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Precentral",                    varname:{Left:"Precentral_L",                         Right:"Precentral_R"                        }, weight: {Left:unitVolume["AAL3"]["Precentral"                  ].Left, Right:unitVolume["AAL3"]["Precentral"                  ].Right}},
        {static: true, primaryKey:  3,  identifier:  3, belongToForeignKey:   1,  volumeForeignKey:  1, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Sup",                   varname:{Left:"Frontal_Sup_L",                        Right:"Frontal_Sup_R"                       }, weight: {Left:unitVolume["AAL3"]["Frontal_Sup"                 ].Left, Right:unitVolume["AAL3"]["Frontal_Sup"                 ].Right}},
        {static: true, primaryKey:  4,  identifier:  4, belongToForeignKey:   1,  volumeForeignKey:  2, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Mid",                   varname:{Left:"Frontal_Mid_L",                        Right:"Frontal_Mid_R"                       }, weight: {Left:unitVolume["AAL3"]["Frontal_Mid"                 ].Left, Right:unitVolume["AAL3"]["Frontal_Mid"                 ].Right}},
        {static: true, primaryKey:  5,  identifier:  5, belongToForeignKey:   1,  volumeForeignKey:  3, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Inf Oper",              varname:{Left:"Frontal_Inf_Oper_L",                   Right:"Frontal_Inf_Oper_R"                  }, weight: {Left:unitVolume["AAL3"]["Frontal_Inf_Oper"            ].Left, Right:unitVolume["AAL3"]["Frontal_Inf_Oper"            ].Right}},
        {static: true, primaryKey:  6,  identifier:  6, belongToForeignKey:   1,  volumeForeignKey:  4, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Inf Tri",               varname:{Left:"Frontal_Inf_Tri_L",                    Right:"Frontal_Inf_Tri_R"                   }, weight: {Left:unitVolume["AAL3"]["Frontal_Inf_Tri"             ].Left, Right:unitVolume["AAL3"]["Frontal_Inf_Tri"             ].Right}},
        {static: true, primaryKey:  7,  identifier:  7, belongToForeignKey:   1,  volumeForeignKey:  5, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Inf Orb",               varname:{Left:"Frontal_Inf_Orb_L",                    Right:"Frontal_Inf_Orb_R"                   }, weight: {Left:unitVolume["AAL3"]["Frontal_Inf_Orb"             ].Left, Right:unitVolume["AAL3"]["Frontal_Inf_Orb"             ].Right}},
        {static: true, primaryKey:  8,  identifier:  8, belongToForeignKey:   1,  volumeForeignKey:  6, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Rolandic Oper",                 varname:{Left:"Rolandic_Oper_L",                      Right:"Rolandic_Oper_R"                     }, weight: {Left:unitVolume["AAL3"]["Rolandic_Oper"               ].Left, Right:unitVolume["AAL3"]["Rolandic_Oper"               ].Right}},
        {static: true, primaryKey:  9,  identifier:  9, belongToForeignKey:   1,  volumeForeignKey:  7, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Supp Motor Area",               varname:{Left:"Supp_Motor_Area_L",                    Right:"Supp_Motor_Area_R"                   }, weight: {Left:unitVolume["AAL3"]["Supp_Motor_Area"             ].Left, Right:unitVolume["AAL3"]["Supp_Motor_Area"             ].Right}},
        {static: true, primaryKey: 10,  identifier: 10, belongToForeignKey:   1,  volumeForeignKey:  8, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Olfactory",                     varname:{Left:"Olfactory_L",                          Right:"Olfactory_R"                         }, weight: {Left:unitVolume["AAL3"]["Olfactory"                   ].Left, Right:unitVolume["AAL3"]["Olfactory"                   ].Right}},
        {static: true, primaryKey: 11,  identifier: 11, belongToForeignKey:   1,  volumeForeignKey:  9, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Sup Med",               varname:{Left:"Frontal_Sup_Med_L",                    Right:"Frontal_Sup_Med_R"                   }, weight: {Left:unitVolume["AAL3"]["Frontal_Sup_Med"             ].Left, Right:unitVolume["AAL3"]["Frontal_Sup_Med"             ].Right}},
        {static: true, primaryKey: 12,  identifier: 12, belongToForeignKey:   1,  volumeForeignKey: 10, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Med Orb",               varname:{Left:"Frontal_Med_Orb_L",                    Right:"Frontal_Med_Orb_R"                   }, weight: {Left:unitVolume["AAL3"]["Frontal_Med_Orb"             ].Left, Right:unitVolume["AAL3"]["Frontal_Med_Orb"             ].Right}},
        {static: true, primaryKey: 13,  identifier: 13, belongToForeignKey:   1,  volumeForeignKey: 11, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Rectus",                        varname:{Left:"Rectus_L",                             Right:"Rectus_R"                            }, weight: {Left:unitVolume["AAL3"]["Rectus"                      ].Left, Right:unitVolume["AAL3"]["Rectus"                      ].Right}},
        {static: true, primaryKey: 14,  identifier: 14, belongToForeignKey:   1,  volumeForeignKey: 12, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "OFCmed",                        varname:{Left:"OFCmed_L",                             Right:"OFCmed_R"                            }, weight: {Left:unitVolume["AAL3"]["OFCmed"                      ].Left, Right:unitVolume["AAL3"]["OFCmed"                      ].Right}},
        {static: true, primaryKey: 15,  identifier: 15, belongToForeignKey:   1,  volumeForeignKey: 13, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "OFCant",                        varname:{Left:"OFCant_L",                             Right:"OFCant_R"                            }, weight: {Left:unitVolume["AAL3"]["OFCant"                      ].Left, Right:unitVolume["AAL3"]["OFCant"                      ].Right}},
        {static: true, primaryKey: 16,  identifier: 16, belongToForeignKey:   1,  volumeForeignKey: 14, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "OFCpost",                       varname:{Left:"OFCpost_L",                            Right:"OFCpost_R"                           }, weight: {Left:unitVolume["AAL3"]["OFCpost"                     ].Left, Right:unitVolume["AAL3"]["OFCpost"                     ].Right}},
        {static: true, primaryKey: 17,  identifier: 17, belongToForeignKey:   1,  volumeForeignKey: 15, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "OFClat",                        varname:{Left:"OFClat_L",                             Right:"OFClat_R"                            }, weight: {Left:unitVolume["AAL3"]["OFClat"                      ].Left, Right:unitVolume["AAL3"]["OFClat"                      ].Right}},
        {static: true, primaryKey: 18,  identifier: 18, belongToForeignKey:   1,  volumeForeignKey: 16, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Insula",                        varname:{Left:"Insula_L",                             Right:"Insula_R"                            }, weight: {Left:unitVolume["AAL3"]["Insula"                      ].Left, Right:unitVolume["AAL3"]["Insula"                      ].Right}},
        {static: true, primaryKey: 19,  identifier: 19, belongToForeignKey:   1,  volumeForeignKey: 17, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Cingulate Ant Sub",             varname:{Left:"Cingulate_Ant_Sub_L",                  Right:"Cingulate_Ant_Sub_R"                 }, weight: {Left:unitVolume["AAL3"]["Cingulate_Ant_Sub"           ].Left, Right:unitVolume["AAL3"]["Cingulate_Ant_Sub"           ].Right}},
        {static: true, primaryKey: 20,  identifier: 20, belongToForeignKey:   1,  volumeForeignKey: 18, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Cingulate Ant Pre",             varname:{Left:"Cingulate_Ant_Pre_L",                  Right:"Cingulate_Ant_Pre_R"                 }, weight: {Left:unitVolume["AAL3"]["Cingulate_Ant_Pre"           ].Left, Right:unitVolume["AAL3"]["Cingulate_Ant_Pre"           ].Right}},
        {static: true, primaryKey: 21,  identifier: 21, belongToForeignKey:   1,  volumeForeignKey: 19, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Cingulate Ant Sup",             varname:{Left:"Cingulate_Ant_Sup_L",                  Right:"Cingulate_Ant_Sup_R"                 }, weight: {Left:unitVolume["AAL3"]["Cingulate_Ant_Sup"           ].Left, Right:unitVolume["AAL3"]["Cingulate_Ant_Sup"           ].Right}},
        {static: true, primaryKey: 22,  identifier: 22, belongToForeignKey:   1,  volumeForeignKey: 20, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Cingulate Mid",                 varname:{Left:"Cingulate_Mid_L",                      Right:"Cingulate_Mid_R"                     }, weight: {Left:unitVolume["AAL3"]["Cingulate_Mid"               ].Left, Right:unitVolume["AAL3"]["Cingulate_Mid"               ].Right}},
        {static: true, primaryKey: 23,  identifier: 23, belongToForeignKey:  23,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"AAL3",            belongTo: "PCC-Precuneus",    fullname: "PCC-Precuneus",                 varname:{Left:"PCC_L",                                Right:"PCC_R"                               }},
        {static: true, primaryKey: 24,  identifier: 24, belongToForeignKey:  23,  volumeForeignKey: 21, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "PCC-Precuneus",    fullname: "Cingulate Post",                varname:{Left:"Cingulate_Post_L",                     Right:"Cingulate_Post_R"                    }, weight: {Left:unitVolume["AAL3"]["Cingulate_Post"              ].Left, Right:unitVolume["AAL3"]["Cingulate_Post"              ].Right}},
        {static: true, primaryKey: 25,  identifier: 25, belongToForeignKey:  23,  volumeForeignKey: 22, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "PCC-Precuneus",    fullname: "Precuneus",                     varname:{Left:"Precuneus_L",                          Right:"Precuneus_R"                         }, weight: {Left:unitVolume["AAL3"]["Precuneus"                   ].Left, Right:unitVolume["AAL3"]["Precuneus"                   ].Right}},
        {static: true, primaryKey: 26,  identifier: 26, belongToForeignKey:  26,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Lateral temporal",              varname:{Left:"Lateral_temporal_L",                   Right:"Lateral_temporal_R"                  }},                      
        {static: true, primaryKey: 27,  identifier: 27, belongToForeignKey:  26,  volumeForeignKey: 23, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Heschl",                        varname:{Left:"Heschl_L",                             Right:"Heschl_R"                            }, weight: {Left:unitVolume["AAL3"]["Heschl"                      ].Left, Right:unitVolume["AAL3"]["Heschl"                      ].Right}},
        {static: true, primaryKey: 28,  identifier: 28, belongToForeignKey:  26,  volumeForeignKey: 24, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Temporal Sup",                  varname:{Left:"Temporal_Sup_L",                       Right:"Temporal_Sup_R"                      }, weight: {Left:unitVolume["AAL3"]["Temporal_Sup"                ].Left, Right:unitVolume["AAL3"]["Temporal_Sup"                ].Right}},
        {static: true, primaryKey: 29,  identifier: 29, belongToForeignKey:  26,  volumeForeignKey: 25, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Temporal Pole Sup",             varname:{Left:"Temporal_Pole_Sup_L",                  Right:"Temporal_Pole_Sup_R"                 }, weight: {Left:unitVolume["AAL3"]["Temporal_Pole_Sup"           ].Left, Right:unitVolume["AAL3"]["Temporal_Pole_Sup"           ].Right}},
        {static: true, primaryKey: 30,  identifier: 30, belongToForeignKey:  26,  volumeForeignKey: 26, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Temporal Mid",                  varname:{Left:"Temporal_Mid_L",                       Right:"Temporal_Mid_R"                      }, weight: {Left:unitVolume["AAL3"]["Temporal_Mid"                ].Left, Right:unitVolume["AAL3"]["Temporal_Mid"                ].Right}},
        {static: true, primaryKey: 31,  identifier: 31, belongToForeignKey:  26,  volumeForeignKey: 27, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Temporal Pole Mid",             varname:{Left:"Temporal_Pole_Mid_L",                  Right:"Temporal_Pole_Mid_R"                 }, weight: {Left:unitVolume["AAL3"]["Temporal_Pole_Mid"           ].Left, Right:unitVolume["AAL3"]["Temporal_Pole_Mid"           ].Right}},
        {static: true, primaryKey: 32,  identifier: 32, belongToForeignKey:  26,  volumeForeignKey: 28, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Temporal Inf",                  varname:{Left:"Temporal_Inf_L",                       Right:"Temporal_Inf_R"                      }, weight: {Left:unitVolume["AAL3"]["Temporal_Inf"                ].Left, Right:unitVolume["AAL3"]["Temporal_Inf"                ].Right}},
        {static: true, primaryKey: 33,  identifier: 33, belongToForeignKey:  33,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Lateral parietal",              varname:{Left:"Lateral_parietal_L",                   Right:"Lateral_parietal_R"                  }},                      
        {static: true, primaryKey: 34,  identifier: 34, belongToForeignKey:  33,  volumeForeignKey: 29, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Postcentral",                   varname:{Left:"Postcentral_L",                        Right:"Postcentral_R"                       }, weight: {Left:unitVolume["AAL3"]["Postcentral"                 ].Left, Right:unitVolume["AAL3"]["Postcentral"                 ].Right}},
        {static: true, primaryKey: 35,  identifier: 35, belongToForeignKey:  33,  volumeForeignKey: 30, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Parietal Sup",                  varname:{Left:"Parietal_Sup_L",                       Right:"Parietal_Sup_R"                      }, weight: {Left:unitVolume["AAL3"]["Parietal_Sup"                ].Left, Right:unitVolume["AAL3"]["Parietal_Sup"                ].Right}},
        {static: true, primaryKey: 36,  identifier: 36, belongToForeignKey:  33,  volumeForeignKey: 31, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Parietal Inf",                  varname:{Left:"Parietal_Inf_L",                       Right:"Parietal_Inf_R"                      }, weight: {Left:unitVolume["AAL3"]["Parietal_Inf"                ].Left, Right:unitVolume["AAL3"]["Parietal_Inf"                ].Right}},
        {static: true, primaryKey: 37,  identifier: 37, belongToForeignKey:  33,  volumeForeignKey: 32, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Supramarginal",                 varname:{Left:"Supramarginal_L",                      Right:"Supramarginal_R"                     }, weight: {Left:unitVolume["AAL3"]["Supramarginal"               ].Left, Right:unitVolume["AAL3"]["Supramarginal"               ].Right}},
        {static: true, primaryKey: 38,  identifier: 38, belongToForeignKey:  33,  volumeForeignKey: 33, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Angular",                       varname:{Left:"Angular_L",                            Right:"Angular_R"                           }, weight: {Left:unitVolume["AAL3"]["Angular"                     ].Left, Right:unitVolume["AAL3"]["Angular"                     ].Right}},
        {static: true, primaryKey: 39,  identifier: 39, belongToForeignKey:  33,  volumeForeignKey: 34, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Paracentral Lobule",            varname:{Left:"Paracentral_Lobule_L",                 Right:"Paracentral_Lobule_R"                }, weight: {Left:unitVolume["AAL3"]["Paracentral_Lobule"          ].Left, Right:unitVolume["AAL3"]["Paracentral_Lobule"          ].Right}},
        {static: true, primaryKey: 40,  identifier: 40, belongToForeignKey:  40,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"AAL3",            belongTo: "Medial temporal",  fullname: "Medial temporal",               varname:{Left:"Medial_temporal_L",                    Right:"Medial_temporal_R"                   }},                      
        {static: true, primaryKey: 41,  identifier: 41, belongToForeignKey:  40,  volumeForeignKey: 35, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Medial temporal",  fullname: "Hippocampus",                   varname:{Left:"Hippocampus_L",                        Right:"Hippocampus_R"                       }, weight: {Left:unitVolume["AAL3"]["Hippocampus"                 ].Left, Right:unitVolume["AAL3"]["Hippocampus"                 ].Right}},
        {static: true, primaryKey: 42,  identifier: 42, belongToForeignKey:  40,  volumeForeignKey: 36, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Medial temporal",  fullname: "Parahippocampal",               varname:{Left:"Parahippocampal_L",                    Right:"Parahippocampal_R"                   }, weight: {Left:unitVolume["AAL3"]["Parahippocampal"             ].Left, Right:unitVolume["AAL3"]["Parahippocampal"             ].Right}},
        {static: true, primaryKey: 43,  identifier: 43, belongToForeignKey:  40,  volumeForeignKey: 37, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Medial temporal",  fullname: "Amygdala",                      varname:{Left:"Amygdala_L",                           Right:"Amygdala_R"                          }, weight: {Left:unitVolume["AAL3"]["Amygdala"                    ].Left, Right:unitVolume["AAL3"]["Amygdala"                    ].Right}},
        {static: true, primaryKey: 44,  identifier: 44, belongToForeignKey:  44,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"AAL3",            belongTo: "Occipital",        fullname: "Occipital",                     varname:{Left:"Occipital_L",                          Right:"Occipital_R"                         }},                      
        {static: true, primaryKey: 45,  identifier: 45, belongToForeignKey:  44,  volumeForeignKey: 38, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Occipital",        fullname: "Calcarine",                     varname:{Left:"Calcarine_L",                          Right:"Calcarine_R"                         }, weight: {Left:unitVolume["AAL3"]["Calcarine"                   ].Left, Right:unitVolume["AAL3"]["Calcarine"                   ].Right}},
        {static: true, primaryKey: 46,  identifier: 46, belongToForeignKey:  44,  volumeForeignKey: 39, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Occipital",        fullname: "Cuneus",                        varname:{Left:"Cuneus_L",                             Right:"Cuneus_R"                            }, weight: {Left:unitVolume["AAL3"]["Cuneus"                      ].Left, Right:unitVolume["AAL3"]["Cuneus"                      ].Right}},
        {static: true, primaryKey: 47,  identifier: 47, belongToForeignKey:  44,  volumeForeignKey: 40, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Occipital",        fullname: "Lingual",                       varname:{Left:"Lingual_L",                            Right:"Lingual_R"                           }, weight: {Left:unitVolume["AAL3"]["Lingual"                     ].Left, Right:unitVolume["AAL3"]["Lingual"                     ].Right}},
        {static: true, primaryKey: 48,  identifier: 48, belongToForeignKey:  44,  volumeForeignKey: 41, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Occipital",        fullname: "Occipital Sup",                 varname:{Left:"Occipital_Sup_L",                      Right:"Occipital_Sup_R"                     }, weight: {Left:unitVolume["AAL3"]["Occipital_Sup"               ].Left, Right:unitVolume["AAL3"]["Occipital_Sup"               ].Right}},
        {static: true, primaryKey: 49,  identifier: 49, belongToForeignKey:  44,  volumeForeignKey: 42, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Occipital",        fullname: "Occipital Mid",                 varname:{Left:"Occipital_Mid_L",                      Right:"Occipital_Mid_R"                     }, weight: {Left:unitVolume["AAL3"]["Occipital_Mid"               ].Left, Right:unitVolume["AAL3"]["Occipital_Mid"               ].Right}},
        {static: true, primaryKey: 50,  identifier: 50, belongToForeignKey:  44,  volumeForeignKey: 43, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Occipital",        fullname: "Occipital Inf",                 varname:{Left:"Occipital_Inf_L",                      Right:"Occipital_Inf_R"                     }, weight: {Left:unitVolume["AAL3"]["Occipital_Inf"               ].Left, Right:unitVolume["AAL3"]["Occipital_Inf"               ].Right}},
        {static: true, primaryKey: 51,  identifier: 51, belongToForeignKey:  44,  volumeForeignKey: 44, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Occipital",        fullname: "Fusiform",                      varname:{Left:"Fusiform_L",                           Right:"Fusiform_R"                          }, weight: {Left:unitVolume["AAL3"]["Fusiform"                    ].Left, Right:unitVolume["AAL3"]["Fusiform"                    ].Right}},
        {static: true, primaryKey: 52,  identifier: 52, belongToForeignKey:  52,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"AAL3",            belongTo: "Basal ganglia",    fullname: "Basal ganglia",                 varname:{Left:"Basal_ganglia_L",                      Right:"Basal_ganglia_R"                     }},                      
        {static: true, primaryKey: 53,  identifier: 53, belongToForeignKey:  52,  volumeForeignKey: 45, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Basal ganglia",    fullname: "Caudate",                       varname:{Left:"Caudate_L",                            Right:"Caudate_R"                           }, weight: {Left:unitVolume["AAL3"]["Caudate"                     ].Left, Right:unitVolume["AAL3"]["Caudate"                     ].Right}},
        {static: true, primaryKey: 54,  identifier: 54, belongToForeignKey:  52,  volumeForeignKey: 46, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Basal ganglia",    fullname: "Putamen",                       varname:{Left:"Putamen_L",                            Right:"Putamen_R"                           }, weight: {Left:unitVolume["AAL3"]["Putamen"                     ].Left, Right:unitVolume["AAL3"]["Putamen"                     ].Right}},
        {static: true, primaryKey: 55,  identifier: 55, belongToForeignKey:  52,  volumeForeignKey: 47, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Basal ganglia",    fullname: "Pallidum",                      varname:{Left:"Pallidum_L",                           Right:"Pallidum_R"                          }, weight: {Left:unitVolume["AAL3"]["Pallidum"                    ].Left, Right:unitVolume["AAL3"]["Pallidum"                    ].Right}},
        {static: true, primaryKey: 56,  identifier: 56, belongToForeignKey:  52,  volumeForeignKey: 48, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Basal ganglia",    fullname: "Thalamus",                      varname:{Left:"Thalamus_L",                           Right:"Thalamus_R"                          }, weight: {Left:unitVolume["AAL3"]["Thalamus"                    ].Left, Right:unitVolume["AAL3"]["Thalamus"                    ].Right}},
        {static: true, primaryKey: 57,  identifier: 57, belongToForeignKey:  52,  volumeForeignKey: 49, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Basal ganglia",    fullname: "N Acc",                         varname:{Left:"N_Acc_L",                              Right:"N_Acc_R"                             }, weight: {Left:unitVolume["AAL3"]["N_Acc"                       ].Left, Right:unitVolume["AAL3"]["N_Acc"                       ].Right}},
        {static: true, primaryKey: 58,  identifier: 58, belongToForeignKey:  58,  volumeForeignKey: -1, level: 0, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Brainstem",        fullname: "Brainstem",                     varname:{Left:"Brainstem_L",                          Right:"Brainstem_R"                         }},
        {static: true, primaryKey: 59,  identifier: 59, belongToForeignKey:  59,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Global",           fullname: "Global",                        varname:{Left:"HO_Global",                            Right:"HO_Global"                           }},
        {static: true, primaryKey: 60,  identifier: 60, belongToForeignKey:  60,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal",                       varname:{Left:"HO_Frontal_L",                         Right:"HO_Frontal_R"                        }},
        {static: true, primaryKey: 61,  identifier: 61, belongToForeignKey:  60,  volumeForeignKey: 50, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Pole",                  varname:{Left:"HO_Frontal_Pole_L",                    Right:"HO_Frontal_Pole_R"                   }, weight: {Left:unitVolume["HO"]["Frontal_Pole"                  ].Left, Right:unitVolume["HO"]["Frontal_Pole"                  ].Right}},
        {static: true, primaryKey: 62,  identifier: 62, belongToForeignKey:  60,  volumeForeignKey: 51, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Insula",                        varname:{Left:"HO_Insula_L",                          Right:"HO_Insula_R"                         }, weight: {Left:unitVolume["HO"]["Insula"                        ].Left, Right:unitVolume["HO"]["Insula"                        ].Right}},
        {static: true, primaryKey: 63,  identifier: 63, belongToForeignKey:  60,  volumeForeignKey: 52, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Sup",                   varname:{Left:"HO_Frontal_Sup_L",                     Right:"HO_Frontal_Sup_R"                    }, weight: {Left:unitVolume["HO"]["Frontal_Sup"                   ].Left, Right:unitVolume["HO"]["Frontal_Sup"                   ].Right}},
        {static: true, primaryKey: 64,  identifier: 64, belongToForeignKey:  60,  volumeForeignKey: 53, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Mid",                   varname:{Left:"HO_Frontal_Mid_L",                     Right:"HO_Frontal_Mid_R"                    }, weight: {Left:unitVolume["HO"]["Frontal_Mid"                   ].Left, Right:unitVolume["HO"]["Frontal_Mid"                   ].Right}},
        {static: true, primaryKey: 65,  identifier: 65, belongToForeignKey:  60,  volumeForeignKey: 54, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Inf Tri",               varname:{Left:"HO_Frontal_Inf_Tri_L",                 Right:"HO_Frontal_Inf_Tri_R"                }, weight: {Left:unitVolume["HO"]["Frontal_Inf_Tri"               ].Left, Right:unitVolume["HO"]["Frontal_Inf_Tri"               ].Right}},
        {static: true, primaryKey: 66,  identifier: 66, belongToForeignKey:  60,  volumeForeignKey: 55, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Inf Oper",              varname:{Left:"HO_Frontal_Inf_Oper_L",                Right:"HO_Frontal_Inf_Oper_R"               }, weight: {Left:unitVolume["HO"]["Frontal_Inf_Oper"              ].Left, Right:unitVolume["HO"]["Frontal_Inf_Oper"              ].Right}},
        {static: true, primaryKey: 67,  identifier: 67, belongToForeignKey:  60,  volumeForeignKey: 56, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Precentral",                    varname:{Left:"HO_Precentral_L",                      Right:"HO_Precentral_R"                     }, weight: {Left:unitVolume["HO"]["Precentral"                    ].Left, Right:unitVolume["HO"]["Precentral"                    ].Right}},
        {static: true, primaryKey: 68,  identifier: 68, belongToForeignKey:  60,  volumeForeignKey: 57, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Med",                   varname:{Left:"HO_Frontal_Med_L",                     Right:"HO_Frontal_Med_R"                    }, weight: {Left:unitVolume["HO"]["Frontal_Med"                   ].Left, Right:unitVolume["HO"]["Frontal_Med"                   ].Right}},
        {static: true, primaryKey: 69,  identifier: 69, belongToForeignKey:  60,  volumeForeignKey: 58, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Supp Motor Area",               varname:{Left:"HO_Supp_Motor_Area_L",                 Right:"HO_Supp_Motor_Area_R"                }, weight: {Left:unitVolume["HO"]["Supp_Motor_Area"               ].Left, Right:unitVolume["HO"]["Supp_Motor_Area"               ].Right}},
        {static: true, primaryKey: 71,  identifier: 71, belongToForeignKey:  60,  volumeForeignKey: 59, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Cingulate Ant",                 varname:{Left:"HO_Cingulate_Ant_L",                   Right:"HO_Cingulate_Ant_R"                  }, weight: {Left:unitVolume["HO"]["Cingulate_Ant"                 ].Left, Right:unitVolume["HO"]["Cingulate_Ant"                 ].Right}},
        {static: true, primaryKey: 71,  identifier: 71, belongToForeignKey:  60,  volumeForeignKey: 60, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Orb",                   varname:{Left:"HO_Frontal_Orb_L",                     Right:"HO_Frontal_Orb_R"                    }, weight: {Left:unitVolume["HO"]["Frontal_Orb"                   ].Left, Right:unitVolume["HO"]["Frontal_Orb"                   ].Right}},
        {static: true, primaryKey: 72,  identifier: 72, belongToForeignKey:  60,  volumeForeignKey: 61, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Oper",                  varname:{Left:"HO_Frontal_Oper_L",                    Right:"HO_Frontal_Oper_R"                   }, weight: {Left:unitVolume["HO"]["Frontal_Oper"                  ].Left, Right:unitVolume["HO"]["Frontal_Oper"                  ].Right}},
        {static: true, primaryKey: 73,  identifier: 73, belongToForeignKey:  60,  volumeForeignKey: 62, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Central Oper",                  varname:{Left:"HO_Central_Oper_L",                    Right:"HO_Central_Oper_R"                   }, weight: {Left:unitVolume["HO"]["Central_Oper"                  ].Left, Right:unitVolume["HO"]["Central_Oper"                  ].Right}},
        {static: true, primaryKey: 74,  identifier: 74, belongToForeignKey:  60,  volumeForeignKey: 63, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Subcallosal",                   varname:{Left:"HO_Subcallosal_L",                     Right:"HO_Subcallosal_R"                    }, weight: {Left:unitVolume["HO"]["Subcallosal"                   ].Left, Right:unitVolume["HO"]["Subcallosal"                   ].Right}},
        {static: true, primaryKey: 75,  identifier: 75, belongToForeignKey:  60,  volumeForeignKey: 64, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Paracingulate",                 varname:{Left:"HO_Paracingulate_L",                   Right:"HO_Paracingulate_R"                  }, weight: {Left:unitVolume["HO"]["Paracingulate"                 ].Left, Right:unitVolume["HO"]["Paracingulate"                 ].Right}},
        {static: true, primaryKey: 76,  identifier: 76, belongToForeignKey:  76,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Retrosplenial",    fullname: "Retrosplenial",                 varname:{Left:"HO_PCC_L",                             Right:"HO_PCC_R"                            }},                      
        {static: true, primaryKey: 77,  identifier: 77, belongToForeignKey:  76,  volumeForeignKey: 65, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Retrosplenial",    fullname: "Cingulate Post",                varname:{Left:"HO_Cingulate_Post_L",                  Right:"HO_Cingulate_Post_R"                 }, weight: {Left:unitVolume["HO"]["Cingulate_Post"                ].Left, Right:unitVolume["HO"]["Cingulate_Post"                ].Right}},
        {static: true, primaryKey: 78,  identifier: 78, belongToForeignKey:  76,  volumeForeignKey: 66, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Retrosplenial",    fullname: "Precuneus",                     varname:{Left:"HO_Precuneus_L",                       Right:"HO_Precuneus_R"                      }, weight: {Left:unitVolume["HO"]["Precuneus"                     ].Left, Right:unitVolume["HO"]["Precuneus"                     ].Right}},
        {static: true, primaryKey: 79,  identifier: 79, belongToForeignKey:  79,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Lateral temporal",              varname:{Left:"HO_Lateral_temporal_L",                Right:"HO_Lateral_temporal_R"               }},
        {static: true, primaryKey: 80,  identifier: 80, belongToForeignKey:  79,  volumeForeignKey: 67, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Pole",                 varname:{Left:"HO_Temporal_Pole_L",                   Right:"HO_Temporal_Pole_R"                  }, weight: {Left:unitVolume["HO"]["Temporal_Pole"                 ].Left, Right:unitVolume["HO"]["Temporal_Pole"                 ].Right}},
        {static: true, primaryKey: 81,  identifier: 81, belongToForeignKey:  79,  volumeForeignKey: 68, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Sup Ant",              varname:{Left:"HO_Temporal_Sup_Ant_L",                Right:"HO_Temporal_Sup_Ant_R"               }, weight: {Left:unitVolume["HO"]["Temporal_Sup_Ant"              ].Left, Right:unitVolume["HO"]["Temporal_Sup_Ant"              ].Right}},
        {static: true, primaryKey: 82,  identifier: 82, belongToForeignKey:  79,  volumeForeignKey: 69, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Sup Post",             varname:{Left:"HO_Temporal_Sup_Post_L",               Right:"HO_Temporal_Sup_Post_R"              }, weight: {Left:unitVolume["HO"]["Temporal_Sup_Post"             ].Left, Right:unitVolume["HO"]["Temporal_Sup_Post"             ].Right}},
        {static: true, primaryKey: 83,  identifier: 83, belongToForeignKey:  79,  volumeForeignKey: 70, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Mid Ant",              varname:{Left:"HO_Temporal_Mid_Ant_L",                Right:"HO_Temporal_Mid_Ant_R"               }, weight: {Left:unitVolume["HO"]["Temporal_Mid_Ant"              ].Left, Right:unitVolume["HO"]["Temporal_Mid_Ant"              ].Right}},
        {static: true, primaryKey: 84,  identifier: 84, belongToForeignKey:  79,  volumeForeignKey: 71, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Mid Post",             varname:{Left:"HO_Temporal_Mid_Post_L",               Right:"HO_Temporal_Mid_Post_R"              }, weight: {Left:unitVolume["HO"]["Temporal_Mid_Post"             ].Left, Right:unitVolume["HO"]["Temporal_Mid_Post"             ].Right}},
        {static: true, primaryKey: 85,  identifier: 85, belongToForeignKey:  79,  volumeForeignKey: 72, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Mid Temporooccipital", varname:{Left:"HO_Temporal_Mid_Temporooccipital_L",   Right:"HO_Temporal_Mid_Temporooccipital_R"  }, weight: {Left:unitVolume["HO"]["Temporal_Mid_Temporooccipital" ].Left, Right:unitVolume["HO"]["Temporal_Mid_Temporooccipital" ].Right}},
        {static: true, primaryKey: 86,  identifier: 86, belongToForeignKey:  79,  volumeForeignKey: 73, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Inf Ant",              varname:{Left:"HO_Temporal_Inf_Ant_L",                Right:"HO_Temporal_Inf_Ant_R"               }, weight: {Left:unitVolume["HO"]["Temporal_Inf_Ant"              ].Left, Right:unitVolume["HO"]["Temporal_Inf_Ant"              ].Right}},
        {static: true, primaryKey: 87,  identifier: 87, belongToForeignKey:  79,  volumeForeignKey: 74, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Inf Post",             varname:{Left:"HO_Temporal_Inf_Post_L",               Right:"HO_Temporal_Inf_Post_R"              }, weight: {Left:unitVolume["HO"]["Temporal_Inf_Post"             ].Left, Right:unitVolume["HO"]["Temporal_Inf_Post"             ].Right}},
        {static: true, primaryKey: 88,  identifier: 88, belongToForeignKey:  79,  volumeForeignKey: 75, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Inf Temporooccipital", varname:{Left:"HO_Temporal_Inf_Temporooccipital_L",   Right:"HO_Temporal_Inf_Temporooccipital_R"  }, weight: {Left:unitVolume["HO"]["Temporal_Inf_Temporooccipital" ].Left, Right:unitVolume["HO"]["Temporal_Inf_Temporooccipital" ].Right}},
        {static: true, primaryKey: 89,  identifier: 89, belongToForeignKey:  79,  volumeForeignKey: 76, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Planum Polare",                 varname:{Left:"HO_Planum_Polare_L",                   Right:"HO_Planum_Polare_R"                  }, weight: {Left:unitVolume["HO"]["Planum_Polare"                 ].Left, Right:unitVolume["HO"]["Planum_Polare"                 ].Right}},
        {static: true, primaryKey: 90,  identifier: 90, belongToForeignKey:  79,  volumeForeignKey: 77, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Heschl",                        varname:{Left:"HO_Heschl_L",                          Right:"HO_Heschl_R"                         }, weight: {Left:unitVolume["HO"]["Heschl"                        ].Left, Right:unitVolume["HO"]["Heschl"                        ].Right}},
        {static: true, primaryKey: 91,  identifier: 91, belongToForeignKey:  79,  volumeForeignKey: 78, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Planum Temporale",              varname:{Left:"HO_Planum_Temporale_L",                Right:"HO_Planum_Temporale_R"               }, weight: {Left:unitVolume["HO"]["Planum_Temporale"              ].Left, Right:unitVolume["HO"]["Planum_Temporale"              ].Right}},
        {static: true, primaryKey: 92,  identifier: 92, belongToForeignKey:  79,  volumeForeignKey: 79, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal fusiform Ant",         varname:{Left:"HO_Temporal_fusiform_Ant_L",           Right:"HO_Temporal_fusiform_Ant_R"          }, weight: {Left:unitVolume["HO"]["Temporal_fusiform_Ant"         ].Left, Right:unitVolume["HO"]["Temporal_fusiform_Ant"         ].Right}},
        {static: true, primaryKey: 93,  identifier: 93, belongToForeignKey:  79,  volumeForeignKey: 80, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal fusiform Post",        varname:{Left:"HO_Temporal_fusiform_Post_L",          Right:"HO_Temporal_fusiform_Post_R"         }, weight: {Left:unitVolume["HO"]["Temporal_fusiform_Post"        ].Left, Right:unitVolume["HO"]["Temporal_fusiform_Post"        ].Right}},
        {static: true, primaryKey: 94,  identifier: 94, belongToForeignKey:  79,  volumeForeignKey: 81, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Occipital Fusiform",   varname:{Left:"HO_Temporal_Occipital_Fusiform_L",     Right:"HO_Temporal_Occipital_Fusiform_R"    }, weight: {Left:unitVolume["HO"]["Temporal_Occipital_Fusiform"   ].Left, Right:unitVolume["HO"]["Temporal_Occipital_Fusiform"   ].Right}},
        {static: true, primaryKey: 95,  identifier: 95, belongToForeignKey:  95,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Lateral parietal",              varname:{Left:"HO_Lateral_parietal_L",                Right:"HO_Lateral_parietal_R"               }},                    
        {static: true, primaryKey: 96,  identifier: 96, belongToForeignKey:  95,  volumeForeignKey: 82, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Postcentral",                   varname:{Left:"HO_Postcentral_L",                     Right:"HO_Postcentral_R"                    }, weight: {Left:unitVolume["HO"]["Postcentral"                   ].Left, Right:unitVolume["HO"]["Postcentral"                   ].Right}},
        {static: true, primaryKey: 97,  identifier: 97, belongToForeignKey:  95,  volumeForeignKey: 83, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Parietal Sup Lobule",           varname:{Left:"HO_Parietal_Sup_Lobule_L",             Right:"HO_Parietal_Sup_Lobule_R"            }, weight: {Left:unitVolume["HO"]["Parietal_Sup_Lobule"           ].Left, Right:unitVolume["HO"]["Parietal_Sup_Lobule"           ].Right}},
        {static: true, primaryKey: 98,  identifier: 98, belongToForeignKey:  95,  volumeForeignKey: 84, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Supramarginal Ant",             varname:{Left:"HO_Supramarginal_Ant_L",               Right:"HO_Supramarginal_Ant_R"              }, weight: {Left:unitVolume["HO"]["Supramarginal_Ant"             ].Left, Right:unitVolume["HO"]["Supramarginal_Ant"             ].Right}},
        {static: true, primaryKey: 99,  identifier: 99, belongToForeignKey:  95,  volumeForeignKey: 85, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Supramarginal Post",            varname:{Left:"HO_Supramarginal_Post_L",              Right:"HO_Supramarginal_Post_R"             }, weight: {Left:unitVolume["HO"]["Supramarginal_Post"            ].Left, Right:unitVolume["HO"]["Supramarginal_Post"            ].Right}},
        {static: true, primaryKey:100,  identifier:100, belongToForeignKey:  95,  volumeForeignKey: 86, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Angular",                       varname:{Left:"HO_Angular_L",                         Right:"HO_Angular_R"                        }, weight: {Left:unitVolume["HO"]["Angular"                       ].Left, Right:unitVolume["HO"]["Angular"                       ].Right}},
        {static: true, primaryKey:101,  identifier:101, belongToForeignKey:  95,  volumeForeignKey: 87, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Parietal Oper",                 varname:{Left:"HO_Parietal_Oper_L",                   Right:"HO_Parietal_Oper_R"                  }, weight: {Left:unitVolume["HO"]["Parietal_Oper"                 ].Left, Right:unitVolume["HO"]["Parietal_Oper"                 ].Right}},
        {static: true, primaryKey:102,  identifier:102, belongToForeignKey: 102,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Medial temporal",  fullname: "Medial temporal",               varname:{Left:"HO_Medial_temporal_L",                 Right:"HO_Medial_temporal_R"                }},                    
        {static: true, primaryKey:103,  identifier:103, belongToForeignKey: 102,  volumeForeignKey: 88, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Medial temporal",  fullname: "Parahippocampal Ant",           varname:{Left:"HO_Parahippocampal_Ant_L",             Right:"HO_Parahippocampal_Ant_R"            }, weight: {Left:unitVolume["HO"]["Parahippocampal_Ant"           ].Left, Right:unitVolume["HO"]["Parahippocampal_Ant"           ].Right}},
        {static: true, primaryKey:104,  identifier:104, belongToForeignKey: 102,  volumeForeignKey: 89, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Medial temporal",  fullname: "Parahippocampal Post",          varname:{Left:"HO_Parahippocampal_Post_L",            Right:"HO_Parahippocampal_Post_R"           }, weight: {Left:unitVolume["HO"]["Parahippocampal_Post"          ].Left, Right:unitVolume["HO"]["Parahippocampal_Post"          ].Right}},
        {static: true, primaryKey:105,  identifier:105, belongToForeignKey: 102,  volumeForeignKey: 90, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Medial temporal",  fullname: "Hippocampus",                   varname:{Left:"HO_Hippocampus_L",                     Right:"HO_Hippocampus_R"                    }, weight: {Left:unitVolume["HO"]["Hippocampus"                   ].Left, Right:unitVolume["HO"]["Hippocampus"                   ].Right}},
        {static: true, primaryKey:106,  identifier:106, belongToForeignKey: 102,  volumeForeignKey: 91, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Medial temporal",  fullname: "Amygdala",                      varname:{Left:"HO_Amygdala_L",                        Right:"HO_Amygdala_R"                       }, weight: {Left:unitVolume["HO"]["Amygdala"                      ].Left, Right:unitVolume["HO"]["Amygdala"                      ].Right}},
        {static: true, primaryKey:107,  identifier:107, belongToForeignKey: 107,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Occipital",                     varname:{Left:"HO_Occipital_L",                       Right:"HO_Occipital_R"                      }},                    
        {static: true, primaryKey:108,  identifier:108, belongToForeignKey: 107,  volumeForeignKey: 92, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Occipital Lat_Sup",             varname:{Left:"HO_Occipital_Lat_Sup_L",               Right:"HO_Occipital_Lat_Sup_R"              }, weight: {Left:unitVolume["HO"]["Occipital_Lat_Sup"             ].Left, Right:unitVolume["HO"]["Occipital_Lat_Sup"             ].Right}},
        {static: true, primaryKey:109,  identifier:109, belongToForeignKey: 107,  volumeForeignKey: 93, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Occipital Lat_Inf",             varname:{Left:"HO_Occipital_Lat_Inf_L",               Right:"HO_Occipital_Lat_Inf_R"              }, weight: {Left:unitVolume["HO"]["Occipital_Lat_Inf"             ].Left, Right:unitVolume["HO"]["Occipital_Lat_Inf"             ].Right}},
        {static: true, primaryKey:110,  identifier:110, belongToForeignKey: 107,  volumeForeignKey: 94, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Intracalcarine",                varname:{Left:"HO_Intracalcarine_L",                  Right:"HO_Intracalcarine_R"                 }, weight: {Left:unitVolume["HO"]["Intracalcarine"                ].Left, Right:unitVolume["HO"]["Intracalcarine"                ].Right}},
        {static: true, primaryKey:111,  identifier:111, belongToForeignKey: 107,  volumeForeignKey: 95, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Occipital Pole",                varname:{Left:"HO_Occipital_Pole_L",                  Right:"HO_Occipital_Pole_R"                 }, weight: {Left:unitVolume["HO"]["Occipital_Pole"                ].Left, Right:unitVolume["HO"]["Occipital_Pole"                ].Right}},
        {static: true, primaryKey:112,  identifier:112, belongToForeignKey: 107,  volumeForeignKey: 96, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Cuneal",                        varname:{Left:"HO_Cuneal_L",                          Right:"HO_Cuneal_R"                         }, weight: {Left:unitVolume["HO"]["Cuneal"                        ].Left, Right:unitVolume["HO"]["Cuneal"                        ].Right}},
        {static: true, primaryKey:113,  identifier:113, belongToForeignKey: 107,  volumeForeignKey: 97, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Lingual",                       varname:{Left:"HO_Lingual_L",                         Right:"HO_Lingual_R"                        }, weight: {Left:unitVolume["HO"]["Lingual"                       ].Left, Right:unitVolume["HO"]["Lingual"                       ].Right}},
        {static: true, primaryKey:114,  identifier:114, belongToForeignKey: 107,  volumeForeignKey: 98, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Occipital Fusiform",            varname:{Left:"HO_Occipital_Fusiform_L",              Right:"HO_Occipital_Fusiform_R"             }, weight: {Left:unitVolume["HO"]["Occipital_Fusiform"            ].Left, Right:unitVolume["HO"]["Occipital_Fusiform"            ].Right}},
        {static: true, primaryKey:115,  identifier:115, belongToForeignKey: 107,  volumeForeignKey: 99, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Supracalcarine",                varname:{Left:"HO_Supracalcarine_L",                  Right:"HO_Supracalcarine_R"                 }, weight: {Left:unitVolume["HO"]["Supracalcarine"                ].Left, Right:unitVolume["HO"]["Supracalcarine"                ].Right}},
        {static: true, primaryKey:116,  identifier:116, belongToForeignKey: 116,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Basal ganglia",    fullname: "Basal ganglia",                 varname:{Left:"HO_Basal_ganglia_L",                   Right:"HO_Basal_ganglia_R"                  }},                    
        {static: true, primaryKey:117,  identifier:117, belongToForeignKey: 116,  volumeForeignKey:100, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Basal ganglia",    fullname: "Caudate",                       varname:{Left:"HO_Caudate_L",                         Right:"HO_Caudate_R"                        }, weight: {Left:unitVolume["HO"]["Caudate"                       ].Left, Right:unitVolume["HO"]["Caudate"                       ].Right}},
        {static: true, primaryKey:118,  identifier:118, belongToForeignKey: 116,  volumeForeignKey:101, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Basal ganglia",    fullname: "Putamen",                       varname:{Left:"HO_Putamen_L",                         Right:"HO_Putamen_R"                        }, weight: {Left:unitVolume["HO"]["Putamen"                       ].Left, Right:unitVolume["HO"]["Putamen"                       ].Right}},
        {static: true, primaryKey:119,  identifier:119, belongToForeignKey: 116,  volumeForeignKey:102, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Basal ganglia",    fullname: "Pallidum",                      varname:{Left:"HO_Pallidum_L",                        Right:"HO_Pallidum_R"                       }, weight: {Left:unitVolume["HO"]["Pallidum"                      ].Left, Right:unitVolume["HO"]["Pallidum"                      ].Right}},
        {static: true, primaryKey:120,  identifier:120, belongToForeignKey: 116,  volumeForeignKey:103, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Basal ganglia",    fullname: "Thalamus",                      varname:{Left:"HO_Thalamus_L",                        Right:"HO_Thalamus_R"                       }, weight: {Left:unitVolume["HO"]["Thalamus"                      ].Left, Right:unitVolume["HO"]["Thalamus"                      ].Right}},
        {static: true, primaryKey:121,  identifier:121, belongToForeignKey: 116,  volumeForeignKey:104, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Basal ganglia",    fullname: "Accumbens",                     varname:{Left:"HO_Accumbens_L",                       Right:"HO_Accumbens_R"                      }, weight: {Left:unitVolume["HO"]["Accumbens"                     ].Left, Right:unitVolume["HO"]["Accumbens"                     ].Right}},
        {static: true, primaryKey:122,  identifier:122, belongToForeignKey: 122,  volumeForeignKey: -1, level: 0, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Brainstem",        fullname: "Brainstem",                     varname:{Left:"HO_Brainstem_L",                       Right:"HO_Brainstem_R"                      }},
        {static: true, primaryKey:123,  identifier:  0, belongToForeignKey: 123,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"AAL3",            belongTo: "Composite",        fullname: "Composite",                     varname:{Left:"Composite_C",                          Right:"Composite_C"                         }},
        {static: true, primaryKey:124,  identifier:  1, belongToForeignKey: 124,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal",                       varname:{Left:"Frontal_L_C",                          Right:"Frontal_R_C"                         }},
        {static: true, primaryKey:125,  identifier:  2, belongToForeignKey: 124,  volumeForeignKey:  0, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Precentral",                    varname:{Left:"Precentral_L_C",                       Right:"Precentral_R_C"                      }, weight: {Left:unitVolume["AAL3"]["Precentral"                  ].Left, Right:unitVolume["AAL3"]["Precentral"                  ].Right}},
        {static: true, primaryKey:126,  identifier:  3, belongToForeignKey: 124,  volumeForeignKey:  1, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Sup",                   varname:{Left:"Frontal_Sup_L_C",                      Right:"Frontal_Sup_R_C"                     }, weight: {Left:unitVolume["AAL3"]["Frontal_Sup"                 ].Left, Right:unitVolume["AAL3"]["Frontal_Sup"                 ].Right}},
        {static: true, primaryKey:127,  identifier:  4, belongToForeignKey: 124,  volumeForeignKey:  2, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Mid",                   varname:{Left:"Frontal_Mid_L_C",                      Right:"Frontal_Mid_R_C"                     }, weight: {Left:unitVolume["AAL3"]["Frontal_Mid"                 ].Left, Right:unitVolume["AAL3"]["Frontal_Mid"                 ].Right}},
        {static: true, primaryKey:128,  identifier:  5, belongToForeignKey: 124,  volumeForeignKey:  3, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Inf Oper",              varname:{Left:"Frontal_Inf_Oper_L_C",                 Right:"Frontal_Inf_Oper_R_C"                }, weight: {Left:unitVolume["AAL3"]["Frontal_Inf_Oper"            ].Left, Right:unitVolume["AAL3"]["Frontal_Inf_Oper"            ].Right}},
        {static: true, primaryKey:129,  identifier:  6, belongToForeignKey: 124,  volumeForeignKey:  4, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Inf Tri",               varname:{Left:"Frontal_Inf_Tri_L_C",                  Right:"Frontal_Inf_Tri_R_C"                 }, weight: {Left:unitVolume["AAL3"]["Frontal_Inf_Tri"             ].Left, Right:unitVolume["AAL3"]["Frontal_Inf_Tri"             ].Right}},
        {static: true, primaryKey:130,  identifier:  7, belongToForeignKey: 124,  volumeForeignKey:  5, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Inf Orb",               varname:{Left:"Frontal_Inf_Orb_L_C",                  Right:"Frontal_Inf_Orb_R_C"                 }, weight: {Left:unitVolume["AAL3"]["Frontal_Inf_Orb"             ].Left, Right:unitVolume["AAL3"]["Frontal_Inf_Orb"             ].Right}},
        {static: true, primaryKey:131,  identifier:  8, belongToForeignKey: 124,  volumeForeignKey:  6, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Rolandic Oper",                 varname:{Left:"Rolandic_Oper_L_C",                    Right:"Rolandic_Oper_R_C"                   }, weight: {Left:unitVolume["AAL3"]["Rolandic_Oper"               ].Left, Right:unitVolume["AAL3"]["Rolandic_Oper"               ].Right}},
        {static: true, primaryKey:132,  identifier:  9, belongToForeignKey: 124,  volumeForeignKey:  7, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Supp Motor Area",               varname:{Left:"Supp_Motor_Area_L_C",                  Right:"Supp_Motor_Area_R_C"                 }, weight: {Left:unitVolume["AAL3"]["Supp_Motor_Area"             ].Left, Right:unitVolume["AAL3"]["Supp_Motor_Area"             ].Right}},
        {static: true, primaryKey:133,  identifier: 10, belongToForeignKey: 124,  volumeForeignKey:  8, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Olfactory",                     varname:{Left:"Olfactory_L_C",                        Right:"Olfactory_R_C"                       }, weight: {Left:unitVolume["AAL3"]["Olfactory"                   ].Left, Right:unitVolume["AAL3"]["Olfactory"                   ].Right}},
        {static: true, primaryKey:134,  identifier: 11, belongToForeignKey: 124,  volumeForeignKey:  9, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Sup Med",               varname:{Left:"Frontal_Sup_Med_L_C",                  Right:"Frontal_Sup_Med_R_C"                 }, weight: {Left:unitVolume["AAL3"]["Frontal_Sup_Med"             ].Left, Right:unitVolume["AAL3"]["Frontal_Sup_Med"             ].Right}},
        {static: true, primaryKey:135,  identifier: 12, belongToForeignKey: 124,  volumeForeignKey: 10, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Med Orb",               varname:{Left:"Frontal_Med_Orb_L_C",                  Right:"Frontal_Med_Orb_R_C"                 }, weight: {Left:unitVolume["AAL3"]["Frontal_Med_Orb"             ].Left, Right:unitVolume["AAL3"]["Frontal_Med_Orb"             ].Right}},
        {static: true, primaryKey:136,  identifier: 13, belongToForeignKey: 124,  volumeForeignKey: 11, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Rectus",                        varname:{Left:"Rectus_L_C",                           Right:"Rectus_R_C"                          }, weight: {Left:unitVolume["AAL3"]["Rectus"                      ].Left, Right:unitVolume["AAL3"]["Rectus"                      ].Right}},
        {static: true, primaryKey:137,  identifier: 14, belongToForeignKey: 124,  volumeForeignKey: 12, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "OFCmed",                        varname:{Left:"OFCmed_L_C",                           Right:"OFCmed_R_C"                          }, weight: {Left:unitVolume["AAL3"]["OFCmed"                      ].Left, Right:unitVolume["AAL3"]["OFCmed"                      ].Right}},
        {static: true, primaryKey:138,  identifier: 15, belongToForeignKey: 124,  volumeForeignKey: 13, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "OFCant",                        varname:{Left:"OFCant_L_C",                           Right:"OFCant_R_C"                          }, weight: {Left:unitVolume["AAL3"]["OFCant"                      ].Left, Right:unitVolume["AAL3"]["OFCant"                      ].Right}},
        {static: true, primaryKey:139,  identifier: 16, belongToForeignKey: 124,  volumeForeignKey: 14, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "OFCpost",                       varname:{Left:"OFCpost_L_C",                          Right:"OFCpost_R_C"                         }, weight: {Left:unitVolume["AAL3"]["OFCpost"                     ].Left, Right:unitVolume["AAL3"]["OFCpost"                     ].Right}},
        {static: true, primaryKey:140,  identifier: 17, belongToForeignKey: 124,  volumeForeignKey: 15, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "OFClat",                        varname:{Left:"OFClat_L_C",                           Right:"OFClat_R_C"                          }, weight: {Left:unitVolume["AAL3"]["OFClat"                      ].Left, Right:unitVolume["AAL3"]["OFClat"                      ].Right}},
        {static: true, primaryKey:141,  identifier: 18, belongToForeignKey: 124,  volumeForeignKey: 16, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Insula",                        varname:{Left:"Insula_L_C",                           Right:"Insula_R_C"                          }, weight: {Left:unitVolume["AAL3"]["Insula"                      ].Left, Right:unitVolume["AAL3"]["Insula"                      ].Right}},
        {static: true, primaryKey:142,  identifier: 19, belongToForeignKey: 124,  volumeForeignKey: 17, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Cingulate Ant Sub",             varname:{Left:"Cingulate_Ant_Sub_L_C",                Right:"Cingulate_Ant_Sub_R_C"               }, weight: {Left:unitVolume["AAL3"]["Cingulate_Ant_Sub"           ].Left, Right:unitVolume["AAL3"]["Cingulate_Ant_Sub"           ].Right}},
        {static: true, primaryKey:143,  identifier: 20, belongToForeignKey: 124,  volumeForeignKey: 18, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Cingulate Ant Pre",             varname:{Left:"Cingulate_Ant_Pre_L_C",                Right:"Cingulate_Ant_Pre_R_C"               }, weight: {Left:unitVolume["AAL3"]["Cingulate_Ant_Pre"           ].Left, Right:unitVolume["AAL3"]["Cingulate_Ant_Pre"           ].Right}},
        {static: true, primaryKey:144,  identifier: 21, belongToForeignKey: 124,  volumeForeignKey: 19, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Cingulate Ant Sup",             varname:{Left:"Cingulate_Ant_Sup_L_C",                Right:"Cingulate_Ant_Sup_R_C"               }, weight: {Left:unitVolume["AAL3"]["Cingulate_Ant_Sup"           ].Left, Right:unitVolume["AAL3"]["Cingulate_Ant_Sup"           ].Right}},
        {static: true, primaryKey:145,  identifier: 22, belongToForeignKey: 124,  volumeForeignKey: 20, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Cingulate Mid",                 varname:{Left:"Cingulate_Mid_L_C",                    Right:"Cingulate_Mid_R_C"                   }, weight: {Left:unitVolume["AAL3"]["Cingulate_Mid"               ].Left, Right:unitVolume["AAL3"]["Cingulate_Mid"               ].Right}},
        {static: true, primaryKey:146,  identifier: 23, belongToForeignKey: 146,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"AAL3",            belongTo: "PCC-Precuneus",    fullname: "PCC-Precuneus",                 varname:{Left:"PCC_L_C",                              Right:"PCC_R_C"                             }},                    
        {static: true, primaryKey:147,  identifier: 24, belongToForeignKey: 146,  volumeForeignKey: 21, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "PCC-Precuneus",    fullname: "Cingulate Post",                varname:{Left:"Cingulate_Post_L_C",                   Right:"Cingulate_Post_R_C"                  }, weight: {Left:unitVolume["AAL3"]["Cingulate_Post"              ].Left, Right:unitVolume["AAL3"]["Cingulate_Post"              ].Right}},
        {static: true, primaryKey:148,  identifier: 25, belongToForeignKey: 146,  volumeForeignKey: 22, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "PCC-Precuneus",    fullname: "Precuneus",                     varname:{Left:"Precuneus_L_C",                        Right:"Precuneus_R_C"                       }, weight: {Left:unitVolume["AAL3"]["Precuneus"                   ].Left, Right:unitVolume["AAL3"]["Precuneus"                   ].Right}},
        {static: true, primaryKey:149,  identifier: 26, belongToForeignKey: 149,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Lateral temporal",              varname:{Left:"Lateral_temporal_L_C",                 Right:"Lateral_temporal_R_C"                }},                    
        {static: true, primaryKey:150,  identifier: 27, belongToForeignKey: 149,  volumeForeignKey: 23, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Heschl",                        varname:{Left:"Heschl_L_C",                           Right:"Heschl_R_C"                          }, weight: {Left:unitVolume["AAL3"]["Heschl"                      ].Left, Right:unitVolume["AAL3"]["Heschl"                      ].Right}},
        {static: true, primaryKey:151,  identifier: 28, belongToForeignKey: 149,  volumeForeignKey: 24, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Temporal Sup",                  varname:{Left:"Temporal_Sup_L_C",                     Right:"Temporal_Sup_R_C"                    }, weight: {Left:unitVolume["AAL3"]["Temporal_Sup"                ].Left, Right:unitVolume["AAL3"]["Temporal_Sup"                ].Right}},
        {static: true, primaryKey:152,  identifier: 29, belongToForeignKey: 149,  volumeForeignKey: 25, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Temporal Pole Sup",             varname:{Left:"Temporal_Pole_Sup_L_C",                Right:"Temporal_Pole_Sup_R_C"               }, weight: {Left:unitVolume["AAL3"]["Temporal_Pole_Sup"           ].Left, Right:unitVolume["AAL3"]["Temporal_Pole_Sup"           ].Right}},
        {static: true, primaryKey:153,  identifier: 30, belongToForeignKey: 149,  volumeForeignKey: 26, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Temporal Mid",                  varname:{Left:"Temporal_Mid_L_C",                     Right:"Temporal_Mid_R_C"                    }, weight: {Left:unitVolume["AAL3"]["Temporal_Mid"                ].Left, Right:unitVolume["AAL3"]["Temporal_Mid"                ].Right}},
        {static: true, primaryKey:154,  identifier: 31, belongToForeignKey: 149,  volumeForeignKey: 27, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Temporal Pole Mid",             varname:{Left:"Temporal_Pole_Mid_L_C",                Right:"Temporal_Pole_Mid_R_C"               }, weight: {Left:unitVolume["AAL3"]["Temporal_Pole_Mid"           ].Left, Right:unitVolume["AAL3"]["Temporal_Pole_Mid"           ].Right}},
        {static: true, primaryKey:155,  identifier: 32, belongToForeignKey: 149,  volumeForeignKey: 28, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Temporal Inf",                  varname:{Left:"Temporal_Inf_L_C",                     Right:"Temporal_Inf_R_C"                    }, weight: {Left:unitVolume["AAL3"]["Temporal_Inf"                ].Left, Right:unitVolume["AAL3"]["Temporal_Inf"                ].Right}},
        {static: true, primaryKey:156,  identifier: 33, belongToForeignKey: 156,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Lateral parietal",              varname:{Left:"Lateral_parietal_L_C",                 Right:"Lateral_parietal_R_C"                }},                    
        {static: true, primaryKey:157,  identifier: 34, belongToForeignKey: 156,  volumeForeignKey: 29, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Postcentral",                   varname:{Left:"Postcentral_L_C",                      Right:"Postcentral_R_C"                     }, weight: {Left:unitVolume["AAL3"]["Postcentral"                 ].Left, Right:unitVolume["AAL3"]["Postcentral"                 ].Right}},
        {static: true, primaryKey:158,  identifier: 35, belongToForeignKey: 156,  volumeForeignKey: 30, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Parietal Sup",                  varname:{Left:"Parietal_Sup_L_C",                     Right:"Parietal_Sup_R_C"                    }, weight: {Left:unitVolume["AAL3"]["Parietal_Sup"                ].Left, Right:unitVolume["AAL3"]["Parietal_Sup"                ].Right}},
        {static: true, primaryKey:159,  identifier: 36, belongToForeignKey: 156,  volumeForeignKey: 31, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Parietal Inf",                  varname:{Left:"Parietal_Inf_L_C",                     Right:"Parietal_Inf_R_C"                    }, weight: {Left:unitVolume["AAL3"]["Parietal_Inf"                ].Left, Right:unitVolume["AAL3"]["Parietal_Inf"                ].Right}},
        {static: true, primaryKey:160,  identifier: 37, belongToForeignKey: 156,  volumeForeignKey: 32, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Supramarginal",                 varname:{Left:"Supramarginal_L_C",                    Right:"Supramarginal_R_C"                   }, weight: {Left:unitVolume["AAL3"]["Supramarginal"               ].Left, Right:unitVolume["AAL3"]["Supramarginal"               ].Right}},
        {static: true, primaryKey:161,  identifier: 38, belongToForeignKey: 156,  volumeForeignKey: 33, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Angular",                       varname:{Left:"Angular_L_C",                          Right:"Angular_R_C"                         }, weight: {Left:unitVolume["AAL3"]["Angular"                     ].Left, Right:unitVolume["AAL3"]["Angular"                     ].Right}},
        {static: true, primaryKey:162,  identifier: 39, belongToForeignKey: 156,  volumeForeignKey: 34, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Paracentral Lobule",            varname:{Left:"Paracentral_Lobule_L_C",               Right:"Paracentral_Lobule_R_C"              }, weight: {Left:unitVolume["AAL3"]["Paracentral_Lobule"          ].Left, Right:unitVolume["AAL3"]["Paracentral_Lobule"          ].Right}},
        {static: true, primaryKey:163,  identifier: 40, belongToForeignKey: 163,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"AAL3",            belongTo: "Medial temporal",  fullname: "Medial temporal",               varname:{Left:"Medial_temporal_L_C",                  Right:"Medial_temporal_R_C"                 }},                    
        {static: true, primaryKey:164,  identifier: 41, belongToForeignKey: 163,  volumeForeignKey: 35, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Medial temporal",  fullname: "Hippocampus",                   varname:{Left:"Hippocampus_L_C",                      Right:"Hippocampus_R_C"                     }, weight: {Left:unitVolume["AAL3"]["Hippocampus"                 ].Left, Right:unitVolume["AAL3"]["Hippocampus"                 ].Right}},
        {static: true, primaryKey:165,  identifier: 42, belongToForeignKey: 163,  volumeForeignKey: 36, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Medial temporal",  fullname: "Parahippocampal",               varname:{Left:"Parahippocampal_L_C",                  Right:"Parahippocampal_R_C"                 }, weight: {Left:unitVolume["AAL3"]["Parahippocampal"             ].Left, Right:unitVolume["AAL3"]["Parahippocampal"             ].Right}},
        {static: true, primaryKey:166,  identifier: 43, belongToForeignKey: 163,  volumeForeignKey: 37, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Medial temporal",  fullname: "Amygdala",                      varname:{Left:"Amygdala_L_C",                         Right:"Amygdala_R_C"                        }, weight: {Left:unitVolume["AAL3"]["Amygdala"                    ].Left, Right:unitVolume["AAL3"]["Amygdala"                    ].Right}},
        {static: true, primaryKey:167,  identifier: 44, belongToForeignKey: 167,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"AAL3",            belongTo: "Occipital",        fullname: "Occipital",                     varname:{Left:"Occipital_L_C",                        Right:"Occipital_R_C"                       }},                    
        {static: true, primaryKey:168,  identifier: 45, belongToForeignKey: 167,  volumeForeignKey: 38, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Occipital",        fullname: "Calcarine",                     varname:{Left:"Calcarine_L_C",                        Right:"Calcarine_R_C"                       }, weight: {Left:unitVolume["AAL3"]["Calcarine"                   ].Left, Right:unitVolume["AAL3"]["Calcarine"                   ].Right}},
        {static: true, primaryKey:169,  identifier: 46, belongToForeignKey: 167,  volumeForeignKey: 39, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Occipital",        fullname: "Cuneus",                        varname:{Left:"Cuneus_L_C",                           Right:"Cuneus_R_C"                          }, weight: {Left:unitVolume["AAL3"]["Cuneus"                      ].Left, Right:unitVolume["AAL3"]["Cuneus"                      ].Right}},
        {static: true, primaryKey:170,  identifier: 47, belongToForeignKey: 167,  volumeForeignKey: 40, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Occipital",        fullname: "Lingual",                       varname:{Left:"Lingual_L_C",                          Right:"Lingual_R_C"                         }, weight: {Left:unitVolume["AAL3"]["Lingual"                     ].Left, Right:unitVolume["AAL3"]["Lingual"                     ].Right}},
        {static: true, primaryKey:171,  identifier: 48, belongToForeignKey: 167,  volumeForeignKey: 41, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Occipital",        fullname: "Occipital Sup",                 varname:{Left:"Occipital_Sup_L_C",                    Right:"Occipital_Sup_R_C"                   }, weight: {Left:unitVolume["AAL3"]["Occipital_Sup"               ].Left, Right:unitVolume["AAL3"]["Occipital_Sup"               ].Right}},
        {static: true, primaryKey:172,  identifier: 49, belongToForeignKey: 167,  volumeForeignKey: 42, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Occipital",        fullname: "Occipital Mid",                 varname:{Left:"Occipital_Mid_L_C",                    Right:"Occipital_Mid_R_C"                   }, weight: {Left:unitVolume["AAL3"]["Occipital_Mid"               ].Left, Right:unitVolume["AAL3"]["Occipital_Mid"               ].Right}},
        {static: true, primaryKey:173,  identifier: 50, belongToForeignKey: 167,  volumeForeignKey: 43, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Occipital",        fullname: "Occipital Inf",                 varname:{Left:"Occipital_Inf_L_C",                    Right:"Occipital_Inf_R_C"                   }, weight: {Left:unitVolume["AAL3"]["Occipital_Inf"               ].Left, Right:unitVolume["AAL3"]["Occipital_Inf"               ].Right}},
        {static: true, primaryKey:174,  identifier: 51, belongToForeignKey: 167,  volumeForeignKey: 44, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Occipital",        fullname: "Fusiform",                      varname:{Left:"Fusiform_L_C",                         Right:"Fusiform_R_C"                        }, weight: {Left:unitVolume["AAL3"]["Fusiform"                    ].Left, Right:unitVolume["AAL3"]["Fusiform"                    ].Right}},
        {static: true, primaryKey:175,  identifier: 52, belongToForeignKey: 175,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"AAL3",            belongTo: "Basal ganglia",    fullname: "Basal ganglia",                 varname:{Left:"Basal_ganglia_L_C",                    Right:"Basal_ganglia_R_C"                   }},
        {static: true, primaryKey:176,  identifier: 53, belongToForeignKey: 175,  volumeForeignKey: 45, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Basal ganglia",    fullname: "Caudate",                       varname:{Left:"Caudate_L_C",                          Right:"Caudate_R_C"                         }, weight: {Left:unitVolume["AAL3"]["Caudate"                     ].Left, Right:unitVolume["AAL3"]["Caudate"                     ].Right}},
        {static: true, primaryKey:177,  identifier: 54, belongToForeignKey: 175,  volumeForeignKey: 46, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Basal ganglia",    fullname: "Putamen",                       varname:{Left:"Putamen_L_C",                          Right:"Putamen_R_C"                         }, weight: {Left:unitVolume["AAL3"]["Putamen"                     ].Left, Right:unitVolume["AAL3"]["Putamen"                     ].Right}},
        {static: true, primaryKey:178,  identifier: 55, belongToForeignKey: 175,  volumeForeignKey: 47, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Basal ganglia",    fullname: "Pallidum",                      varname:{Left:"Pallidum_L_C",                         Right:"Pallidum_R_C"                        }, weight: {Left:unitVolume["AAL3"]["Pallidum"                    ].Left, Right:unitVolume["AAL3"]["Pallidum"                    ].Right}},
        {static: true, primaryKey:179,  identifier: 56, belongToForeignKey: 175,  volumeForeignKey: 48, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Basal ganglia",    fullname: "Thalamus",                      varname:{Left:"Thalamus_L_C",                         Right:"Thalamus_R_C"                        }, weight: {Left:unitVolume["AAL3"]["Thalamus"                    ].Left, Right:unitVolume["AAL3"]["Thalamus"                    ].Right}},
        {static: true, primaryKey:180,  identifier: 57, belongToForeignKey: 175,  volumeForeignKey: 49, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Basal ganglia",    fullname: "N Acc",                         varname:{Left:"N_Acc_L_C",                            Right:"N_Acc_R_C"                           }, weight: {Left:unitVolume["AAL3"]["N_Acc"                       ].Left, Right:unitVolume["AAL3"]["N_Acc"                       ].Right}},
        {static: true, primaryKey:181,  identifier: 58, belongToForeignKey: 181,  volumeForeignKey: -1, level: 0, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Brainstem",        fullname: "Brainstem",                     varname:{Left:"Brainstem_L_C",                        Right:"Brainstem_R_C"                       }},                    
        {static: true, primaryKey:182,  identifier: 59, belongToForeignKey: 182,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Composite",        fullname: "Composite",                     varname:{Left:"Composite_C",                          Right:"Composite_C"                         }},                    
        {static: true, primaryKey:183,  identifier: 60, belongToForeignKey: 183,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal",                       varname:{Left:"HO_Frontal_L_C",                       Right:"HO_Frontal_R_C"                      }},                    
        {static: true, primaryKey:184,  identifier: 61, belongToForeignKey: 183,  volumeForeignKey: 50, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Pole",                  varname:{Left:"HO_Frontal_Pole_L_C",                  Right:"HO_Frontal_Pole_R_C"                 }, weight: {Left:unitVolume["HO"]["Frontal_Pole"                  ].Left, Right:unitVolume["HO"]["Frontal_Pole"                  ].Right}},
        {static: true, primaryKey:185,  identifier: 62, belongToForeignKey: 183,  volumeForeignKey: 51, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Insula",                        varname:{Left:"HO_Insula_L_C",                        Right:"HO_Insula_R_C"                       }, weight: {Left:unitVolume["HO"]["Insula"                        ].Left, Right:unitVolume["HO"]["Insula"                        ].Right}},
        {static: true, primaryKey:186,  identifier: 63, belongToForeignKey: 183,  volumeForeignKey: 52, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Sup",                   varname:{Left:"HO_Frontal_Sup_L_C",                   Right:"HO_Frontal_Sup_R_C"                  }, weight: {Left:unitVolume["HO"]["Frontal_Sup"                   ].Left, Right:unitVolume["HO"]["Frontal_Sup"                   ].Right}},
        {static: true, primaryKey:187,  identifier: 64, belongToForeignKey: 183,  volumeForeignKey: 53, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Mid",                   varname:{Left:"HO_Frontal_Mid_L_C",                   Right:"HO_Frontal_Mid_R_C"                  }, weight: {Left:unitVolume["HO"]["Frontal_Mid"                   ].Left, Right:unitVolume["HO"]["Frontal_Mid"                   ].Right}},
        {static: true, primaryKey:188,  identifier: 65, belongToForeignKey: 183,  volumeForeignKey: 54, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Inf Tri",               varname:{Left:"HO_Frontal_Inf_Tri_L_C",               Right:"HO_Frontal_Inf_Tri_R_C"              }, weight: {Left:unitVolume["HO"]["Frontal_Inf_Tri"               ].Left, Right:unitVolume["HO"]["Frontal_Inf_Tri"               ].Right}},
        {static: true, primaryKey:189,  identifier: 66, belongToForeignKey: 183,  volumeForeignKey: 55, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Inf Oper",              varname:{Left:"HO_Frontal_Inf_Oper_L_C",              Right:"HO_Frontal_Inf_Oper_R_C"             }, weight: {Left:unitVolume["HO"]["Frontal_Inf_Oper"              ].Left, Right:unitVolume["HO"]["Frontal_Inf_Oper"              ].Right}},
        {static: true, primaryKey:190,  identifier: 67, belongToForeignKey: 183,  volumeForeignKey: 56, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Precentral",                    varname:{Left:"HO_Precentral_L_C",                    Right:"HO_Precentral_R_C"                   }, weight: {Left:unitVolume["HO"]["Precentral"                    ].Left, Right:unitVolume["HO"]["Precentral"                    ].Right}},
        {static: true, primaryKey:191,  identifier: 68, belongToForeignKey: 183,  volumeForeignKey: 57, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Med",                   varname:{Left:"HO_Frontal_Med_L_C",                   Right:"HO_Frontal_Med_R_C"                  }, weight: {Left:unitVolume["HO"]["Frontal_Med"                   ].Left, Right:unitVolume["HO"]["Frontal_Med"                   ].Right}},
        {static: true, primaryKey:192,  identifier: 69, belongToForeignKey: 183,  volumeForeignKey: 58, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Supp Motor Area",               varname:{Left:"HO_Supp_Motor_Area_L_C",               Right:"HO_Supp_Motor_Area_R_C"              }, weight: {Left:unitVolume["HO"]["Supp_Motor_Area"               ].Left, Right:unitVolume["HO"]["Supp_Motor_Area"               ].Right}},
        {static: true, primaryKey:193,  identifier: 71, belongToForeignKey: 183,  volumeForeignKey: 59, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Cingulate Ant",                 varname:{Left:"HO_Cingulate_Ant_L_C",                 Right:"HO_Cingulate_Ant_R_C"                }, weight: {Left:unitVolume["HO"]["Cingulate_Ant"                 ].Left, Right:unitVolume["HO"]["Cingulate_Ant"                 ].Right}},
        {static: true, primaryKey:194,  identifier: 71, belongToForeignKey: 183,  volumeForeignKey: 60, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Orb",                   varname:{Left:"HO_Frontal_Orb_L_C",                   Right:"HO_Frontal_Orb_R_C"                  }, weight: {Left:unitVolume["HO"]["Frontal_Orb"                   ].Left, Right:unitVolume["HO"]["Frontal_Orb"                   ].Right}},
        {static: true, primaryKey:195,  identifier: 72, belongToForeignKey: 183,  volumeForeignKey: 61, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Oper",                  varname:{Left:"HO_Frontal_Oper_L_C",                  Right:"HO_Frontal_Oper_R_C"                 }, weight: {Left:unitVolume["HO"]["Frontal_Oper"                  ].Left, Right:unitVolume["HO"]["Frontal_Oper"                  ].Right}},
        {static: true, primaryKey:196,  identifier: 73, belongToForeignKey: 183,  volumeForeignKey: 62, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Central Oper",                  varname:{Left:"HO_Central_Oper_L_C",                  Right:"HO_Central_Oper_R_C"                 }, weight: {Left:unitVolume["HO"]["Central_Oper"                  ].Left, Right:unitVolume["HO"]["Central_Oper"                  ].Right}},
        {static: true, primaryKey:197,  identifier: 74, belongToForeignKey: 183,  volumeForeignKey: 63, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Subcallosal",                   varname:{Left:"HO_Subcallosal_L_C",                   Right:"HO_Subcallosal_R_C"                  }, weight: {Left:unitVolume["HO"]["Subcallosal"                   ].Left, Right:unitVolume["HO"]["Subcallosal"                   ].Right}},
        {static: true, primaryKey:198,  identifier: 75, belongToForeignKey: 183,  volumeForeignKey: 64, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Paracingulate",                 varname:{Left:"HO_Paracingulate_L_C",                 Right:"HO_Paracingulate_R_C"                }, weight: {Left:unitVolume["HO"]["Paracingulate"                 ].Left, Right:unitVolume["HO"]["Paracingulate"                 ].Right}},
        {static: true, primaryKey:199,  identifier: 76, belongToForeignKey: 199,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Retrosplenial",    fullname: "Retrosplenial",                 varname:{Left:"HO_PCC_L_C",                           Right:"HO_PCC_R_C"                          }},                    
        {static: true, primaryKey:200,  identifier: 77, belongToForeignKey: 199,  volumeForeignKey: 65, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Retrosplenial",    fullname: "Cingulate Post",                varname:{Left:"HO_Cingulate_Post_L_C",                Right:"HO_Cingulate_Post_R_C"               }, weight: {Left:unitVolume["HO"]["Cingulate_Post"                ].Left, Right:unitVolume["HO"]["Cingulate_Post"                ].Right}},
        {static: true, primaryKey:201,  identifier: 78, belongToForeignKey: 199,  volumeForeignKey: 66, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Retrosplenial",    fullname: "Precuneus",                     varname:{Left:"HO_Precuneus_L_C",                     Right:"HO_Precuneus_R_C"                    }, weight: {Left:unitVolume["HO"]["Precuneus"                     ].Left, Right:unitVolume["HO"]["Precuneus"                     ].Right}},
        {static: true, primaryKey:202,  identifier: 79, belongToForeignKey: 202,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Lateral temporal",              varname:{Left:"HO_Lateral_temporal_L_C",              Right:"HO_Lateral_temporal_R_C"             }},                    
        {static: true, primaryKey:203,  identifier: 80, belongToForeignKey: 202,  volumeForeignKey: 67, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Pole",                 varname:{Left:"HO_Temporal_Pole_L_C",                 Right:"HO_Temporal_Pole_R_C"                }, weight: {Left:unitVolume["HO"]["Temporal_Pole"                 ].Left, Right:unitVolume["HO"]["Temporal_Pole"                 ].Right}},
        {static: true, primaryKey:204,  identifier: 81, belongToForeignKey: 202,  volumeForeignKey: 68, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Sup Ant",              varname:{Left:"HO_Temporal_Sup_Ant_L_C",              Right:"HO_Temporal_Sup_Ant_R_C"             }, weight: {Left:unitVolume["HO"]["Temporal_Sup_Ant"              ].Left, Right:unitVolume["HO"]["Temporal_Sup_Ant"              ].Right}},
        {static: true, primaryKey:205,  identifier: 82, belongToForeignKey: 202,  volumeForeignKey: 69, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Sup Post",             varname:{Left:"HO_Temporal_Sup_Post_L_C",             Right:"HO_Temporal_Sup_Post_R_C"            }, weight: {Left:unitVolume["HO"]["Temporal_Sup_Post"             ].Left, Right:unitVolume["HO"]["Temporal_Sup_Post"             ].Right}},
        {static: true, primaryKey:206,  identifier: 83, belongToForeignKey: 202,  volumeForeignKey: 70, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Mid Ant",              varname:{Left:"HO_Temporal_Mid_Ant_L_C",              Right:"HO_Temporal_Mid_Ant_R_C"             }, weight: {Left:unitVolume["HO"]["Temporal_Mid_Ant"              ].Left, Right:unitVolume["HO"]["Temporal_Mid_Ant"              ].Right}},
        {static: true, primaryKey:207,  identifier: 84, belongToForeignKey: 202,  volumeForeignKey: 71, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Mid Post",             varname:{Left:"HO_Temporal_Mid_Post_L_C",             Right:"HO_Temporal_Mid_Post_R_C"            }, weight: {Left:unitVolume["HO"]["Temporal_Mid_Post"             ].Left, Right:unitVolume["HO"]["Temporal_Mid_Post"             ].Right}},
        {static: true, primaryKey:208,  identifier: 85, belongToForeignKey: 202,  volumeForeignKey: 72, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Mid Temporooccipital", varname:{Left:"HO_Temporal_Mid_Temporooccipital_L_C", Right:"HO_Temporal_Mid_Temporooccipital_R_C"}, weight: {Left:unitVolume["HO"]["Temporal_Mid_Temporooccipital" ].Left, Right:unitVolume["HO"]["Temporal_Mid_Temporooccipital" ].Right}},
        {static: true, primaryKey:209,  identifier: 86, belongToForeignKey: 202,  volumeForeignKey: 73, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Inf Ant",              varname:{Left:"HO_Temporal_Inf_Ant_L_C",              Right:"HO_Temporal_Inf_Ant_R_C"             }, weight: {Left:unitVolume["HO"]["Temporal_Inf_Ant"              ].Left, Right:unitVolume["HO"]["Temporal_Inf_Ant"              ].Right}},
        {static: true, primaryKey:210,  identifier: 87, belongToForeignKey: 202,  volumeForeignKey: 74, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Inf Post",             varname:{Left:"HO_Temporal_Inf_Post_L_C",             Right:"HO_Temporal_Inf_Post_R_C"            }, weight: {Left:unitVolume["HO"]["Temporal_Inf_Post"             ].Left, Right:unitVolume["HO"]["Temporal_Inf_Post"             ].Right}},
        {static: true, primaryKey:211,  identifier: 88, belongToForeignKey: 202,  volumeForeignKey: 75, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Inf Temporooccipital", varname:{Left:"HO_Temporal_Inf_Temporooccipital_L_C", Right:"HO_Temporal_Inf_Temporooccipital_R_C"}, weight: {Left:unitVolume["HO"]["Temporal_Inf_Temporooccipital" ].Left, Right:unitVolume["HO"]["Temporal_Inf_Temporooccipital" ].Right}},
        {static: true, primaryKey:212,  identifier: 89, belongToForeignKey: 202,  volumeForeignKey: 76, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Planum Polare",                 varname:{Left:"HO_Planum_Polare_L_C",                 Right:"HO_Planum_Polare_R_C"                }, weight: {Left:unitVolume["HO"]["Planum_Polare"                 ].Left, Right:unitVolume["HO"]["Planum_Polare"                 ].Right}},
        {static: true, primaryKey:213,  identifier: 90, belongToForeignKey: 202,  volumeForeignKey: 77, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Heschl",                        varname:{Left:"HO_Heschl_L_C",                        Right:"HO_Heschl_R_C"                       }, weight: {Left:unitVolume["HO"]["Heschl"                        ].Left, Right:unitVolume["HO"]["Heschl"                        ].Right}},
        {static: true, primaryKey:214,  identifier: 91, belongToForeignKey: 202,  volumeForeignKey: 78, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Planum Temporale",              varname:{Left:"HO_Planum_Temporale_L_C",              Right:"HO_Planum_Temporale_R_C"             }, weight: {Left:unitVolume["HO"]["Planum_Temporale"              ].Left, Right:unitVolume["HO"]["Planum_Temporale"              ].Right}},
        {static: true, primaryKey:215,  identifier: 92, belongToForeignKey: 202,  volumeForeignKey: 79, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal fusiform Ant",         varname:{Left:"HO_Temporal_fusiform_Ant_L_C",         Right:"HO_Temporal_fusiform_Ant_R_C"        }, weight: {Left:unitVolume["HO"]["Temporal_fusiform_Ant"         ].Left, Right:unitVolume["HO"]["Temporal_fusiform_Ant"         ].Right}},
        {static: true, primaryKey:216,  identifier: 93, belongToForeignKey: 202,  volumeForeignKey: 80, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal fusiform Post",        varname:{Left:"HO_Temporal_fusiform_Post_L_C",        Right:"HO_Temporal_fusiform_Post_R_C"       }, weight: {Left:unitVolume["HO"]["Temporal_fusiform_Post"        ].Left, Right:unitVolume["HO"]["Temporal_fusiform_Post"        ].Right}},
        {static: true, primaryKey:217,  identifier: 94, belongToForeignKey: 202,  volumeForeignKey: 81, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Occipital Fusiform",   varname:{Left:"HO_Temporal_Occipital_Fusiform_L_C",   Right:"HO_Temporal_Occipital_Fusiform_R_C"  }, weight: {Left:unitVolume["HO"]["Temporal_Occipital_Fusiform"   ].Left, Right:unitVolume["HO"]["Temporal_Occipital_Fusiform"   ].Right}},
        {static: true, primaryKey:218,  identifier: 95, belongToForeignKey: 218,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Lateral parietal",              varname:{Left:"HO_Lateral_parietal_L_C",              Right:"HO_Lateral_parietal_R_C"             }},                    
        {static: true, primaryKey:219,  identifier: 96, belongToForeignKey: 218,  volumeForeignKey: 82, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Postcentral",                   varname:{Left:"HO_Postcentral_L_C",                   Right:"HO_Postcentral_R_C"                  }, weight: {Left:unitVolume["HO"]["Postcentral"                   ].Left, Right:unitVolume["HO"]["Postcentral"                   ].Right}},
        {static: true, primaryKey:220,  identifier: 97, belongToForeignKey: 218,  volumeForeignKey: 83, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Parietal Sup Lobule",           varname:{Left:"HO_Parietal_Sup_Lobule_L_C",           Right:"HO_Parietal_Sup_Lobule_R_C"          }, weight: {Left:unitVolume["HO"]["Parietal_Sup_Lobule"           ].Left, Right:unitVolume["HO"]["Parietal_Sup_Lobule"           ].Right}},
        {static: true, primaryKey:221,  identifier: 98, belongToForeignKey: 218,  volumeForeignKey: 84, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Supramarginal Ant",             varname:{Left:"HO_Supramarginal_Ant_L_C",             Right:"HO_Supramarginal_Ant_R_C"            }, weight: {Left:unitVolume["HO"]["Supramarginal_Ant"             ].Left, Right:unitVolume["HO"]["Supramarginal_Ant"             ].Right}},
        {static: true, primaryKey:222,  identifier: 99, belongToForeignKey: 218,  volumeForeignKey: 85, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Supramarginal Post",            varname:{Left:"HO_Supramarginal_Post_L_C",            Right:"HO_Supramarginal_Post_R_C"           }, weight: {Left:unitVolume["HO"]["Supramarginal_Post"            ].Left, Right:unitVolume["HO"]["Supramarginal_Post"            ].Right}},
        {static: true, primaryKey:223,  identifier:100, belongToForeignKey: 218,  volumeForeignKey: 86, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Angular",                       varname:{Left:"HO_Angular_L_C",                       Right:"HO_Angular_R_C"                      }, weight: {Left:unitVolume["HO"]["Angular"                       ].Left, Right:unitVolume["HO"]["Angular"                       ].Right}},
        {static: true, primaryKey:224,  identifier:101, belongToForeignKey: 218,  volumeForeignKey: 87, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Parietal Oper",                 varname:{Left:"HO_Parietal_Oper_L_C",                 Right:"HO_Parietal_Oper_R_C"                }, weight: {Left:unitVolume["HO"]["Parietal_Oper"                 ].Left, Right:unitVolume["HO"]["Parietal_Oper"                 ].Right}},
        {static: true, primaryKey:225,  identifier:102, belongToForeignKey: 225,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Medial temporal",  fullname: "Medial temporal",               varname:{Left:"HO_Medial_temporal_L_C",               Right:"HO_Medial_temporal_R_C"              }},                    
        {static: true, primaryKey:226,  identifier:103, belongToForeignKey: 225,  volumeForeignKey: 88, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Medial temporal",  fullname: "Parahippocampal Ant",           varname:{Left:"HO_Parahippocampal_Ant_L_C",           Right:"HO_Parahippocampal_Ant_R_C"          }, weight: {Left:unitVolume["HO"]["Parahippocampal_Ant"           ].Left, Right:unitVolume["HO"]["Parahippocampal_Ant"           ].Right}},
        {static: true, primaryKey:227,  identifier:104, belongToForeignKey: 225,  volumeForeignKey: 89, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Medial temporal",  fullname: "Parahippocampal Post",          varname:{Left:"HO_Parahippocampal_Post_L_C",          Right:"HO_Parahippocampal_Post_R_C"         }, weight: {Left:unitVolume["HO"]["Parahippocampal_Post"          ].Left, Right:unitVolume["HO"]["Parahippocampal_Post"          ].Right}},
        {static: true, primaryKey:228,  identifier:105, belongToForeignKey: 225,  volumeForeignKey: 90, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Medial temporal",  fullname: "Hippocampus",                   varname:{Left:"HO_Hippocampus_L_C",                   Right:"HO_Hippocampus_R_C"                  }, weight: {Left:unitVolume["HO"]["Hippocampus"                   ].Left, Right:unitVolume["HO"]["Hippocampus"                   ].Right}},
        {static: true, primaryKey:229,  identifier:106, belongToForeignKey: 225,  volumeForeignKey: 91, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Medial temporal",  fullname: "Amygdala",                      varname:{Left:"HO_Amygdala_L_C",                      Right:"HO_Amygdala_R_C"                     }, weight: {Left:unitVolume["HO"]["Amygdala"                      ].Left, Right:unitVolume["HO"]["Amygdala"                      ].Right}},
        {static: true, primaryKey:230,  identifier:107, belongToForeignKey: 230,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Occipital",                     varname:{Left:"HO_Occipital_L_C",                     Right:"HO_Occipital_R_C"                    }},                    
        {static: true, primaryKey:231,  identifier:108, belongToForeignKey: 230,  volumeForeignKey: 92, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Occipital Lat_Sup",             varname:{Left:"HO_Occipital_Lat_Sup_L_C",             Right:"HO_Occipital_Lat_Sup_R_C"            }, weight: {Left:unitVolume["HO"]["Occipital_Lat_Sup"             ].Left, Right:unitVolume["HO"]["Occipital_Lat_Sup"             ].Right}},
        {static: true, primaryKey:232,  identifier:109, belongToForeignKey: 230,  volumeForeignKey: 93, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Occipital Lat_Inf",             varname:{Left:"HO_Occipital_Lat_Inf_L_C",             Right:"HO_Occipital_Lat_Inf_R_C"            }, weight: {Left:unitVolume["HO"]["Occipital_Lat_Inf"             ].Left, Right:unitVolume["HO"]["Occipital_Lat_Inf"             ].Right}},
        {static: true, primaryKey:233,  identifier:110, belongToForeignKey: 230,  volumeForeignKey: 94, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Intracalcarine",                varname:{Left:"HO_Intracalcarine_L_C",                Right:"HO_Intracalcarine_R_C"               }, weight: {Left:unitVolume["HO"]["Intracalcarine"                ].Left, Right:unitVolume["HO"]["Intracalcarine"                ].Right}},
        {static: true, primaryKey:234,  identifier:111, belongToForeignKey: 230,  volumeForeignKey: 95, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Occipital Pole",                varname:{Left:"HO_Occipital_Pole_L_C",                Right:"HO_Occipital_Pole_R_C"               }, weight: {Left:unitVolume["HO"]["Occipital_Pole"                ].Left, Right:unitVolume["HO"]["Occipital_Pole"                ].Right}},
        {static: true, primaryKey:235,  identifier:112, belongToForeignKey: 230,  volumeForeignKey: 96, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Cuneal",                        varname:{Left:"HO_Cuneal_L_C",                        Right:"HO_Cuneal_R_C"                       }, weight: {Left:unitVolume["HO"]["Cuneal"                        ].Left, Right:unitVolume["HO"]["Cuneal"                        ].Right}},
        {static: true, primaryKey:236,  identifier:113, belongToForeignKey: 230,  volumeForeignKey: 97, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Lingual",                       varname:{Left:"HO_Lingual_L_C",                       Right:"HO_Lingual_R_C"                      }, weight: {Left:unitVolume["HO"]["Lingual"                       ].Left, Right:unitVolume["HO"]["Lingual"                       ].Right}},
        {static: true, primaryKey:237,  identifier:114, belongToForeignKey: 230,  volumeForeignKey: 98, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Occipital Fusiform",            varname:{Left:"HO_Occipital_Fusiform_L_C",            Right:"HO_Occipital_Fusiform_R_C"           }, weight: {Left:unitVolume["HO"]["Occipital_Fusiform"            ].Left, Right:unitVolume["HO"]["Occipital_Fusiform"            ].Right}},
        {static: true, primaryKey:238,  identifier:115, belongToForeignKey: 230,  volumeForeignKey: 99, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Supracalcarine",                varname:{Left:"HO_Supracalcarine_L_C",                Right:"HO_Supracalcarine_R_C"               }, weight: {Left:unitVolume["HO"]["Supracalcarine"                ].Left, Right:unitVolume["HO"]["Supracalcarine"                ].Right}},
        {static: true, primaryKey:239,  identifier:116, belongToForeignKey: 239,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Basal ganglia",    fullname: "Basal ganglia",                 varname:{Left:"HO_Basal_ganglia_L_C",                 Right:"HO_Basal_ganglia_R_C"                }},                    
        {static: true, primaryKey:240,  identifier:117, belongToForeignKey: 239,  volumeForeignKey:100, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Basal ganglia",    fullname: "Caudate",                       varname:{Left:"HO_Caudate_L_C",                       Right:"HO_Caudate_R_C"                      }, weight: {Left:unitVolume["HO"]["Caudate"                       ].Left, Right:unitVolume["HO"]["Caudate"                       ].Right}},
        {static: true, primaryKey:241,  identifier:118, belongToForeignKey: 239,  volumeForeignKey:101, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Basal ganglia",    fullname: "Putamen",                       varname:{Left:"HO_Putamen_L_C",                       Right:"HO_Putamen_R_C"                      }, weight: {Left:unitVolume["HO"]["Putamen"                       ].Left, Right:unitVolume["HO"]["Putamen"                       ].Right}},
        {static: true, primaryKey:242,  identifier:119, belongToForeignKey: 239,  volumeForeignKey:102, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Basal ganglia",    fullname: "Pallidum",                      varname:{Left:"HO_Pallidum_L_C",                      Right:"HO_Pallidum_R_C"                     }, weight: {Left:unitVolume["HO"]["Pallidum"                      ].Left, Right:unitVolume["HO"]["Pallidum"                      ].Right}},
        {static: true, primaryKey:243,  identifier:120, belongToForeignKey: 239,  volumeForeignKey:103, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Basal ganglia",    fullname: "Thalamus",                      varname:{Left:"HO_Thalamus_L_C",                      Right:"HO_Thalamus_R_C"                     }, weight: {Left:unitVolume["HO"]["Thalamus"                      ].Left, Right:unitVolume["HO"]["Thalamus"                      ].Right}},
        {static: true, primaryKey:244,  identifier:121, belongToForeignKey: 239,  volumeForeignKey:104, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Basal ganglia",    fullname: "Accumbens",                     varname:{Left:"HO_Accumbens_L_C",                     Right:"HO_Accumbens_R_C"                    }, weight: {Left:unitVolume["HO"]["Accumbens"                     ].Left, Right:unitVolume["HO"]["Accumbens"                     ].Right}},
        {static: true, primaryKey:245,  identifier:122, belongToForeignKey: 245,  volumeForeignKey: -1, level: 0, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Brainstem",        fullname: "Brainstem",                     varname:{Left:"HO_Brainstem_L_C",                     Right:"HO_Brainstem_R_C"                    }},
        // {primaryKey:246,  foreignKey: 246, level: 0, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal1",          fullname: "Frontal1",                     varname:{Left:"Cingulate_Ant_Sub_L", Right:"Cingulate_Ant_Sub_R"}},
        // {primaryKey:247,  foreignKey: 246, level: 1, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal1",          fullname: "subFrontal1",                  varname:{Left:"Cingulate_Ant_Pre_L", Right:"Cingulate_Ant_Pre_R"}},
        // {primaryKey:248,  foreignKey: 246, level: 1, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal1",          fullname: "subFrontal2",                  varname:{Left:"Cingulate_Ant_Sup_L", Right:"Cingulate_Ant_Sup_R"}},
        // {primaryKey:249,  foreignKey: 246, level: 1, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal1",          fullname: "subFrontal3",                  varname:{Left:"Cingulate_Mid_L", Right:"Cingulate_Mid_R"}},
      ]
      const primaryKeyArr = arr.map((el,idx)=>({...el, primaryKey:idx}))
      let belongToForeignKeyVar=0;
      const belongToForeignKeyArr = primaryKeyArr.map((el,idx)=>{
        if (el.level===0) belongToForeignKeyVar = el.primaryKey
        return {...el, belongToForeignKey:belongToForeignKeyVar}
      })
      const identifierSUVR = belongToForeignKeyArr.filter(el=>el.type==='suvr').map((el,idx)=>({...el, identifier:idx}))
      const identifierCentil = belongToForeignKeyArr.filter(el=>el.type==='centiloid').map((el,idx)=>({...el, identifier:idx}))
      const resultArr = [...identifierSUVR, ...identifierCentil]
      return resultArr
    })(),
    dat:(()=>{
      const arr = [
        // 
        {static: true, primaryKey:  0,  identifier:  0, belongToForeignKey:  39, level: 0, report:true,  type: 'sbr',                 multiAtlas:"Melbourne Subcortical",             atlas:"Melbourne Subcortical",                  belongTo: "Dorsal striatum",                   fullname: "Dorsal striatum",                  varname:{Left:"DAT_Dorsal_striatum_L",                  Right:"DAT_Dorsal_striatum_R"                   }},
        {static: true, primaryKey:  1,  identifier:  1, belongToForeignKey:  40, level: 0, report:false, type: 'sbr',                 multiAtlas:"Melbourne Subcortical",             atlas:"Melbourne Subcortical",                  belongTo: "Caudate nucleus",                   fullname: "Caudate nucleus",                  varname:{Left:"DAT_Caudate_nucleus_L",                  Right:"DAT_Caudate_nucleus_R"                   }},
        {static: true, primaryKey:  2,  identifier:  2, belongToForeignKey:  40, level: 1, report:true,  type: 'sbr',                 multiAtlas:"Melbourne Subcortical",             atlas:"Melbourne Subcortical",                  belongTo: "Caudate nucleus",                   fullname: "Caudate DA",                       varname:{Left:"DAT_Dorsoant_caudate_L",                 Right:"DAT_Dorsoant_caudate_R"                  }, weight: {Left:unitVolume["MS"]["dorsoant_caudate"  ].Left, Right:unitVolume["MS"]["dorsoant_caudate"  ].Right} },
        {static: true, primaryKey:  3,  identifier:  3, belongToForeignKey:  40, level: 1, report:true,  type: 'sbr',                 multiAtlas:"Melbourne Subcortical",             atlas:"Melbourne Subcortical",                  belongTo: "Caudate nucleus",                   fullname: "Caudate VA",                       varname:{Left:"DAT_Ventroant_caudate_L",                Right:"DAT_Ventroant_caudate_R"                 }, weight: {Left:unitVolume["MS"]["ventroant_caudate" ].Left, Right:unitVolume["MS"]["ventroant_caudate" ].Right} },
        {static: true, primaryKey:  2,  identifier:  2, belongToForeignKey:  40, level: 1, report:true,  type: 'sbr',                 multiAtlas:"Melbourne Subcortical",             atlas:"Melbourne Subcortical",                  belongTo: "Caudate nucleus",                   fullname: "Caudate tail",                     varname:{Left:"DAT_Caudate_tail_L",                     Right:"DAT_Caudate_tail_R"                      }, weight: {Left:unitVolume["MS"]["caudate_tail"      ].Left, Right:unitVolume["MS"]["caudate_tail"      ].Right} },
        {static: true, primaryKey:  3,  identifier:  3, belongToForeignKey:  40, level: 1, report:true,  type: 'sbr',                 multiAtlas:"Melbourne Subcortical",             atlas:"Melbourne Subcortical",                  belongTo: "Caudate nucleus",                   fullname: "Caudate body",                     varname:{Left:"DAT_Caudate_body_L",                     Right:"DAT_Caudate_body_R"                      }, weight: {Left:unitVolume["MS"]["caudate_body"      ].Left, Right:unitVolume["MS"]["caudate_body"      ].Right} },
        {static: true, primaryKey:  4,  identifier:  4, belongToForeignKey:  43, level: 0, report:false, type: 'sbr',                 multiAtlas:"Melbourne Subcortical",             atlas:"Melbourne Subcortical",                  belongTo: "Putamen",                           fullname: "Putamen",                          varname:{Left:"DAT_Putamen_L",                          Right:"DAT_Putamen_R"                           }},
        {static: true, primaryKey:  5,  identifier:  5, belongToForeignKey:  43, level: 1, report:true,  type: 'sbr',                 multiAtlas:"Melbourne Subcortical",             atlas:"Melbourne Subcortical",                  belongTo: "Putamen",                           fullname: "Putamen DA",                       varname:{Left:"DAT_Dorsoant_putamen_L",                 Right:"DAT_Dorsoant_putamen_R"                  }, weight: {Left:unitVolume["MS"]["dorsoant_putamen"  ].Left, Right:unitVolume["MS"]["dorsoant_putamen"  ].Right}  },
        {static: true, primaryKey:  6,  identifier:  6, belongToForeignKey:  43, level: 1, report:true,  type: 'sbr',                 multiAtlas:"Melbourne Subcortical",             atlas:"Melbourne Subcortical",                  belongTo: "Putamen",                           fullname: "Putamen VA",                       varname:{Left:"DAT_Ventroant_putamen_L",                Right:"DAT_Ventroant_putamen_R"                 }, weight: {Left:unitVolume["MS"]["ventroant_putamen" ].Left, Right:unitVolume["MS"]["ventroant_putamen" ].Right}  },
        {static: true, primaryKey:  7,  identifier:  7, belongToForeignKey:  43, level: 1, report:true,  type: 'sbr',                 multiAtlas:"Melbourne Subcortical",             atlas:"Melbourne Subcortical",                  belongTo: "Putamen",                           fullname: "Putamen DP",                       varname:{Left:"DAT_Dorsopost_putamen_L",                Right:"DAT_Dorsopost_putamen_R"                 }, weight: {Left:unitVolume["MS"]["dorsopost_putamen" ].Left, Right:unitVolume["MS"]["dorsopost_putamen" ].Right}  },
        {static: true, primaryKey:  8,  identifier:  8, belongToForeignKey:  43, level: 1, report:true,  type: 'sbr',                 multiAtlas:"Melbourne Subcortical",             atlas:"Melbourne Subcortical",                  belongTo: "Putamen",                           fullname: "Putamen VP",                       varname:{Left:"DAT_Ventropost_Putamen_L",               Right:"DAT_Ventropost_Putamen_R"                }, weight: {Left:unitVolume["MS"]["ventropost_putamen"].Left, Right:unitVolume["MS"]["ventropost_putamen"].Right}  },
        {static: true, primaryKey:  9,  identifier:  9, belongToForeignKey:  48, level: 0, report:false, type: 'sbr',                 multiAtlas:"Melbourne Subcortical",             atlas:"Melbourne Subcortical",                  belongTo: "Pallidum",                          fullname: "Pallidum",                         varname:{Left:"DAT_Pallidum_L",                         Right:"DAT_Pallidum_R"                          }},
        {static: true, primaryKey: 10,  identifier: 10, belongToForeignKey:  48, level: 1, report:true,  type: 'sbr',                 multiAtlas:"Melbourne Subcortical",             atlas:"Melbourne Subcortical",                  belongTo: "Pallidum",                          fullname: "Ant Pallidum",                     varname:{Left:"DAT_Ant_pallidum_L",                     Right:"DAT_Ant_pallidum_R"                      }, weight: {Left:unitVolume["MS"]["ant_pallidum"      ].Left, Right:unitVolume["MS"]["ant_pallidum"      ].Right}  },
        {static: true, primaryKey: 11,  identifier: 11, belongToForeignKey:  48, level: 1, report:true,  type: 'sbr',                 multiAtlas:"Melbourne Subcortical",             atlas:"Melbourne Subcortical",                  belongTo: "Pallidum",                          fullname: "Post Pallidum",                    varname:{Left:"DAT_Post_pallidum_L",                    Right:"DAT_Post_pallidum_R"                     }, weight: {Left:unitVolume["MS"]["post_pallidum"     ].Left, Right:unitVolume["MS"]["post_pallidum"     ].Right}  },
        {static: true, primaryKey: 12,  identifier: 12, belongToForeignKey:  51, level: 0, report:true,  type: 'sbr',                 multiAtlas:"Melbourne Subcortical",             atlas:"Melbourne Subcortical",                  belongTo: "Ventral striatum",                  fullname: "Ventral striatum",                 varname:{Left:"DAT_Ventral_striatum_L",                 Right:"DAT_Ventral_striatum_R"                  }},
        // TODO: Dorsal raphe nucleus 변수명 DB에 추가 필요 (현재: DAT_Caudate_asymmetry_index, R 사용중)
        {static: true, primaryKey: 13,  identifier: 13, belongToForeignKey:  52, level: 0, report:false,  type: 'sbr',                 multiAtlas:"Melbourne Subcortical",             atlas:"AAL3",                                   belongTo: "Dorsal raphe nucleus",              fullname: "Dorsal raphe nucleus",             varname:{Left:"DAT_Dorsal_raphe_nucleus",               Right:"DAT_Dorsal_raphe_nucleus"             }},
        {static: true, primaryKey: 14,  identifier: 14, belongToForeignKey:  53, level: 0, report:false,  type: 'sbr',                 multiAtlas:"Melbourne Subcortical",             atlas:"AAL3",                                   belongTo: "Locus coeruleus",                   fullname: "Locus coeruleus",                  varname:{Left:"DAT_Locus_coeruleus_L",                  Right:"DAT_Locus_coeruleus_R"                  }},     
        
        {static: true, primaryKey: 15,  identifier: 15, belongToForeignKey:  54, level: 0, report:false,  type: 'sbr',                 multiAtlas:"Melbourne Subcortical",             atlas:"ATAG",                                   belongTo: "Substantia nigra",                  fullname: "Substantia nigra",                 varname:{Left:"DAT_Substantia_nigra_L",                 Right:"DAT_Substantia_nigra_R"                  }},
        {static: true, primaryKey: 16,  identifier: 16, belongToForeignKey:  55, level: 0, report:false,  type: 'sbr',                 multiAtlas:"Melbourne Subcortical",             atlas:"ATAG",                                   belongTo: "Subthalamic nucleus",               fullname: "Subthalamic nucleus",              varname:{Left:"DAT_Subthalamic_nucleus_L",              Right:"DAT_Subthalamic_nucleus_R"                  }},
        
        {static: true, primaryKey: 17,  identifier: 17, belongToForeignKey:  56, level: 0, report:true,  type: 'semiquantification',  multiAtlas:"Melbourne Subcortical",             atlas:"Melbourne Subcortical",                  belongTo: "Striatal asymmetry index",          fullname: "Striatal asymmetry index",         varname:{Left:"DAT_Striatal_asymmetry_index",           Right:"DAT_Striatal_asymmetry_index"            }},
        {static: true, primaryKey: 18,  identifier: 18, belongToForeignKey:  57, level: 0, report:true,  type: 'semiquantification',  multiAtlas:"Melbourne Subcortical",             atlas:"Melbourne Subcortical",                  belongTo: "Caudate asymmetry index",           fullname: "Caudate asymmetry index",          varname:{Left:"DAT_Caudate_asymmetry_index",            Right:"DAT_Caudate_asymmetry_index"             }},
        {static: true, primaryKey: 19,  identifier: 19, belongToForeignKey:  58, level: 0, report:true,  type: 'semiquantification',  multiAtlas:"Melbourne Subcortical",             atlas:"Melbourne Subcortical",                  belongTo: "Putamen asymmetry index",           fullname: "Putamen asymmetry index",          varname:{Left:"DAT_Putamen_asymmetry_index",            Right:"DAT_Putamen_asymmetry_index"             }},
        {static: true, primaryKey: 20,  identifier: 20, belongToForeignKey:  59, level: 0, report:true,  type: 'semiquantification',  multiAtlas:"Melbourne Subcortical",             atlas:"Melbourne Subcortical",                  belongTo: "Putamen-caudate ratio",             fullname: "Putamen-caudate ratio",            varname:{Left:"DAT_Putamen_caudate_ratio_L",            Right:"DAT_Putamen_caudate_ratio_R"             }},
        {static: true, primaryKey: 21,  identifier: 21, belongToForeignKey:  60, level: 0, report:true,  type: 'semiquantification',  multiAtlas:"Melbourne Subcortical",             atlas:"Melbourne Subcortical",                  belongTo: "Caudate-putamen ratio",             fullname: "Caudate-putamen ratio",            varname:{Left:"DAT_Caudate_putamen_ratio_L",            Right:"DAT_Caudate_putamen_ratio_R"             }},
        {static: true, primaryKey: 22,  identifier: 22, belongToForeignKey:  61, level: 0, report:true,  type: 'semiquantification',  multiAtlas:"Melbourne Subcortical",             atlas:"Melbourne Subcortical",                  belongTo: "Anterior-posterior putamen ratio",  fullname: "Anterior-posterior putamen ratio", varname:{Left:"DAT_Anterior_posterior_putamen_ratio_L", Right:"DAT_Anterior_posterior_putamen_ratio_R"  }},

        
        
        {static: true, primaryKey: 33,  identifier: 25, belongToForeignKey:  64, level: 0, report:true, type: 'sbr',                 multiAtlas:"AAL3",                              atlas:"AAL3",                                   belongTo: "Caudate",                           fullname: "Caudate",                          varname:{Left:"DAT_AAL3_Caudate_L",                      Right:"DAT_AAL3_Caudate_R"                        }, weight: {Left:unitVolume["DAT_AAL3"]["caudate"     ].Left, Right:unitVolume["DAT_AAL3"]["caudate"     ].Right}  },
        {static: true, primaryKey: 34,  identifier: 26, belongToForeignKey:  65, level: 0, report:true, type: 'sbr',                 multiAtlas:"AAL3",                              atlas:"AAL3",                                   belongTo: "Putamen",                           fullname: "Putamen",                          varname:{Left:"DAT_AAL3_Putamen_L",                      Right:"DAT_AAL3_Putamen_R"                        }, weight: {Left:unitVolume["DAT_AAL3"]["putamen"     ].Left, Right:unitVolume["DAT_AAL3"]["putamen"     ].Right}  },
        {static: true, primaryKey: 35,  identifier: 27, belongToForeignKey:  66, level: 0, report:true, type: 'sbr',                 multiAtlas:"AAL3",                              atlas:"AAL3",                                   belongTo: "Pallidum",                          fullname: "Pallidum",                         varname:{Left:"DAT_AAL3_Pallidum_L",                     Right:"DAT_AAL3_Pallidum_R"                       }},     
        //TODO : Nucleus accumbens varname이 Ventral_striatum L/R 로 들어가있음                                                                                                                                                                                                                                                                                                                   
        {static: true, primaryKey: 36,  identifier: 28, belongToForeignKey:  67, level: 0, report:true,  type: 'sbr',                 multiAtlas:"AAL3",                              atlas:"AAL3",                                   belongTo: "Nucleus accumbens",                 fullname: "Nucleus accumbens",                varname:{Left:"DAT_Ventral_striatum_L",                 Right:"DAT_Ventral_striatum_R"                  }},
                                                                                                                                                                                                                                                                                                                                                                                           
        {static: true, primaryKey: 23,  identifier: 23, belongToForeignKey:  62, level: 0, report:true,  type: 'sbr',                 multiAtlas:"AAL3",                              atlas:"AAL3",                                   belongTo: "Dorsal raphe nucleus",              fullname: "Dorsal raphe nucleus",             varname:{Left:"DAT_Dorsal_raphe_nucleus",               Right:"DAT_Dorsal_raphe_nucleus"             }},
        {static: true, primaryKey: 24,  identifier: 24, belongToForeignKey:  63, level: 0, report:true,  type: 'sbr',                 multiAtlas:"AAL3",                              atlas:"AAL3",                                   belongTo: "Locus coeruleus",                   fullname: "Locus coeruleus",                  varname:{Left:"DAT_Locus_coeruleus_L",                  Right:"DAT_Locus_coeruleus_R"                  }},
        {static: true, primaryKey: 25,  identifier: 29, belongToForeignKey:  68, level: 0, report:true,  type: 'sbr',                 multiAtlas:"AAL3",                              atlas:"ATAG",                                   belongTo: "Substantia nigra",                  fullname: "Substantia nigra",                 varname:{Left:"DAT_Substantia_nigra_L",                 Right:"DAT_Substantia_nigra_R"                  }},
        {static: true, primaryKey: 26,  identifier: 30, belongToForeignKey:  69, level: 0, report:true,  type: 'sbr',                 multiAtlas:"AAL3",                              atlas:"ATAG",                                   belongTo: "Subthalamic nucleus",               fullname: "Subthalamic nucleus",              varname:{Left:"DAT_Subthalamic_nucleus_L",              Right:"DAT_Subthalamic_nucleus_R"                  }},

        {static: true, primaryKey: 27,  identifier: 31, belongToForeignKey:  70, level: 0, report:true,  type: 'semiquantification',  multiAtlas:"AAL3",                              atlas:"AAL3",                                   belongTo: "Striatal asymmetry index",          fullname: "Striatal asymmetry index",         varname:{Left:"DAT_Striatal_asymmetry_index",           Right:"DAT_Striatal_asymmetry_index"            }},
        {static: true, primaryKey: 28,  identifier: 32, belongToForeignKey:  71, level: 0, report:true,  type: 'semiquantification',  multiAtlas:"AAL3",                              atlas:"AAL3",                                   belongTo: "Caudate asymmetry index",           fullname: "Caudate asymmetry index",          varname:{Left:"DAT_Caudate_asymmetry_index",            Right:"DAT_Caudate_asymmetry_index"             }},
        {static: true, primaryKey: 29,  identifier: 33, belongToForeignKey:  72, level: 0, report:true,  type: 'semiquantification',  multiAtlas:"AAL3",                              atlas:"AAL3",                                   belongTo: "Putamen asymmetry index",           fullname: "Putamen asymmetry index",          varname:{Left:"DAT_Putamen_asymmetry_index",            Right:"DAT_Putamen_asymmetry_index"             }},

        {static: true, primaryKey: 30,  identifier: 34, belongToForeignKey:  73, level: 0, report:true,  type: 'semiquantification',  multiAtlas:"AAL3",                              atlas:"AAL3",                                   belongTo: "Putamen-caudate ratio",             fullname: "Putamen-caudate ratio",            varname:{Left:"DAT_Putamen_caudate_ratio_L",            Right:"DAT_Putamen_caudate_ratio_R"             }},
        {static: true, primaryKey: 31,  identifier: 35, belongToForeignKey:  74, level: 0, report:true,  type: 'semiquantification',  multiAtlas:"AAL3",                              atlas:"AAL3",                                   belongTo: "Caudate-putamen ratio",             fullname: "Caudate-putamen ratio",            varname:{Left:"DAT_Caudate_putamen_ratio_L",            Right:"DAT_Caudate_putamen_ratio_R"             }},
        {static: true, primaryKey: 32,  identifier: 36, belongToForeignKey:  75, level: 0, report:true,  type: 'semiquantification',  multiAtlas:"AAL3",                              atlas:"AAL3",                                   belongTo: "Anterior-posterior putamen ratio",  fullname: "Anterior-posterior putamen ratio", varname:{Left:"DAT_Anterior_posterior_putamen_ratio_L", Right:"DAT_Anterior_posterior_putamen_ratio_R"  }},
        

        {static: true, primaryKey: 33,  identifier: 37, belongToForeignKey:  76, level: 0, report:true, type: 'sbr',                 multiAtlas:"Harvard-Oxford",                    atlas:"Harvard-Oxford",                         belongTo: "Caudate",                           fullname: "Caudate",                          varname:{Left:"DAT_HO_Caudate_L",                        Right:"DAT_HO_Caudate_R"                        }, weight: {Left:unitVolume["DAT_HO"]["caudate"     ].Left, Right:unitVolume["DAT_HO"]["caudate"     ].Right}  },
        {static: true, primaryKey: 34,  identifier: 38, belongToForeignKey:  77, level: 0, report:true, type: 'sbr',                 multiAtlas:"Harvard-Oxford",                    atlas:"Harvard-Oxford",                         belongTo: "Putamen",                           fullname: "Putamen",                          varname:{Left:"DAT_HO_Putamen_L",                        Right:"DAT_HO_Putamen_R"                        }, weight: {Left:unitVolume["DAT_HO"]["putamen"     ].Left, Right:unitVolume["DAT_HO"]["putamen"     ].Right}  },
        {static: true, primaryKey: 35,  identifier: 39, belongToForeignKey:  78, level: 0, report:true, type: 'sbr',                 multiAtlas:"Harvard-Oxford",                    atlas:"Harvard-Oxford",                         belongTo: "Pallidum",                          fullname: "Pallidum",                         varname:{Left:"DAT_HO_Pallidum_L",                       Right:"DAT_HO_Pallidum_R"                       }},     
        //TODO : Nucleus accumbens varname이 Ventral_striatum L/R 로 들어가있음                                                                                                                                                                                                                                                                                                                   
        {static: true, primaryKey: 36,  identifier: 40, belongToForeignKey:  79, level: 0, report:true,  type: 'sbr',                 multiAtlas:"Harvard-Oxford",                    atlas:"Harvard-Oxford",                         belongTo: "Nucleus accumbens",                 fullname: "Nucleus accumbens",                varname:{Left:"DAT_Ventral_striatum_L",                 Right:"DAT_Ventral_striatum_R"                  }},
        {static: true, primaryKey: 37,  identifier: 41, belongToForeignKey:  80, level: 0, report:true,  type: 'sbr',                 multiAtlas:"Harvard-Oxford",                    atlas:"AAL3",                                   belongTo: "Dorsal raphe nucleus",              fullname: "Dorsal raphe nucleus",             varname:{Left:"DAT_Dorsal_raphe_nucleus",               Right:"DAT_Dorsal_raphe_nucleus"             }},
        {static: true, primaryKey: 38,  identifier: 42, belongToForeignKey:  81, level: 0, report:true,  type: 'sbr',                 multiAtlas:"Harvard-Oxford",                    atlas:"AAL3",                                   belongTo: "Locus coeruleus",                   fullname: "Locus coeruleus",                  varname:{Left:"DAT_Locus_coeruleus_L",                  Right:"DAT_Locus_coeruleus_R"                  }},     
        {static: true, primaryKey: 39,  identifier: 43, belongToForeignKey:  82, level: 0, report:true,  type: 'sbr',                 multiAtlas:"Harvard-Oxford",                    atlas:"ATAG",                                   belongTo: "Substantia nigra",                  fullname: "Substantia nigra",                 varname:{Left:"DAT_Substantia_nigra_L",                 Right:"DAT_Substantia_nigra_R"                  }},
        {static: true, primaryKey: 40,  identifier: 44, belongToForeignKey:  83, level: 0, report:true,  type: 'sbr',                 multiAtlas:"Harvard-Oxford",                    atlas:"ATAG",                                   belongTo: "Subthalamic nucleus",               fullname: "Subthalamic nucleus",              varname:{Left:"DAT_Subthalamic_nucleus_L",              Right:"DAT_Subthalamic_nucleus_R"                  }},

        {static: true, primaryKey: 27,  identifier: 31, belongToForeignKey:  70, level: 0, report:true,  type: 'semiquantification',  multiAtlas:"Harvard-Oxford",                    atlas:"Harvard-Oxford",                         belongTo: "Striatal asymmetry index",          fullname: "Striatal asymmetry index",         varname:{Left:"DAT_Striatal_asymmetry_index",           Right:"DAT_Striatal_asymmetry_index"            }},
        {static: true, primaryKey: 28,  identifier: 32, belongToForeignKey:  71, level: 0, report:true,  type: 'semiquantification',  multiAtlas:"Harvard-Oxford",                    atlas:"Harvard-Oxford",                         belongTo: "Caudate asymmetry index",           fullname: "Caudate asymmetry index",          varname:{Left:"DAT_Caudate_asymmetry_index",            Right:"DAT_Caudate_asymmetry_index"             }},
        {static: true, primaryKey: 29,  identifier: 33, belongToForeignKey:  72, level: 0, report:true,  type: 'semiquantification',  multiAtlas:"Harvard-Oxford",                    atlas:"Harvard-Oxford",                         belongTo: "Putamen asymmetry index",           fullname: "Putamen asymmetry index",          varname:{Left:"DAT_Putamen_asymmetry_index",            Right:"DAT_Putamen_asymmetry_index"             }},

        {static: true, primaryKey: 41,  identifier: 45, belongToForeignKey:  84, level: 0, report:true,  type: 'semiquantification',  multiAtlas:"Harvard-Oxford",                    atlas:"Harvard-Oxford",                         belongTo: "Putamen-caudate ratio",             fullname: "Putamen-caudate ratio",            varname:{Left:"DAT_Putamen_caudate_ratio_L",            Right:"DAT_Putamen_caudate_ratio_R"             }},
        {static: true, primaryKey: 42,  identifier: 46, belongToForeignKey:  85, level: 0, report:true,  type: 'semiquantification',  multiAtlas:"Harvard-Oxford",                    atlas:"Harvard-Oxford",                         belongTo: "Caudate-putamen ratio",             fullname: "Caudate-putamen ratio",            varname:{Left:"DAT_Caudate_putamen_ratio_L",            Right:"DAT_Caudate_putamen_ratio_R"             }},
        {static: true, primaryKey: 43,  identifier: 47, belongToForeignKey:  86, level: 0, report:true,  type: 'semiquantification',  multiAtlas:"Harvard-Oxford",                    atlas:"Harvard-Oxford",                         belongTo: "Anterior-posterior putamen ratio",  fullname: "Anterior-posterior putamen ratio", varname:{Left:"DAT_Anterior_posterior_putamen_ratio_L", Right:"DAT_Anterior_posterior_putamen_ratio_R"  }},
        // {static: true, primaryKey:  0,  identifier:  0, belongToForeignKey:   0, level: 0, report:true,  type: 'sbr',                 atlas:"Melbourne Subcortical", belongTo: "Dorsal striatum",                   fullname: "Dorsal striatum",                  varname:{Left:"DAT_Dorsal_striatum_L",                  Right:"DAT_Dorsal_striatum_R"                   }},
        // {static: true, primaryKey:  1,  identifier:  1, belongToForeignKey:   1, level: 0, report:false, type: 'sbr',                 atlas:"Melbourne Subcortical", belongTo: "Caudate nucleus",                   fullname: "Caudate nucleus",                  varname:{Left:"DAT_Caudate_nucleus_L",                  Right:"DAT_Caudate_nucleus_R"                   }},
        // {static: true, primaryKey:  2,  identifier:  2, belongToForeignKey:   1, level: 1, report:true,  type: 'sbr',                 atlas:"Melbourne Subcortical", belongTo: "Caudate nucleus",                   fullname: "Caudate DA",                       varname:{Left:"DAT_Dorsoant_caudate_L",                 Right:"DAT_Dorsoant_caudate_R"                  },weight: {Left:unitVolume["MS"]["dorsoant_caudate"  ].Left, Right:unitVolume["MS"]["dorsoant_caudate"  ].Right} },
        // {static: true, primaryKey:  3,  identifier:  3, belongToForeignKey:   1, level: 1, report:true,  type: 'sbr',                 atlas:"Melbourne Subcortical", belongTo: "Caudate nucleus",                   fullname: "Caudate VA",                       varname:{Left:"DAT_Ventroant_caudate_L",                Right:"DAT_Ventroant_caudate_R"                 },weight: {Left:unitVolume["MS"]["ventroant_caudate" ].Left, Right:unitVolume["MS"]["ventroant_caudate" ].Right} },
        // {static: true, primaryKey:  4,  identifier:  4, belongToForeignKey:   1, level: 1, report:true,  type: 'sbr',                 atlas:"Melbourne Subcortical", belongTo: "Caudate nucleus",                   fullname: "Caudate body",                     varname:{Left:"DAT_Caudate_body_L",                     Right:"DAT_Caudate_body_R"                      },weight: {Left:unitVolume["MS"]["caudate_body"      ].Left, Right:unitVolume["MS"]["caudate_body"      ].Right} },
        // {static: true, primaryKey:  5,  identifier:  5, belongToForeignKey:   1, level: 1, report:true,  type: 'sbr',                 atlas:"Melbourne Subcortical", belongTo: "Caudate nucleus",                   fullname: "Caudate tail",                     varname:{Left:"DAT_Caudate_tail_L",                     Right:"DAT_Caudate_tail_R"                      },weight: {Left:unitVolume["MS"]["caudate_tail"      ].Left, Right:unitVolume["MS"]["caudate_tail"      ].Right} },
        // {static: true, primaryKey:  6,  identifier:  6, belongToForeignKey:   6, level: 0, report:false, type: 'sbr',                 atlas:"Melbourne Subcortical", belongTo: "Putamen",                           fullname: "Putamen",                          varname:{Left:"DAT_Putamen_L",                          Right:"DAT_Putamen_R"                           }},
        // {static: true, primaryKey:  7,  identifier:  7, belongToForeignKey:   6, level: 1, report:true,  type: 'sbr',                 atlas:"Melbourne Subcortical", belongTo: "Putamen",                           fullname: "Putamen DA",                       varname:{Left:"DAT_Dorsoant_putamen_L",                 Right:"DAT_Dorsoant_putamen_R"                  },weight: {Left:unitVolume["MS"]["dorsoant_putamen"  ].Left, Right:unitVolume["MS"]["dorsoant_putamen"  ].Right} },
        // {static: true, primaryKey:  8,  identifier:  8, belongToForeignKey:   6, level: 1, report:true,  type: 'sbr',                 atlas:"Melbourne Subcortical", belongTo: "Putamen",                           fullname: "Putamen VA",                       varname:{Left:"DAT_Ventroant_putamen_L",                Right:"DAT_Ventroant_putamen_R"                 },weight: {Left:unitVolume["MS"]["ventroant_putamen" ].Left, Right:unitVolume["MS"]["ventroant_putamen" ].Right} },
        // {static: true, primaryKey:  9,  identifier:  9, belongToForeignKey:   6, level: 1, report:true,  type: 'sbr',                 atlas:"Melbourne Subcortical", belongTo: "Putamen",                           fullname: "Putamen DP",                       varname:{Left:"DAT_Dorsopost_putamen_L",                Right:"DAT_Dorsopost_putamen_R"                 },weight: {Left:unitVolume["MS"]["dorsopost_putamen" ].Left, Right:unitVolume["MS"]["dorsopost_putamen" ].Right} },
        // {static: true, primaryKey: 10,  identifier: 10, belongToForeignKey:   6, level: 1, report:true,  type: 'sbr',                 atlas:"Melbourne Subcortical", belongTo: "Putamen",                           fullname: "Putamen VP",                       varname:{Left:"DAT_Ventropost_Putamen_L",               Right:"DAT_Ventropost_Putamen_R"                },weight: {Left:unitVolume["MS"]["ventropost_putamen"].Left, Right:unitVolume["MS"]["ventropost_putamen"].Right} },
        // {static: true, primaryKey: 11,  identifier: 11, belongToForeignKey:  11, level: 0, report:false, type: 'sbr',                 atlas:"Melbourne Subcortical", belongTo: "Pallidum",                          fullname: "Pallidum",                         varname:{Left:"DAT_Pallidum_L",                         Right:"DAT_Pallidum_R"                          }},
        // {static: true, primaryKey: 12,  identifier: 12, belongToForeignKey:  11, level: 1, report:true,  type: 'sbr',                 atlas:"Melbourne Subcortical", belongTo: "Pallidum",                          fullname: "Ant Pallidum",                     varname:{Left:"DAT_Ant_pallidum_L",                     Right:"DAT_Ant_pallidum_R"                      },weight: {Left:unitVolume["MS"]["ant_pallidum"      ].Left, Right:unitVolume["MS"]["ant_pallidum"      ].Right} },
        // {static: true, primaryKey: 13,  identifier: 13, belongToForeignKey:  11, level: 1, report:true,  type: 'sbr',                 atlas:"Melbourne Subcortical", belongTo: "Pallidum",                          fullname: "Post Pallidum",                    varname:{Left:"DAT_Post_pallidum_L",                    Right:"DAT_Post_pallidum_R"                     },weight: {Left:unitVolume["MS"]["post_pallidum"     ].Left, Right:unitVolume["MS"]["post_pallidum"     ].Right} },
        // {static: true, primaryKey: 14,  identifier: 14, belongToForeignKey:  14, level: 0, report:true,  type: 'sbr',                 atlas:"Melbourne Subcortical", belongTo: "Ventral striatum",                  fullname: "Ventral striatum",                 varname:{Left:"DAT_Ventral_striatum_L",                 Right:"DAT_Ventral_striatum_R"                  }},
        // {static: true, primaryKey: 15,  identifier: 15, belongToForeignKey:  15, level: 0, report:false, type: 'sbr',                 atlas:"AAL3",                  belongTo: "Caudate",                           fullname: "Caudate",                          varname:{Left:"DAT_AAL3_Caudate_L",                     Right:"DAT_AAL3_Caudate_R"                      }},
        // {static: true, primaryKey: 16,  identifier: 16, belongToForeignKey:  16, level: 0, report:false, type: 'sbr',                 atlas:"AAL3",                  belongTo: "Putamen",                           fullname: "Putamen",                          varname:{Left:"DAT_AAL3_Putamen_L",                     Right:"DAT_AAL3_Putamen_R"                      }},
        // {static: true, primaryKey: 17,  identifier: 17, belongToForeignKey:  17, level: 0, report:false, type: 'sbr',                 atlas:"AAL3",                  belongTo: "Pallidum",                          fullname: "Pallidum",                         varname:{Left:"DAT_AAL3_Pallidum_L",                    Right:"DAT_AAL3_Pallidum_R"                     }},
        // {static: true, primaryKey: 18,  identifier: 18, belongToForeignKey:  18, level: 0, report:false, type: 'sbr',                 atlas:"Harvard-Oxford",        belongTo: "Caudate",                           fullname: "Caudate",                          varname:{Left:"DAT_HO_Caudate_L",                       Right:"DAT_HO_Caudate_R"                        }},
        // {static: true, primaryKey: 19,  identifier: 19, belongToForeignKey:  19, level: 0, report:false, type: 'sbr',                 atlas:"Harvard-Oxford",        belongTo: "Putamen",                           fullname: "Putamen",                          varname:{Left:"DAT_HO_Putamen_L",                       Right:"DAT_HO_Putamen_R"                        }},
        // {static: true, primaryKey: 20,  identifier: 20, belongToForeignKey:  20, level: 0, report:false, type: 'sbr',                 atlas:"Harvard-Oxford",        belongTo: "Pallidum",                          fullname: "Pallidum",                         varname:{Left:"DAT_HO_Pallidum_L",                      Right:"DAT_HO_Pallidum_R"                       }},     
        // {static: true, primaryKey: 21,  identifier: 21, belongToForeignKey:  21, level: 0, report:true,  type: 'semiquantification',  atlas:"Melbourne Subcortical", belongTo: "Striatal asymmetry index",          fullname: "Striatal asymmetry index",         varname:{Left:"DAT_Striatal_asymmetry_index",           Right:"DAT_Striatal_asymmetry_index"            }},
        // {static: true, primaryKey: 22,  identifier: 22, belongToForeignKey:  22, level: 0, report:true,  type: 'semiquantification',  atlas:"Melbourne Subcortical", belongTo: "Caudate asymmetry index",           fullname: "Caudate asymmetry index",          varname:{Left:"DAT_Caudate_asymmetry_index",            Right:"DAT_Caudate_asymmetry_index"             }},
        // {static: true, primaryKey: 23,  identifier: 23, belongToForeignKey:  23, level: 0, report:true,  type: 'semiquantification',  atlas:"Melbourne Subcortical", belongTo: "Putamen asymmetry index",           fullname: "Putamen asymmetry index",          varname:{Left:"DAT_Putamen_asymmetry_index",            Right:"DAT_Putamen_asymmetry_index"             }},
        // {static: true, primaryKey: 24,  identifier: 24, belongToForeignKey:  24, level: 0, report:true,  type: 'semiquantification',  atlas:"Melbourne Subcortical", belongTo: "Putamen-caudate ratio",             fullname: "Putamen-caudate ratio",            varname:{Left:"DAT_Putamen_caudate_ratio_L",            Right:"DAT_Putamen_caudate_ratio_R"             }},
        // {static: true, primaryKey: 25,  identifier: 25, belongToForeignKey:  25, level: 0, report:true,  type: 'semiquantification',  atlas:"Melbourne Subcortical", belongTo: "Caudate-putamen ratio",             fullname: "Caudate-putamen ratio",            varname:{Left:"DAT_Caudate_putamen_ratio_L",            Right:"DAT_Caudate_putamen_ratio_R"             }},
        // {static: true, primaryKey: 26,  identifier: 26, belongToForeignKey:  26, level: 0, report:true,  type: 'semiquantification',  atlas:"Melbourne Subcortical", belongTo: "Anterior-posterior putamen ratio",  fullname: "Anterior-posterior putamen ratio", varname:{Left:"DAT_Anterior_posterior_putamen_ratio_L", Right:"DAT_Anterior_posterior_putamen_ratio_R"  }},
        // {static: true, primaryKey: 27,  identifier: 27, belongToForeignKey:  27, level: 0, report:true,  type: 'semiquantification',  atlas:"AAL3",                  belongTo: "Striatal asymmetry index",          fullname: "Striatal asymmetry index",         varname:{Left:"DAT_Striatal_asymmetry_index",           Right:"DAT_Striatal_asymmetry_index"            }},
        // {static: true, primaryKey: 28,  identifier: 28, belongToForeignKey:  28, level: 0, report:true,  type: 'semiquantification',  atlas:"AAL3",                  belongTo: "Caudate asymmetry index",           fullname: "Caudate asymmetry index",          varname:{Left:"DAT_Caudate_asymmetry_index",            Right:"DAT_Caudate_asymmetry_index"             }},
        // {static: true, primaryKey: 29,  identifier: 29, belongToForeignKey:  29, level: 0, report:true,  type: 'semiquantification',  atlas:"AAL3",                  belongTo: "Putamen asymmetry index",           fullname: "Putamen asymmetry index",          varname:{Left:"DAT_Putamen_asymmetry_index",            Right:"DAT_Putamen_asymmetry_index"             }},
        // {static: true, primaryKey: 30,  identifier: 30, belongToForeignKey:  30, level: 0, report:true,  type: 'semiquantification',  atlas:"AAL3",                  belongTo: "Putamen-caudate ratio",             fullname: "Putamen-caudate ratio",            varname:{Left:"DAT_Putamen_caudate_ratio_L",            Right:"DAT_Putamen_caudate_ratio_R"             }},
        // {static: true, primaryKey: 31,  identifier: 31, belongToForeignKey:  31, level: 0, report:true,  type: 'semiquantification',  atlas:"AAL3",                  belongTo: "Caudate-putamen ratio",             fullname: "Caudate-putamen ratio",            varname:{Left:"DAT_Caudate_putamen_ratio_L",            Right:"DAT_Caudate_putamen_ratio_R"             }},
        // {static: true, primaryKey: 32,  identifier: 32, belongToForeignKey:  32, level: 0, report:true,  type: 'semiquantification',  atlas:"AAL3",                  belongTo: "Anterior-posterior putamen ratio",  fullname: "Anterior-posterior putamen ratio", varname:{Left:"DAT_Anterior_posterior_putamen_ratio_L", Right:"DAT_Anterior_posterior_putamen_ratio_R"  }},
        // {static: true, primaryKey: 33,  identifier: 33, belongToForeignKey:  33, level: 0, report:true,  type: 'semiquantification',  atlas:"Harvard-Oxford",        belongTo: "Striatal asymmetry index",          fullname: "Striatal asymmetry index",         varname:{Left:"DAT_Striatal_asymmetry_index",           Right:"DAT_Striatal_asymmetry_index"            }},
        // {static: true, primaryKey: 34,  identifier: 34, belongToForeignKey:  34, level: 0, report:true,  type: 'semiquantification',  atlas:"Harvard-Oxford",        belongTo: "Caudate asymmetry index",           fullname: "Caudate asymmetry index",          varname:{Left:"DAT_Caudate_asymmetry_index",            Right:"DAT_Caudate_asymmetry_index"             }},
        // {static: true, primaryKey: 35,  identifier: 35, belongToForeignKey:  35, level: 0, report:true,  type: 'semiquantification',  atlas:"Harvard-Oxford",        belongTo: "Putamen asymmetry index",           fullname: "Putamen asymmetry index",          varname:{Left:"DAT_Putamen_asymmetry_index",            Right:"DAT_Putamen_asymmetry_index"             }},
        // {static: true, primaryKey: 36,  identifier: 36, belongToForeignKey:  36, level: 0, report:true,  type: 'semiquantification',  atlas:"Harvard-Oxford",        belongTo: "Putamen-caudate ratio",             fullname: "Putamen-caudate ratio",            varname:{Left:"DAT_Putamen_caudate_ratio_L",            Right:"DAT_Putamen_caudate_ratio_R"             }},
        // {static: true, primaryKey: 37,  identifier: 37, belongToForeignKey:  37, level: 0, report:true,  type: 'semiquantification',  atlas:"Harvard-Oxford",        belongTo: "Caudate-putamen ratio",             fullname: "Caudate-putamen ratio",            varname:{Left:"DAT_Caudate_putamen_ratio_L",            Right:"DAT_Caudate_putamen_ratio_R"             }},
        // {static: true, primaryKey: 38,  identifier: 38, belongToForeignKey:  38, level: 0, report:true,  type: 'semiquantification',  atlas:"Harvard-Oxford",        belongTo: "Anterior-posterior putamen ratio",  fullname: "Anterior-posterior putamen ratio", varname:{Left:"DAT_Anterior_posterior_putamen_ratio_L", Right:"DAT_Anterior_posterior_putamen_ratio_R"  }},
        
        // {static: true, primaryKey: 39,  identifier: 39, belongToForeignKey:  39, level: 0, report:true,  type: 'sbr',                 atlas:"Mul1",                  belongTo: "Dorsal striatum",                   fullname: "Dorsal striatum",                  varname:{Left:"DAT_Dorsal_striatum_L",                  Right:"DAT_Dorsal_striatum_R"                   }},
        // {static: true, primaryKey: 40,  identifier: 40, belongToForeignKey:  40, level: 0, report:false, type: 'sbr',                 atlas:"Mul1",                  belongTo: "Caudate nucleus",                   fullname: "Caudate nucleus",                  varname:{Left:"DAT_Caudate_nucleus_L",                  Right:"DAT_Caudate_nucleus_R"                   }},
        // {static: true, primaryKey: 41,  identifier: 41, belongToForeignKey:  40, level: 1, report:true,  type: 'sbr',                 atlas:"Mul1",                  belongTo: "Caudate nucleus",                   fullname: "Caudate DA",                       varname:{Left:"DAT_Dorsoant_caudate_L",                 Right:"DAT_Dorsoant_caudate_R"                  }, weight: {Left:unitVolume["MS"]["dorsoant_caudate" ].Left,  Right:unitVolume["MS"]["dorsoant_caudate"   ].Right} },
        // {static: true, primaryKey: 42,  identifier: 42, belongToForeignKey:  40, level: 1, report:true,  type: 'sbr',                 atlas:"Mul1",                  belongTo: "Caudate nucleus",                   fullname: "Caudate VA",                       varname:{Left:"DAT_Ventroant_caudate_L",                Right:"DAT_Ventroant_caudate_R"                 }, weight: {Left:unitVolume["MS"]["ventroant_caudate"].Left,  Right:unitVolume["MS"]["ventroant_caudate"  ].Right} },
        // {static: true, primaryKey: 43,  identifier: 43, belongToForeignKey:  43, level: 0, report:false, type: 'sbr',                 atlas:"Mul1",                  belongTo: "Putamen",                           fullname: "Putamen",                          varname:{Left:"DAT_Putamen_L",                          Right:"DAT_Putamen_R"                           }},
        // {static: true, primaryKey: 44,  identifier: 44, belongToForeignKey:  43, level: 1, report:true,  type: 'sbr',                 atlas:"Mul1",                  belongTo: "Putamen",                           fullname: "Putamen DA",                       varname:{Left:"DAT_Dorsoant_putamen_L",                 Right:"DAT_Dorsoant_putamen_R"                  }, weight: {Left:unitVolume["MS"]["dorsoant_putamen"  ].Left, Right:unitVolume["MS"]["dorsoant_putamen"  ].Right}  },
        // {static: true, primaryKey: 45,  identifier: 45, belongToForeignKey:  43, level: 1, report:true,  type: 'sbr',                 atlas:"Mul1",                  belongTo: "Putamen",                           fullname: "Putamen VA",                       varname:{Left:"DAT_Ventroant_putamen_L",                Right:"DAT_Ventroant_putamen_R"                 }, weight: {Left:unitVolume["MS"]["ventroant_putamen" ].Left, Right:unitVolume["MS"]["ventroant_putamen" ].Right}  },
        // {static: true, primaryKey: 46,  identifier: 46, belongToForeignKey:  43, level: 1, report:true,  type: 'sbr',                 atlas:"Mul1",                  belongTo: "Putamen",                           fullname: "Putamen DP",                       varname:{Left:"DAT_Dorsopost_putamen_L",                Right:"DAT_Dorsopost_putamen_R"                 }, weight: {Left:unitVolume["MS"]["dorsopost_putamen" ].Left, Right:unitVolume["MS"]["dorsopost_putamen" ].Right}  },
        // {static: true, primaryKey: 47,  identifier: 47, belongToForeignKey:  43, level: 1, report:true,  type: 'sbr',                 atlas:"Mul1",                  belongTo: "Putamen",                           fullname: "Putamen VP",                       varname:{Left:"DAT_Ventropost_Putamen_L",               Right:"DAT_Ventropost_Putamen_R"                }, weight: {Left:unitVolume["MS"]["ventropost_putamen"].Left, Right:unitVolume["MS"]["ventropost_putamen"].Right}  },
        // {static: true, primaryKey: 48,  identifier: 48, belongToForeignKey:  48, level: 0, report:false, type: 'sbr',                 atlas:"Mul1",                  belongTo: "Pallidum",                          fullname: "Pallidum",                         varname:{Left:"DAT_Pallidum_L",                         Right:"DAT_Pallidum_R"                          }},
        // {static: true, primaryKey: 49,  identifier: 49, belongToForeignKey:  48, level: 1, report:true,  type: 'sbr',                 atlas:"Mul1",                  belongTo: "Pallidum",                          fullname: "Ant Pallidum",                     varname:{Left:"DAT_Ant_pallidum_L",                     Right:"DAT_Ant_pallidum_R"                      }, weight: {Left:unitVolume["MS"]["ant_pallidum"      ].Left, Right:unitVolume["MS"]["ant_pallidum"      ].Right}  },
        // {static: true, primaryKey: 50,  identifier: 50, belongToForeignKey:  48, level: 1, report:true,  type: 'sbr',                 atlas:"Mul1",                  belongTo: "Pallidum",                          fullname: "Post Pallidum",                    varname:{Left:"DAT_Post_pallidum_L",                    Right:"DAT_Post_pallidum_R"                     }, weight: {Left:unitVolume["MS"]["post_pallidum"     ].Left, Right:unitVolume["MS"]["post_pallidum"     ].Right}  },
        // {static: true, primaryKey: 51,  identifier: 51, belongToForeignKey:  51, level: 0, report:true,  type: 'sbr',                 atlas:"Mul1",                  belongTo: "Ventral striatum",                  fullname: "Ventral striatum",                 varname:{Left:"DAT_Ventral_striatum_L",                 Right:"DAT_Ventral_striatum_R"                  }},
        // // TODO: Dorsal raphe nucleus 변수명 DB에 추가 필요 (현재: DAT_Caudate_asymmetry_index, R 사용중)
        // {static: true, primaryKey: 52,  identifier: 52, belongToForeignKey:  52, level: 0, report:true,  type: 'sbr',                 atlas:"Mul1",                  belongTo: "Dorsal raphe nucleus",              fullname: "Dorsal raphe nucleus",             varname:{Left:"DAT_Caudate_asymmetry_index",            Right:"DAT_Caudate_asymmetry_index"             }},
        // {static: true, primaryKey: 53,  identifier: 53, belongToForeignKey:  53, level: 0, report:true,  type: 'sbr',                 atlas:"Mul1",                  belongTo: "Locus coeruleus",                   fullname: "Locus coeruleus",                  varname:{Left:"DAT_Ventral_striatum_L",                 Right:"DAT_Ventral_striatum_R"                  }},     
        // {static: true, primaryKey: 54,  identifier: 54, belongToForeignKey:  54, level: 0, report:true,  type: 'sbr',                 atlas:"Mul1",                  belongTo: "Substantia nigra",                  fullname: "Substantia nigra",                 varname:{Left:"DAT_Ventral_striatum_L",                 Right:"DAT_Ventral_striatum_R"                  }},
        // {static: true, primaryKey: 55,  identifier: 55, belongToForeignKey:  55, level: 0, report:true,  type: 'sbr',                 atlas:"Mul1",                  belongTo: "Subthalamic nucleus",               fullname: "Subthalamic nucleus",              varname:{Left:"DAT_Ventral_striatum_L",                 Right:"DAT_Ventral_striatum_R"                  }},
        // {static: true, primaryKey: 56,  identifier: 56, belongToForeignKey:  56, level: 0, report:true,  type: 'semiquantification',  atlas:"Mul1",                  belongTo: "Striatal asymmetry index",          fullname: "Striatal asymmetry index",         varname:{Left:"DAT_Striatal_asymmetry_index",           Right:"DAT_Striatal_asymmetry_index"            }},
        // {static: true, primaryKey: 57,  identifier: 57, belongToForeignKey:  57, level: 0, report:true,  type: 'semiquantification',  atlas:"Mul1",                  belongTo: "Caudate asymmetry index",           fullname: "Caudate asymmetry index",          varname:{Left:"DAT_Caudate_asymmetry_index",            Right:"DAT_Caudate_asymmetry_index"             }},
        // {static: true, primaryKey: 58,  identifier: 58, belongToForeignKey:  58, level: 0, report:true,  type: 'semiquantification',  atlas:"Mul1",                  belongTo: "Putamen asymmetry index",           fullname: "Putamen asymmetry index",          varname:{Left:"DAT_Putamen_asymmetry_index",            Right:"DAT_Putamen_asymmetry_index"             }},
        // {static: true, primaryKey: 59,  identifier: 59, belongToForeignKey:  59, level: 0, report:true,  type: 'semiquantification',  atlas:"Mul1",                  belongTo: "Putamen-caudate ratio",             fullname: "Putamen-caudate ratio",            varname:{Left:"DAT_Putamen_caudate_ratio_L",            Right:"DAT_Putamen_caudate_ratio_R"             }},
        // {static: true, primaryKey: 60,  identifier: 60, belongToForeignKey:  60, level: 0, report:true,  type: 'semiquantification',  atlas:"Mul1",                  belongTo: "Caudate-putamen ratio",             fullname: "Caudate-putamen ratio",            varname:{Left:"DAT_Caudate_putamen_ratio_L",            Right:"DAT_Caudate_putamen_ratio_R"             }},
        // {static: true, primaryKey: 61,  identifier: 61, belongToForeignKey:  61, level: 0, report:true,  type: 'semiquantification',  atlas:"Mul1",                  belongTo: "Anterior-posterior putamen ratio",  fullname: "Anterior-posterior putamen ratio", varname:{Left:"DAT_Anterior_posterior_putamen_ratio_L", Right:"DAT_Anterior_posterior_putamen_ratio_R"  }},

        // {static: true, primaryKey: 62,  identifier: 62, belongToForeignKey:  62, level: 0, report:true,  type: 'sbr',                 atlas:"Mul2",                  belongTo: "Dorsal raphe nucleus",              fullname: "Dorsal raphe nucleus",             varname:{Left:"DAT_Caudate_asymmetry_index",            Right:"DAT_Caudate_asymmetry_index"             }},
        // {static: true, primaryKey: 63,  identifier: 63, belongToForeignKey:  63, level: 0, report:true,  type: 'sbr',                 atlas:"Mul2",                  belongTo: "Locus coeruleus",                   fullname: "Locus coeruleus",                  varname:{Left:"DAT_Ventral_striatum_L",                 Right:"DAT_Ventral_striatum_R"                  }},
        // {static: true, primaryKey: 64,  identifier: 64, belongToForeignKey:  64, level: 0, report:true,  type: 'sbr',                 atlas:"Mul2",                  belongTo: "Substantia nigra",                  fullname: "Substantia nigra",                 varname:{Left:"DAT_Ventral_striatum_L",                 Right:"DAT_Ventral_striatum_R"                  }},
        // {static: true, primaryKey: 65,  identifier: 65, belongToForeignKey:  65, level: 0, report:true,  type: 'sbr',                 atlas:"Mul2",                  belongTo: "Subthalamic nucleus",               fullname: "Subthalamic nucleus",              varname:{Left:"DAT_Ventral_striatum_L",                 Right:"DAT_Ventral_striatum_R"                  }},
        // {static: true, primaryKey: 66,  identifier: 66, belongToForeignKey:  66, level: 0, report:true,  type: 'semiquantification',  atlas:"Mul2",                  belongTo: "Striatal asymmetry index",          fullname: "Striatal asymmetry index",         varname:{Left:"DAT_Striatal_asymmetry_index",           Right:"DAT_Striatal_asymmetry_index"            }},
        // {static: true, primaryKey: 67,  identifier: 67, belongToForeignKey:  67, level: 0, report:true,  type: 'semiquantification',  atlas:"Mul2",                  belongTo: "Caudate asymmetry index",           fullname: "Caudate asymmetry index",          varname:{Left:"DAT_Caudate_asymmetry_index",            Right:"DAT_Caudate_asymmetry_index"             }},
        // {static: true, primaryKey: 68,  identifier: 68, belongToForeignKey:  68, level: 0, report:true,  type: 'semiquantification',  atlas:"Mul2",                  belongTo: "Putamen asymmetry index",           fullname: "Putamen asymmetry index",          varname:{Left:"DAT_Putamen_asymmetry_index",            Right:"DAT_Putamen_asymmetry_index"             }},
        // {static: true, primaryKey: 69,  identifier: 69, belongToForeignKey:  69, level: 0, report:true,  type: 'semiquantification',  atlas:"Mul2",                  belongTo: "Putamen-caudate ratio",             fullname: "Putamen-caudate ratio",            varname:{Left:"DAT_Putamen_caudate_ratio_L",            Right:"DAT_Putamen_caudate_ratio_R"             }},
        // {static: true, primaryKey: 70,  identifier: 70, belongToForeignKey:  70, level: 0, report:true,  type: 'semiquantification',  atlas:"Mul2",                  belongTo: "Caudate-putamen ratio",             fullname: "Caudate-putamen ratio",            varname:{Left:"DAT_Caudate_putamen_ratio_L",            Right:"DAT_Caudate_putamen_ratio_R"             }},
        // {static: true, primaryKey: 71,  identifier: 71, belongToForeignKey:  71, level: 0, report:true,  type: 'semiquantification',  atlas:"Mul2",                  belongTo: "Anterior-posterior putamen ratio",  fullname: "Anterior-posterior putamen ratio", varname:{Left:"DAT_Anterior_posterior_putamen_ratio_L", Right:"DAT_Anterior_posterior_putamen_ratio_R"  }},
        

        // {static: true, primaryKey: 72,  identifier: 72, belongToForeignKey:  72, level: 0, report:false, type: 'sbr',                 atlas:"Mul3",                  belongTo: "Caudate",                           fullname: "Caudate",                          varname:{Left:"DAT_HO_Caudate_L",                       Right:"DAT_HO_Caudate_R"                        }},
        // {static: true, primaryKey: 73,  identifier: 73, belongToForeignKey:  73, level: 0, report:false, type: 'sbr',                 atlas:"Mul3",                  belongTo: "Putamen",                           fullname: "Putamen",                          varname:{Left:"DAT_HO_Putamen_L",                       Right:"DAT_HO_Putamen_R"                        }},
        // {static: true, primaryKey: 74,  identifier: 74, belongToForeignKey:  74, level: 0, report:false, type: 'sbr',                 atlas:"Mul3",                  belongTo: "Pallidum",                          fullname: "Pallidum",                         varname:{Left:"DAT_HO_Pallidum_L",                      Right:"DAT_HO_Pallidum_R"                       }},     
        // //TODO : Nucleus accumbens varname이 Ventral_striatum L/R 로 들어가있음 
        // {static: true, primaryKey: 75,  identifier: 75, belongToForeignKey:  75, level: 0, report:true,  type: 'sbr',                 atlas:"Mul3",                  belongTo: "Nucleus accumbens",                 fullname: "Nucleus accumbens",                varname:{Left:"DAT_Ventral_striatum_L",                 Right:"DAT_Ventral_striatum_R"                  }},
        // {static: true, primaryKey: 76,  identifier: 76, belongToForeignKey:  76, level: 0, report:true,  type: 'sbr',                 atlas:"Mul3",                  belongTo: "Dorsal raphe nucleus",              fullname: "Dorsal raphe nucleus",             varname:{Left:"DAT_Caudate_asymmetry_index",            Right:"DAT_Caudate_asymmetry_index"             }},
        // {static: true, primaryKey: 77,  identifier: 77, belongToForeignKey:  77, level: 0, report:true,  type: 'sbr',                 atlas:"Mul3",                  belongTo: "Locus coeruleus",                   fullname: "Locus coeruleus",                  varname:{Left:"DAT_Ventral_striatum_L",                 Right:"DAT_Ventral_striatum_R"                  }},     
        // {static: true, primaryKey: 78,  identifier: 78, belongToForeignKey:  78, level: 0, report:true,  type: 'sbr',                 atlas:"Mul3",                  belongTo: "Substantia nigra",                  fullname: "Substantia nigra",                 varname:{Left:"DAT_Ventral_striatum_L",                 Right:"DAT_Ventral_striatum_R"                  }},
        // {static: true, primaryKey: 79,  identifier: 79, belongToForeignKey:  79, level: 0, report:true,  type: 'sbr',                 atlas:"Mul3",                  belongTo: "Subthalamic nucleus",               fullname: "Subthalamic nucleus",              varname:{Left:"DAT_Ventral_striatum_L",                 Right:"DAT_Ventral_striatum_R"                  }},
        // {static: true, primaryKey: 80,  identifier: 80, belongToForeignKey:  80, level: 0, report:true,  type: 'semiquantification',  atlas:"Mul3",                  belongTo: "Putamen-caudate ratio",             fullname: "Putamen-caudate ratio",            varname:{Left:"DAT_Putamen_caudate_ratio_L",            Right:"DAT_Putamen_caudate_ratio_R"             }},
        // {static: true, primaryKey: 81,  identifier: 81, belongToForeignKey:  81, level: 0, report:true,  type: 'semiquantification',  atlas:"Mul3",                  belongTo: "Caudate-putamen ratio",             fullname: "Caudate-putamen ratio",            varname:{Left:"DAT_Caudate_putamen_ratio_L",            Right:"DAT_Caudate_putamen_ratio_R"             }},
        // {static: true, primaryKey: 82,  identifier: 82, belongToForeignKey:  82, level: 0, report:true,  type: 'semiquantification',  atlas:"Mul3",                  belongTo: "Anterior-posterior putamen ratio",  fullname: "Anterior-posterior putamen ratio", varname:{Left:"DAT_Anterior_posterior_putamen_ratio_L", Right:"DAT_Anterior_posterior_putamen_ratio_R"  }},
      ]
      const primaryKeyAndIdentifierArr = arr.map((el,idx)=>({...el, primaryKey:idx, identifier:idx}))
      let belongToForeignKeyVar=0;
      const belongToForeignKeyArr = primaryKeyAndIdentifierArr.map((el,idx)=>{
        if (el.level===0) belongToForeignKeyVar = el.primaryKey
        return {...el, belongToForeignKey:belongToForeignKeyVar}
      })
      const resultArr = belongToForeignKeyArr
      return resultArr
    })(),
    fdg:(()=>{
      const arr = [
        {static: true, primaryKey:  0,  identifier:  0, belongToForeignKey:   0,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"AAL3",            belongTo: "Global",           fullname: "Global",                        varname:{Left:"Global",                               Right:"Global"                              }},
        {static: true, primaryKey:  1,  identifier:  1, belongToForeignKey:   1,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal",                       varname:{Left:"Frontal_L",                            Right:"Frontal_R"                           }},
        {static: true, primaryKey:  2,  identifier:  2, belongToForeignKey:   1,  volumeForeignKey:  0, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Precentral",                    varname:{Left:"Precentral_L",                         Right:"Precentral_R"                        }, weight: {Left:unitVolume["AAL3"]["Precentral"                  ].Left, Right:unitVolume["AAL3"]["Precentral"                  ].Right}},
        {static: true, primaryKey:  3,  identifier:  3, belongToForeignKey:   1,  volumeForeignKey:  1, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Sup",                   varname:{Left:"Frontal_Sup_L",                        Right:"Frontal_Sup_R"                       }, weight: {Left:unitVolume["AAL3"]["Frontal_Sup"                 ].Left, Right:unitVolume["AAL3"]["Frontal_Sup"                 ].Right}},
        {static: true, primaryKey:  4,  identifier:  4, belongToForeignKey:   1,  volumeForeignKey:  2, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Mid",                   varname:{Left:"Frontal_Mid_L",                        Right:"Frontal_Mid_R"                       }, weight: {Left:unitVolume["AAL3"]["Frontal_Mid"                 ].Left, Right:unitVolume["AAL3"]["Frontal_Mid"                 ].Right}},
        {static: true, primaryKey:  5,  identifier:  5, belongToForeignKey:   1,  volumeForeignKey:  3, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Inf Oper",              varname:{Left:"Frontal_Inf_Oper_L",                   Right:"Frontal_Inf_Oper_R"                  }, weight: {Left:unitVolume["AAL3"]["Frontal_Inf_Oper"            ].Left, Right:unitVolume["AAL3"]["Frontal_Inf_Oper"            ].Right}},
        {static: true, primaryKey:  6,  identifier:  6, belongToForeignKey:   1,  volumeForeignKey:  4, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Inf Tri",               varname:{Left:"Frontal_Inf_Tri_L",                    Right:"Frontal_Inf_Tri_R"                   }, weight: {Left:unitVolume["AAL3"]["Frontal_Inf_Tri"             ].Left, Right:unitVolume["AAL3"]["Frontal_Inf_Tri"             ].Right}},
        {static: true, primaryKey:  7,  identifier:  7, belongToForeignKey:   1,  volumeForeignKey:  5, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Inf Orb",               varname:{Left:"Frontal_Inf_Orb_L",                    Right:"Frontal_Inf_Orb_R"                   }, weight: {Left:unitVolume["AAL3"]["Frontal_Inf_Orb"             ].Left, Right:unitVolume["AAL3"]["Frontal_Inf_Orb"             ].Right}},
        {static: true, primaryKey:  8,  identifier:  8, belongToForeignKey:   1,  volumeForeignKey:  6, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Rolandic Oper",                 varname:{Left:"Rolandic_Oper_L",                      Right:"Rolandic_Oper_R"                     }, weight: {Left:unitVolume["AAL3"]["Rolandic_Oper"               ].Left, Right:unitVolume["AAL3"]["Rolandic_Oper"               ].Right}},
        {static: true, primaryKey:  9,  identifier:  9, belongToForeignKey:   1,  volumeForeignKey:  7, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Supp Motor Area",               varname:{Left:"Supp_Motor_Area_L",                    Right:"Supp_Motor_Area_R"                   }, weight: {Left:unitVolume["AAL3"]["Supp_Motor_Area"             ].Left, Right:unitVolume["AAL3"]["Supp_Motor_Area"             ].Right}},
        {static: true, primaryKey: 10,  identifier: 10, belongToForeignKey:   1,  volumeForeignKey:  8, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Olfactory",                     varname:{Left:"Olfactory_L",                          Right:"Olfactory_R"                         }, weight: {Left:unitVolume["AAL3"]["Olfactory"                   ].Left, Right:unitVolume["AAL3"]["Olfactory"                   ].Right}},
        {static: true, primaryKey: 11,  identifier: 11, belongToForeignKey:   1,  volumeForeignKey:  9, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Sup Med",               varname:{Left:"Frontal_Sup_Med_L",                    Right:"Frontal_Sup_Med_R"                   }, weight: {Left:unitVolume["AAL3"]["Frontal_Sup_Med"             ].Left, Right:unitVolume["AAL3"]["Frontal_Sup_Med"             ].Right}},
        {static: true, primaryKey: 12,  identifier: 12, belongToForeignKey:   1,  volumeForeignKey: 10, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Med Orb",               varname:{Left:"Frontal_Med_Orb_L",                    Right:"Frontal_Med_Orb_R"                   }, weight: {Left:unitVolume["AAL3"]["Frontal_Med_Orb"             ].Left, Right:unitVolume["AAL3"]["Frontal_Med_Orb"             ].Right}},
        {static: true, primaryKey: 13,  identifier: 13, belongToForeignKey:   1,  volumeForeignKey: 11, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Rectus",                        varname:{Left:"Rectus_L",                             Right:"Rectus_R"                            }, weight: {Left:unitVolume["AAL3"]["Rectus"                      ].Left, Right:unitVolume["AAL3"]["Rectus"                      ].Right}},
        {static: true, primaryKey: 14,  identifier: 14, belongToForeignKey:   1,  volumeForeignKey: 12, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "OFCmed",                        varname:{Left:"OFCmed_L",                             Right:"OFCmed_R"                            }, weight: {Left:unitVolume["AAL3"]["OFCmed"                      ].Left, Right:unitVolume["AAL3"]["OFCmed"                      ].Right}},
        {static: true, primaryKey: 15,  identifier: 15, belongToForeignKey:   1,  volumeForeignKey: 13, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "OFCant",                        varname:{Left:"OFCant_L",                             Right:"OFCant_R"                            }, weight: {Left:unitVolume["AAL3"]["OFCant"                      ].Left, Right:unitVolume["AAL3"]["OFCant"                      ].Right}},
        {static: true, primaryKey: 16,  identifier: 16, belongToForeignKey:   1,  volumeForeignKey: 14, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "OFCpost",                       varname:{Left:"OFCpost_L",                            Right:"OFCpost_R"                           }, weight: {Left:unitVolume["AAL3"]["OFCpost"                     ].Left, Right:unitVolume["AAL3"]["OFCpost"                     ].Right}},
        {static: true, primaryKey: 17,  identifier: 17, belongToForeignKey:   1,  volumeForeignKey: 15, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "OFClat",                        varname:{Left:"OFClat_L",                             Right:"OFClat_R"                            }, weight: {Left:unitVolume["AAL3"]["OFClat"                      ].Left, Right:unitVolume["AAL3"]["OFClat"                      ].Right}},
        {static: true, primaryKey: 18,  identifier: 18, belongToForeignKey:   1,  volumeForeignKey: 16, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Insula",                        varname:{Left:"Insula_L",                             Right:"Insula_R"                            }, weight: {Left:unitVolume["AAL3"]["Insula"                      ].Left, Right:unitVolume["AAL3"]["Insula"                      ].Right}},
        {static: true, primaryKey: 19,  identifier: 19, belongToForeignKey:   1,  volumeForeignKey: 17, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Cingulate Ant Sub",             varname:{Left:"Cingulate_Ant_Sub_L",                  Right:"Cingulate_Ant_Sub_R"                 }, weight: {Left:unitVolume["AAL3"]["Cingulate_Ant_Sub"           ].Left, Right:unitVolume["AAL3"]["Cingulate_Ant_Sub"           ].Right}},
        {static: true, primaryKey: 20,  identifier: 20, belongToForeignKey:   1,  volumeForeignKey: 18, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Cingulate Ant Pre",             varname:{Left:"Cingulate_Ant_Pre_L",                  Right:"Cingulate_Ant_Pre_R"                 }, weight: {Left:unitVolume["AAL3"]["Cingulate_Ant_Pre"           ].Left, Right:unitVolume["AAL3"]["Cingulate_Ant_Pre"           ].Right}},
        {static: true, primaryKey: 21,  identifier: 21, belongToForeignKey:   1,  volumeForeignKey: 19, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Cingulate Ant Sup",             varname:{Left:"Cingulate_Ant_Sup_L",                  Right:"Cingulate_Ant_Sup_R"                 }, weight: {Left:unitVolume["AAL3"]["Cingulate_Ant_Sup"           ].Left, Right:unitVolume["AAL3"]["Cingulate_Ant_Sup"           ].Right}},
        {static: true, primaryKey: 22,  identifier: 22, belongToForeignKey:   1,  volumeForeignKey: 20, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Cingulate Mid",                 varname:{Left:"Cingulate_Mid_L",                      Right:"Cingulate_Mid_R"                     }, weight: {Left:unitVolume["AAL3"]["Cingulate_Mid"               ].Left, Right:unitVolume["AAL3"]["Cingulate_Mid"               ].Right}},
        {static: true, primaryKey: 23,  identifier: 23, belongToForeignKey:  23,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"AAL3",            belongTo: "PCC-Precuneus",    fullname: "PCC-Precuneus",                 varname:{Left:"PCC_L",                                Right:"PCC_R"                               }},
        {static: true, primaryKey: 24,  identifier: 24, belongToForeignKey:  23,  volumeForeignKey: 21, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "PCC-Precuneus",    fullname: "Cingulate Post",                varname:{Left:"Cingulate_Post_L",                     Right:"Cingulate_Post_R"                    }, weight: {Left:unitVolume["AAL3"]["Cingulate_Post"              ].Left, Right:unitVolume["AAL3"]["Cingulate_Post"              ].Right}},
        {static: true, primaryKey: 25,  identifier: 25, belongToForeignKey:  23,  volumeForeignKey: 22, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "PCC-Precuneus",    fullname: "Precuneus",                     varname:{Left:"Precuneus_L",                          Right:"Precuneus_R"                         }, weight: {Left:unitVolume["AAL3"]["Precuneus"                   ].Left, Right:unitVolume["AAL3"]["Precuneus"                   ].Right}},
        {static: true, primaryKey: 26,  identifier: 26, belongToForeignKey:  26,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Lateral temporal",              varname:{Left:"Lateral_temporal_L",                   Right:"Lateral_temporal_R"                  }},                      
        {static: true, primaryKey: 27,  identifier: 27, belongToForeignKey:  26,  volumeForeignKey: 23, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Heschl",                        varname:{Left:"Heschl_L",                             Right:"Heschl_R"                            }, weight: {Left:unitVolume["AAL3"]["Heschl"                      ].Left, Right:unitVolume["AAL3"]["Heschl"                      ].Right}},
        {static: true, primaryKey: 28,  identifier: 28, belongToForeignKey:  26,  volumeForeignKey: 24, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Temporal Sup",                  varname:{Left:"Temporal_Sup_L",                       Right:"Temporal_Sup_R"                      }, weight: {Left:unitVolume["AAL3"]["Temporal_Sup"                ].Left, Right:unitVolume["AAL3"]["Temporal_Sup"                ].Right}},
        {static: true, primaryKey: 29,  identifier: 29, belongToForeignKey:  26,  volumeForeignKey: 25, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Temporal Pole Sup",             varname:{Left:"Temporal_Pole_Sup_L",                  Right:"Temporal_Pole_Sup_R"                 }, weight: {Left:unitVolume["AAL3"]["Temporal_Pole_Sup"           ].Left, Right:unitVolume["AAL3"]["Temporal_Pole_Sup"           ].Right}},
        {static: true, primaryKey: 30,  identifier: 30, belongToForeignKey:  26,  volumeForeignKey: 26, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Temporal Mid",                  varname:{Left:"Temporal_Mid_L",                       Right:"Temporal_Mid_R"                      }, weight: {Left:unitVolume["AAL3"]["Temporal_Mid"                ].Left, Right:unitVolume["AAL3"]["Temporal_Mid"                ].Right}},
        {static: true, primaryKey: 31,  identifier: 31, belongToForeignKey:  26,  volumeForeignKey: 27, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Temporal Pole Mid",             varname:{Left:"Temporal_Pole_Mid_L",                  Right:"Temporal_Pole_Mid_R"                 }, weight: {Left:unitVolume["AAL3"]["Temporal_Pole_Mid"           ].Left, Right:unitVolume["AAL3"]["Temporal_Pole_Mid"           ].Right}},
        {static: true, primaryKey: 32,  identifier: 32, belongToForeignKey:  26,  volumeForeignKey: 28, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Temporal Inf",                  varname:{Left:"Temporal_Inf_L",                       Right:"Temporal_Inf_R"                      }, weight: {Left:unitVolume["AAL3"]["Temporal_Inf"                ].Left, Right:unitVolume["AAL3"]["Temporal_Inf"                ].Right}},
        {static: true, primaryKey: 33,  identifier: 33, belongToForeignKey:  33,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Lateral parietal",              varname:{Left:"Lateral_parietal_L",                   Right:"Lateral_parietal_R"                  }},                      
        {static: true, primaryKey: 34,  identifier: 34, belongToForeignKey:  33,  volumeForeignKey: 29, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Postcentral",                   varname:{Left:"Postcentral_L",                        Right:"Postcentral_R"                       }, weight: {Left:unitVolume["AAL3"]["Postcentral"                 ].Left, Right:unitVolume["AAL3"]["Postcentral"                 ].Right}},
        {static: true, primaryKey: 35,  identifier: 35, belongToForeignKey:  33,  volumeForeignKey: 30, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Parietal Sup",                  varname:{Left:"Parietal_Sup_L",                       Right:"Parietal_Sup_R"                      }, weight: {Left:unitVolume["AAL3"]["Parietal_Sup"                ].Left, Right:unitVolume["AAL3"]["Parietal_Sup"                ].Right}},
        {static: true, primaryKey: 36,  identifier: 36, belongToForeignKey:  33,  volumeForeignKey: 31, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Parietal Inf",                  varname:{Left:"Parietal_Inf_L",                       Right:"Parietal_Inf_R"                      }, weight: {Left:unitVolume["AAL3"]["Parietal_Inf"                ].Left, Right:unitVolume["AAL3"]["Parietal_Inf"                ].Right}},
        {static: true, primaryKey: 37,  identifier: 37, belongToForeignKey:  33,  volumeForeignKey: 32, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Supramarginal",                 varname:{Left:"Supramarginal_L",                      Right:"Supramarginal_R"                     }, weight: {Left:unitVolume["AAL3"]["Supramarginal"               ].Left, Right:unitVolume["AAL3"]["Supramarginal"               ].Right}},
        {static: true, primaryKey: 38,  identifier: 38, belongToForeignKey:  33,  volumeForeignKey: 33, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Angular",                       varname:{Left:"Angular_L",                            Right:"Angular_R"                           }, weight: {Left:unitVolume["AAL3"]["Angular"                     ].Left, Right:unitVolume["AAL3"]["Angular"                     ].Right}},
        {static: true, primaryKey: 39,  identifier: 39, belongToForeignKey:  33,  volumeForeignKey: 34, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Paracentral Lobule",            varname:{Left:"Paracentral_Lobule_L",                 Right:"Paracentral_Lobule_R"                }, weight: {Left:unitVolume["AAL3"]["Paracentral_Lobule"          ].Left, Right:unitVolume["AAL3"]["Paracentral_Lobule"          ].Right}},
        {static: true, primaryKey: 40,  identifier: 40, belongToForeignKey:  40,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"AAL3",            belongTo: "Medial temporal",  fullname: "Medial temporal",               varname:{Left:"Medial_temporal_L",                    Right:"Medial_temporal_R"                   }},                      
        {static: true, primaryKey: 41,  identifier: 41, belongToForeignKey:  40,  volumeForeignKey: 35, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Medial temporal",  fullname: "Hippocampus",                   varname:{Left:"Hippocampus_L",                        Right:"Hippocampus_R"                       }, weight: {Left:unitVolume["AAL3"]["Hippocampus"                 ].Left, Right:unitVolume["AAL3"]["Hippocampus"                 ].Right}},
        {static: true, primaryKey: 42,  identifier: 42, belongToForeignKey:  40,  volumeForeignKey: 36, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Medial temporal",  fullname: "Parahippocampal",               varname:{Left:"Parahippocampal_L",                    Right:"Parahippocampal_R"                   }, weight: {Left:unitVolume["AAL3"]["Parahippocampal"             ].Left, Right:unitVolume["AAL3"]["Parahippocampal"             ].Right}},
        {static: true, primaryKey: 43,  identifier: 43, belongToForeignKey:  40,  volumeForeignKey: 37, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Medial temporal",  fullname: "Amygdala",                      varname:{Left:"Amygdala_L",                           Right:"Amygdala_R"                          }, weight: {Left:unitVolume["AAL3"]["Amygdala"                    ].Left, Right:unitVolume["AAL3"]["Amygdala"                    ].Right}},
        {static: true, primaryKey: 44,  identifier: 44, belongToForeignKey:  44,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"AAL3",            belongTo: "Occipital",        fullname: "Occipital",                     varname:{Left:"Occipital_L",                          Right:"Occipital_R"                         }},                      
        {static: true, primaryKey: 45,  identifier: 45, belongToForeignKey:  44,  volumeForeignKey: 38, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Occipital",        fullname: "Calcarine",                     varname:{Left:"Calcarine_L",                          Right:"Calcarine_R"                         }, weight: {Left:unitVolume["AAL3"]["Calcarine"                   ].Left, Right:unitVolume["AAL3"]["Calcarine"                   ].Right}},
        {static: true, primaryKey: 46,  identifier: 46, belongToForeignKey:  44,  volumeForeignKey: 39, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Occipital",        fullname: "Cuneus",                        varname:{Left:"Cuneus_L",                             Right:"Cuneus_R"                            }, weight: {Left:unitVolume["AAL3"]["Cuneus"                      ].Left, Right:unitVolume["AAL3"]["Cuneus"                      ].Right}},
        {static: true, primaryKey: 47,  identifier: 47, belongToForeignKey:  44,  volumeForeignKey: 40, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Occipital",        fullname: "Lingual",                       varname:{Left:"Lingual_L",                            Right:"Lingual_R"                           }, weight: {Left:unitVolume["AAL3"]["Lingual"                     ].Left, Right:unitVolume["AAL3"]["Lingual"                     ].Right}},
        {static: true, primaryKey: 48,  identifier: 48, belongToForeignKey:  44,  volumeForeignKey: 41, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Occipital",        fullname: "Occipital Sup",                 varname:{Left:"Occipital_Sup_L",                      Right:"Occipital_Sup_R"                     }, weight: {Left:unitVolume["AAL3"]["Occipital_Sup"               ].Left, Right:unitVolume["AAL3"]["Occipital_Sup"               ].Right}},
        {static: true, primaryKey: 49,  identifier: 49, belongToForeignKey:  44,  volumeForeignKey: 42, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Occipital",        fullname: "Occipital Mid",                 varname:{Left:"Occipital_Mid_L",                      Right:"Occipital_Mid_R"                     }, weight: {Left:unitVolume["AAL3"]["Occipital_Mid"               ].Left, Right:unitVolume["AAL3"]["Occipital_Mid"               ].Right}},
        {static: true, primaryKey: 50,  identifier: 50, belongToForeignKey:  44,  volumeForeignKey: 43, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Occipital",        fullname: "Occipital Inf",                 varname:{Left:"Occipital_Inf_L",                      Right:"Occipital_Inf_R"                     }, weight: {Left:unitVolume["AAL3"]["Occipital_Inf"               ].Left, Right:unitVolume["AAL3"]["Occipital_Inf"               ].Right}},
        {static: true, primaryKey: 51,  identifier: 51, belongToForeignKey:  44,  volumeForeignKey: 44, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Occipital",        fullname: "Fusiform",                      varname:{Left:"Fusiform_L",                           Right:"Fusiform_R"                          }, weight: {Left:unitVolume["AAL3"]["Fusiform"                    ].Left, Right:unitVolume["AAL3"]["Fusiform"                    ].Right}},
        {static: true, primaryKey: 52,  identifier: 52, belongToForeignKey:  52,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"AAL3",            belongTo: "Basal ganglia",    fullname: "Basal ganglia",                 varname:{Left:"Basal_ganglia_L",                      Right:"Basal_ganglia_R"                     }},                      
        {static: true, primaryKey: 53,  identifier: 53, belongToForeignKey:  52,  volumeForeignKey: 45, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Basal ganglia",    fullname: "Caudate",                       varname:{Left:"Caudate_L",                            Right:"Caudate_R"                           }, weight: {Left:unitVolume["AAL3"]["Caudate"                     ].Left, Right:unitVolume["AAL3"]["Caudate"                     ].Right}},
        {static: true, primaryKey: 54,  identifier: 54, belongToForeignKey:  52,  volumeForeignKey: 46, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Basal ganglia",    fullname: "Putamen",                       varname:{Left:"Putamen_L",                            Right:"Putamen_R"                           }, weight: {Left:unitVolume["AAL3"]["Putamen"                     ].Left, Right:unitVolume["AAL3"]["Putamen"                     ].Right}},
        {static: true, primaryKey: 55,  identifier: 55, belongToForeignKey:  52,  volumeForeignKey: 47, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Basal ganglia",    fullname: "Pallidum",                      varname:{Left:"Pallidum_L",                           Right:"Pallidum_R"                          }, weight: {Left:unitVolume["AAL3"]["Pallidum"                    ].Left, Right:unitVolume["AAL3"]["Pallidum"                    ].Right}},
        {static: true, primaryKey: 56,  identifier: 56, belongToForeignKey:  52,  volumeForeignKey: 48, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Basal ganglia",    fullname: "Thalamus",                      varname:{Left:"Thalamus_L",                           Right:"Thalamus_R"                          }, weight: {Left:unitVolume["AAL3"]["Thalamus"                    ].Left, Right:unitVolume["AAL3"]["Thalamus"                    ].Right}},
        {static: true, primaryKey: 57,  identifier: 57, belongToForeignKey:  52,  volumeForeignKey: 49, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Basal ganglia",    fullname: "N Acc",                         varname:{Left:"N_Acc_L",                              Right:"N_Acc_R"                             }, weight: {Left:unitVolume["AAL3"]["N_Acc"                       ].Left, Right:unitVolume["AAL3"]["N_Acc"                       ].Right}},
        {static: true, primaryKey: 58,  identifier: 58, belongToForeignKey:  58,  volumeForeignKey: -1, level: 0, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Brainstem",        fullname: "Brainstem",                     varname:{Left:"Brainstem_L",                          Right:"Brainstem_R"                         }},
        {static: true, primaryKey: 59,  identifier: 59, belongToForeignKey:  59,  volumeForeignKey:  0, level: 0, report:true,  type: 'suvr',      atlas:"AAL3",            belongTo: "Cerebellum gray",  fullname: "Cerebellum gray",               varname:{Left:"OutputAffineParamsY1",                 Right:"OutputAffineParamsX0"                }},

        {static: true, primaryKey: 60,  identifier: 60, belongToForeignKey:  60,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Global",           fullname: "Global",                        varname:{Left:"HO_Global",                            Right:"HO_Global"                           }},
        {static: true, primaryKey: 61,  identifier: 61, belongToForeignKey:  61,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal",                       varname:{Left:"HO_Frontal_L",                         Right:"HO_Frontal_R"                        }},
        {static: true, primaryKey: 62,  identifier: 62, belongToForeignKey:  61,  volumeForeignKey: 50, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Pole",                  varname:{Left:"HO_Frontal_Pole_L",                    Right:"HO_Frontal_Pole_R"                   }, weight: {Left:unitVolume["HO"]["Frontal_Pole"                  ].Left, Right:unitVolume["HO"]["Frontal_Pole"                  ].Right}},
        {static: true, primaryKey: 63,  identifier: 63, belongToForeignKey:  61,  volumeForeignKey: 51, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Insula",                        varname:{Left:"HO_Insula_L",                          Right:"HO_Insula_R"                         }, weight: {Left:unitVolume["HO"]["Insula"                        ].Left, Right:unitVolume["HO"]["Insula"                        ].Right}},
        {static: true, primaryKey: 64,  identifier: 64, belongToForeignKey:  61,  volumeForeignKey: 52, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Sup",                   varname:{Left:"HO_Frontal_Sup_L",                     Right:"HO_Frontal_Sup_R"                    }, weight: {Left:unitVolume["HO"]["Frontal_Sup"                   ].Left, Right:unitVolume["HO"]["Frontal_Sup"                   ].Right}},
        {static: true, primaryKey: 65,  identifier: 65, belongToForeignKey:  61,  volumeForeignKey: 53, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Mid",                   varname:{Left:"HO_Frontal_Mid_L",                     Right:"HO_Frontal_Mid_R"                    }, weight: {Left:unitVolume["HO"]["Frontal_Mid"                   ].Left, Right:unitVolume["HO"]["Frontal_Mid"                   ].Right}},
        {static: true, primaryKey: 66,  identifier: 66, belongToForeignKey:  61,  volumeForeignKey: 54, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Inf Tri",               varname:{Left:"HO_Frontal_Inf_Tri_L",                 Right:"HO_Frontal_Inf_Tri_R"                }, weight: {Left:unitVolume["HO"]["Frontal_Inf_Tri"               ].Left, Right:unitVolume["HO"]["Frontal_Inf_Tri"               ].Right}},
        {static: true, primaryKey: 67,  identifier: 67, belongToForeignKey:  61,  volumeForeignKey: 55, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Inf Oper",              varname:{Left:"HO_Frontal_Inf_Oper_L",                Right:"HO_Frontal_Inf_Oper_R"               }, weight: {Left:unitVolume["HO"]["Frontal_Inf_Oper"              ].Left, Right:unitVolume["HO"]["Frontal_Inf_Oper"              ].Right}},
        {static: true, primaryKey: 68,  identifier: 68, belongToForeignKey:  61,  volumeForeignKey: 56, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Precentral",                    varname:{Left:"HO_Precentral_L",                      Right:"HO_Precentral_R"                     }, weight: {Left:unitVolume["HO"]["Precentral"                    ].Left, Right:unitVolume["HO"]["Precentral"                    ].Right}},
        {static: true, primaryKey: 69,  identifier: 69, belongToForeignKey:  61,  volumeForeignKey: 57, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Med",                   varname:{Left:"HO_Frontal_Med_L",                     Right:"HO_Frontal_Med_R"                    }, weight: {Left:unitVolume["HO"]["Frontal_Med"                   ].Left, Right:unitVolume["HO"]["Frontal_Med"                   ].Right}},
        {static: true, primaryKey: 71,  identifier: 71, belongToForeignKey:  61,  volumeForeignKey: 58, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Supp Motor Area",               varname:{Left:"HO_Supp_Motor_Area_L",                 Right:"HO_Supp_Motor_Area_R"                }, weight: {Left:unitVolume["HO"]["Supp_Motor_Area"               ].Left, Right:unitVolume["HO"]["Supp_Motor_Area"               ].Right}},
        {static: true, primaryKey: 71,  identifier: 71, belongToForeignKey:  61,  volumeForeignKey: 59, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Cingulate Ant",                 varname:{Left:"HO_Cingulate_Ant_L",                   Right:"HO_Cingulate_Ant_R"                  }, weight: {Left:unitVolume["HO"]["Cingulate_Ant"                 ].Left, Right:unitVolume["HO"]["Cingulate_Ant"                 ].Right}},
        {static: true, primaryKey: 72,  identifier: 72, belongToForeignKey:  61,  volumeForeignKey: 60, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Orb",                   varname:{Left:"HO_Frontal_Orb_L",                     Right:"HO_Frontal_Orb_R"                    }, weight: {Left:unitVolume["HO"]["Frontal_Orb"                   ].Left, Right:unitVolume["HO"]["Frontal_Orb"                   ].Right}},
        {static: true, primaryKey: 73,  identifier: 73, belongToForeignKey:  61,  volumeForeignKey: 61, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Oper",                  varname:{Left:"HO_Frontal_Oper_L",                    Right:"HO_Frontal_Oper_R"                   }, weight: {Left:unitVolume["HO"]["Frontal_Oper"                  ].Left, Right:unitVolume["HO"]["Frontal_Oper"                  ].Right}},
        {static: true, primaryKey: 74,  identifier: 74, belongToForeignKey:  61,  volumeForeignKey: 62, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Central Oper",                  varname:{Left:"HO_Central_Oper_L",                    Right:"HO_Central_Oper_R"                   }, weight: {Left:unitVolume["HO"]["Central_Oper"                  ].Left, Right:unitVolume["HO"]["Central_Oper"                  ].Right}},
        {static: true, primaryKey: 75,  identifier: 75, belongToForeignKey:  61,  volumeForeignKey: 63, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Subcallosal",                   varname:{Left:"HO_Subcallosal_L",                     Right:"HO_Subcallosal_R"                    }, weight: {Left:unitVolume["HO"]["Subcallosal"                   ].Left, Right:unitVolume["HO"]["Subcallosal"                   ].Right}},
        {static: true, primaryKey: 76,  identifier: 76, belongToForeignKey:  61,  volumeForeignKey: 64, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Paracingulate",                 varname:{Left:"HO_Paracingulate_L",                   Right:"HO_Paracingulate_R"                  }, weight: {Left:unitVolume["HO"]["Paracingulate"                 ].Left, Right:unitVolume["HO"]["Paracingulate"                 ].Right}},
        {static: true, primaryKey: 77,  identifier: 77, belongToForeignKey:  77,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Retrosplenial",    fullname: "Retrosplenial",                 varname:{Left:"HO_PCC_L",                             Right:"HO_PCC_R"                            }},                      
        {static: true, primaryKey: 78,  identifier: 78, belongToForeignKey:  77,  volumeForeignKey: 65, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Retrosplenial",    fullname: "Cingulate Post",                varname:{Left:"HO_Cingulate_Post_L",                  Right:"HO_Cingulate_Post_R"                 }, weight: {Left:unitVolume["HO"]["Cingulate_Post"                ].Left, Right:unitVolume["HO"]["Cingulate_Post"                ].Right}},
        {static: true, primaryKey: 79,  identifier: 79, belongToForeignKey:  77,  volumeForeignKey: 66, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Retrosplenial",    fullname: "Precuneus",                     varname:{Left:"HO_Precuneus_L",                       Right:"HO_Precuneus_R"                      }, weight: {Left:unitVolume["HO"]["Precuneus"                     ].Left, Right:unitVolume["HO"]["Precuneus"                     ].Right}},
        {static: true, primaryKey: 80,  identifier: 80, belongToForeignKey:  80,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Lateral temporal",              varname:{Left:"HO_Lateral_temporal_L",                Right:"HO_Lateral_temporal_R"               }},
        {static: true, primaryKey: 81,  identifier: 81, belongToForeignKey:  80,  volumeForeignKey: 67, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Pole",                 varname:{Left:"HO_Temporal_Pole_L",                   Right:"HO_Temporal_Pole_R"                  }, weight: {Left:unitVolume["HO"]["Temporal_Pole"                 ].Left, Right:unitVolume["HO"]["Temporal_Pole"                 ].Right}},
        {static: true, primaryKey: 82,  identifier: 82, belongToForeignKey:  80,  volumeForeignKey: 68, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Sup Ant",              varname:{Left:"HO_Temporal_Sup_Ant_L",                Right:"HO_Temporal_Sup_Ant_R"               }, weight: {Left:unitVolume["HO"]["Temporal_Sup_Ant"              ].Left, Right:unitVolume["HO"]["Temporal_Sup_Ant"              ].Right}},
        {static: true, primaryKey: 83,  identifier: 83, belongToForeignKey:  80,  volumeForeignKey: 69, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Sup Post",             varname:{Left:"HO_Temporal_Sup_Post_L",               Right:"HO_Temporal_Sup_Post_R"              }, weight: {Left:unitVolume["HO"]["Temporal_Sup_Post"             ].Left, Right:unitVolume["HO"]["Temporal_Sup_Post"             ].Right}},
        {static: true, primaryKey: 84,  identifier: 84, belongToForeignKey:  80,  volumeForeignKey: 70, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Mid Ant",              varname:{Left:"HO_Temporal_Mid_Ant_L",                Right:"HO_Temporal_Mid_Ant_R"               }, weight: {Left:unitVolume["HO"]["Temporal_Mid_Ant"              ].Left, Right:unitVolume["HO"]["Temporal_Mid_Ant"              ].Right}},
        {static: true, primaryKey: 85,  identifier: 85, belongToForeignKey:  80,  volumeForeignKey: 71, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Mid Post",             varname:{Left:"HO_Temporal_Mid_Post_L",               Right:"HO_Temporal_Mid_Post_R"              }, weight: {Left:unitVolume["HO"]["Temporal_Mid_Post"             ].Left, Right:unitVolume["HO"]["Temporal_Mid_Post"             ].Right}},
        {static: true, primaryKey: 86,  identifier: 86, belongToForeignKey:  80,  volumeForeignKey: 72, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Mid Temporooccipital", varname:{Left:"HO_Temporal_Mid_Temporooccipital_L",   Right:"HO_Temporal_Mid_Temporooccipital_R"  }, weight: {Left:unitVolume["HO"]["Temporal_Mid_Temporooccipital" ].Left, Right:unitVolume["HO"]["Temporal_Mid_Temporooccipital" ].Right}},
        {static: true, primaryKey: 87,  identifier: 87, belongToForeignKey:  80,  volumeForeignKey: 73, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Inf Ant",              varname:{Left:"HO_Temporal_Inf_Ant_L",                Right:"HO_Temporal_Inf_Ant_R"               }, weight: {Left:unitVolume["HO"]["Temporal_Inf_Ant"              ].Left, Right:unitVolume["HO"]["Temporal_Inf_Ant"              ].Right}},
        {static: true, primaryKey: 88,  identifier: 88, belongToForeignKey:  80,  volumeForeignKey: 74, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Inf Post",             varname:{Left:"HO_Temporal_Inf_Post_L",               Right:"HO_Temporal_Inf_Post_R"              }, weight: {Left:unitVolume["HO"]["Temporal_Inf_Post"             ].Left, Right:unitVolume["HO"]["Temporal_Inf_Post"             ].Right}},
        {static: true, primaryKey: 89,  identifier: 89, belongToForeignKey:  80,  volumeForeignKey: 75, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Inf Temporooccipital", varname:{Left:"HO_Temporal_Inf_Temporooccipital_L",   Right:"HO_Temporal_Inf_Temporooccipital_R"  }, weight: {Left:unitVolume["HO"]["Temporal_Inf_Temporooccipital" ].Left, Right:unitVolume["HO"]["Temporal_Inf_Temporooccipital" ].Right}},
        {static: true, primaryKey: 90,  identifier: 90, belongToForeignKey:  80,  volumeForeignKey: 76, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Planum Polare",                 varname:{Left:"HO_Planum_Polare_L",                   Right:"HO_Planum_Polare_R"                  }, weight: {Left:unitVolume["HO"]["Planum_Polare"                 ].Left, Right:unitVolume["HO"]["Planum_Polare"                 ].Right}},
        {static: true, primaryKey: 91,  identifier: 91, belongToForeignKey:  80,  volumeForeignKey: 77, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Heschl",                        varname:{Left:"HO_Heschl_L",                          Right:"HO_Heschl_R"                         }, weight: {Left:unitVolume["HO"]["Heschl"                        ].Left, Right:unitVolume["HO"]["Heschl"                        ].Right}},
        {static: true, primaryKey: 92,  identifier: 92, belongToForeignKey:  80,  volumeForeignKey: 78, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Planum Temporale",              varname:{Left:"HO_Planum_Temporale_L",                Right:"HO_Planum_Temporale_R"               }, weight: {Left:unitVolume["HO"]["Planum_Temporale"              ].Left, Right:unitVolume["HO"]["Planum_Temporale"              ].Right}},
        {static: true, primaryKey: 93,  identifier: 93, belongToForeignKey:  80,  volumeForeignKey: 79, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal fusiform Ant",         varname:{Left:"HO_Temporal_fusiform_Ant_L",           Right:"HO_Temporal_fusiform_Ant_R"          }, weight: {Left:unitVolume["HO"]["Temporal_fusiform_Ant"         ].Left, Right:unitVolume["HO"]["Temporal_fusiform_Ant"         ].Right}},
        {static: true, primaryKey: 94,  identifier: 94, belongToForeignKey:  80,  volumeForeignKey: 80, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal fusiform Post",        varname:{Left:"HO_Temporal_fusiform_Post_L",          Right:"HO_Temporal_fusiform_Post_R"         }, weight: {Left:unitVolume["HO"]["Temporal_fusiform_Post"        ].Left, Right:unitVolume["HO"]["Temporal_fusiform_Post"        ].Right}},
        {static: true, primaryKey: 95,  identifier: 95, belongToForeignKey:  80,  volumeForeignKey: 81, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Occipital Fusiform",   varname:{Left:"HO_Temporal_Occipital_Fusiform_L",     Right:"HO_Temporal_Occipital_Fusiform_R"    }, weight: {Left:unitVolume["HO"]["Temporal_Occipital_Fusiform"   ].Left, Right:unitVolume["HO"]["Temporal_Occipital_Fusiform"   ].Right}},
        {static: true, primaryKey: 96,  identifier: 96, belongToForeignKey:  96,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Lateral parietal",              varname:{Left:"HO_Lateral_parietal_L",                Right:"HO_Lateral_parietal_R"               }},                    
        {static: true, primaryKey: 97,  identifier: 97, belongToForeignKey:  96,  volumeForeignKey: 82, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Postcentral",                   varname:{Left:"HO_Postcentral_L",                     Right:"HO_Postcentral_R"                    }, weight: {Left:unitVolume["HO"]["Postcentral"                   ].Left, Right:unitVolume["HO"]["Postcentral"                   ].Right}},
        {static: true, primaryKey: 98,  identifier: 98, belongToForeignKey:  96,  volumeForeignKey: 83, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Parietal Sup Lobule",           varname:{Left:"HO_Parietal_Sup_Lobule_L",             Right:"HO_Parietal_Sup_Lobule_R"            }, weight: {Left:unitVolume["HO"]["Parietal_Sup_Lobule"           ].Left, Right:unitVolume["HO"]["Parietal_Sup_Lobule"           ].Right}},
        {static: true, primaryKey: 99,  identifier: 99, belongToForeignKey:  96,  volumeForeignKey: 84, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Supramarginal Ant",             varname:{Left:"HO_Supramarginal_Ant_L",               Right:"HO_Supramarginal_Ant_R"              }, weight: {Left:unitVolume["HO"]["Supramarginal_Ant"             ].Left, Right:unitVolume["HO"]["Supramarginal_Ant"             ].Right}},
        {static: true, primaryKey:100,  identifier:100, belongToForeignKey:  96,  volumeForeignKey: 85, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Supramarginal Post",            varname:{Left:"HO_Supramarginal_Post_L",              Right:"HO_Supramarginal_Post_R"             }, weight: {Left:unitVolume["HO"]["Supramarginal_Post"            ].Left, Right:unitVolume["HO"]["Supramarginal_Post"            ].Right}},
        {static: true, primaryKey:101,  identifier:101, belongToForeignKey:  96,  volumeForeignKey: 86, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Angular",                       varname:{Left:"HO_Angular_L",                         Right:"HO_Angular_R"                        }, weight: {Left:unitVolume["HO"]["Angular"                       ].Left, Right:unitVolume["HO"]["Angular"                       ].Right}},
        {static: true, primaryKey:102,  identifier:102, belongToForeignKey:  96,  volumeForeignKey: 87, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Parietal Oper",                 varname:{Left:"HO_Parietal_Oper_L",                   Right:"HO_Parietal_Oper_R"                  }, weight: {Left:unitVolume["HO"]["Parietal_Oper"                 ].Left, Right:unitVolume["HO"]["Parietal_Oper"                 ].Right}},
        {static: true, primaryKey:103,  identifier:103, belongToForeignKey: 103,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Medial temporal",  fullname: "Medial temporal",               varname:{Left:"HO_Medial_temporal_L",                 Right:"HO_Medial_temporal_R"                }},                    
        {static: true, primaryKey:104,  identifier:104, belongToForeignKey: 103,  volumeForeignKey: 88, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Medial temporal",  fullname: "Parahippocampal Ant",           varname:{Left:"HO_Parahippocampal_Ant_L",             Right:"HO_Parahippocampal_Ant_R"            }, weight: {Left:unitVolume["HO"]["Parahippocampal_Ant"           ].Left, Right:unitVolume["HO"]["Parahippocampal_Ant"           ].Right}},
        {static: true, primaryKey:105,  identifier:105, belongToForeignKey: 103,  volumeForeignKey: 89, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Medial temporal",  fullname: "Parahippocampal Post",          varname:{Left:"HO_Parahippocampal_Post_L",            Right:"HO_Parahippocampal_Post_R"           }, weight: {Left:unitVolume["HO"]["Parahippocampal_Post"          ].Left, Right:unitVolume["HO"]["Parahippocampal_Post"          ].Right}},
        {static: true, primaryKey:106,  identifier:106, belongToForeignKey: 103,  volumeForeignKey: 90, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Medial temporal",  fullname: "Hippocampus",                   varname:{Left:"HO_Hippocampus_L",                     Right:"HO_Hippocampus_R"                    }, weight: {Left:unitVolume["HO"]["Hippocampus"                   ].Left, Right:unitVolume["HO"]["Hippocampus"                   ].Right}},
        {static: true, primaryKey:107,  identifier:107, belongToForeignKey: 103,  volumeForeignKey: 91, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Medial temporal",  fullname: "Amygdala",                      varname:{Left:"HO_Amygdala_L",                        Right:"HO_Amygdala_R"                       }, weight: {Left:unitVolume["HO"]["Amygdala"                      ].Left, Right:unitVolume["HO"]["Amygdala"                      ].Right}},
        {static: true, primaryKey:108,  identifier:108, belongToForeignKey: 108,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Occipital",                     varname:{Left:"HO_Occipital_L",                       Right:"HO_Occipital_R"                      }},                    
        {static: true, primaryKey:109,  identifier:109, belongToForeignKey: 108,  volumeForeignKey: 92, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Occipital Lat_Sup",             varname:{Left:"HO_Occipital_Lat_Sup_L",               Right:"HO_Occipital_Lat_Sup_R"              }, weight: {Left:unitVolume["HO"]["Occipital_Lat_Sup"             ].Left, Right:unitVolume["HO"]["Occipital_Lat_Sup"             ].Right}},
        {static: true, primaryKey:110,  identifier:110, belongToForeignKey: 108,  volumeForeignKey: 93, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Occipital Lat_Inf",             varname:{Left:"HO_Occipital_Lat_Inf_L",               Right:"HO_Occipital_Lat_Inf_R"              }, weight: {Left:unitVolume["HO"]["Occipital_Lat_Inf"             ].Left, Right:unitVolume["HO"]["Occipital_Lat_Inf"             ].Right}},
        {static: true, primaryKey:111,  identifier:111, belongToForeignKey: 108,  volumeForeignKey: 94, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Intracalcarine",                varname:{Left:"HO_Intracalcarine_L",                  Right:"HO_Intracalcarine_R"                 }, weight: {Left:unitVolume["HO"]["Intracalcarine"                ].Left, Right:unitVolume["HO"]["Intracalcarine"                ].Right}},
        {static: true, primaryKey:112,  identifier:112, belongToForeignKey: 108,  volumeForeignKey: 95, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Occipital Pole",                varname:{Left:"HO_Occipital_Pole_L",                  Right:"HO_Occipital_Pole_R"                 }, weight: {Left:unitVolume["HO"]["Occipital_Pole"                ].Left, Right:unitVolume["HO"]["Occipital_Pole"                ].Right}},
        {static: true, primaryKey:113,  identifier:113, belongToForeignKey: 108,  volumeForeignKey: 96, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Cuneal",                        varname:{Left:"HO_Cuneal_L",                          Right:"HO_Cuneal_R"                         }, weight: {Left:unitVolume["HO"]["Cuneal"                        ].Left, Right:unitVolume["HO"]["Cuneal"                        ].Right}},
        {static: true, primaryKey:114,  identifier:114, belongToForeignKey: 108,  volumeForeignKey: 97, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Lingual",                       varname:{Left:"HO_Lingual_L",                         Right:"HO_Lingual_R"                        }, weight: {Left:unitVolume["HO"]["Lingual"                       ].Left, Right:unitVolume["HO"]["Lingual"                       ].Right}},
        {static: true, primaryKey:115,  identifier:115, belongToForeignKey: 108,  volumeForeignKey: 98, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Occipital Fusiform",            varname:{Left:"HO_Occipital_Fusiform_L",              Right:"HO_Occipital_Fusiform_R"             }, weight: {Left:unitVolume["HO"]["Occipital_Fusiform"            ].Left, Right:unitVolume["HO"]["Occipital_Fusiform"            ].Right}},
        {static: true, primaryKey:116,  identifier:116, belongToForeignKey: 108,  volumeForeignKey: 99, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Supracalcarine",                varname:{Left:"HO_Supracalcarine_L",                  Right:"HO_Supracalcarine_R"                 }, weight: {Left:unitVolume["HO"]["Supracalcarine"                ].Left, Right:unitVolume["HO"]["Supracalcarine"                ].Right}},
        {static: true, primaryKey:117,  identifier:117, belongToForeignKey: 117,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Basal ganglia",    fullname: "Basal ganglia",                 varname:{Left:"HO_Basal_ganglia_L",                   Right:"HO_Basal_ganglia_R"                  }},                    
        {static: true, primaryKey:118,  identifier:118, belongToForeignKey: 117,  volumeForeignKey:100, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Basal ganglia",    fullname: "Caudate",                       varname:{Left:"HO_Caudate_L",                         Right:"HO_Caudate_R"                        }, weight: {Left:unitVolume["HO"]["Caudate"                       ].Left, Right:unitVolume["HO"]["Caudate"                       ].Right}},
        {static: true, primaryKey:119,  identifier:119, belongToForeignKey: 117,  volumeForeignKey:101, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Basal ganglia",    fullname: "Putamen",                       varname:{Left:"HO_Putamen_L",                         Right:"HO_Putamen_R"                        }, weight: {Left:unitVolume["HO"]["Putamen"                       ].Left, Right:unitVolume["HO"]["Putamen"                       ].Right}},
        {static: true, primaryKey:120,  identifier:120, belongToForeignKey: 117,  volumeForeignKey:102, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Basal ganglia",    fullname: "Pallidum",                      varname:{Left:"HO_Pallidum_L",                        Right:"HO_Pallidum_R"                       }, weight: {Left:unitVolume["HO"]["Pallidum"                      ].Left, Right:unitVolume["HO"]["Pallidum"                      ].Right}},
        {static: true, primaryKey:121,  identifier:121, belongToForeignKey: 117,  volumeForeignKey:103, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Basal ganglia",    fullname: "Thalamus",                      varname:{Left:"HO_Thalamus_L",                        Right:"HO_Thalamus_R"                       }, weight: {Left:unitVolume["HO"]["Thalamus"                      ].Left, Right:unitVolume["HO"]["Thalamus"                      ].Right}},
        {static: true, primaryKey:122,  identifier:122, belongToForeignKey: 117,  volumeForeignKey:104, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Basal ganglia",    fullname: "Accumbens",                     varname:{Left:"HO_Accumbens_L",                       Right:"HO_Accumbens_R"                      }, weight: {Left:unitVolume["HO"]["Accumbens"                     ].Left, Right:unitVolume["HO"]["Accumbens"                     ].Right}},
        {static: true, primaryKey:123,  identifier:123, belongToForeignKey: 123,  volumeForeignKey: -1, level: 0, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Brainstem",        fullname: "Brainstem",                     varname:{Left:"HO_Brainstem_L",                       Right:"HO_Brainstem_R"                      }},
        {static: true, primaryKey:124,  identifier:124, belongToForeignKey: 124,  volumeForeignKey:  0, level: 0, report:true,  type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Cerebellum gray",  fullname: "Cerebellum gray",               varname:{Left:"OutputAffineParamsY1",                 Right:"OutputAffineParamsX0"                }},

        {static: true, primaryKey:125,  identifier:  0, belongToForeignKey: 125,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"AAL3",            belongTo: "Composite",        fullname: "Composite",                     varname:{Left:"Composite_C",                          Right:"Composite_C"                         }},
        {static: true, primaryKey:126,  identifier:  1, belongToForeignKey: 126,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal",                       varname:{Left:"Frontal_L_C",                          Right:"Frontal_R_C"                         }},
        {static: true, primaryKey:127,  identifier:  2, belongToForeignKey: 126,  volumeForeignKey:  0, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Precentral",                    varname:{Left:"Precentral_L_C",                       Right:"Precentral_R_C"                      }, weight: {Left:unitVolume["AAL3"]["Precentral"                  ].Left, Right:unitVolume["AAL3"]["Precentral"                  ].Right}},
        {static: true, primaryKey:128,  identifier:  3, belongToForeignKey: 126,  volumeForeignKey:  1, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Sup",                   varname:{Left:"Frontal_Sup_L_C",                      Right:"Frontal_Sup_R_C"                     }, weight: {Left:unitVolume["AAL3"]["Frontal_Sup"                 ].Left, Right:unitVolume["AAL3"]["Frontal_Sup"                 ].Right}},
        {static: true, primaryKey:129,  identifier:  4, belongToForeignKey: 126,  volumeForeignKey:  2, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Mid",                   varname:{Left:"Frontal_Mid_L_C",                      Right:"Frontal_Mid_R_C"                     }, weight: {Left:unitVolume["AAL3"]["Frontal_Mid"                 ].Left, Right:unitVolume["AAL3"]["Frontal_Mid"                 ].Right}},
        {static: true, primaryKey:130,  identifier:  5, belongToForeignKey: 126,  volumeForeignKey:  3, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Inf Oper",              varname:{Left:"Frontal_Inf_Oper_L_C",                 Right:"Frontal_Inf_Oper_R_C"                }, weight: {Left:unitVolume["AAL3"]["Frontal_Inf_Oper"            ].Left, Right:unitVolume["AAL3"]["Frontal_Inf_Oper"            ].Right}},
        {static: true, primaryKey:131,  identifier:  6, belongToForeignKey: 126,  volumeForeignKey:  4, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Inf Tri",               varname:{Left:"Frontal_Inf_Tri_L_C",                  Right:"Frontal_Inf_Tri_R_C"                 }, weight: {Left:unitVolume["AAL3"]["Frontal_Inf_Tri"             ].Left, Right:unitVolume["AAL3"]["Frontal_Inf_Tri"             ].Right}},
        {static: true, primaryKey:132,  identifier:  7, belongToForeignKey: 126,  volumeForeignKey:  5, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Inf Orb",               varname:{Left:"Frontal_Inf_Orb_L_C",                  Right:"Frontal_Inf_Orb_R_C"                 }, weight: {Left:unitVolume["AAL3"]["Frontal_Inf_Orb"             ].Left, Right:unitVolume["AAL3"]["Frontal_Inf_Orb"             ].Right}},
        {static: true, primaryKey:133,  identifier:  8, belongToForeignKey: 126,  volumeForeignKey:  6, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Rolandic Oper",                 varname:{Left:"Rolandic_Oper_L_C",                    Right:"Rolandic_Oper_R_C"                   }, weight: {Left:unitVolume["AAL3"]["Rolandic_Oper"               ].Left, Right:unitVolume["AAL3"]["Rolandic_Oper"               ].Right}},
        {static: true, primaryKey:134,  identifier:  9, belongToForeignKey: 126,  volumeForeignKey:  7, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Supp Motor Area",               varname:{Left:"Supp_Motor_Area_L_C",                  Right:"Supp_Motor_Area_R_C"                 }, weight: {Left:unitVolume["AAL3"]["Supp_Motor_Area"             ].Left, Right:unitVolume["AAL3"]["Supp_Motor_Area"             ].Right}},
        {static: true, primaryKey:135,  identifier: 10, belongToForeignKey: 126,  volumeForeignKey:  8, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Olfactory",                     varname:{Left:"Olfactory_L_C",                        Right:"Olfactory_R_C"                       }, weight: {Left:unitVolume["AAL3"]["Olfactory"                   ].Left, Right:unitVolume["AAL3"]["Olfactory"                   ].Right}},
        {static: true, primaryKey:136,  identifier: 11, belongToForeignKey: 126,  volumeForeignKey:  9, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Sup Med",               varname:{Left:"Frontal_Sup_Med_L_C",                  Right:"Frontal_Sup_Med_R_C"                 }, weight: {Left:unitVolume["AAL3"]["Frontal_Sup_Med"             ].Left, Right:unitVolume["AAL3"]["Frontal_Sup_Med"             ].Right}},
        {static: true, primaryKey:137,  identifier: 12, belongToForeignKey: 126,  volumeForeignKey: 10, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Med Orb",               varname:{Left:"Frontal_Med_Orb_L_C",                  Right:"Frontal_Med_Orb_R_C"                 }, weight: {Left:unitVolume["AAL3"]["Frontal_Med_Orb"             ].Left, Right:unitVolume["AAL3"]["Frontal_Med_Orb"             ].Right}},
        {static: true, primaryKey:138,  identifier: 13, belongToForeignKey: 126,  volumeForeignKey: 11, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Rectus",                        varname:{Left:"Rectus_L_C",                           Right:"Rectus_R_C"                          }, weight: {Left:unitVolume["AAL3"]["Rectus"                      ].Left, Right:unitVolume["AAL3"]["Rectus"                      ].Right}},
        {static: true, primaryKey:139,  identifier: 14, belongToForeignKey: 126,  volumeForeignKey: 12, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "OFCmed",                        varname:{Left:"OFCmed_L_C",                           Right:"OFCmed_R_C"                          }, weight: {Left:unitVolume["AAL3"]["OFCmed"                      ].Left, Right:unitVolume["AAL3"]["OFCmed"                      ].Right}},
        {static: true, primaryKey:140,  identifier: 15, belongToForeignKey: 126,  volumeForeignKey: 13, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "OFCant",                        varname:{Left:"OFCant_L_C",                           Right:"OFCant_R_C"                          }, weight: {Left:unitVolume["AAL3"]["OFCant"                      ].Left, Right:unitVolume["AAL3"]["OFCant"                      ].Right}},
        {static: true, primaryKey:141,  identifier: 16, belongToForeignKey: 126,  volumeForeignKey: 14, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "OFCpost",                       varname:{Left:"OFCpost_L_C",                          Right:"OFCpost_R_C"                         }, weight: {Left:unitVolume["AAL3"]["OFCpost"                     ].Left, Right:unitVolume["AAL3"]["OFCpost"                     ].Right}},
        {static: true, primaryKey:142,  identifier: 17, belongToForeignKey: 126,  volumeForeignKey: 15, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "OFClat",                        varname:{Left:"OFClat_L_C",                           Right:"OFClat_R_C"                          }, weight: {Left:unitVolume["AAL3"]["OFClat"                      ].Left, Right:unitVolume["AAL3"]["OFClat"                      ].Right}},
        {static: true, primaryKey:143,  identifier: 18, belongToForeignKey: 126,  volumeForeignKey: 16, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Insula",                        varname:{Left:"Insula_L_C",                           Right:"Insula_R_C"                          }, weight: {Left:unitVolume["AAL3"]["Insula"                      ].Left, Right:unitVolume["AAL3"]["Insula"                      ].Right}},
        {static: true, primaryKey:144,  identifier: 19, belongToForeignKey: 126,  volumeForeignKey: 17, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Cingulate Ant Sub",             varname:{Left:"Cingulate_Ant_Sub_L_C",                Right:"Cingulate_Ant_Sub_R_C"               }, weight: {Left:unitVolume["AAL3"]["Cingulate_Ant_Sub"           ].Left, Right:unitVolume["AAL3"]["Cingulate_Ant_Sub"           ].Right}},
        {static: true, primaryKey:145,  identifier: 20, belongToForeignKey: 126,  volumeForeignKey: 18, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Cingulate Ant Pre",             varname:{Left:"Cingulate_Ant_Pre_L_C",                Right:"Cingulate_Ant_Pre_R_C"               }, weight: {Left:unitVolume["AAL3"]["Cingulate_Ant_Pre"           ].Left, Right:unitVolume["AAL3"]["Cingulate_Ant_Pre"           ].Right}},
        {static: true, primaryKey:146,  identifier: 21, belongToForeignKey: 126,  volumeForeignKey: 19, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Cingulate Ant Sup",             varname:{Left:"Cingulate_Ant_Sup_L_C",                Right:"Cingulate_Ant_Sup_R_C"               }, weight: {Left:unitVolume["AAL3"]["Cingulate_Ant_Sup"           ].Left, Right:unitVolume["AAL3"]["Cingulate_Ant_Sup"           ].Right}},
        {static: true, primaryKey:147,  identifier: 22, belongToForeignKey: 126,  volumeForeignKey: 20, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Cingulate Mid",                 varname:{Left:"Cingulate_Mid_L_C",                    Right:"Cingulate_Mid_R_C"                   }, weight: {Left:unitVolume["AAL3"]["Cingulate_Mid"               ].Left, Right:unitVolume["AAL3"]["Cingulate_Mid"               ].Right}},
        {static: true, primaryKey:148,  identifier: 23, belongToForeignKey: 148,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"AAL3",            belongTo: "PCC-Precuneus",    fullname: "PCC-Precuneus",                 varname:{Left:"PCC_L_C",                              Right:"PCC_R_C"                             }},                    
        {static: true, primaryKey:149,  identifier: 24, belongToForeignKey: 148,  volumeForeignKey: 21, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "PCC-Precuneus",    fullname: "Cingulate Post",                varname:{Left:"Cingulate_Post_L_C",                   Right:"Cingulate_Post_R_C"                  }, weight: {Left:unitVolume["AAL3"]["Cingulate_Post"              ].Left, Right:unitVolume["AAL3"]["Cingulate_Post"              ].Right}},
        {static: true, primaryKey:150,  identifier: 25, belongToForeignKey: 148,  volumeForeignKey: 22, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "PCC-Precuneus",    fullname: "Precuneus",                     varname:{Left:"Precuneus_L_C",                        Right:"Precuneus_R_C"                       }, weight: {Left:unitVolume["AAL3"]["Precuneus"                   ].Left, Right:unitVolume["AAL3"]["Precuneus"                   ].Right}},
        {static: true, primaryKey:151,  identifier: 26, belongToForeignKey: 151,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Lateral temporal",              varname:{Left:"Lateral_temporal_L_C",                 Right:"Lateral_temporal_R_C"                }},                    
        {static: true, primaryKey:152,  identifier: 27, belongToForeignKey: 151,  volumeForeignKey: 23, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Heschl",                        varname:{Left:"Heschl_L_C",                           Right:"Heschl_R_C"                          }, weight: {Left:unitVolume["AAL3"]["Heschl"                      ].Left, Right:unitVolume["AAL3"]["Heschl"                      ].Right}},
        {static: true, primaryKey:153,  identifier: 28, belongToForeignKey: 151,  volumeForeignKey: 24, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Temporal Sup",                  varname:{Left:"Temporal_Sup_L_C",                     Right:"Temporal_Sup_R_C"                    }, weight: {Left:unitVolume["AAL3"]["Temporal_Sup"                ].Left, Right:unitVolume["AAL3"]["Temporal_Sup"                ].Right}},
        {static: true, primaryKey:154,  identifier: 29, belongToForeignKey: 151,  volumeForeignKey: 25, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Temporal Pole Sup",             varname:{Left:"Temporal_Pole_Sup_L_C",                Right:"Temporal_Pole_Sup_R_C"               }, weight: {Left:unitVolume["AAL3"]["Temporal_Pole_Sup"           ].Left, Right:unitVolume["AAL3"]["Temporal_Pole_Sup"           ].Right}},
        {static: true, primaryKey:155,  identifier: 30, belongToForeignKey: 151,  volumeForeignKey: 26, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Temporal Mid",                  varname:{Left:"Temporal_Mid_L_C",                     Right:"Temporal_Mid_R_C"                    }, weight: {Left:unitVolume["AAL3"]["Temporal_Mid"                ].Left, Right:unitVolume["AAL3"]["Temporal_Mid"                ].Right}},
        {static: true, primaryKey:156,  identifier: 31, belongToForeignKey: 151,  volumeForeignKey: 27, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Temporal Pole Mid",             varname:{Left:"Temporal_Pole_Mid_L_C",                Right:"Temporal_Pole_Mid_R_C"               }, weight: {Left:unitVolume["AAL3"]["Temporal_Pole_Mid"           ].Left, Right:unitVolume["AAL3"]["Temporal_Pole_Mid"           ].Right}},
        {static: true, primaryKey:157,  identifier: 32, belongToForeignKey: 151,  volumeForeignKey: 28, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Temporal Inf",                  varname:{Left:"Temporal_Inf_L_C",                     Right:"Temporal_Inf_R_C"                    }, weight: {Left:unitVolume["AAL3"]["Temporal_Inf"                ].Left, Right:unitVolume["AAL3"]["Temporal_Inf"                ].Right}},
        {static: true, primaryKey:158,  identifier: 33, belongToForeignKey: 158,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Lateral parietal",              varname:{Left:"Lateral_parietal_L_C",                 Right:"Lateral_parietal_R_C"                }},                    
        {static: true, primaryKey:159,  identifier: 34, belongToForeignKey: 158,  volumeForeignKey: 29, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Postcentral",                   varname:{Left:"Postcentral_L_C",                      Right:"Postcentral_R_C"                     }, weight: {Left:unitVolume["AAL3"]["Postcentral"                 ].Left, Right:unitVolume["AAL3"]["Postcentral"                 ].Right}},
        {static: true, primaryKey:160,  identifier: 35, belongToForeignKey: 158,  volumeForeignKey: 30, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Parietal Sup",                  varname:{Left:"Parietal_Sup_L_C",                     Right:"Parietal_Sup_R_C"                    }, weight: {Left:unitVolume["AAL3"]["Parietal_Sup"                ].Left, Right:unitVolume["AAL3"]["Parietal_Sup"                ].Right}},
        {static: true, primaryKey:161,  identifier: 36, belongToForeignKey: 158,  volumeForeignKey: 31, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Parietal Inf",                  varname:{Left:"Parietal_Inf_L_C",                     Right:"Parietal_Inf_R_C"                    }, weight: {Left:unitVolume["AAL3"]["Parietal_Inf"                ].Left, Right:unitVolume["AAL3"]["Parietal_Inf"                ].Right}},
        {static: true, primaryKey:162,  identifier: 37, belongToForeignKey: 158,  volumeForeignKey: 32, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Supramarginal",                 varname:{Left:"Supramarginal_L_C",                    Right:"Supramarginal_R_C"                   }, weight: {Left:unitVolume["AAL3"]["Supramarginal"               ].Left, Right:unitVolume["AAL3"]["Supramarginal"               ].Right}},
        {static: true, primaryKey:163,  identifier: 38, belongToForeignKey: 158,  volumeForeignKey: 33, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Angular",                       varname:{Left:"Angular_L_C",                          Right:"Angular_R_C"                         }, weight: {Left:unitVolume["AAL3"]["Angular"                     ].Left, Right:unitVolume["AAL3"]["Angular"                     ].Right}},
        {static: true, primaryKey:164,  identifier: 39, belongToForeignKey: 158,  volumeForeignKey: 34, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Paracentral Lobule",            varname:{Left:"Paracentral_Lobule_L_C",               Right:"Paracentral_Lobule_R_C"              }, weight: {Left:unitVolume["AAL3"]["Paracentral_Lobule"          ].Left, Right:unitVolume["AAL3"]["Paracentral_Lobule"          ].Right}},
        {static: true, primaryKey:165,  identifier: 40, belongToForeignKey: 165,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"AAL3",            belongTo: "Medial temporal",  fullname: "Medial temporal",               varname:{Left:"Medial_temporal_L_C",                  Right:"Medial_temporal_R_C"                 }},                    
        {static: true, primaryKey:166,  identifier: 41, belongToForeignKey: 165,  volumeForeignKey: 35, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Medial temporal",  fullname: "Hippocampus",                   varname:{Left:"Hippocampus_L_C",                      Right:"Hippocampus_R_C"                     }, weight: {Left:unitVolume["AAL3"]["Hippocampus"                 ].Left, Right:unitVolume["AAL3"]["Hippocampus"                 ].Right}},
        {static: true, primaryKey:167,  identifier: 42, belongToForeignKey: 165,  volumeForeignKey: 36, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Medial temporal",  fullname: "Parahippocampal",               varname:{Left:"Parahippocampal_L_C",                  Right:"Parahippocampal_R_C"                 }, weight: {Left:unitVolume["AAL3"]["Parahippocampal"             ].Left, Right:unitVolume["AAL3"]["Parahippocampal"             ].Right}},
        {static: true, primaryKey:168,  identifier: 43, belongToForeignKey: 165,  volumeForeignKey: 37, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Medial temporal",  fullname: "Amygdala",                      varname:{Left:"Amygdala_L_C",                         Right:"Amygdala_R_C"                        }, weight: {Left:unitVolume["AAL3"]["Amygdala"                    ].Left, Right:unitVolume["AAL3"]["Amygdala"                    ].Right}},
        {static: true, primaryKey:169,  identifier: 44, belongToForeignKey: 169,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"AAL3",            belongTo: "Occipital",        fullname: "Occipital",                     varname:{Left:"Occipital_L_C",                        Right:"Occipital_R_C"                       }},                    
        {static: true, primaryKey:170,  identifier: 45, belongToForeignKey: 169,  volumeForeignKey: 38, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Occipital",        fullname: "Calcarine",                     varname:{Left:"Calcarine_L_C",                        Right:"Calcarine_R_C"                       }, weight: {Left:unitVolume["AAL3"]["Calcarine"                   ].Left, Right:unitVolume["AAL3"]["Calcarine"                   ].Right}},
        {static: true, primaryKey:171,  identifier: 46, belongToForeignKey: 169,  volumeForeignKey: 39, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Occipital",        fullname: "Cuneus",                        varname:{Left:"Cuneus_L_C",                           Right:"Cuneus_R_C"                          }, weight: {Left:unitVolume["AAL3"]["Cuneus"                      ].Left, Right:unitVolume["AAL3"]["Cuneus"                      ].Right}},
        {static: true, primaryKey:172,  identifier: 47, belongToForeignKey: 169,  volumeForeignKey: 40, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Occipital",        fullname: "Lingual",                       varname:{Left:"Lingual_L_C",                          Right:"Lingual_R_C"                         }, weight: {Left:unitVolume["AAL3"]["Lingual"                     ].Left, Right:unitVolume["AAL3"]["Lingual"                     ].Right}},
        {static: true, primaryKey:173,  identifier: 48, belongToForeignKey: 169,  volumeForeignKey: 41, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Occipital",        fullname: "Occipital Sup",                 varname:{Left:"Occipital_Sup_L_C",                    Right:"Occipital_Sup_R_C"                   }, weight: {Left:unitVolume["AAL3"]["Occipital_Sup"               ].Left, Right:unitVolume["AAL3"]["Occipital_Sup"               ].Right}},
        {static: true, primaryKey:174,  identifier: 49, belongToForeignKey: 169,  volumeForeignKey: 42, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Occipital",        fullname: "Occipital Mid",                 varname:{Left:"Occipital_Mid_L_C",                    Right:"Occipital_Mid_R_C"                   }, weight: {Left:unitVolume["AAL3"]["Occipital_Mid"               ].Left, Right:unitVolume["AAL3"]["Occipital_Mid"               ].Right}},
        {static: true, primaryKey:175,  identifier: 50, belongToForeignKey: 169,  volumeForeignKey: 43, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Occipital",        fullname: "Occipital Inf",                 varname:{Left:"Occipital_Inf_L_C",                    Right:"Occipital_Inf_R_C"                   }, weight: {Left:unitVolume["AAL3"]["Occipital_Inf"               ].Left, Right:unitVolume["AAL3"]["Occipital_Inf"               ].Right}},
        {static: true, primaryKey:176,  identifier: 51, belongToForeignKey: 169,  volumeForeignKey: 44, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Occipital",        fullname: "Fusiform",                      varname:{Left:"Fusiform_L_C",                         Right:"Fusiform_R_C"                        }, weight: {Left:unitVolume["AAL3"]["Fusiform"                    ].Left, Right:unitVolume["AAL3"]["Fusiform"                    ].Right}},
        {static: true, primaryKey:177,  identifier: 52, belongToForeignKey: 177,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"AAL3",            belongTo: "Basal ganglia",    fullname: "Basal ganglia",                 varname:{Left:"Basal_ganglia_L_C",                    Right:"Basal_ganglia_R_C"                   }},
        {static: true, primaryKey:178,  identifier: 53, belongToForeignKey: 177,  volumeForeignKey: 45, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Basal ganglia",    fullname: "Caudate",                       varname:{Left:"Caudate_L_C",                          Right:"Caudate_R_C"                         }, weight: {Left:unitVolume["AAL3"]["Caudate"                     ].Left, Right:unitVolume["AAL3"]["Caudate"                     ].Right}},
        {static: true, primaryKey:179,  identifier: 54, belongToForeignKey: 177,  volumeForeignKey: 46, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Basal ganglia",    fullname: "Putamen",                       varname:{Left:"Putamen_L_C",                          Right:"Putamen_R_C"                         }, weight: {Left:unitVolume["AAL3"]["Putamen"                     ].Left, Right:unitVolume["AAL3"]["Putamen"                     ].Right}},
        {static: true, primaryKey:180,  identifier: 55, belongToForeignKey: 177,  volumeForeignKey: 47, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Basal ganglia",    fullname: "Pallidum",                      varname:{Left:"Pallidum_L_C",                         Right:"Pallidum_R_C"                        }, weight: {Left:unitVolume["AAL3"]["Pallidum"                    ].Left, Right:unitVolume["AAL3"]["Pallidum"                    ].Right}},
        {static: true, primaryKey:181,  identifier: 56, belongToForeignKey: 177,  volumeForeignKey: 48, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Basal ganglia",    fullname: "Thalamus",                      varname:{Left:"Thalamus_L_C",                         Right:"Thalamus_R_C"                        }, weight: {Left:unitVolume["AAL3"]["Thalamus"                    ].Left, Right:unitVolume["AAL3"]["Thalamus"                    ].Right}},
        {static: true, primaryKey:182,  identifier: 57, belongToForeignKey: 177,  volumeForeignKey: 49, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Basal ganglia",    fullname: "N Acc",                         varname:{Left:"N_Acc_L_C",                            Right:"N_Acc_R_C"                           }, weight: {Left:unitVolume["AAL3"]["N_Acc"                       ].Left, Right:unitVolume["AAL3"]["N_Acc"                       ].Right}},
        {static: true, primaryKey:183,  identifier: 58, belongToForeignKey: 183,  volumeForeignKey: -1, level: 0, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Brainstem",        fullname: "Brainstem",                     varname:{Left:"Brainstem_L_C",                        Right:"Brainstem_R_C"                       }},                    
        {static: true, primaryKey:184,  identifier: 59, belongToForeignKey: 184,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Composite",        fullname: "Composite",                     varname:{Left:"Composite_C",                          Right:"Composite_C"                         }},                    
        {static: true, primaryKey:185,  identifier: 60, belongToForeignKey: 185,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal",                       varname:{Left:"HO_Frontal_L_C",                       Right:"HO_Frontal_R_C"                      }},                    
        {static: true, primaryKey:186,  identifier: 61, belongToForeignKey: 185,  volumeForeignKey: 50, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Pole",                  varname:{Left:"HO_Frontal_Pole_L_C",                  Right:"HO_Frontal_Pole_R_C"                 }, weight: {Left:unitVolume["HO"]["Frontal_Pole"                  ].Left, Right:unitVolume["HO"]["Frontal_Pole"                  ].Right}},
        {static: true, primaryKey:187,  identifier: 62, belongToForeignKey: 185,  volumeForeignKey: 51, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Insula",                        varname:{Left:"HO_Insula_L_C",                        Right:"HO_Insula_R_C"                       }, weight: {Left:unitVolume["HO"]["Insula"                        ].Left, Right:unitVolume["HO"]["Insula"                        ].Right}},
        {static: true, primaryKey:188,  identifier: 63, belongToForeignKey: 185,  volumeForeignKey: 52, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Sup",                   varname:{Left:"HO_Frontal_Sup_L_C",                   Right:"HO_Frontal_Sup_R_C"                  }, weight: {Left:unitVolume["HO"]["Frontal_Sup"                   ].Left, Right:unitVolume["HO"]["Frontal_Sup"                   ].Right}},
        {static: true, primaryKey:189,  identifier: 64, belongToForeignKey: 185,  volumeForeignKey: 53, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Mid",                   varname:{Left:"HO_Frontal_Mid_L_C",                   Right:"HO_Frontal_Mid_R_C"                  }, weight: {Left:unitVolume["HO"]["Frontal_Mid"                   ].Left, Right:unitVolume["HO"]["Frontal_Mid"                   ].Right}},
        {static: true, primaryKey:190,  identifier: 65, belongToForeignKey: 185,  volumeForeignKey: 54, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Inf Tri",               varname:{Left:"HO_Frontal_Inf_Tri_L_C",               Right:"HO_Frontal_Inf_Tri_R_C"              }, weight: {Left:unitVolume["HO"]["Frontal_Inf_Tri"               ].Left, Right:unitVolume["HO"]["Frontal_Inf_Tri"               ].Right}},
        {static: true, primaryKey:191,  identifier: 66, belongToForeignKey: 185,  volumeForeignKey: 55, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Inf Oper",              varname:{Left:"HO_Frontal_Inf_Oper_L_C",              Right:"HO_Frontal_Inf_Oper_R_C"             }, weight: {Left:unitVolume["HO"]["Frontal_Inf_Oper"              ].Left, Right:unitVolume["HO"]["Frontal_Inf_Oper"              ].Right}},
        {static: true, primaryKey:192,  identifier: 67, belongToForeignKey: 185,  volumeForeignKey: 56, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Precentral",                    varname:{Left:"HO_Precentral_L_C",                    Right:"HO_Precentral_R_C"                   }, weight: {Left:unitVolume["HO"]["Precentral"                    ].Left, Right:unitVolume["HO"]["Precentral"                    ].Right}},
        {static: true, primaryKey:193,  identifier: 68, belongToForeignKey: 185,  volumeForeignKey: 57, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Med",                   varname:{Left:"HO_Frontal_Med_L_C",                   Right:"HO_Frontal_Med_R_C"                  }, weight: {Left:unitVolume["HO"]["Frontal_Med"                   ].Left, Right:unitVolume["HO"]["Frontal_Med"                   ].Right}},
        {static: true, primaryKey:194,  identifier: 69, belongToForeignKey: 185,  volumeForeignKey: 58, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Supp Motor Area",               varname:{Left:"HO_Supp_Motor_Area_L_C",               Right:"HO_Supp_Motor_Area_R_C"              }, weight: {Left:unitVolume["HO"]["Supp_Motor_Area"               ].Left, Right:unitVolume["HO"]["Supp_Motor_Area"               ].Right}},
        {static: true, primaryKey:195,  identifier: 71, belongToForeignKey: 185,  volumeForeignKey: 59, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Cingulate Ant",                 varname:{Left:"HO_Cingulate_Ant_L_C",                 Right:"HO_Cingulate_Ant_R_C"                }, weight: {Left:unitVolume["HO"]["Cingulate_Ant"                 ].Left, Right:unitVolume["HO"]["Cingulate_Ant"                 ].Right}},
        {static: true, primaryKey:196,  identifier: 71, belongToForeignKey: 185,  volumeForeignKey: 60, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Orb",                   varname:{Left:"HO_Frontal_Orb_L_C",                   Right:"HO_Frontal_Orb_R_C"                  }, weight: {Left:unitVolume["HO"]["Frontal_Orb"                   ].Left, Right:unitVolume["HO"]["Frontal_Orb"                   ].Right}},
        {static: true, primaryKey:197,  identifier: 72, belongToForeignKey: 185,  volumeForeignKey: 61, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Oper",                  varname:{Left:"HO_Frontal_Oper_L_C",                  Right:"HO_Frontal_Oper_R_C"                 }, weight: {Left:unitVolume["HO"]["Frontal_Oper"                  ].Left, Right:unitVolume["HO"]["Frontal_Oper"                  ].Right}},
        {static: true, primaryKey:198,  identifier: 73, belongToForeignKey: 185,  volumeForeignKey: 62, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Central Oper",                  varname:{Left:"HO_Central_Oper_L_C",                  Right:"HO_Central_Oper_R_C"                 }, weight: {Left:unitVolume["HO"]["Central_Oper"                  ].Left, Right:unitVolume["HO"]["Central_Oper"                  ].Right}},
        {static: true, primaryKey:199,  identifier: 74, belongToForeignKey: 185,  volumeForeignKey: 63, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Subcallosal",                   varname:{Left:"HO_Subcallosal_L_C",                   Right:"HO_Subcallosal_R_C"                  }, weight: {Left:unitVolume["HO"]["Subcallosal"                   ].Left, Right:unitVolume["HO"]["Subcallosal"                   ].Right}},
        {static: true, primaryKey:200,  identifier: 75, belongToForeignKey: 185,  volumeForeignKey: 64, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Paracingulate",                 varname:{Left:"HO_Paracingulate_L_C",                 Right:"HO_Paracingulate_R_C"                }, weight: {Left:unitVolume["HO"]["Paracingulate"                 ].Left, Right:unitVolume["HO"]["Paracingulate"                 ].Right}},
        {static: true, primaryKey:201,  identifier: 76, belongToForeignKey: 201,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Retrosplenial",    fullname: "Retrosplenial",                 varname:{Left:"HO_PCC_L_C",                           Right:"HO_PCC_R_C"                          }},                    
        {static: true, primaryKey:202,  identifier: 77, belongToForeignKey: 201,  volumeForeignKey: 65, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Retrosplenial",    fullname: "Cingulate Post",                varname:{Left:"HO_Cingulate_Post_L_C",                Right:"HO_Cingulate_Post_R_C"               }, weight: {Left:unitVolume["HO"]["Cingulate_Post"                ].Left, Right:unitVolume["HO"]["Cingulate_Post"                ].Right}},
        {static: true, primaryKey:203,  identifier: 78, belongToForeignKey: 201,  volumeForeignKey: 66, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Retrosplenial",    fullname: "Precuneus",                     varname:{Left:"HO_Precuneus_L_C",                     Right:"HO_Precuneus_R_C"                    }, weight: {Left:unitVolume["HO"]["Precuneus"                     ].Left, Right:unitVolume["HO"]["Precuneus"                     ].Right}},
        {static: true, primaryKey:204,  identifier: 79, belongToForeignKey: 204,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Lateral temporal",              varname:{Left:"HO_Lateral_temporal_L_C",              Right:"HO_Lateral_temporal_R_C"             }},                    
        {static: true, primaryKey:205,  identifier: 80, belongToForeignKey: 204,  volumeForeignKey: 67, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Pole",                 varname:{Left:"HO_Temporal_Pole_L_C",                 Right:"HO_Temporal_Pole_R_C"                }, weight: {Left:unitVolume["HO"]["Temporal_Pole"                 ].Left, Right:unitVolume["HO"]["Temporal_Pole"                 ].Right}},
        {static: true, primaryKey:206,  identifier: 81, belongToForeignKey: 204,  volumeForeignKey: 68, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Sup Ant",              varname:{Left:"HO_Temporal_Sup_Ant_L_C",              Right:"HO_Temporal_Sup_Ant_R_C"             }, weight: {Left:unitVolume["HO"]["Temporal_Sup_Ant"              ].Left, Right:unitVolume["HO"]["Temporal_Sup_Ant"              ].Right}},
        {static: true, primaryKey:207,  identifier: 82, belongToForeignKey: 204,  volumeForeignKey: 69, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Sup Post",             varname:{Left:"HO_Temporal_Sup_Post_L_C",             Right:"HO_Temporal_Sup_Post_R_C"            }, weight: {Left:unitVolume["HO"]["Temporal_Sup_Post"             ].Left, Right:unitVolume["HO"]["Temporal_Sup_Post"             ].Right}},
        {static: true, primaryKey:208,  identifier: 83, belongToForeignKey: 204,  volumeForeignKey: 70, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Mid Ant",              varname:{Left:"HO_Temporal_Mid_Ant_L_C",              Right:"HO_Temporal_Mid_Ant_R_C"             }, weight: {Left:unitVolume["HO"]["Temporal_Mid_Ant"              ].Left, Right:unitVolume["HO"]["Temporal_Mid_Ant"              ].Right}},
        {static: true, primaryKey:209,  identifier: 84, belongToForeignKey: 204,  volumeForeignKey: 71, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Mid Post",             varname:{Left:"HO_Temporal_Mid_Post_L_C",             Right:"HO_Temporal_Mid_Post_R_C"            }, weight: {Left:unitVolume["HO"]["Temporal_Mid_Post"             ].Left, Right:unitVolume["HO"]["Temporal_Mid_Post"             ].Right}},
        {static: true, primaryKey:210,  identifier: 85, belongToForeignKey: 204,  volumeForeignKey: 72, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Mid Temporooccipital", varname:{Left:"HO_Temporal_Mid_Temporooccipital_L_C", Right:"HO_Temporal_Mid_Temporooccipital_R_C"}, weight: {Left:unitVolume["HO"]["Temporal_Mid_Temporooccipital" ].Left, Right:unitVolume["HO"]["Temporal_Mid_Temporooccipital" ].Right}},
        {static: true, primaryKey:211,  identifier: 86, belongToForeignKey: 204,  volumeForeignKey: 73, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Inf Ant",              varname:{Left:"HO_Temporal_Inf_Ant_L_C",              Right:"HO_Temporal_Inf_Ant_R_C"             }, weight: {Left:unitVolume["HO"]["Temporal_Inf_Ant"              ].Left, Right:unitVolume["HO"]["Temporal_Inf_Ant"              ].Right}},
        {static: true, primaryKey:212,  identifier: 87, belongToForeignKey: 204,  volumeForeignKey: 74, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Inf Post",             varname:{Left:"HO_Temporal_Inf_Post_L_C",             Right:"HO_Temporal_Inf_Post_R_C"            }, weight: {Left:unitVolume["HO"]["Temporal_Inf_Post"             ].Left, Right:unitVolume["HO"]["Temporal_Inf_Post"             ].Right}},
        {static: true, primaryKey:213,  identifier: 88, belongToForeignKey: 204,  volumeForeignKey: 75, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Inf Temporooccipital", varname:{Left:"HO_Temporal_Inf_Temporooccipital_L_C", Right:"HO_Temporal_Inf_Temporooccipital_R_C"}, weight: {Left:unitVolume["HO"]["Temporal_Inf_Temporooccipital" ].Left, Right:unitVolume["HO"]["Temporal_Inf_Temporooccipital" ].Right}},
        {static: true, primaryKey:214,  identifier: 89, belongToForeignKey: 204,  volumeForeignKey: 76, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Planum Polare",                 varname:{Left:"HO_Planum_Polare_L_C",                 Right:"HO_Planum_Polare_R_C"                }, weight: {Left:unitVolume["HO"]["Planum_Polare"                 ].Left, Right:unitVolume["HO"]["Planum_Polare"                 ].Right}},
        {static: true, primaryKey:215,  identifier: 90, belongToForeignKey: 204,  volumeForeignKey: 77, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Heschl",                        varname:{Left:"HO_Heschl_L_C",                        Right:"HO_Heschl_R_C"                       }, weight: {Left:unitVolume["HO"]["Heschl"                        ].Left, Right:unitVolume["HO"]["Heschl"                        ].Right}},
        {static: true, primaryKey:216,  identifier: 91, belongToForeignKey: 204,  volumeForeignKey: 78, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Planum Temporale",              varname:{Left:"HO_Planum_Temporale_L_C",              Right:"HO_Planum_Temporale_R_C"             }, weight: {Left:unitVolume["HO"]["Planum_Temporale"              ].Left, Right:unitVolume["HO"]["Planum_Temporale"              ].Right}},
        {static: true, primaryKey:217,  identifier: 92, belongToForeignKey: 204,  volumeForeignKey: 79, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal fusiform Ant",         varname:{Left:"HO_Temporal_fusiform_Ant_L_C",         Right:"HO_Temporal_fusiform_Ant_R_C"        }, weight: {Left:unitVolume["HO"]["Temporal_fusiform_Ant"         ].Left, Right:unitVolume["HO"]["Temporal_fusiform_Ant"         ].Right}},
        {static: true, primaryKey:218,  identifier: 93, belongToForeignKey: 204,  volumeForeignKey: 80, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal fusiform Post",        varname:{Left:"HO_Temporal_fusiform_Post_L_C",        Right:"HO_Temporal_fusiform_Post_R_C"       }, weight: {Left:unitVolume["HO"]["Temporal_fusiform_Post"        ].Left, Right:unitVolume["HO"]["Temporal_fusiform_Post"        ].Right}},
        {static: true, primaryKey:219,  identifier: 94, belongToForeignKey: 204,  volumeForeignKey: 81, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Occipital Fusiform",   varname:{Left:"HO_Temporal_Occipital_Fusiform_L_C",   Right:"HO_Temporal_Occipital_Fusiform_R_C"  }, weight: {Left:unitVolume["HO"]["Temporal_Occipital_Fusiform"   ].Left, Right:unitVolume["HO"]["Temporal_Occipital_Fusiform"   ].Right}},
        {static: true, primaryKey:220,  identifier: 95, belongToForeignKey: 220,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Lateral parietal",              varname:{Left:"HO_Lateral_parietal_L_C",              Right:"HO_Lateral_parietal_R_C"             }},                    
        {static: true, primaryKey:221,  identifier: 96, belongToForeignKey: 220,  volumeForeignKey: 82, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Postcentral",                   varname:{Left:"HO_Postcentral_L_C",                   Right:"HO_Postcentral_R_C"                  }, weight: {Left:unitVolume["HO"]["Postcentral"                   ].Left, Right:unitVolume["HO"]["Postcentral"                   ].Right}},
        {static: true, primaryKey:222,  identifier: 97, belongToForeignKey: 220,  volumeForeignKey: 83, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Parietal Sup Lobule",           varname:{Left:"HO_Parietal_Sup_Lobule_L_C",           Right:"HO_Parietal_Sup_Lobule_R_C"          }, weight: {Left:unitVolume["HO"]["Parietal_Sup_Lobule"           ].Left, Right:unitVolume["HO"]["Parietal_Sup_Lobule"           ].Right}},
        {static: true, primaryKey:223,  identifier: 98, belongToForeignKey: 220,  volumeForeignKey: 84, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Supramarginal Ant",             varname:{Left:"HO_Supramarginal_Ant_L_C",             Right:"HO_Supramarginal_Ant_R_C"            }, weight: {Left:unitVolume["HO"]["Supramarginal_Ant"             ].Left, Right:unitVolume["HO"]["Supramarginal_Ant"             ].Right}},
        {static: true, primaryKey:224,  identifier: 99, belongToForeignKey: 220,  volumeForeignKey: 85, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Supramarginal Post",            varname:{Left:"HO_Supramarginal_Post_L_C",            Right:"HO_Supramarginal_Post_R_C"           }, weight: {Left:unitVolume["HO"]["Supramarginal_Post"            ].Left, Right:unitVolume["HO"]["Supramarginal_Post"            ].Right}},
        {static: true, primaryKey:225,  identifier:100, belongToForeignKey: 220,  volumeForeignKey: 86, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Angular",                       varname:{Left:"HO_Angular_L_C",                       Right:"HO_Angular_R_C"                      }, weight: {Left:unitVolume["HO"]["Angular"                       ].Left, Right:unitVolume["HO"]["Angular"                       ].Right}},
        {static: true, primaryKey:226,  identifier:101, belongToForeignKey: 220,  volumeForeignKey: 87, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Parietal Oper",                 varname:{Left:"HO_Parietal_Oper_L_C",                 Right:"HO_Parietal_Oper_R_C"                }, weight: {Left:unitVolume["HO"]["Parietal_Oper"                 ].Left, Right:unitVolume["HO"]["Parietal_Oper"                 ].Right}},
        {static: true, primaryKey:227,  identifier:102, belongToForeignKey: 227,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Medial temporal",  fullname: "Medial temporal",               varname:{Left:"HO_Medial_temporal_L_C",               Right:"HO_Medial_temporal_R_C"              }},                    
        {static: true, primaryKey:228,  identifier:103, belongToForeignKey: 227,  volumeForeignKey: 88, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Medial temporal",  fullname: "Parahippocampal Ant",           varname:{Left:"HO_Parahippocampal_Ant_L_C",           Right:"HO_Parahippocampal_Ant_R_C"          }, weight: {Left:unitVolume["HO"]["Parahippocampal_Ant"           ].Left, Right:unitVolume["HO"]["Parahippocampal_Ant"           ].Right}},
        {static: true, primaryKey:229,  identifier:104, belongToForeignKey: 227,  volumeForeignKey: 89, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Medial temporal",  fullname: "Parahippocampal Post",          varname:{Left:"HO_Parahippocampal_Post_L_C",          Right:"HO_Parahippocampal_Post_R_C"         }, weight: {Left:unitVolume["HO"]["Parahippocampal_Post"          ].Left, Right:unitVolume["HO"]["Parahippocampal_Post"          ].Right}},
        {static: true, primaryKey:230,  identifier:105, belongToForeignKey: 227,  volumeForeignKey: 90, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Medial temporal",  fullname: "Hippocampus",                   varname:{Left:"HO_Hippocampus_L_C",                   Right:"HO_Hippocampus_R_C"                  }, weight: {Left:unitVolume["HO"]["Hippocampus"                   ].Left, Right:unitVolume["HO"]["Hippocampus"                   ].Right}},
        {static: true, primaryKey:231,  identifier:106, belongToForeignKey: 227,  volumeForeignKey: 91, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Medial temporal",  fullname: "Amygdala",                      varname:{Left:"HO_Amygdala_L_C",                      Right:"HO_Amygdala_R_C"                     }, weight: {Left:unitVolume["HO"]["Amygdala"                      ].Left, Right:unitVolume["HO"]["Amygdala"                      ].Right}},
        {static: true, primaryKey:232,  identifier:107, belongToForeignKey: 232,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Occipital",                     varname:{Left:"HO_Occipital_L_C",                     Right:"HO_Occipital_R_C"                    }},                    
        {static: true, primaryKey:233,  identifier:108, belongToForeignKey: 232,  volumeForeignKey: 92, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Occipital Lat_Sup",             varname:{Left:"HO_Occipital_Lat_Sup_L_C",             Right:"HO_Occipital_Lat_Sup_R_C"            }, weight: {Left:unitVolume["HO"]["Occipital_Lat_Sup"             ].Left, Right:unitVolume["HO"]["Occipital_Lat_Sup"             ].Right}},
        {static: true, primaryKey:234,  identifier:109, belongToForeignKey: 232,  volumeForeignKey: 93, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Occipital Lat_Inf",             varname:{Left:"HO_Occipital_Lat_Inf_L_C",             Right:"HO_Occipital_Lat_Inf_R_C"            }, weight: {Left:unitVolume["HO"]["Occipital_Lat_Inf"             ].Left, Right:unitVolume["HO"]["Occipital_Lat_Inf"             ].Right}},
        {static: true, primaryKey:235,  identifier:110, belongToForeignKey: 232,  volumeForeignKey: 94, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Intracalcarine",                varname:{Left:"HO_Intracalcarine_L_C",                Right:"HO_Intracalcarine_R_C"               }, weight: {Left:unitVolume["HO"]["Intracalcarine"                ].Left, Right:unitVolume["HO"]["Intracalcarine"                ].Right}},
        {static: true, primaryKey:236,  identifier:111, belongToForeignKey: 232,  volumeForeignKey: 95, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Occipital Pole",                varname:{Left:"HO_Occipital_Pole_L_C",                Right:"HO_Occipital_Pole_R_C"               }, weight: {Left:unitVolume["HO"]["Occipital_Pole"                ].Left, Right:unitVolume["HO"]["Occipital_Pole"                ].Right}},
        {static: true, primaryKey:237,  identifier:112, belongToForeignKey: 232,  volumeForeignKey: 96, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Cuneal",                        varname:{Left:"HO_Cuneal_L_C",                        Right:"HO_Cuneal_R_C"                       }, weight: {Left:unitVolume["HO"]["Cuneal"                        ].Left, Right:unitVolume["HO"]["Cuneal"                        ].Right}},
        {static: true, primaryKey:238,  identifier:113, belongToForeignKey: 232,  volumeForeignKey: 97, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Lingual",                       varname:{Left:"HO_Lingual_L_C",                       Right:"HO_Lingual_R_C"                      }, weight: {Left:unitVolume["HO"]["Lingual"                       ].Left, Right:unitVolume["HO"]["Lingual"                       ].Right}},
        {static: true, primaryKey:239,  identifier:114, belongToForeignKey: 232,  volumeForeignKey: 98, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Occipital Fusiform",            varname:{Left:"HO_Occipital_Fusiform_L_C",            Right:"HO_Occipital_Fusiform_R_C"           }, weight: {Left:unitVolume["HO"]["Occipital_Fusiform"            ].Left, Right:unitVolume["HO"]["Occipital_Fusiform"            ].Right}},
        {static: true, primaryKey:240,  identifier:115, belongToForeignKey: 232,  volumeForeignKey: 99, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Supracalcarine",                varname:{Left:"HO_Supracalcarine_L_C",                Right:"HO_Supracalcarine_R_C"               }, weight: {Left:unitVolume["HO"]["Supracalcarine"                ].Left, Right:unitVolume["HO"]["Supracalcarine"                ].Right}},
        {static: true, primaryKey:241,  identifier:116, belongToForeignKey: 241,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Basal ganglia",    fullname: "Basal ganglia",                 varname:{Left:"HO_Basal_ganglia_L_C",                 Right:"HO_Basal_ganglia_R_C"                }},                    
        {static: true, primaryKey:242,  identifier:117, belongToForeignKey: 241,  volumeForeignKey:100, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Basal ganglia",    fullname: "Caudate",                       varname:{Left:"HO_Caudate_L_C",                       Right:"HO_Caudate_R_C"                      }, weight: {Left:unitVolume["HO"]["Caudate"                       ].Left, Right:unitVolume["HO"]["Caudate"                       ].Right}},
        {static: true, primaryKey:243,  identifier:118, belongToForeignKey: 241,  volumeForeignKey:101, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Basal ganglia",    fullname: "Putamen",                       varname:{Left:"HO_Putamen_L_C",                       Right:"HO_Putamen_R_C"                      }, weight: {Left:unitVolume["HO"]["Putamen"                       ].Left, Right:unitVolume["HO"]["Putamen"                       ].Right}},
        {static: true, primaryKey:244,  identifier:119, belongToForeignKey: 241,  volumeForeignKey:102, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Basal ganglia",    fullname: "Pallidum",                      varname:{Left:"HO_Pallidum_L_C",                      Right:"HO_Pallidum_R_C"                     }, weight: {Left:unitVolume["HO"]["Pallidum"                      ].Left, Right:unitVolume["HO"]["Pallidum"                      ].Right}},
        {static: true, primaryKey:245,  identifier:120, belongToForeignKey: 241,  volumeForeignKey:103, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Basal ganglia",    fullname: "Thalamus",                      varname:{Left:"HO_Thalamus_L_C",                      Right:"HO_Thalamus_R_C"                     }, weight: {Left:unitVolume["HO"]["Thalamus"                      ].Left, Right:unitVolume["HO"]["Thalamus"                      ].Right}},
        {static: true, primaryKey:246,  identifier:121, belongToForeignKey: 241,  volumeForeignKey:104, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Basal ganglia",    fullname: "Accumbens",                     varname:{Left:"HO_Accumbens_L_C",                     Right:"HO_Accumbens_R_C"                    }, weight: {Left:unitVolume["HO"]["Accumbens"                     ].Left, Right:unitVolume["HO"]["Accumbens"                     ].Right}},
        {static: true, primaryKey:247,  identifier:122, belongToForeignKey: 247,  volumeForeignKey: -1, level: 0, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Brainstem",        fullname: "Brainstem",                     varname:{Left:"HO_Brainstem_L_C",                     Right:"HO_Brainstem_R_C"                    }},
        // {primaryKey:246,  foreignKey: 246, level: 0, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal1",          fullname: "Frontal1",                     varname:{Left:"Cingulate_Ant_Sub_L", Right:"Cingulate_Ant_Sub_R"}},
        // {primaryKey:247,  foreignKey: 246, level: 1, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal1",          fullname: "subFrontal1",                  varname:{Left:"Cingulate_Ant_Pre_L", Right:"Cingulate_Ant_Pre_R"}},
        // {primaryKey:248,  foreignKey: 246, level: 1, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal1",          fullname: "subFrontal2",                  varname:{Left:"Cingulate_Ant_Sup_L", Right:"Cingulate_Ant_Sup_R"}},
        // {primaryKey:249,  foreignKey: 246, level: 1, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal1",          fullname: "subFrontal3",                  varname:{Left:"Cingulate_Mid_L", Right:"Cingulate_Mid_R"}},
      ]
      const primaryKeyArr = arr.map((el,idx)=>({...el, primaryKey:idx}))
      let belongToForeignKeyVar=0;
      const belongToForeignKeyArr = primaryKeyArr.map((el,idx)=>{
        if (el.level===0) belongToForeignKeyVar = el.primaryKey
        return {...el, belongToForeignKey:belongToForeignKeyVar}
      })
      const identifierSUVR = belongToForeignKeyArr.filter(el=>el.type==='suvr').map((el,idx)=>({...el, identifier:idx}))
      const identifierCentil = belongToForeignKeyArr.filter(el=>el.type==='centiloid').map((el,idx)=>({...el, identifier:idx}))
      const resultArr = [...identifierSUVR, ...identifierCentil]
      return resultArr
    })(),
    tau:(()=>{
      const arr = [
        {static: true, primaryKey:  0,  identifier:  0, belongToForeignKey:   0,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"AAL3",            belongTo: "Global",           fullname: "Global",                        varname:{Left:"Global",                               Right:"Global"                              }},
        {static: true, primaryKey:  1,  identifier:  1, belongToForeignKey:   1,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal",                       varname:{Left:"Frontal_L",                            Right:"Frontal_R"                           }},
        {static: true, primaryKey:  2,  identifier:  2, belongToForeignKey:   1,  volumeForeignKey:  0, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Precentral",                    varname:{Left:"Precentral_L",                         Right:"Precentral_R"                        }, weight: {Left:unitVolume["AAL3"]["Precentral"                  ].Left, Right:unitVolume["AAL3"]["Precentral"                  ].Right}},
        {static: true, primaryKey:  3,  identifier:  3, belongToForeignKey:   1,  volumeForeignKey:  1, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Sup",                   varname:{Left:"Frontal_Sup_L",                        Right:"Frontal_Sup_R"                       }, weight: {Left:unitVolume["AAL3"]["Frontal_Sup"                 ].Left, Right:unitVolume["AAL3"]["Frontal_Sup"                 ].Right}},
        {static: true, primaryKey:  4,  identifier:  4, belongToForeignKey:   1,  volumeForeignKey:  2, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Mid",                   varname:{Left:"Frontal_Mid_L",                        Right:"Frontal_Mid_R"                       }, weight: {Left:unitVolume["AAL3"]["Frontal_Mid"                 ].Left, Right:unitVolume["AAL3"]["Frontal_Mid"                 ].Right}},
        {static: true, primaryKey:  5,  identifier:  5, belongToForeignKey:   1,  volumeForeignKey:  3, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Inf Oper",              varname:{Left:"Frontal_Inf_Oper_L",                   Right:"Frontal_Inf_Oper_R"                  }, weight: {Left:unitVolume["AAL3"]["Frontal_Inf_Oper"            ].Left, Right:unitVolume["AAL3"]["Frontal_Inf_Oper"            ].Right}},
        {static: true, primaryKey:  6,  identifier:  6, belongToForeignKey:   1,  volumeForeignKey:  4, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Inf Tri",               varname:{Left:"Frontal_Inf_Tri_L",                    Right:"Frontal_Inf_Tri_R"                   }, weight: {Left:unitVolume["AAL3"]["Frontal_Inf_Tri"             ].Left, Right:unitVolume["AAL3"]["Frontal_Inf_Tri"             ].Right}},
        {static: true, primaryKey:  7,  identifier:  7, belongToForeignKey:   1,  volumeForeignKey:  5, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Inf Orb",               varname:{Left:"Frontal_Inf_Orb_L",                    Right:"Frontal_Inf_Orb_R"                   }, weight: {Left:unitVolume["AAL3"]["Frontal_Inf_Orb"             ].Left, Right:unitVolume["AAL3"]["Frontal_Inf_Orb"             ].Right}},
        {static: true, primaryKey:  8,  identifier:  8, belongToForeignKey:   1,  volumeForeignKey:  6, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Rolandic Oper",                 varname:{Left:"Rolandic_Oper_L",                      Right:"Rolandic_Oper_R"                     }, weight: {Left:unitVolume["AAL3"]["Rolandic_Oper"               ].Left, Right:unitVolume["AAL3"]["Rolandic_Oper"               ].Right}},
        {static: true, primaryKey:  9,  identifier:  9, belongToForeignKey:   1,  volumeForeignKey:  7, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Supp Motor Area",               varname:{Left:"Supp_Motor_Area_L",                    Right:"Supp_Motor_Area_R"                   }, weight: {Left:unitVolume["AAL3"]["Supp_Motor_Area"             ].Left, Right:unitVolume["AAL3"]["Supp_Motor_Area"             ].Right}},
        {static: true, primaryKey: 10,  identifier: 10, belongToForeignKey:   1,  volumeForeignKey:  8, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Olfactory",                     varname:{Left:"Olfactory_L",                          Right:"Olfactory_R"                         }, weight: {Left:unitVolume["AAL3"]["Olfactory"                   ].Left, Right:unitVolume["AAL3"]["Olfactory"                   ].Right}},
        {static: true, primaryKey: 11,  identifier: 11, belongToForeignKey:   1,  volumeForeignKey:  9, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Sup Med",               varname:{Left:"Frontal_Sup_Med_L",                    Right:"Frontal_Sup_Med_R"                   }, weight: {Left:unitVolume["AAL3"]["Frontal_Sup_Med"             ].Left, Right:unitVolume["AAL3"]["Frontal_Sup_Med"             ].Right}},
        {static: true, primaryKey: 12,  identifier: 12, belongToForeignKey:   1,  volumeForeignKey: 10, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Med Orb",               varname:{Left:"Frontal_Med_Orb_L",                    Right:"Frontal_Med_Orb_R"                   }, weight: {Left:unitVolume["AAL3"]["Frontal_Med_Orb"             ].Left, Right:unitVolume["AAL3"]["Frontal_Med_Orb"             ].Right}},
        {static: true, primaryKey: 13,  identifier: 13, belongToForeignKey:   1,  volumeForeignKey: 11, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Rectus",                        varname:{Left:"Rectus_L",                             Right:"Rectus_R"                            }, weight: {Left:unitVolume["AAL3"]["Rectus"                      ].Left, Right:unitVolume["AAL3"]["Rectus"                      ].Right}},
        {static: true, primaryKey: 14,  identifier: 14, belongToForeignKey:   1,  volumeForeignKey: 12, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "OFCmed",                        varname:{Left:"OFCmed_L",                             Right:"OFCmed_R"                            }, weight: {Left:unitVolume["AAL3"]["OFCmed"                      ].Left, Right:unitVolume["AAL3"]["OFCmed"                      ].Right}},
        {static: true, primaryKey: 15,  identifier: 15, belongToForeignKey:   1,  volumeForeignKey: 13, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "OFCant",                        varname:{Left:"OFCant_L",                             Right:"OFCant_R"                            }, weight: {Left:unitVolume["AAL3"]["OFCant"                      ].Left, Right:unitVolume["AAL3"]["OFCant"                      ].Right}},
        {static: true, primaryKey: 16,  identifier: 16, belongToForeignKey:   1,  volumeForeignKey: 14, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "OFCpost",                       varname:{Left:"OFCpost_L",                            Right:"OFCpost_R"                           }, weight: {Left:unitVolume["AAL3"]["OFCpost"                     ].Left, Right:unitVolume["AAL3"]["OFCpost"                     ].Right}},
        {static: true, primaryKey: 17,  identifier: 17, belongToForeignKey:   1,  volumeForeignKey: 15, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "OFClat",                        varname:{Left:"OFClat_L",                             Right:"OFClat_R"                            }, weight: {Left:unitVolume["AAL3"]["OFClat"                      ].Left, Right:unitVolume["AAL3"]["OFClat"                      ].Right}},
        {static: true, primaryKey: 18,  identifier: 18, belongToForeignKey:   1,  volumeForeignKey: 16, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Insula",                        varname:{Left:"Insula_L",                             Right:"Insula_R"                            }, weight: {Left:unitVolume["AAL3"]["Insula"                      ].Left, Right:unitVolume["AAL3"]["Insula"                      ].Right}},
        {static: true, primaryKey: 19,  identifier: 19, belongToForeignKey:   1,  volumeForeignKey: 17, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Cingulate Ant Sub",             varname:{Left:"Cingulate_Ant_Sub_L",                  Right:"Cingulate_Ant_Sub_R"                 }, weight: {Left:unitVolume["AAL3"]["Cingulate_Ant_Sub"           ].Left, Right:unitVolume["AAL3"]["Cingulate_Ant_Sub"           ].Right}},
        {static: true, primaryKey: 20,  identifier: 20, belongToForeignKey:   1,  volumeForeignKey: 18, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Cingulate Ant Pre",             varname:{Left:"Cingulate_Ant_Pre_L",                  Right:"Cingulate_Ant_Pre_R"                 }, weight: {Left:unitVolume["AAL3"]["Cingulate_Ant_Pre"           ].Left, Right:unitVolume["AAL3"]["Cingulate_Ant_Pre"           ].Right}},
        {static: true, primaryKey: 21,  identifier: 21, belongToForeignKey:   1,  volumeForeignKey: 19, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Cingulate Ant Sup",             varname:{Left:"Cingulate_Ant_Sup_L",                  Right:"Cingulate_Ant_Sup_R"                 }, weight: {Left:unitVolume["AAL3"]["Cingulate_Ant_Sup"           ].Left, Right:unitVolume["AAL3"]["Cingulate_Ant_Sup"           ].Right}},
        {static: true, primaryKey: 22,  identifier: 22, belongToForeignKey:   1,  volumeForeignKey: 20, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Cingulate Mid",                 varname:{Left:"Cingulate_Mid_L",                      Right:"Cingulate_Mid_R"                     }, weight: {Left:unitVolume["AAL3"]["Cingulate_Mid"               ].Left, Right:unitVolume["AAL3"]["Cingulate_Mid"               ].Right}},
        {static: true, primaryKey: 23,  identifier: 23, belongToForeignKey:  23,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"AAL3",            belongTo: "PCC-Precuneus",    fullname: "PCC-Precuneus",                 varname:{Left:"PCC_L",                                Right:"PCC_R"                               }},
        {static: true, primaryKey: 24,  identifier: 24, belongToForeignKey:  23,  volumeForeignKey: 21, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "PCC-Precuneus",    fullname: "Cingulate Post",                varname:{Left:"Cingulate_Post_L",                     Right:"Cingulate_Post_R"                    }, weight: {Left:unitVolume["AAL3"]["Cingulate_Post"              ].Left, Right:unitVolume["AAL3"]["Cingulate_Post"              ].Right}},
        {static: true, primaryKey: 25,  identifier: 25, belongToForeignKey:  23,  volumeForeignKey: 22, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "PCC-Precuneus",    fullname: "Precuneus",                     varname:{Left:"Precuneus_L",                          Right:"Precuneus_R"                         }, weight: {Left:unitVolume["AAL3"]["Precuneus"                   ].Left, Right:unitVolume["AAL3"]["Precuneus"                   ].Right}},
        {static: true, primaryKey: 26,  identifier: 26, belongToForeignKey:  26,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Lateral temporal",              varname:{Left:"Lateral_temporal_L",                   Right:"Lateral_temporal_R"                  }},                      
        {static: true, primaryKey: 27,  identifier: 27, belongToForeignKey:  26,  volumeForeignKey: 23, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Heschl",                        varname:{Left:"Heschl_L",                             Right:"Heschl_R"                            }, weight: {Left:unitVolume["AAL3"]["Heschl"                      ].Left, Right:unitVolume["AAL3"]["Heschl"                      ].Right}},
        {static: true, primaryKey: 28,  identifier: 28, belongToForeignKey:  26,  volumeForeignKey: 24, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Temporal Sup",                  varname:{Left:"Temporal_Sup_L",                       Right:"Temporal_Sup_R"                      }, weight: {Left:unitVolume["AAL3"]["Temporal_Sup"                ].Left, Right:unitVolume["AAL3"]["Temporal_Sup"                ].Right}},
        {static: true, primaryKey: 29,  identifier: 29, belongToForeignKey:  26,  volumeForeignKey: 25, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Temporal Pole Sup",             varname:{Left:"Temporal_Pole_Sup_L",                  Right:"Temporal_Pole_Sup_R"                 }, weight: {Left:unitVolume["AAL3"]["Temporal_Pole_Sup"           ].Left, Right:unitVolume["AAL3"]["Temporal_Pole_Sup"           ].Right}},
        {static: true, primaryKey: 30,  identifier: 30, belongToForeignKey:  26,  volumeForeignKey: 26, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Temporal Mid",                  varname:{Left:"Temporal_Mid_L",                       Right:"Temporal_Mid_R"                      }, weight: {Left:unitVolume["AAL3"]["Temporal_Mid"                ].Left, Right:unitVolume["AAL3"]["Temporal_Mid"                ].Right}},
        {static: true, primaryKey: 31,  identifier: 31, belongToForeignKey:  26,  volumeForeignKey: 27, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Temporal Pole Mid",             varname:{Left:"Temporal_Pole_Mid_L",                  Right:"Temporal_Pole_Mid_R"                 }, weight: {Left:unitVolume["AAL3"]["Temporal_Pole_Mid"           ].Left, Right:unitVolume["AAL3"]["Temporal_Pole_Mid"           ].Right}},
        {static: true, primaryKey: 32,  identifier: 32, belongToForeignKey:  26,  volumeForeignKey: 28, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Temporal Inf",                  varname:{Left:"Temporal_Inf_L",                       Right:"Temporal_Inf_R"                      }, weight: {Left:unitVolume["AAL3"]["Temporal_Inf"                ].Left, Right:unitVolume["AAL3"]["Temporal_Inf"                ].Right}},
        {static: true, primaryKey: 33,  identifier: 33, belongToForeignKey:  33,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Lateral parietal",              varname:{Left:"Lateral_parietal_L",                   Right:"Lateral_parietal_R"                  }},                      
        {static: true, primaryKey: 34,  identifier: 34, belongToForeignKey:  33,  volumeForeignKey: 29, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Postcentral",                   varname:{Left:"Postcentral_L",                        Right:"Postcentral_R"                       }, weight: {Left:unitVolume["AAL3"]["Postcentral"                 ].Left, Right:unitVolume["AAL3"]["Postcentral"                 ].Right}},
        {static: true, primaryKey: 35,  identifier: 35, belongToForeignKey:  33,  volumeForeignKey: 30, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Parietal Sup",                  varname:{Left:"Parietal_Sup_L",                       Right:"Parietal_Sup_R"                      }, weight: {Left:unitVolume["AAL3"]["Parietal_Sup"                ].Left, Right:unitVolume["AAL3"]["Parietal_Sup"                ].Right}},
        {static: true, primaryKey: 36,  identifier: 36, belongToForeignKey:  33,  volumeForeignKey: 31, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Parietal Inf",                  varname:{Left:"Parietal_Inf_L",                       Right:"Parietal_Inf_R"                      }, weight: {Left:unitVolume["AAL3"]["Parietal_Inf"                ].Left, Right:unitVolume["AAL3"]["Parietal_Inf"                ].Right}},
        {static: true, primaryKey: 37,  identifier: 37, belongToForeignKey:  33,  volumeForeignKey: 32, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Supramarginal",                 varname:{Left:"Supramarginal_L",                      Right:"Supramarginal_R"                     }, weight: {Left:unitVolume["AAL3"]["Supramarginal"               ].Left, Right:unitVolume["AAL3"]["Supramarginal"               ].Right}},
        {static: true, primaryKey: 38,  identifier: 38, belongToForeignKey:  33,  volumeForeignKey: 33, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Angular",                       varname:{Left:"Angular_L",                            Right:"Angular_R"                           }, weight: {Left:unitVolume["AAL3"]["Angular"                     ].Left, Right:unitVolume["AAL3"]["Angular"                     ].Right}},
        {static: true, primaryKey: 39,  identifier: 39, belongToForeignKey:  33,  volumeForeignKey: 34, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Paracentral Lobule",            varname:{Left:"Paracentral_Lobule_L",                 Right:"Paracentral_Lobule_R"                }, weight: {Left:unitVolume["AAL3"]["Paracentral_Lobule"          ].Left, Right:unitVolume["AAL3"]["Paracentral_Lobule"          ].Right}},
        {static: true, primaryKey: 40,  identifier: 40, belongToForeignKey:  40,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"AAL3",            belongTo: "Medial temporal",  fullname: "Medial temporal",               varname:{Left:"Medial_temporal_L",                    Right:"Medial_temporal_R"                   }},                      
        {static: true, primaryKey: 41,  identifier: 41, belongToForeignKey:  40,  volumeForeignKey: 35, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Medial temporal",  fullname: "Hippocampus",                   varname:{Left:"Hippocampus_L",                        Right:"Hippocampus_R"                       }, weight: {Left:unitVolume["AAL3"]["Hippocampus"                 ].Left, Right:unitVolume["AAL3"]["Hippocampus"                 ].Right}},
        {static: true, primaryKey: 42,  identifier: 42, belongToForeignKey:  40,  volumeForeignKey: 36, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Medial temporal",  fullname: "Parahippocampal",               varname:{Left:"Parahippocampal_L",                    Right:"Parahippocampal_R"                   }, weight: {Left:unitVolume["AAL3"]["Parahippocampal"             ].Left, Right:unitVolume["AAL3"]["Parahippocampal"             ].Right}},
        {static: true, primaryKey: 43,  identifier: 43, belongToForeignKey:  40,  volumeForeignKey: 37, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Medial temporal",  fullname: "Amygdala",                      varname:{Left:"Amygdala_L",                           Right:"Amygdala_R"                          }, weight: {Left:unitVolume["AAL3"]["Amygdala"                    ].Left, Right:unitVolume["AAL3"]["Amygdala"                    ].Right}},
        {static: true, primaryKey: 44,  identifier: 44, belongToForeignKey:  44,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"AAL3",            belongTo: "Occipital",        fullname: "Occipital",                     varname:{Left:"Occipital_L",                          Right:"Occipital_R"                         }},                      
        {static: true, primaryKey: 45,  identifier: 45, belongToForeignKey:  44,  volumeForeignKey: 38, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Occipital",        fullname: "Calcarine",                     varname:{Left:"Calcarine_L",                          Right:"Calcarine_R"                         }, weight: {Left:unitVolume["AAL3"]["Calcarine"                   ].Left, Right:unitVolume["AAL3"]["Calcarine"                   ].Right}},
        {static: true, primaryKey: 46,  identifier: 46, belongToForeignKey:  44,  volumeForeignKey: 39, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Occipital",        fullname: "Cuneus",                        varname:{Left:"Cuneus_L",                             Right:"Cuneus_R"                            }, weight: {Left:unitVolume["AAL3"]["Cuneus"                      ].Left, Right:unitVolume["AAL3"]["Cuneus"                      ].Right}},
        {static: true, primaryKey: 47,  identifier: 47, belongToForeignKey:  44,  volumeForeignKey: 40, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Occipital",        fullname: "Lingual",                       varname:{Left:"Lingual_L",                            Right:"Lingual_R"                           }, weight: {Left:unitVolume["AAL3"]["Lingual"                     ].Left, Right:unitVolume["AAL3"]["Lingual"                     ].Right}},
        {static: true, primaryKey: 48,  identifier: 48, belongToForeignKey:  44,  volumeForeignKey: 41, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Occipital",        fullname: "Occipital Sup",                 varname:{Left:"Occipital_Sup_L",                      Right:"Occipital_Sup_R"                     }, weight: {Left:unitVolume["AAL3"]["Occipital_Sup"               ].Left, Right:unitVolume["AAL3"]["Occipital_Sup"               ].Right}},
        {static: true, primaryKey: 49,  identifier: 49, belongToForeignKey:  44,  volumeForeignKey: 42, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Occipital",        fullname: "Occipital Mid",                 varname:{Left:"Occipital_Mid_L",                      Right:"Occipital_Mid_R"                     }, weight: {Left:unitVolume["AAL3"]["Occipital_Mid"               ].Left, Right:unitVolume["AAL3"]["Occipital_Mid"               ].Right}},
        {static: true, primaryKey: 50,  identifier: 50, belongToForeignKey:  44,  volumeForeignKey: 43, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Occipital",        fullname: "Occipital Inf",                 varname:{Left:"Occipital_Inf_L",                      Right:"Occipital_Inf_R"                     }, weight: {Left:unitVolume["AAL3"]["Occipital_Inf"               ].Left, Right:unitVolume["AAL3"]["Occipital_Inf"               ].Right}},
        {static: true, primaryKey: 51,  identifier: 51, belongToForeignKey:  44,  volumeForeignKey: 44, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Occipital",        fullname: "Fusiform",                      varname:{Left:"Fusiform_L",                           Right:"Fusiform_R"                          }, weight: {Left:unitVolume["AAL3"]["Fusiform"                    ].Left, Right:unitVolume["AAL3"]["Fusiform"                    ].Right}},
        {static: true, primaryKey: 52,  identifier: 52, belongToForeignKey:  52,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"AAL3",            belongTo: "Basal ganglia",    fullname: "Basal ganglia",                 varname:{Left:"Basal_ganglia_L",                      Right:"Basal_ganglia_R"                     }},                      
        {static: true, primaryKey: 53,  identifier: 53, belongToForeignKey:  52,  volumeForeignKey: 45, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Basal ganglia",    fullname: "Caudate",                       varname:{Left:"Caudate_L",                            Right:"Caudate_R"                           }, weight: {Left:unitVolume["AAL3"]["Caudate"                     ].Left, Right:unitVolume["AAL3"]["Caudate"                     ].Right}},
        {static: true, primaryKey: 54,  identifier: 54, belongToForeignKey:  52,  volumeForeignKey: 46, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Basal ganglia",    fullname: "Putamen",                       varname:{Left:"Putamen_L",                            Right:"Putamen_R"                           }, weight: {Left:unitVolume["AAL3"]["Putamen"                     ].Left, Right:unitVolume["AAL3"]["Putamen"                     ].Right}},
        {static: true, primaryKey: 55,  identifier: 55, belongToForeignKey:  52,  volumeForeignKey: 47, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Basal ganglia",    fullname: "Pallidum",                      varname:{Left:"Pallidum_L",                           Right:"Pallidum_R"                          }, weight: {Left:unitVolume["AAL3"]["Pallidum"                    ].Left, Right:unitVolume["AAL3"]["Pallidum"                    ].Right}},
        {static: true, primaryKey: 56,  identifier: 56, belongToForeignKey:  52,  volumeForeignKey: 48, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Basal ganglia",    fullname: "Thalamus",                      varname:{Left:"Thalamus_L",                           Right:"Thalamus_R"                          }, weight: {Left:unitVolume["AAL3"]["Thalamus"                    ].Left, Right:unitVolume["AAL3"]["Thalamus"                    ].Right}},
        {static: true, primaryKey: 57,  identifier: 57, belongToForeignKey:  52,  volumeForeignKey: 49, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Basal ganglia",    fullname: "N Acc",                         varname:{Left:"N_Acc_L",                              Right:"N_Acc_R"                             }, weight: {Left:unitVolume["AAL3"]["N_Acc"                       ].Left, Right:unitVolume["AAL3"]["N_Acc"                       ].Right}},
        {static: true, primaryKey: 58,  identifier: 58, belongToForeignKey:  58,  volumeForeignKey: -1, level: 0, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Brainstem",        fullname: "Brainstem",                     varname:{Left:"Brainstem_L",                          Right:"Brainstem_R"                         }},
        {static: true, primaryKey: 59,  identifier: 59, belongToForeignKey:  59,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Global",           fullname: "Global",                        varname:{Left:"HO_Global",                            Right:"HO_Global"                           }},
        {static: true, primaryKey: 60,  identifier: 60, belongToForeignKey:  60,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal",                       varname:{Left:"HO_Frontal_L",                         Right:"HO_Frontal_R"                        }},
        {static: true, primaryKey: 61,  identifier: 61, belongToForeignKey:  60,  volumeForeignKey: 50, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Pole",                  varname:{Left:"HO_Frontal_Pole_L",                    Right:"HO_Frontal_Pole_R"                   }, weight: {Left:unitVolume["HO"]["Frontal_Pole"                  ].Left, Right:unitVolume["HO"]["Frontal_Pole"                  ].Right}},
        {static: true, primaryKey: 62,  identifier: 62, belongToForeignKey:  60,  volumeForeignKey: 51, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Insula",                        varname:{Left:"HO_Insula_L",                          Right:"HO_Insula_R"                         }, weight: {Left:unitVolume["HO"]["Insula"                        ].Left, Right:unitVolume["HO"]["Insula"                        ].Right}},
        {static: true, primaryKey: 63,  identifier: 63, belongToForeignKey:  60,  volumeForeignKey: 52, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Sup",                   varname:{Left:"HO_Frontal_Sup_L",                     Right:"HO_Frontal_Sup_R"                    }, weight: {Left:unitVolume["HO"]["Frontal_Sup"                   ].Left, Right:unitVolume["HO"]["Frontal_Sup"                   ].Right}},
        {static: true, primaryKey: 64,  identifier: 64, belongToForeignKey:  60,  volumeForeignKey: 53, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Mid",                   varname:{Left:"HO_Frontal_Mid_L",                     Right:"HO_Frontal_Mid_R"                    }, weight: {Left:unitVolume["HO"]["Frontal_Mid"                   ].Left, Right:unitVolume["HO"]["Frontal_Mid"                   ].Right}},
        {static: true, primaryKey: 65,  identifier: 65, belongToForeignKey:  60,  volumeForeignKey: 54, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Inf Tri",               varname:{Left:"HO_Frontal_Inf_Tri_L",                 Right:"HO_Frontal_Inf_Tri_R"                }, weight: {Left:unitVolume["HO"]["Frontal_Inf_Tri"               ].Left, Right:unitVolume["HO"]["Frontal_Inf_Tri"               ].Right}},
        {static: true, primaryKey: 66,  identifier: 66, belongToForeignKey:  60,  volumeForeignKey: 55, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Inf Oper",              varname:{Left:"HO_Frontal_Inf_Oper_L",                Right:"HO_Frontal_Inf_Oper_R"               }, weight: {Left:unitVolume["HO"]["Frontal_Inf_Oper"              ].Left, Right:unitVolume["HO"]["Frontal_Inf_Oper"              ].Right}},
        {static: true, primaryKey: 67,  identifier: 67, belongToForeignKey:  60,  volumeForeignKey: 56, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Precentral",                    varname:{Left:"HO_Precentral_L",                      Right:"HO_Precentral_R"                     }, weight: {Left:unitVolume["HO"]["Precentral"                    ].Left, Right:unitVolume["HO"]["Precentral"                    ].Right}},
        {static: true, primaryKey: 68,  identifier: 68, belongToForeignKey:  60,  volumeForeignKey: 57, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Med",                   varname:{Left:"HO_Frontal_Med_L",                     Right:"HO_Frontal_Med_R"                    }, weight: {Left:unitVolume["HO"]["Frontal_Med"                   ].Left, Right:unitVolume["HO"]["Frontal_Med"                   ].Right}},
        {static: true, primaryKey: 69,  identifier: 69, belongToForeignKey:  60,  volumeForeignKey: 58, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Supp Motor Area",               varname:{Left:"HO_Supp_Motor_Area_L",                 Right:"HO_Supp_Motor_Area_R"                }, weight: {Left:unitVolume["HO"]["Supp_Motor_Area"               ].Left, Right:unitVolume["HO"]["Supp_Motor_Area"               ].Right}},
        {static: true, primaryKey: 71,  identifier: 71, belongToForeignKey:  60,  volumeForeignKey: 59, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Cingulate Ant",                 varname:{Left:"HO_Cingulate_Ant_L",                   Right:"HO_Cingulate_Ant_R"                  }, weight: {Left:unitVolume["HO"]["Cingulate_Ant"                 ].Left, Right:unitVolume["HO"]["Cingulate_Ant"                 ].Right}},
        {static: true, primaryKey: 71,  identifier: 71, belongToForeignKey:  60,  volumeForeignKey: 60, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Orb",                   varname:{Left:"HO_Frontal_Orb_L",                     Right:"HO_Frontal_Orb_R"                    }, weight: {Left:unitVolume["HO"]["Frontal_Orb"                   ].Left, Right:unitVolume["HO"]["Frontal_Orb"                   ].Right}},
        {static: true, primaryKey: 72,  identifier: 72, belongToForeignKey:  60,  volumeForeignKey: 61, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Oper",                  varname:{Left:"HO_Frontal_Oper_L",                    Right:"HO_Frontal_Oper_R"                   }, weight: {Left:unitVolume["HO"]["Frontal_Oper"                  ].Left, Right:unitVolume["HO"]["Frontal_Oper"                  ].Right}},
        {static: true, primaryKey: 73,  identifier: 73, belongToForeignKey:  60,  volumeForeignKey: 62, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Central Oper",                  varname:{Left:"HO_Central_Oper_L",                    Right:"HO_Central_Oper_R"                   }, weight: {Left:unitVolume["HO"]["Central_Oper"                  ].Left, Right:unitVolume["HO"]["Central_Oper"                  ].Right}},
        {static: true, primaryKey: 74,  identifier: 74, belongToForeignKey:  60,  volumeForeignKey: 63, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Subcallosal",                   varname:{Left:"HO_Subcallosal_L",                     Right:"HO_Subcallosal_R"                    }, weight: {Left:unitVolume["HO"]["Subcallosal"                   ].Left, Right:unitVolume["HO"]["Subcallosal"                   ].Right}},
        {static: true, primaryKey: 75,  identifier: 75, belongToForeignKey:  60,  volumeForeignKey: 64, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Paracingulate",                 varname:{Left:"HO_Paracingulate_L",                   Right:"HO_Paracingulate_R"                  }, weight: {Left:unitVolume["HO"]["Paracingulate"                 ].Left, Right:unitVolume["HO"]["Paracingulate"                 ].Right}},
        {static: true, primaryKey: 76,  identifier: 76, belongToForeignKey:  76,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Retrosplenial",    fullname: "Retrosplenial",                 varname:{Left:"HO_PCC_L",                             Right:"HO_PCC_R"                            }},                      
        {static: true, primaryKey: 77,  identifier: 77, belongToForeignKey:  76,  volumeForeignKey: 65, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Retrosplenial",    fullname: "Cingulate Post",                varname:{Left:"HO_Cingulate_Post_L",                  Right:"HO_Cingulate_Post_R"                 }, weight: {Left:unitVolume["HO"]["Cingulate_Post"                ].Left, Right:unitVolume["HO"]["Cingulate_Post"                ].Right}},
        {static: true, primaryKey: 78,  identifier: 78, belongToForeignKey:  76,  volumeForeignKey: 66, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Retrosplenial",    fullname: "Precuneus",                     varname:{Left:"HO_Precuneus_L",                       Right:"HO_Precuneus_R"                      }, weight: {Left:unitVolume["HO"]["Precuneus"                     ].Left, Right:unitVolume["HO"]["Precuneus"                     ].Right}},
        {static: true, primaryKey: 79,  identifier: 79, belongToForeignKey:  79,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Lateral temporal",              varname:{Left:"HO_Lateral_temporal_L",                Right:"HO_Lateral_temporal_R"               }},
        {static: true, primaryKey: 80,  identifier: 80, belongToForeignKey:  79,  volumeForeignKey: 67, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Pole",                 varname:{Left:"HO_Temporal_Pole_L",                   Right:"HO_Temporal_Pole_R"                  }, weight: {Left:unitVolume["HO"]["Temporal_Pole"                 ].Left, Right:unitVolume["HO"]["Temporal_Pole"                 ].Right}},
        {static: true, primaryKey: 81,  identifier: 81, belongToForeignKey:  79,  volumeForeignKey: 68, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Sup Ant",              varname:{Left:"HO_Temporal_Sup_Ant_L",                Right:"HO_Temporal_Sup_Ant_R"               }, weight: {Left:unitVolume["HO"]["Temporal_Sup_Ant"              ].Left, Right:unitVolume["HO"]["Temporal_Sup_Ant"              ].Right}},
        {static: true, primaryKey: 82,  identifier: 82, belongToForeignKey:  79,  volumeForeignKey: 69, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Sup Post",             varname:{Left:"HO_Temporal_Sup_Post_L",               Right:"HO_Temporal_Sup_Post_R"              }, weight: {Left:unitVolume["HO"]["Temporal_Sup_Post"             ].Left, Right:unitVolume["HO"]["Temporal_Sup_Post"             ].Right}},
        {static: true, primaryKey: 83,  identifier: 83, belongToForeignKey:  79,  volumeForeignKey: 70, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Mid Ant",              varname:{Left:"HO_Temporal_Mid_Ant_L",                Right:"HO_Temporal_Mid_Ant_R"               }, weight: {Left:unitVolume["HO"]["Temporal_Mid_Ant"              ].Left, Right:unitVolume["HO"]["Temporal_Mid_Ant"              ].Right}},
        {static: true, primaryKey: 84,  identifier: 84, belongToForeignKey:  79,  volumeForeignKey: 71, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Mid Post",             varname:{Left:"HO_Temporal_Mid_Post_L",               Right:"HO_Temporal_Mid_Post_R"              }, weight: {Left:unitVolume["HO"]["Temporal_Mid_Post"             ].Left, Right:unitVolume["HO"]["Temporal_Mid_Post"             ].Right}},
        {static: true, primaryKey: 85,  identifier: 85, belongToForeignKey:  79,  volumeForeignKey: 72, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Mid Temporooccipital", varname:{Left:"HO_Temporal_Mid_Temporooccipital_L",   Right:"HO_Temporal_Mid_Temporooccipital_R"  }, weight: {Left:unitVolume["HO"]["Temporal_Mid_Temporooccipital" ].Left, Right:unitVolume["HO"]["Temporal_Mid_Temporooccipital" ].Right}},
        {static: true, primaryKey: 86,  identifier: 86, belongToForeignKey:  79,  volumeForeignKey: 73, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Inf Ant",              varname:{Left:"HO_Temporal_Inf_Ant_L",                Right:"HO_Temporal_Inf_Ant_R"               }, weight: {Left:unitVolume["HO"]["Temporal_Inf_Ant"              ].Left, Right:unitVolume["HO"]["Temporal_Inf_Ant"              ].Right}},
        {static: true, primaryKey: 87,  identifier: 87, belongToForeignKey:  79,  volumeForeignKey: 74, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Inf Post",             varname:{Left:"HO_Temporal_Inf_Post_L",               Right:"HO_Temporal_Inf_Post_R"              }, weight: {Left:unitVolume["HO"]["Temporal_Inf_Post"             ].Left, Right:unitVolume["HO"]["Temporal_Inf_Post"             ].Right}},
        {static: true, primaryKey: 88,  identifier: 88, belongToForeignKey:  79,  volumeForeignKey: 75, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Inf Temporooccipital", varname:{Left:"HO_Temporal_Inf_Temporooccipital_L",   Right:"HO_Temporal_Inf_Temporooccipital_R"  }, weight: {Left:unitVolume["HO"]["Temporal_Inf_Temporooccipital" ].Left, Right:unitVolume["HO"]["Temporal_Inf_Temporooccipital" ].Right}},
        {static: true, primaryKey: 89,  identifier: 89, belongToForeignKey:  79,  volumeForeignKey: 76, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Planum Polare",                 varname:{Left:"HO_Planum_Polare_L",                   Right:"HO_Planum_Polare_R"                  }, weight: {Left:unitVolume["HO"]["Planum_Polare"                 ].Left, Right:unitVolume["HO"]["Planum_Polare"                 ].Right}},
        {static: true, primaryKey: 90,  identifier: 90, belongToForeignKey:  79,  volumeForeignKey: 77, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Heschl",                        varname:{Left:"HO_Heschl_L",                          Right:"HO_Heschl_R"                         }, weight: {Left:unitVolume["HO"]["Heschl"                        ].Left, Right:unitVolume["HO"]["Heschl"                        ].Right}},
        {static: true, primaryKey: 91,  identifier: 91, belongToForeignKey:  79,  volumeForeignKey: 78, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Planum Temporale",              varname:{Left:"HO_Planum_Temporale_L",                Right:"HO_Planum_Temporale_R"               }, weight: {Left:unitVolume["HO"]["Planum_Temporale"              ].Left, Right:unitVolume["HO"]["Planum_Temporale"              ].Right}},
        {static: true, primaryKey: 92,  identifier: 92, belongToForeignKey:  79,  volumeForeignKey: 79, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal fusiform Ant",         varname:{Left:"HO_Temporal_fusiform_Ant_L",           Right:"HO_Temporal_fusiform_Ant_R"          }, weight: {Left:unitVolume["HO"]["Temporal_fusiform_Ant"         ].Left, Right:unitVolume["HO"]["Temporal_fusiform_Ant"         ].Right}},
        {static: true, primaryKey: 93,  identifier: 93, belongToForeignKey:  79,  volumeForeignKey: 80, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal fusiform Post",        varname:{Left:"HO_Temporal_fusiform_Post_L",          Right:"HO_Temporal_fusiform_Post_R"         }, weight: {Left:unitVolume["HO"]["Temporal_fusiform_Post"        ].Left, Right:unitVolume["HO"]["Temporal_fusiform_Post"        ].Right}},
        {static: true, primaryKey: 94,  identifier: 94, belongToForeignKey:  79,  volumeForeignKey: 81, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Occipital Fusiform",   varname:{Left:"HO_Temporal_Occipital_Fusiform_L",     Right:"HO_Temporal_Occipital_Fusiform_R"    }, weight: {Left:unitVolume["HO"]["Temporal_Occipital_Fusiform"   ].Left, Right:unitVolume["HO"]["Temporal_Occipital_Fusiform"   ].Right}},
        {static: true, primaryKey: 95,  identifier: 95, belongToForeignKey:  95,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Lateral parietal",              varname:{Left:"HO_Lateral_parietal_L",                Right:"HO_Lateral_parietal_R"               }},                    
        {static: true, primaryKey: 96,  identifier: 96, belongToForeignKey:  95,  volumeForeignKey: 82, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Postcentral",                   varname:{Left:"HO_Postcentral_L",                     Right:"HO_Postcentral_R"                    }, weight: {Left:unitVolume["HO"]["Postcentral"                   ].Left, Right:unitVolume["HO"]["Postcentral"                   ].Right}},
        {static: true, primaryKey: 97,  identifier: 97, belongToForeignKey:  95,  volumeForeignKey: 83, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Parietal Sup Lobule",           varname:{Left:"HO_Parietal_Sup_Lobule_L",             Right:"HO_Parietal_Sup_Lobule_R"            }, weight: {Left:unitVolume["HO"]["Parietal_Sup_Lobule"           ].Left, Right:unitVolume["HO"]["Parietal_Sup_Lobule"           ].Right}},
        {static: true, primaryKey: 98,  identifier: 98, belongToForeignKey:  95,  volumeForeignKey: 84, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Supramarginal Ant",             varname:{Left:"HO_Supramarginal_Ant_L",               Right:"HO_Supramarginal_Ant_R"              }, weight: {Left:unitVolume["HO"]["Supramarginal_Ant"             ].Left, Right:unitVolume["HO"]["Supramarginal_Ant"             ].Right}},
        {static: true, primaryKey: 99,  identifier: 99, belongToForeignKey:  95,  volumeForeignKey: 85, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Supramarginal Post",            varname:{Left:"HO_Supramarginal_Post_L",              Right:"HO_Supramarginal_Post_R"             }, weight: {Left:unitVolume["HO"]["Supramarginal_Post"            ].Left, Right:unitVolume["HO"]["Supramarginal_Post"            ].Right}},
        {static: true, primaryKey:100,  identifier:100, belongToForeignKey:  95,  volumeForeignKey: 86, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Angular",                       varname:{Left:"HO_Angular_L",                         Right:"HO_Angular_R"                        }, weight: {Left:unitVolume["HO"]["Angular"                       ].Left, Right:unitVolume["HO"]["Angular"                       ].Right}},
        {static: true, primaryKey:101,  identifier:101, belongToForeignKey:  95,  volumeForeignKey: 87, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Parietal Oper",                 varname:{Left:"HO_Parietal_Oper_L",                   Right:"HO_Parietal_Oper_R"                  }, weight: {Left:unitVolume["HO"]["Parietal_Oper"                 ].Left, Right:unitVolume["HO"]["Parietal_Oper"                 ].Right}},
        {static: true, primaryKey:102,  identifier:102, belongToForeignKey: 102,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Medial temporal",  fullname: "Medial temporal",               varname:{Left:"HO_Medial_temporal_L",                 Right:"HO_Medial_temporal_R"                }},                    
        {static: true, primaryKey:103,  identifier:103, belongToForeignKey: 102,  volumeForeignKey: 88, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Medial temporal",  fullname: "Parahippocampal Ant",           varname:{Left:"HO_Parahippocampal_Ant_L",             Right:"HO_Parahippocampal_Ant_R"            }, weight: {Left:unitVolume["HO"]["Parahippocampal_Ant"           ].Left, Right:unitVolume["HO"]["Parahippocampal_Ant"           ].Right}},
        {static: true, primaryKey:104,  identifier:104, belongToForeignKey: 102,  volumeForeignKey: 89, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Medial temporal",  fullname: "Parahippocampal Post",          varname:{Left:"HO_Parahippocampal_Post_L",            Right:"HO_Parahippocampal_Post_R"           }, weight: {Left:unitVolume["HO"]["Parahippocampal_Post"          ].Left, Right:unitVolume["HO"]["Parahippocampal_Post"          ].Right}},
        {static: true, primaryKey:105,  identifier:105, belongToForeignKey: 102,  volumeForeignKey: 90, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Medial temporal",  fullname: "Hippocampus",                   varname:{Left:"HO_Hippocampus_L",                     Right:"HO_Hippocampus_R"                    }, weight: {Left:unitVolume["HO"]["Hippocampus"                   ].Left, Right:unitVolume["HO"]["Hippocampus"                   ].Right}},
        {static: true, primaryKey:106,  identifier:106, belongToForeignKey: 102,  volumeForeignKey: 91, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Medial temporal",  fullname: "Amygdala",                      varname:{Left:"HO_Amygdala_L",                        Right:"HO_Amygdala_R"                       }, weight: {Left:unitVolume["HO"]["Amygdala"                      ].Left, Right:unitVolume["HO"]["Amygdala"                      ].Right}},
        {static: true, primaryKey:107,  identifier:107, belongToForeignKey: 107,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Occipital",                     varname:{Left:"HO_Occipital_L",                       Right:"HO_Occipital_R"                      }},                    
        {static: true, primaryKey:108,  identifier:108, belongToForeignKey: 107,  volumeForeignKey: 92, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Occipital Lat_Sup",             varname:{Left:"HO_Occipital_Lat_Sup_L",               Right:"HO_Occipital_Lat_Sup_R"              }, weight: {Left:unitVolume["HO"]["Occipital_Lat_Sup"             ].Left, Right:unitVolume["HO"]["Occipital_Lat_Sup"             ].Right}},
        {static: true, primaryKey:109,  identifier:109, belongToForeignKey: 107,  volumeForeignKey: 93, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Occipital Lat_Inf",             varname:{Left:"HO_Occipital_Lat_Inf_L",               Right:"HO_Occipital_Lat_Inf_R"              }, weight: {Left:unitVolume["HO"]["Occipital_Lat_Inf"             ].Left, Right:unitVolume["HO"]["Occipital_Lat_Inf"             ].Right}},
        {static: true, primaryKey:110,  identifier:110, belongToForeignKey: 107,  volumeForeignKey: 94, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Intracalcarine",                varname:{Left:"HO_Intracalcarine_L",                  Right:"HO_Intracalcarine_R"                 }, weight: {Left:unitVolume["HO"]["Intracalcarine"                ].Left, Right:unitVolume["HO"]["Intracalcarine"                ].Right}},
        {static: true, primaryKey:111,  identifier:111, belongToForeignKey: 107,  volumeForeignKey: 95, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Occipital Pole",                varname:{Left:"HO_Occipital_Pole_L",                  Right:"HO_Occipital_Pole_R"                 }, weight: {Left:unitVolume["HO"]["Occipital_Pole"                ].Left, Right:unitVolume["HO"]["Occipital_Pole"                ].Right}},
        {static: true, primaryKey:112,  identifier:112, belongToForeignKey: 107,  volumeForeignKey: 96, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Cuneal",                        varname:{Left:"HO_Cuneal_L",                          Right:"HO_Cuneal_R"                         }, weight: {Left:unitVolume["HO"]["Cuneal"                        ].Left, Right:unitVolume["HO"]["Cuneal"                        ].Right}},
        {static: true, primaryKey:113,  identifier:113, belongToForeignKey: 107,  volumeForeignKey: 97, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Lingual",                       varname:{Left:"HO_Lingual_L",                         Right:"HO_Lingual_R"                        }, weight: {Left:unitVolume["HO"]["Lingual"                       ].Left, Right:unitVolume["HO"]["Lingual"                       ].Right}},
        {static: true, primaryKey:114,  identifier:114, belongToForeignKey: 107,  volumeForeignKey: 98, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Occipital Fusiform",            varname:{Left:"HO_Occipital_Fusiform_L",              Right:"HO_Occipital_Fusiform_R"             }, weight: {Left:unitVolume["HO"]["Occipital_Fusiform"            ].Left, Right:unitVolume["HO"]["Occipital_Fusiform"            ].Right}},
        {static: true, primaryKey:115,  identifier:115, belongToForeignKey: 107,  volumeForeignKey: 99, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Supracalcarine",                varname:{Left:"HO_Supracalcarine_L",                  Right:"HO_Supracalcarine_R"                 }, weight: {Left:unitVolume["HO"]["Supracalcarine"                ].Left, Right:unitVolume["HO"]["Supracalcarine"                ].Right}},
        {static: true, primaryKey:116,  identifier:116, belongToForeignKey: 116,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Basal ganglia",    fullname: "Basal ganglia",                 varname:{Left:"HO_Basal_ganglia_L",                   Right:"HO_Basal_ganglia_R"                  }},                    
        {static: true, primaryKey:117,  identifier:117, belongToForeignKey: 116,  volumeForeignKey:100, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Basal ganglia",    fullname: "Caudate",                       varname:{Left:"HO_Caudate_L",                         Right:"HO_Caudate_R"                        }, weight: {Left:unitVolume["HO"]["Caudate"                       ].Left, Right:unitVolume["HO"]["Caudate"                       ].Right}},
        {static: true, primaryKey:118,  identifier:118, belongToForeignKey: 116,  volumeForeignKey:101, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Basal ganglia",    fullname: "Putamen",                       varname:{Left:"HO_Putamen_L",                         Right:"HO_Putamen_R"                        }, weight: {Left:unitVolume["HO"]["Putamen"                       ].Left, Right:unitVolume["HO"]["Putamen"                       ].Right}},
        {static: true, primaryKey:119,  identifier:119, belongToForeignKey: 116,  volumeForeignKey:102, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Basal ganglia",    fullname: "Pallidum",                      varname:{Left:"HO_Pallidum_L",                        Right:"HO_Pallidum_R"                       }, weight: {Left:unitVolume["HO"]["Pallidum"                      ].Left, Right:unitVolume["HO"]["Pallidum"                      ].Right}},
        {static: true, primaryKey:120,  identifier:120, belongToForeignKey: 116,  volumeForeignKey:103, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Basal ganglia",    fullname: "Thalamus",                      varname:{Left:"HO_Thalamus_L",                        Right:"HO_Thalamus_R"                       }, weight: {Left:unitVolume["HO"]["Thalamus"                      ].Left, Right:unitVolume["HO"]["Thalamus"                      ].Right}},
        {static: true, primaryKey:121,  identifier:121, belongToForeignKey: 116,  volumeForeignKey:104, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Basal ganglia",    fullname: "Accumbens",                     varname:{Left:"HO_Accumbens_L",                       Right:"HO_Accumbens_R"                      }, weight: {Left:unitVolume["HO"]["Accumbens"                     ].Left, Right:unitVolume["HO"]["Accumbens"                     ].Right}},
        {static: true, primaryKey:122,  identifier:122, belongToForeignKey: 122,  volumeForeignKey: -1, level: 0, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Brainstem",        fullname: "Brainstem",                     varname:{Left:"HO_Brainstem_L",                       Right:"HO_Brainstem_R"                      }},
        {static: true, primaryKey:123,  identifier:  0, belongToForeignKey: 123,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"AAL3",            belongTo: "Composite",        fullname: "Composite",                     varname:{Left:"Composite_C",                          Right:"Composite_C"                         }},
        {static: true, primaryKey:124,  identifier:  1, belongToForeignKey: 124,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal",                       varname:{Left:"Frontal_L_C",                          Right:"Frontal_R_C"                         }},
        {static: true, primaryKey:125,  identifier:  2, belongToForeignKey: 124,  volumeForeignKey:  0, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Precentral",                    varname:{Left:"Precentral_L_C",                       Right:"Precentral_R_C"                      }, weight: {Left:unitVolume["AAL3"]["Precentral"                  ].Left, Right:unitVolume["AAL3"]["Precentral"                  ].Right}},
        {static: true, primaryKey:126,  identifier:  3, belongToForeignKey: 124,  volumeForeignKey:  1, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Sup",                   varname:{Left:"Frontal_Sup_L_C",                      Right:"Frontal_Sup_R_C"                     }, weight: {Left:unitVolume["AAL3"]["Frontal_Sup"                 ].Left, Right:unitVolume["AAL3"]["Frontal_Sup"                 ].Right}},
        {static: true, primaryKey:127,  identifier:  4, belongToForeignKey: 124,  volumeForeignKey:  2, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Mid",                   varname:{Left:"Frontal_Mid_L_C",                      Right:"Frontal_Mid_R_C"                     }, weight: {Left:unitVolume["AAL3"]["Frontal_Mid"                 ].Left, Right:unitVolume["AAL3"]["Frontal_Mid"                 ].Right}},
        {static: true, primaryKey:128,  identifier:  5, belongToForeignKey: 124,  volumeForeignKey:  3, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Inf Oper",              varname:{Left:"Frontal_Inf_Oper_L_C",                 Right:"Frontal_Inf_Oper_R_C"                }, weight: {Left:unitVolume["AAL3"]["Frontal_Inf_Oper"            ].Left, Right:unitVolume["AAL3"]["Frontal_Inf_Oper"            ].Right}},
        {static: true, primaryKey:129,  identifier:  6, belongToForeignKey: 124,  volumeForeignKey:  4, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Inf Tri",               varname:{Left:"Frontal_Inf_Tri_L_C",                  Right:"Frontal_Inf_Tri_R_C"                 }, weight: {Left:unitVolume["AAL3"]["Frontal_Inf_Tri"             ].Left, Right:unitVolume["AAL3"]["Frontal_Inf_Tri"             ].Right}},
        {static: true, primaryKey:130,  identifier:  7, belongToForeignKey: 124,  volumeForeignKey:  5, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Inf Orb",               varname:{Left:"Frontal_Inf_Orb_L_C",                  Right:"Frontal_Inf_Orb_R_C"                 }, weight: {Left:unitVolume["AAL3"]["Frontal_Inf_Orb"             ].Left, Right:unitVolume["AAL3"]["Frontal_Inf_Orb"             ].Right}},
        {static: true, primaryKey:131,  identifier:  8, belongToForeignKey: 124,  volumeForeignKey:  6, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Rolandic Oper",                 varname:{Left:"Rolandic_Oper_L_C",                    Right:"Rolandic_Oper_R_C"                   }, weight: {Left:unitVolume["AAL3"]["Rolandic_Oper"               ].Left, Right:unitVolume["AAL3"]["Rolandic_Oper"               ].Right}},
        {static: true, primaryKey:132,  identifier:  9, belongToForeignKey: 124,  volumeForeignKey:  7, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Supp Motor Area",               varname:{Left:"Supp_Motor_Area_L_C",                  Right:"Supp_Motor_Area_R_C"                 }, weight: {Left:unitVolume["AAL3"]["Supp_Motor_Area"             ].Left, Right:unitVolume["AAL3"]["Supp_Motor_Area"             ].Right}},
        {static: true, primaryKey:133,  identifier: 10, belongToForeignKey: 124,  volumeForeignKey:  8, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Olfactory",                     varname:{Left:"Olfactory_L_C",                        Right:"Olfactory_R_C"                       }, weight: {Left:unitVolume["AAL3"]["Olfactory"                   ].Left, Right:unitVolume["AAL3"]["Olfactory"                   ].Right}},
        {static: true, primaryKey:134,  identifier: 11, belongToForeignKey: 124,  volumeForeignKey:  9, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Sup Med",               varname:{Left:"Frontal_Sup_Med_L_C",                  Right:"Frontal_Sup_Med_R_C"                 }, weight: {Left:unitVolume["AAL3"]["Frontal_Sup_Med"             ].Left, Right:unitVolume["AAL3"]["Frontal_Sup_Med"             ].Right}},
        {static: true, primaryKey:135,  identifier: 12, belongToForeignKey: 124,  volumeForeignKey: 10, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Med Orb",               varname:{Left:"Frontal_Med_Orb_L_C",                  Right:"Frontal_Med_Orb_R_C"                 }, weight: {Left:unitVolume["AAL3"]["Frontal_Med_Orb"             ].Left, Right:unitVolume["AAL3"]["Frontal_Med_Orb"             ].Right}},
        {static: true, primaryKey:136,  identifier: 13, belongToForeignKey: 124,  volumeForeignKey: 11, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Rectus",                        varname:{Left:"Rectus_L_C",                           Right:"Rectus_R_C"                          }, weight: {Left:unitVolume["AAL3"]["Rectus"                      ].Left, Right:unitVolume["AAL3"]["Rectus"                      ].Right}},
        {static: true, primaryKey:137,  identifier: 14, belongToForeignKey: 124,  volumeForeignKey: 12, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "OFCmed",                        varname:{Left:"OFCmed_L_C",                           Right:"OFCmed_R_C"                          }, weight: {Left:unitVolume["AAL3"]["OFCmed"                      ].Left, Right:unitVolume["AAL3"]["OFCmed"                      ].Right}},
        {static: true, primaryKey:138,  identifier: 15, belongToForeignKey: 124,  volumeForeignKey: 13, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "OFCant",                        varname:{Left:"OFCant_L_C",                           Right:"OFCant_R_C"                          }, weight: {Left:unitVolume["AAL3"]["OFCant"                      ].Left, Right:unitVolume["AAL3"]["OFCant"                      ].Right}},
        {static: true, primaryKey:139,  identifier: 16, belongToForeignKey: 124,  volumeForeignKey: 14, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "OFCpost",                       varname:{Left:"OFCpost_L_C",                          Right:"OFCpost_R_C"                         }, weight: {Left:unitVolume["AAL3"]["OFCpost"                     ].Left, Right:unitVolume["AAL3"]["OFCpost"                     ].Right}},
        {static: true, primaryKey:140,  identifier: 17, belongToForeignKey: 124,  volumeForeignKey: 15, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "OFClat",                        varname:{Left:"OFClat_L_C",                           Right:"OFClat_R_C"                          }, weight: {Left:unitVolume["AAL3"]["OFClat"                      ].Left, Right:unitVolume["AAL3"]["OFClat"                      ].Right}},
        {static: true, primaryKey:141,  identifier: 18, belongToForeignKey: 124,  volumeForeignKey: 16, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Insula",                        varname:{Left:"Insula_L_C",                           Right:"Insula_R_C"                          }, weight: {Left:unitVolume["AAL3"]["Insula"                      ].Left, Right:unitVolume["AAL3"]["Insula"                      ].Right}},
        {static: true, primaryKey:142,  identifier: 19, belongToForeignKey: 124,  volumeForeignKey: 17, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Cingulate Ant Sub",             varname:{Left:"Cingulate_Ant_Sub_L_C",                Right:"Cingulate_Ant_Sub_R_C"               }, weight: {Left:unitVolume["AAL3"]["Cingulate_Ant_Sub"           ].Left, Right:unitVolume["AAL3"]["Cingulate_Ant_Sub"           ].Right}},
        {static: true, primaryKey:143,  identifier: 20, belongToForeignKey: 124,  volumeForeignKey: 18, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Cingulate Ant Pre",             varname:{Left:"Cingulate_Ant_Pre_L_C",                Right:"Cingulate_Ant_Pre_R_C"               }, weight: {Left:unitVolume["AAL3"]["Cingulate_Ant_Pre"           ].Left, Right:unitVolume["AAL3"]["Cingulate_Ant_Pre"           ].Right}},
        {static: true, primaryKey:144,  identifier: 21, belongToForeignKey: 124,  volumeForeignKey: 19, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Cingulate Ant Sup",             varname:{Left:"Cingulate_Ant_Sup_L_C",                Right:"Cingulate_Ant_Sup_R_C"               }, weight: {Left:unitVolume["AAL3"]["Cingulate_Ant_Sup"           ].Left, Right:unitVolume["AAL3"]["Cingulate_Ant_Sup"           ].Right}},
        {static: true, primaryKey:145,  identifier: 22, belongToForeignKey: 124,  volumeForeignKey: 20, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Cingulate Mid",                 varname:{Left:"Cingulate_Mid_L_C",                    Right:"Cingulate_Mid_R_C"                   }, weight: {Left:unitVolume["AAL3"]["Cingulate_Mid"               ].Left, Right:unitVolume["AAL3"]["Cingulate_Mid"               ].Right}},
        {static: true, primaryKey:146,  identifier: 23, belongToForeignKey: 146,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"AAL3",            belongTo: "PCC-Precuneus",    fullname: "PCC-Precuneus",                 varname:{Left:"PCC_L_C",                              Right:"PCC_R_C"                             }},                    
        {static: true, primaryKey:147,  identifier: 24, belongToForeignKey: 146,  volumeForeignKey: 21, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "PCC-Precuneus",    fullname: "Cingulate Post",                varname:{Left:"Cingulate_Post_L_C",                   Right:"Cingulate_Post_R_C"                  }, weight: {Left:unitVolume["AAL3"]["Cingulate_Post"              ].Left, Right:unitVolume["AAL3"]["Cingulate_Post"              ].Right}},
        {static: true, primaryKey:148,  identifier: 25, belongToForeignKey: 146,  volumeForeignKey: 22, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "PCC-Precuneus",    fullname: "Precuneus",                     varname:{Left:"Precuneus_L_C",                        Right:"Precuneus_R_C"                       }, weight: {Left:unitVolume["AAL3"]["Precuneus"                   ].Left, Right:unitVolume["AAL3"]["Precuneus"                   ].Right}},
        {static: true, primaryKey:149,  identifier: 26, belongToForeignKey: 149,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Lateral temporal",              varname:{Left:"Lateral_temporal_L_C",                 Right:"Lateral_temporal_R_C"                }},                    
        {static: true, primaryKey:150,  identifier: 27, belongToForeignKey: 149,  volumeForeignKey: 23, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Heschl",                        varname:{Left:"Heschl_L_C",                           Right:"Heschl_R_C"                          }, weight: {Left:unitVolume["AAL3"]["Heschl"                      ].Left, Right:unitVolume["AAL3"]["Heschl"                      ].Right}},
        {static: true, primaryKey:151,  identifier: 28, belongToForeignKey: 149,  volumeForeignKey: 24, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Temporal Sup",                  varname:{Left:"Temporal_Sup_L_C",                     Right:"Temporal_Sup_R_C"                    }, weight: {Left:unitVolume["AAL3"]["Temporal_Sup"                ].Left, Right:unitVolume["AAL3"]["Temporal_Sup"                ].Right}},
        {static: true, primaryKey:152,  identifier: 29, belongToForeignKey: 149,  volumeForeignKey: 25, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Temporal Pole Sup",             varname:{Left:"Temporal_Pole_Sup_L_C",                Right:"Temporal_Pole_Sup_R_C"               }, weight: {Left:unitVolume["AAL3"]["Temporal_Pole_Sup"           ].Left, Right:unitVolume["AAL3"]["Temporal_Pole_Sup"           ].Right}},
        {static: true, primaryKey:153,  identifier: 30, belongToForeignKey: 149,  volumeForeignKey: 26, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Temporal Mid",                  varname:{Left:"Temporal_Mid_L_C",                     Right:"Temporal_Mid_R_C"                    }, weight: {Left:unitVolume["AAL3"]["Temporal_Mid"                ].Left, Right:unitVolume["AAL3"]["Temporal_Mid"                ].Right}},
        {static: true, primaryKey:154,  identifier: 31, belongToForeignKey: 149,  volumeForeignKey: 27, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Temporal Pole Mid",             varname:{Left:"Temporal_Pole_Mid_L_C",                Right:"Temporal_Pole_Mid_R_C"               }, weight: {Left:unitVolume["AAL3"]["Temporal_Pole_Mid"           ].Left, Right:unitVolume["AAL3"]["Temporal_Pole_Mid"           ].Right}},
        {static: true, primaryKey:155,  identifier: 32, belongToForeignKey: 149,  volumeForeignKey: 28, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Temporal Inf",                  varname:{Left:"Temporal_Inf_L_C",                     Right:"Temporal_Inf_R_C"                    }, weight: {Left:unitVolume["AAL3"]["Temporal_Inf"                ].Left, Right:unitVolume["AAL3"]["Temporal_Inf"                ].Right}},
        {static: true, primaryKey:156,  identifier: 33, belongToForeignKey: 156,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Lateral parietal",              varname:{Left:"Lateral_parietal_L_C",                 Right:"Lateral_parietal_R_C"                }},                    
        {static: true, primaryKey:157,  identifier: 34, belongToForeignKey: 156,  volumeForeignKey: 29, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Postcentral",                   varname:{Left:"Postcentral_L_C",                      Right:"Postcentral_R_C"                     }, weight: {Left:unitVolume["AAL3"]["Postcentral"                 ].Left, Right:unitVolume["AAL3"]["Postcentral"                 ].Right}},
        {static: true, primaryKey:158,  identifier: 35, belongToForeignKey: 156,  volumeForeignKey: 30, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Parietal Sup",                  varname:{Left:"Parietal_Sup_L_C",                     Right:"Parietal_Sup_R_C"                    }, weight: {Left:unitVolume["AAL3"]["Parietal_Sup"                ].Left, Right:unitVolume["AAL3"]["Parietal_Sup"                ].Right}},
        {static: true, primaryKey:159,  identifier: 36, belongToForeignKey: 156,  volumeForeignKey: 31, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Parietal Inf",                  varname:{Left:"Parietal_Inf_L_C",                     Right:"Parietal_Inf_R_C"                    }, weight: {Left:unitVolume["AAL3"]["Parietal_Inf"                ].Left, Right:unitVolume["AAL3"]["Parietal_Inf"                ].Right}},
        {static: true, primaryKey:160,  identifier: 37, belongToForeignKey: 156,  volumeForeignKey: 32, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Supramarginal",                 varname:{Left:"Supramarginal_L_C",                    Right:"Supramarginal_R_C"                   }, weight: {Left:unitVolume["AAL3"]["Supramarginal"               ].Left, Right:unitVolume["AAL3"]["Supramarginal"               ].Right}},
        {static: true, primaryKey:161,  identifier: 38, belongToForeignKey: 156,  volumeForeignKey: 33, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Angular",                       varname:{Left:"Angular_L_C",                          Right:"Angular_R_C"                         }, weight: {Left:unitVolume["AAL3"]["Angular"                     ].Left, Right:unitVolume["AAL3"]["Angular"                     ].Right}},
        {static: true, primaryKey:162,  identifier: 39, belongToForeignKey: 156,  volumeForeignKey: 34, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Paracentral Lobule",            varname:{Left:"Paracentral_Lobule_L_C",               Right:"Paracentral_Lobule_R_C"              }, weight: {Left:unitVolume["AAL3"]["Paracentral_Lobule"          ].Left, Right:unitVolume["AAL3"]["Paracentral_Lobule"          ].Right}},
        {static: true, primaryKey:163,  identifier: 40, belongToForeignKey: 163,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"AAL3",            belongTo: "Medial temporal",  fullname: "Medial temporal",               varname:{Left:"Medial_temporal_L_C",                  Right:"Medial_temporal_R_C"                 }},                    
        {static: true, primaryKey:164,  identifier: 41, belongToForeignKey: 163,  volumeForeignKey: 35, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Medial temporal",  fullname: "Hippocampus",                   varname:{Left:"Hippocampus_L_C",                      Right:"Hippocampus_R_C"                     }, weight: {Left:unitVolume["AAL3"]["Hippocampus"                 ].Left, Right:unitVolume["AAL3"]["Hippocampus"                 ].Right}},
        {static: true, primaryKey:165,  identifier: 42, belongToForeignKey: 163,  volumeForeignKey: 36, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Medial temporal",  fullname: "Parahippocampal",               varname:{Left:"Parahippocampal_L_C",                  Right:"Parahippocampal_R_C"                 }, weight: {Left:unitVolume["AAL3"]["Parahippocampal"             ].Left, Right:unitVolume["AAL3"]["Parahippocampal"             ].Right}},
        {static: true, primaryKey:166,  identifier: 43, belongToForeignKey: 163,  volumeForeignKey: 37, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Medial temporal",  fullname: "Amygdala",                      varname:{Left:"Amygdala_L_C",                         Right:"Amygdala_R_C"                        }, weight: {Left:unitVolume["AAL3"]["Amygdala"                    ].Left, Right:unitVolume["AAL3"]["Amygdala"                    ].Right}},
        {static: true, primaryKey:167,  identifier: 44, belongToForeignKey: 167,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"AAL3",            belongTo: "Occipital",        fullname: "Occipital",                     varname:{Left:"Occipital_L_C",                        Right:"Occipital_R_C"                       }},                    
        {static: true, primaryKey:168,  identifier: 45, belongToForeignKey: 167,  volumeForeignKey: 38, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Occipital",        fullname: "Calcarine",                     varname:{Left:"Calcarine_L_C",                        Right:"Calcarine_R_C"                       }, weight: {Left:unitVolume["AAL3"]["Calcarine"                   ].Left, Right:unitVolume["AAL3"]["Calcarine"                   ].Right}},
        {static: true, primaryKey:169,  identifier: 46, belongToForeignKey: 167,  volumeForeignKey: 39, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Occipital",        fullname: "Cuneus",                        varname:{Left:"Cuneus_L_C",                           Right:"Cuneus_R_C"                          }, weight: {Left:unitVolume["AAL3"]["Cuneus"                      ].Left, Right:unitVolume["AAL3"]["Cuneus"                      ].Right}},
        {static: true, primaryKey:170,  identifier: 47, belongToForeignKey: 167,  volumeForeignKey: 40, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Occipital",        fullname: "Lingual",                       varname:{Left:"Lingual_L_C",                          Right:"Lingual_R_C"                         }, weight: {Left:unitVolume["AAL3"]["Lingual"                     ].Left, Right:unitVolume["AAL3"]["Lingual"                     ].Right}},
        {static: true, primaryKey:171,  identifier: 48, belongToForeignKey: 167,  volumeForeignKey: 41, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Occipital",        fullname: "Occipital Sup",                 varname:{Left:"Occipital_Sup_L_C",                    Right:"Occipital_Sup_R_C"                   }, weight: {Left:unitVolume["AAL3"]["Occipital_Sup"               ].Left, Right:unitVolume["AAL3"]["Occipital_Sup"               ].Right}},
        {static: true, primaryKey:172,  identifier: 49, belongToForeignKey: 167,  volumeForeignKey: 42, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Occipital",        fullname: "Occipital Mid",                 varname:{Left:"Occipital_Mid_L_C",                    Right:"Occipital_Mid_R_C"                   }, weight: {Left:unitVolume["AAL3"]["Occipital_Mid"               ].Left, Right:unitVolume["AAL3"]["Occipital_Mid"               ].Right}},
        {static: true, primaryKey:173,  identifier: 50, belongToForeignKey: 167,  volumeForeignKey: 43, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Occipital",        fullname: "Occipital Inf",                 varname:{Left:"Occipital_Inf_L_C",                    Right:"Occipital_Inf_R_C"                   }, weight: {Left:unitVolume["AAL3"]["Occipital_Inf"               ].Left, Right:unitVolume["AAL3"]["Occipital_Inf"               ].Right}},
        {static: true, primaryKey:174,  identifier: 51, belongToForeignKey: 167,  volumeForeignKey: 44, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Occipital",        fullname: "Fusiform",                      varname:{Left:"Fusiform_L_C",                         Right:"Fusiform_R_C"                        }, weight: {Left:unitVolume["AAL3"]["Fusiform"                    ].Left, Right:unitVolume["AAL3"]["Fusiform"                    ].Right}},
        {static: true, primaryKey:175,  identifier: 52, belongToForeignKey: 175,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"AAL3",            belongTo: "Basal ganglia",    fullname: "Basal ganglia",                 varname:{Left:"Basal_ganglia_L_C",                    Right:"Basal_ganglia_R_C"                   }},
        {static: true, primaryKey:176,  identifier: 53, belongToForeignKey: 175,  volumeForeignKey: 45, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Basal ganglia",    fullname: "Caudate",                       varname:{Left:"Caudate_L_C",                          Right:"Caudate_R_C"                         }, weight: {Left:unitVolume["AAL3"]["Caudate"                     ].Left, Right:unitVolume["AAL3"]["Caudate"                     ].Right}},
        {static: true, primaryKey:177,  identifier: 54, belongToForeignKey: 175,  volumeForeignKey: 46, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Basal ganglia",    fullname: "Putamen",                       varname:{Left:"Putamen_L_C",                          Right:"Putamen_R_C"                         }, weight: {Left:unitVolume["AAL3"]["Putamen"                     ].Left, Right:unitVolume["AAL3"]["Putamen"                     ].Right}},
        {static: true, primaryKey:178,  identifier: 55, belongToForeignKey: 175,  volumeForeignKey: 47, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Basal ganglia",    fullname: "Pallidum",                      varname:{Left:"Pallidum_L_C",                         Right:"Pallidum_R_C"                        }, weight: {Left:unitVolume["AAL3"]["Pallidum"                    ].Left, Right:unitVolume["AAL3"]["Pallidum"                    ].Right}},
        {static: true, primaryKey:179,  identifier: 56, belongToForeignKey: 175,  volumeForeignKey: 48, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Basal ganglia",    fullname: "Thalamus",                      varname:{Left:"Thalamus_L_C",                         Right:"Thalamus_R_C"                        }, weight: {Left:unitVolume["AAL3"]["Thalamus"                    ].Left, Right:unitVolume["AAL3"]["Thalamus"                    ].Right}},
        {static: true, primaryKey:180,  identifier: 57, belongToForeignKey: 175,  volumeForeignKey: 49, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Basal ganglia",    fullname: "N Acc",                         varname:{Left:"N_Acc_L_C",                            Right:"N_Acc_R_C"                           }, weight: {Left:unitVolume["AAL3"]["N_Acc"                       ].Left, Right:unitVolume["AAL3"]["N_Acc"                       ].Right}},
        {static: true, primaryKey:181,  identifier: 58, belongToForeignKey: 181,  volumeForeignKey: -1, level: 0, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Brainstem",        fullname: "Brainstem",                     varname:{Left:"Brainstem_L_C",                        Right:"Brainstem_R_C"                       }},                    
        {static: true, primaryKey:182,  identifier: 59, belongToForeignKey: 182,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Composite",        fullname: "Composite",                     varname:{Left:"Composite_C",                          Right:"Composite_C"                         }},                    
        {static: true, primaryKey:183,  identifier: 60, belongToForeignKey: 183,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal",                       varname:{Left:"HO_Frontal_L_C",                       Right:"HO_Frontal_R_C"                      }},                    
        {static: true, primaryKey:184,  identifier: 61, belongToForeignKey: 183,  volumeForeignKey: 50, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Pole",                  varname:{Left:"HO_Frontal_Pole_L_C",                  Right:"HO_Frontal_Pole_R_C"                 }, weight: {Left:unitVolume["HO"]["Frontal_Pole"                  ].Left, Right:unitVolume["HO"]["Frontal_Pole"                  ].Right}},
        {static: true, primaryKey:185,  identifier: 62, belongToForeignKey: 183,  volumeForeignKey: 51, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Insula",                        varname:{Left:"HO_Insula_L_C",                        Right:"HO_Insula_R_C"                       }, weight: {Left:unitVolume["HO"]["Insula"                        ].Left, Right:unitVolume["HO"]["Insula"                        ].Right}},
        {static: true, primaryKey:186,  identifier: 63, belongToForeignKey: 183,  volumeForeignKey: 52, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Sup",                   varname:{Left:"HO_Frontal_Sup_L_C",                   Right:"HO_Frontal_Sup_R_C"                  }, weight: {Left:unitVolume["HO"]["Frontal_Sup"                   ].Left, Right:unitVolume["HO"]["Frontal_Sup"                   ].Right}},
        {static: true, primaryKey:187,  identifier: 64, belongToForeignKey: 183,  volumeForeignKey: 53, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Mid",                   varname:{Left:"HO_Frontal_Mid_L_C",                   Right:"HO_Frontal_Mid_R_C"                  }, weight: {Left:unitVolume["HO"]["Frontal_Mid"                   ].Left, Right:unitVolume["HO"]["Frontal_Mid"                   ].Right}},
        {static: true, primaryKey:188,  identifier: 65, belongToForeignKey: 183,  volumeForeignKey: 54, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Inf Tri",               varname:{Left:"HO_Frontal_Inf_Tri_L_C",               Right:"HO_Frontal_Inf_Tri_R_C"              }, weight: {Left:unitVolume["HO"]["Frontal_Inf_Tri"               ].Left, Right:unitVolume["HO"]["Frontal_Inf_Tri"               ].Right}},
        {static: true, primaryKey:189,  identifier: 66, belongToForeignKey: 183,  volumeForeignKey: 55, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Inf Oper",              varname:{Left:"HO_Frontal_Inf_Oper_L_C",              Right:"HO_Frontal_Inf_Oper_R_C"             }, weight: {Left:unitVolume["HO"]["Frontal_Inf_Oper"              ].Left, Right:unitVolume["HO"]["Frontal_Inf_Oper"              ].Right}},
        {static: true, primaryKey:190,  identifier: 67, belongToForeignKey: 183,  volumeForeignKey: 56, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Precentral",                    varname:{Left:"HO_Precentral_L_C",                    Right:"HO_Precentral_R_C"                   }, weight: {Left:unitVolume["HO"]["Precentral"                    ].Left, Right:unitVolume["HO"]["Precentral"                    ].Right}},
        {static: true, primaryKey:191,  identifier: 68, belongToForeignKey: 183,  volumeForeignKey: 57, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Med",                   varname:{Left:"HO_Frontal_Med_L_C",                   Right:"HO_Frontal_Med_R_C"                  }, weight: {Left:unitVolume["HO"]["Frontal_Med"                   ].Left, Right:unitVolume["HO"]["Frontal_Med"                   ].Right}},
        {static: true, primaryKey:192,  identifier: 69, belongToForeignKey: 183,  volumeForeignKey: 58, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Supp Motor Area",               varname:{Left:"HO_Supp_Motor_Area_L_C",               Right:"HO_Supp_Motor_Area_R_C"              }, weight: {Left:unitVolume["HO"]["Supp_Motor_Area"               ].Left, Right:unitVolume["HO"]["Supp_Motor_Area"               ].Right}},
        {static: true, primaryKey:193,  identifier: 71, belongToForeignKey: 183,  volumeForeignKey: 59, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Cingulate Ant",                 varname:{Left:"HO_Cingulate_Ant_L_C",                 Right:"HO_Cingulate_Ant_R_C"                }, weight: {Left:unitVolume["HO"]["Cingulate_Ant"                 ].Left, Right:unitVolume["HO"]["Cingulate_Ant"                 ].Right}},
        {static: true, primaryKey:194,  identifier: 71, belongToForeignKey: 183,  volumeForeignKey: 60, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Orb",                   varname:{Left:"HO_Frontal_Orb_L_C",                   Right:"HO_Frontal_Orb_R_C"                  }, weight: {Left:unitVolume["HO"]["Frontal_Orb"                   ].Left, Right:unitVolume["HO"]["Frontal_Orb"                   ].Right}},
        {static: true, primaryKey:195,  identifier: 72, belongToForeignKey: 183,  volumeForeignKey: 61, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Oper",                  varname:{Left:"HO_Frontal_Oper_L_C",                  Right:"HO_Frontal_Oper_R_C"                 }, weight: {Left:unitVolume["HO"]["Frontal_Oper"                  ].Left, Right:unitVolume["HO"]["Frontal_Oper"                  ].Right}},
        {static: true, primaryKey:196,  identifier: 73, belongToForeignKey: 183,  volumeForeignKey: 62, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Central Oper",                  varname:{Left:"HO_Central_Oper_L_C",                  Right:"HO_Central_Oper_R_C"                 }, weight: {Left:unitVolume["HO"]["Central_Oper"                  ].Left, Right:unitVolume["HO"]["Central_Oper"                  ].Right}},
        {static: true, primaryKey:197,  identifier: 74, belongToForeignKey: 183,  volumeForeignKey: 63, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Subcallosal",                   varname:{Left:"HO_Subcallosal_L_C",                   Right:"HO_Subcallosal_R_C"                  }, weight: {Left:unitVolume["HO"]["Subcallosal"                   ].Left, Right:unitVolume["HO"]["Subcallosal"                   ].Right}},
        {static: true, primaryKey:198,  identifier: 75, belongToForeignKey: 183,  volumeForeignKey: 64, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Paracingulate",                 varname:{Left:"HO_Paracingulate_L_C",                 Right:"HO_Paracingulate_R_C"                }, weight: {Left:unitVolume["HO"]["Paracingulate"                 ].Left, Right:unitVolume["HO"]["Paracingulate"                 ].Right}},
        {static: true, primaryKey:199,  identifier: 76, belongToForeignKey: 199,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Retrosplenial",    fullname: "Retrosplenial",                 varname:{Left:"HO_PCC_L_C",                           Right:"HO_PCC_R_C"                          }},                    
        {static: true, primaryKey:200,  identifier: 77, belongToForeignKey: 199,  volumeForeignKey: 65, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Retrosplenial",    fullname: "Cingulate Post",                varname:{Left:"HO_Cingulate_Post_L_C",                Right:"HO_Cingulate_Post_R_C"               }, weight: {Left:unitVolume["HO"]["Cingulate_Post"                ].Left, Right:unitVolume["HO"]["Cingulate_Post"                ].Right}},
        {static: true, primaryKey:201,  identifier: 78, belongToForeignKey: 199,  volumeForeignKey: 66, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Retrosplenial",    fullname: "Precuneus",                     varname:{Left:"HO_Precuneus_L_C",                     Right:"HO_Precuneus_R_C"                    }, weight: {Left:unitVolume["HO"]["Precuneus"                     ].Left, Right:unitVolume["HO"]["Precuneus"                     ].Right}},
        {static: true, primaryKey:202,  identifier: 79, belongToForeignKey: 202,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Lateral temporal",              varname:{Left:"HO_Lateral_temporal_L_C",              Right:"HO_Lateral_temporal_R_C"             }},                    
        {static: true, primaryKey:203,  identifier: 80, belongToForeignKey: 202,  volumeForeignKey: 67, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Pole",                 varname:{Left:"HO_Temporal_Pole_L_C",                 Right:"HO_Temporal_Pole_R_C"                }, weight: {Left:unitVolume["HO"]["Temporal_Pole"                 ].Left, Right:unitVolume["HO"]["Temporal_Pole"                 ].Right}},
        {static: true, primaryKey:204,  identifier: 81, belongToForeignKey: 202,  volumeForeignKey: 68, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Sup Ant",              varname:{Left:"HO_Temporal_Sup_Ant_L_C",              Right:"HO_Temporal_Sup_Ant_R_C"             }, weight: {Left:unitVolume["HO"]["Temporal_Sup_Ant"              ].Left, Right:unitVolume["HO"]["Temporal_Sup_Ant"              ].Right}},
        {static: true, primaryKey:205,  identifier: 82, belongToForeignKey: 202,  volumeForeignKey: 69, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Sup Post",             varname:{Left:"HO_Temporal_Sup_Post_L_C",             Right:"HO_Temporal_Sup_Post_R_C"            }, weight: {Left:unitVolume["HO"]["Temporal_Sup_Post"             ].Left, Right:unitVolume["HO"]["Temporal_Sup_Post"             ].Right}},
        {static: true, primaryKey:206,  identifier: 83, belongToForeignKey: 202,  volumeForeignKey: 70, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Mid Ant",              varname:{Left:"HO_Temporal_Mid_Ant_L_C",              Right:"HO_Temporal_Mid_Ant_R_C"             }, weight: {Left:unitVolume["HO"]["Temporal_Mid_Ant"              ].Left, Right:unitVolume["HO"]["Temporal_Mid_Ant"              ].Right}},
        {static: true, primaryKey:207,  identifier: 84, belongToForeignKey: 202,  volumeForeignKey: 71, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Mid Post",             varname:{Left:"HO_Temporal_Mid_Post_L_C",             Right:"HO_Temporal_Mid_Post_R_C"            }, weight: {Left:unitVolume["HO"]["Temporal_Mid_Post"             ].Left, Right:unitVolume["HO"]["Temporal_Mid_Post"             ].Right}},
        {static: true, primaryKey:208,  identifier: 85, belongToForeignKey: 202,  volumeForeignKey: 72, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Mid Temporooccipital", varname:{Left:"HO_Temporal_Mid_Temporooccipital_L_C", Right:"HO_Temporal_Mid_Temporooccipital_R_C"}, weight: {Left:unitVolume["HO"]["Temporal_Mid_Temporooccipital" ].Left, Right:unitVolume["HO"]["Temporal_Mid_Temporooccipital" ].Right}},
        {static: true, primaryKey:209,  identifier: 86, belongToForeignKey: 202,  volumeForeignKey: 73, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Inf Ant",              varname:{Left:"HO_Temporal_Inf_Ant_L_C",              Right:"HO_Temporal_Inf_Ant_R_C"             }, weight: {Left:unitVolume["HO"]["Temporal_Inf_Ant"              ].Left, Right:unitVolume["HO"]["Temporal_Inf_Ant"              ].Right}},
        {static: true, primaryKey:210,  identifier: 87, belongToForeignKey: 202,  volumeForeignKey: 74, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Inf Post",             varname:{Left:"HO_Temporal_Inf_Post_L_C",             Right:"HO_Temporal_Inf_Post_R_C"            }, weight: {Left:unitVolume["HO"]["Temporal_Inf_Post"             ].Left, Right:unitVolume["HO"]["Temporal_Inf_Post"             ].Right}},
        {static: true, primaryKey:211,  identifier: 88, belongToForeignKey: 202,  volumeForeignKey: 75, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Inf Temporooccipital", varname:{Left:"HO_Temporal_Inf_Temporooccipital_L_C", Right:"HO_Temporal_Inf_Temporooccipital_R_C"}, weight: {Left:unitVolume["HO"]["Temporal_Inf_Temporooccipital" ].Left, Right:unitVolume["HO"]["Temporal_Inf_Temporooccipital" ].Right}},
        {static: true, primaryKey:212,  identifier: 89, belongToForeignKey: 202,  volumeForeignKey: 76, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Planum Polare",                 varname:{Left:"HO_Planum_Polare_L_C",                 Right:"HO_Planum_Polare_R_C"                }, weight: {Left:unitVolume["HO"]["Planum_Polare"                 ].Left, Right:unitVolume["HO"]["Planum_Polare"                 ].Right}},
        {static: true, primaryKey:213,  identifier: 90, belongToForeignKey: 202,  volumeForeignKey: 77, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Heschl",                        varname:{Left:"HO_Heschl_L_C",                        Right:"HO_Heschl_R_C"                       }, weight: {Left:unitVolume["HO"]["Heschl"                        ].Left, Right:unitVolume["HO"]["Heschl"                        ].Right}},
        {static: true, primaryKey:214,  identifier: 91, belongToForeignKey: 202,  volumeForeignKey: 78, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Planum Temporale",              varname:{Left:"HO_Planum_Temporale_L_C",              Right:"HO_Planum_Temporale_R_C"             }, weight: {Left:unitVolume["HO"]["Planum_Temporale"              ].Left, Right:unitVolume["HO"]["Planum_Temporale"              ].Right}},
        {static: true, primaryKey:215,  identifier: 92, belongToForeignKey: 202,  volumeForeignKey: 79, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal fusiform Ant",         varname:{Left:"HO_Temporal_fusiform_Ant_L_C",         Right:"HO_Temporal_fusiform_Ant_R_C"        }, weight: {Left:unitVolume["HO"]["Temporal_fusiform_Ant"         ].Left, Right:unitVolume["HO"]["Temporal_fusiform_Ant"         ].Right}},
        {static: true, primaryKey:216,  identifier: 93, belongToForeignKey: 202,  volumeForeignKey: 80, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal fusiform Post",        varname:{Left:"HO_Temporal_fusiform_Post_L_C",        Right:"HO_Temporal_fusiform_Post_R_C"       }, weight: {Left:unitVolume["HO"]["Temporal_fusiform_Post"        ].Left, Right:unitVolume["HO"]["Temporal_fusiform_Post"        ].Right}},
        {static: true, primaryKey:217,  identifier: 94, belongToForeignKey: 202,  volumeForeignKey: 81, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Occipital Fusiform",   varname:{Left:"HO_Temporal_Occipital_Fusiform_L_C",   Right:"HO_Temporal_Occipital_Fusiform_R_C"  }, weight: {Left:unitVolume["HO"]["Temporal_Occipital_Fusiform"   ].Left, Right:unitVolume["HO"]["Temporal_Occipital_Fusiform"   ].Right}},
        {static: true, primaryKey:218,  identifier: 95, belongToForeignKey: 218,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Lateral parietal",              varname:{Left:"HO_Lateral_parietal_L_C",              Right:"HO_Lateral_parietal_R_C"             }},                    
        {static: true, primaryKey:219,  identifier: 96, belongToForeignKey: 218,  volumeForeignKey: 82, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Postcentral",                   varname:{Left:"HO_Postcentral_L_C",                   Right:"HO_Postcentral_R_C"                  }, weight: {Left:unitVolume["HO"]["Postcentral"                   ].Left, Right:unitVolume["HO"]["Postcentral"                   ].Right}},
        {static: true, primaryKey:220,  identifier: 97, belongToForeignKey: 218,  volumeForeignKey: 83, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Parietal Sup Lobule",           varname:{Left:"HO_Parietal_Sup_Lobule_L_C",           Right:"HO_Parietal_Sup_Lobule_R_C"          }, weight: {Left:unitVolume["HO"]["Parietal_Sup_Lobule"           ].Left, Right:unitVolume["HO"]["Parietal_Sup_Lobule"           ].Right}},
        {static: true, primaryKey:221,  identifier: 98, belongToForeignKey: 218,  volumeForeignKey: 84, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Supramarginal Ant",             varname:{Left:"HO_Supramarginal_Ant_L_C",             Right:"HO_Supramarginal_Ant_R_C"            }, weight: {Left:unitVolume["HO"]["Supramarginal_Ant"             ].Left, Right:unitVolume["HO"]["Supramarginal_Ant"             ].Right}},
        {static: true, primaryKey:222,  identifier: 99, belongToForeignKey: 218,  volumeForeignKey: 85, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Supramarginal Post",            varname:{Left:"HO_Supramarginal_Post_L_C",            Right:"HO_Supramarginal_Post_R_C"           }, weight: {Left:unitVolume["HO"]["Supramarginal_Post"            ].Left, Right:unitVolume["HO"]["Supramarginal_Post"            ].Right}},
        {static: true, primaryKey:223,  identifier:100, belongToForeignKey: 218,  volumeForeignKey: 86, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Angular",                       varname:{Left:"HO_Angular_L_C",                       Right:"HO_Angular_R_C"                      }, weight: {Left:unitVolume["HO"]["Angular"                       ].Left, Right:unitVolume["HO"]["Angular"                       ].Right}},
        {static: true, primaryKey:224,  identifier:101, belongToForeignKey: 218,  volumeForeignKey: 87, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Parietal Oper",                 varname:{Left:"HO_Parietal_Oper_L_C",                 Right:"HO_Parietal_Oper_R_C"                }, weight: {Left:unitVolume["HO"]["Parietal_Oper"                 ].Left, Right:unitVolume["HO"]["Parietal_Oper"                 ].Right}},
        {static: true, primaryKey:225,  identifier:102, belongToForeignKey: 225,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Medial temporal",  fullname: "Medial temporal",               varname:{Left:"HO_Medial_temporal_L_C",               Right:"HO_Medial_temporal_R_C"              }},                    
        {static: true, primaryKey:226,  identifier:103, belongToForeignKey: 225,  volumeForeignKey: 88, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Medial temporal",  fullname: "Parahippocampal Ant",           varname:{Left:"HO_Parahippocampal_Ant_L_C",           Right:"HO_Parahippocampal_Ant_R_C"          }, weight: {Left:unitVolume["HO"]["Parahippocampal_Ant"           ].Left, Right:unitVolume["HO"]["Parahippocampal_Ant"           ].Right}},
        {static: true, primaryKey:227,  identifier:104, belongToForeignKey: 225,  volumeForeignKey: 89, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Medial temporal",  fullname: "Parahippocampal Post",          varname:{Left:"HO_Parahippocampal_Post_L_C",          Right:"HO_Parahippocampal_Post_R_C"         }, weight: {Left:unitVolume["HO"]["Parahippocampal_Post"          ].Left, Right:unitVolume["HO"]["Parahippocampal_Post"          ].Right}},
        {static: true, primaryKey:228,  identifier:105, belongToForeignKey: 225,  volumeForeignKey: 90, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Medial temporal",  fullname: "Hippocampus",                   varname:{Left:"HO_Hippocampus_L_C",                   Right:"HO_Hippocampus_R_C"                  }, weight: {Left:unitVolume["HO"]["Hippocampus"                   ].Left, Right:unitVolume["HO"]["Hippocampus"                   ].Right}},
        {static: true, primaryKey:229,  identifier:106, belongToForeignKey: 225,  volumeForeignKey: 91, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Medial temporal",  fullname: "Amygdala",                      varname:{Left:"HO_Amygdala_L_C",                      Right:"HO_Amygdala_R_C"                     }, weight: {Left:unitVolume["HO"]["Amygdala"                      ].Left, Right:unitVolume["HO"]["Amygdala"                      ].Right}},
        {static: true, primaryKey:230,  identifier:107, belongToForeignKey: 230,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Occipital",                     varname:{Left:"HO_Occipital_L_C",                     Right:"HO_Occipital_R_C"                    }},                    
        {static: true, primaryKey:231,  identifier:108, belongToForeignKey: 230,  volumeForeignKey: 92, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Occipital Lat_Sup",             varname:{Left:"HO_Occipital_Lat_Sup_L_C",             Right:"HO_Occipital_Lat_Sup_R_C"            }, weight: {Left:unitVolume["HO"]["Occipital_Lat_Sup"             ].Left, Right:unitVolume["HO"]["Occipital_Lat_Sup"             ].Right}},
        {static: true, primaryKey:232,  identifier:109, belongToForeignKey: 230,  volumeForeignKey: 93, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Occipital Lat_Inf",             varname:{Left:"HO_Occipital_Lat_Inf_L_C",             Right:"HO_Occipital_Lat_Inf_R_C"            }, weight: {Left:unitVolume["HO"]["Occipital_Lat_Inf"             ].Left, Right:unitVolume["HO"]["Occipital_Lat_Inf"             ].Right}},
        {static: true, primaryKey:233,  identifier:110, belongToForeignKey: 230,  volumeForeignKey: 94, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Intracalcarine",                varname:{Left:"HO_Intracalcarine_L_C",                Right:"HO_Intracalcarine_R_C"               }, weight: {Left:unitVolume["HO"]["Intracalcarine"                ].Left, Right:unitVolume["HO"]["Intracalcarine"                ].Right}},
        {static: true, primaryKey:234,  identifier:111, belongToForeignKey: 230,  volumeForeignKey: 95, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Occipital Pole",                varname:{Left:"HO_Occipital_Pole_L_C",                Right:"HO_Occipital_Pole_R_C"               }, weight: {Left:unitVolume["HO"]["Occipital_Pole"                ].Left, Right:unitVolume["HO"]["Occipital_Pole"                ].Right}},
        {static: true, primaryKey:235,  identifier:112, belongToForeignKey: 230,  volumeForeignKey: 96, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Cuneal",                        varname:{Left:"HO_Cuneal_L_C",                        Right:"HO_Cuneal_R_C"                       }, weight: {Left:unitVolume["HO"]["Cuneal"                        ].Left, Right:unitVolume["HO"]["Cuneal"                        ].Right}},
        {static: true, primaryKey:236,  identifier:113, belongToForeignKey: 230,  volumeForeignKey: 97, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Lingual",                       varname:{Left:"HO_Lingual_L_C",                       Right:"HO_Lingual_R_C"                      }, weight: {Left:unitVolume["HO"]["Lingual"                       ].Left, Right:unitVolume["HO"]["Lingual"                       ].Right}},
        {static: true, primaryKey:237,  identifier:114, belongToForeignKey: 230,  volumeForeignKey: 98, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Occipital Fusiform",            varname:{Left:"HO_Occipital_Fusiform_L_C",            Right:"HO_Occipital_Fusiform_R_C"           }, weight: {Left:unitVolume["HO"]["Occipital_Fusiform"            ].Left, Right:unitVolume["HO"]["Occipital_Fusiform"            ].Right}},
        {static: true, primaryKey:238,  identifier:115, belongToForeignKey: 230,  volumeForeignKey: 99, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Supracalcarine",                varname:{Left:"HO_Supracalcarine_L_C",                Right:"HO_Supracalcarine_R_C"               }, weight: {Left:unitVolume["HO"]["Supracalcarine"                ].Left, Right:unitVolume["HO"]["Supracalcarine"                ].Right}},
        {static: true, primaryKey:239,  identifier:116, belongToForeignKey: 239,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Basal ganglia",    fullname: "Basal ganglia",                 varname:{Left:"HO_Basal_ganglia_L_C",                 Right:"HO_Basal_ganglia_R_C"                }},                    
        {static: true, primaryKey:240,  identifier:117, belongToForeignKey: 239,  volumeForeignKey:100, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Basal ganglia",    fullname: "Caudate",                       varname:{Left:"HO_Caudate_L_C",                       Right:"HO_Caudate_R_C"                      }, weight: {Left:unitVolume["HO"]["Caudate"                       ].Left, Right:unitVolume["HO"]["Caudate"                       ].Right}},
        {static: true, primaryKey:241,  identifier:118, belongToForeignKey: 239,  volumeForeignKey:101, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Basal ganglia",    fullname: "Putamen",                       varname:{Left:"HO_Putamen_L_C",                       Right:"HO_Putamen_R_C"                      }, weight: {Left:unitVolume["HO"]["Putamen"                       ].Left, Right:unitVolume["HO"]["Putamen"                       ].Right}},
        {static: true, primaryKey:242,  identifier:119, belongToForeignKey: 239,  volumeForeignKey:102, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Basal ganglia",    fullname: "Pallidum",                      varname:{Left:"HO_Pallidum_L_C",                      Right:"HO_Pallidum_R_C"                     }, weight: {Left:unitVolume["HO"]["Pallidum"                      ].Left, Right:unitVolume["HO"]["Pallidum"                      ].Right}},
        {static: true, primaryKey:243,  identifier:120, belongToForeignKey: 239,  volumeForeignKey:103, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Basal ganglia",    fullname: "Thalamus",                      varname:{Left:"HO_Thalamus_L_C",                      Right:"HO_Thalamus_R_C"                     }, weight: {Left:unitVolume["HO"]["Thalamus"                      ].Left, Right:unitVolume["HO"]["Thalamus"                      ].Right}},
        {static: true, primaryKey:244,  identifier:121, belongToForeignKey: 239,  volumeForeignKey:104, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Basal ganglia",    fullname: "Accumbens",                     varname:{Left:"HO_Accumbens_L_C",                     Right:"HO_Accumbens_R_C"                    }, weight: {Left:unitVolume["HO"]["Accumbens"                     ].Left, Right:unitVolume["HO"]["Accumbens"                     ].Right}},
        {static: true, primaryKey:245,  identifier:122, belongToForeignKey: 245,  volumeForeignKey: -1, level: 0, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Brainstem",        fullname: "Brainstem",                     varname:{Left:"HO_Brainstem_L_C",                     Right:"HO_Brainstem_R_C"                    }},
        // {primaryKey:246,  foreignKey: 246, level: 0, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal1",          fullname: "Frontal1",                     varname:{Left:"Cingulate_Ant_Sub_L", Right:"Cingulate_Ant_Sub_R"}},
        // {primaryKey:247,  foreignKey: 246, level: 1, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal1",          fullname: "subFrontal1",                  varname:{Left:"Cingulate_Ant_Pre_L", Right:"Cingulate_Ant_Pre_R"}},
        // {primaryKey:248,  foreignKey: 246, level: 1, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal1",          fullname: "subFrontal2",                  varname:{Left:"Cingulate_Ant_Sup_L", Right:"Cingulate_Ant_Sup_R"}},
        // {primaryKey:249,  foreignKey: 246, level: 1, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal1",          fullname: "subFrontal3",                  varname:{Left:"Cingulate_Mid_L", Right:"Cingulate_Mid_R"}},
      ]
      const primaryKeyArr = arr.map((el,idx)=>({...el, primaryKey:idx}))
      let belongToForeignKeyVar=0;
      const belongToForeignKeyArr = primaryKeyArr.map((el,idx)=>{
        if (el.level===0) belongToForeignKeyVar = el.primaryKey
        return {...el, belongToForeignKey:belongToForeignKeyVar}
      })
      const identifierSUVR = belongToForeignKeyArr.filter(el=>el.type==='suvr').map((el,idx)=>({...el, identifier:idx}))
      const identifierCentil = belongToForeignKeyArr.filter(el=>el.type==='centiloid').map((el,idx)=>({...el, identifier:idx}))
      const resultArr = [...identifierSUVR, ...identifierCentil]
      return resultArr
    })(),
    perfusion:(()=>{
      const arr = [
        {static: true, primaryKey:  0,  identifier:  0, belongToForeignKey:   0,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"AAL3",            belongTo: "Global",           fullname: "Global",                        varname:{Left:"Global",                               Right:"Global"                              }},
        {static: true, primaryKey:  1,  identifier:  1, belongToForeignKey:   1,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal",                       varname:{Left:"Frontal_L",                            Right:"Frontal_R"                           }},
        {static: true, primaryKey:  2,  identifier:  2, belongToForeignKey:   1,  volumeForeignKey:  0, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Precentral",                    varname:{Left:"Precentral_L",                         Right:"Precentral_R"                        }, weight: {Left:unitVolume["AAL3"]["Precentral"                  ].Left, Right:unitVolume["AAL3"]["Precentral"                  ].Right}},
        {static: true, primaryKey:  3,  identifier:  3, belongToForeignKey:   1,  volumeForeignKey:  1, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Sup",                   varname:{Left:"Frontal_Sup_L",                        Right:"Frontal_Sup_R"                       }, weight: {Left:unitVolume["AAL3"]["Frontal_Sup"                 ].Left, Right:unitVolume["AAL3"]["Frontal_Sup"                 ].Right}},
        {static: true, primaryKey:  4,  identifier:  4, belongToForeignKey:   1,  volumeForeignKey:  2, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Mid",                   varname:{Left:"Frontal_Mid_L",                        Right:"Frontal_Mid_R"                       }, weight: {Left:unitVolume["AAL3"]["Frontal_Mid"                 ].Left, Right:unitVolume["AAL3"]["Frontal_Mid"                 ].Right}},
        {static: true, primaryKey:  5,  identifier:  5, belongToForeignKey:   1,  volumeForeignKey:  3, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Inf Oper",              varname:{Left:"Frontal_Inf_Oper_L",                   Right:"Frontal_Inf_Oper_R"                  }, weight: {Left:unitVolume["AAL3"]["Frontal_Inf_Oper"            ].Left, Right:unitVolume["AAL3"]["Frontal_Inf_Oper"            ].Right}},
        {static: true, primaryKey:  6,  identifier:  6, belongToForeignKey:   1,  volumeForeignKey:  4, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Inf Tri",               varname:{Left:"Frontal_Inf_Tri_L",                    Right:"Frontal_Inf_Tri_R"                   }, weight: {Left:unitVolume["AAL3"]["Frontal_Inf_Tri"             ].Left, Right:unitVolume["AAL3"]["Frontal_Inf_Tri"             ].Right}},
        {static: true, primaryKey:  7,  identifier:  7, belongToForeignKey:   1,  volumeForeignKey:  5, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Inf Orb",               varname:{Left:"Frontal_Inf_Orb_L",                    Right:"Frontal_Inf_Orb_R"                   }, weight: {Left:unitVolume["AAL3"]["Frontal_Inf_Orb"             ].Left, Right:unitVolume["AAL3"]["Frontal_Inf_Orb"             ].Right}},
        {static: true, primaryKey:  8,  identifier:  8, belongToForeignKey:   1,  volumeForeignKey:  6, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Rolandic Oper",                 varname:{Left:"Rolandic_Oper_L",                      Right:"Rolandic_Oper_R"                     }, weight: {Left:unitVolume["AAL3"]["Rolandic_Oper"               ].Left, Right:unitVolume["AAL3"]["Rolandic_Oper"               ].Right}},
        {static: true, primaryKey:  9,  identifier:  9, belongToForeignKey:   1,  volumeForeignKey:  7, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Supp Motor Area",               varname:{Left:"Supp_Motor_Area_L",                    Right:"Supp_Motor_Area_R"                   }, weight: {Left:unitVolume["AAL3"]["Supp_Motor_Area"             ].Left, Right:unitVolume["AAL3"]["Supp_Motor_Area"             ].Right}},
        {static: true, primaryKey: 10,  identifier: 10, belongToForeignKey:   1,  volumeForeignKey:  8, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Olfactory",                     varname:{Left:"Olfactory_L",                          Right:"Olfactory_R"                         }, weight: {Left:unitVolume["AAL3"]["Olfactory"                   ].Left, Right:unitVolume["AAL3"]["Olfactory"                   ].Right}},
        {static: true, primaryKey: 11,  identifier: 11, belongToForeignKey:   1,  volumeForeignKey:  9, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Sup Med",               varname:{Left:"Frontal_Sup_Med_L",                    Right:"Frontal_Sup_Med_R"                   }, weight: {Left:unitVolume["AAL3"]["Frontal_Sup_Med"             ].Left, Right:unitVolume["AAL3"]["Frontal_Sup_Med"             ].Right}},
        {static: true, primaryKey: 12,  identifier: 12, belongToForeignKey:   1,  volumeForeignKey: 10, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Med Orb",               varname:{Left:"Frontal_Med_Orb_L",                    Right:"Frontal_Med_Orb_R"                   }, weight: {Left:unitVolume["AAL3"]["Frontal_Med_Orb"             ].Left, Right:unitVolume["AAL3"]["Frontal_Med_Orb"             ].Right}},
        {static: true, primaryKey: 13,  identifier: 13, belongToForeignKey:   1,  volumeForeignKey: 11, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Rectus",                        varname:{Left:"Rectus_L",                             Right:"Rectus_R"                            }, weight: {Left:unitVolume["AAL3"]["Rectus"                      ].Left, Right:unitVolume["AAL3"]["Rectus"                      ].Right}},
        {static: true, primaryKey: 14,  identifier: 14, belongToForeignKey:   1,  volumeForeignKey: 12, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "OFCmed",                        varname:{Left:"OFCmed_L",                             Right:"OFCmed_R"                            }, weight: {Left:unitVolume["AAL3"]["OFCmed"                      ].Left, Right:unitVolume["AAL3"]["OFCmed"                      ].Right}},
        {static: true, primaryKey: 15,  identifier: 15, belongToForeignKey:   1,  volumeForeignKey: 13, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "OFCant",                        varname:{Left:"OFCant_L",                             Right:"OFCant_R"                            }, weight: {Left:unitVolume["AAL3"]["OFCant"                      ].Left, Right:unitVolume["AAL3"]["OFCant"                      ].Right}},
        {static: true, primaryKey: 16,  identifier: 16, belongToForeignKey:   1,  volumeForeignKey: 14, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "OFCpost",                       varname:{Left:"OFCpost_L",                            Right:"OFCpost_R"                           }, weight: {Left:unitVolume["AAL3"]["OFCpost"                     ].Left, Right:unitVolume["AAL3"]["OFCpost"                     ].Right}},
        {static: true, primaryKey: 17,  identifier: 17, belongToForeignKey:   1,  volumeForeignKey: 15, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "OFClat",                        varname:{Left:"OFClat_L",                             Right:"OFClat_R"                            }, weight: {Left:unitVolume["AAL3"]["OFClat"                      ].Left, Right:unitVolume["AAL3"]["OFClat"                      ].Right}},
        {static: true, primaryKey: 18,  identifier: 18, belongToForeignKey:   1,  volumeForeignKey: 16, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Insula",                        varname:{Left:"Insula_L",                             Right:"Insula_R"                            }, weight: {Left:unitVolume["AAL3"]["Insula"                      ].Left, Right:unitVolume["AAL3"]["Insula"                      ].Right}},
        {static: true, primaryKey: 19,  identifier: 19, belongToForeignKey:   1,  volumeForeignKey: 17, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Cingulate Ant Sub",             varname:{Left:"Cingulate_Ant_Sub_L",                  Right:"Cingulate_Ant_Sub_R"                 }, weight: {Left:unitVolume["AAL3"]["Cingulate_Ant_Sub"           ].Left, Right:unitVolume["AAL3"]["Cingulate_Ant_Sub"           ].Right}},
        {static: true, primaryKey: 20,  identifier: 20, belongToForeignKey:   1,  volumeForeignKey: 18, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Cingulate Ant Pre",             varname:{Left:"Cingulate_Ant_Pre_L",                  Right:"Cingulate_Ant_Pre_R"                 }, weight: {Left:unitVolume["AAL3"]["Cingulate_Ant_Pre"           ].Left, Right:unitVolume["AAL3"]["Cingulate_Ant_Pre"           ].Right}},
        {static: true, primaryKey: 21,  identifier: 21, belongToForeignKey:   1,  volumeForeignKey: 19, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Cingulate Ant Sup",             varname:{Left:"Cingulate_Ant_Sup_L",                  Right:"Cingulate_Ant_Sup_R"                 }, weight: {Left:unitVolume["AAL3"]["Cingulate_Ant_Sup"           ].Left, Right:unitVolume["AAL3"]["Cingulate_Ant_Sup"           ].Right}},
        {static: true, primaryKey: 22,  identifier: 22, belongToForeignKey:   1,  volumeForeignKey: 20, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Cingulate Mid",                 varname:{Left:"Cingulate_Mid_L",                      Right:"Cingulate_Mid_R"                     }, weight: {Left:unitVolume["AAL3"]["Cingulate_Mid"               ].Left, Right:unitVolume["AAL3"]["Cingulate_Mid"               ].Right}},
        {static: true, primaryKey: 23,  identifier: 23, belongToForeignKey:  23,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"AAL3",            belongTo: "PCC-Precuneus",    fullname: "PCC-Precuneus",                 varname:{Left:"PCC_L",                                Right:"PCC_R"                               }},
        {static: true, primaryKey: 24,  identifier: 24, belongToForeignKey:  23,  volumeForeignKey: 21, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "PCC-Precuneus",    fullname: "Cingulate Post",                varname:{Left:"Cingulate_Post_L",                     Right:"Cingulate_Post_R"                    }, weight: {Left:unitVolume["AAL3"]["Cingulate_Post"              ].Left, Right:unitVolume["AAL3"]["Cingulate_Post"              ].Right}},
        {static: true, primaryKey: 25,  identifier: 25, belongToForeignKey:  23,  volumeForeignKey: 22, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "PCC-Precuneus",    fullname: "Precuneus",                     varname:{Left:"Precuneus_L",                          Right:"Precuneus_R"                         }, weight: {Left:unitVolume["AAL3"]["Precuneus"                   ].Left, Right:unitVolume["AAL3"]["Precuneus"                   ].Right}},
        {static: true, primaryKey: 26,  identifier: 26, belongToForeignKey:  26,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Lateral temporal",              varname:{Left:"Lateral_temporal_L",                   Right:"Lateral_temporal_R"                  }},                      
        {static: true, primaryKey: 27,  identifier: 27, belongToForeignKey:  26,  volumeForeignKey: 23, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Heschl",                        varname:{Left:"Heschl_L",                             Right:"Heschl_R"                            }, weight: {Left:unitVolume["AAL3"]["Heschl"                      ].Left, Right:unitVolume["AAL3"]["Heschl"                      ].Right}},
        {static: true, primaryKey: 28,  identifier: 28, belongToForeignKey:  26,  volumeForeignKey: 24, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Temporal Sup",                  varname:{Left:"Temporal_Sup_L",                       Right:"Temporal_Sup_R"                      }, weight: {Left:unitVolume["AAL3"]["Temporal_Sup"                ].Left, Right:unitVolume["AAL3"]["Temporal_Sup"                ].Right}},
        {static: true, primaryKey: 29,  identifier: 29, belongToForeignKey:  26,  volumeForeignKey: 25, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Temporal Pole Sup",             varname:{Left:"Temporal_Pole_Sup_L",                  Right:"Temporal_Pole_Sup_R"                 }, weight: {Left:unitVolume["AAL3"]["Temporal_Pole_Sup"           ].Left, Right:unitVolume["AAL3"]["Temporal_Pole_Sup"           ].Right}},
        {static: true, primaryKey: 30,  identifier: 30, belongToForeignKey:  26,  volumeForeignKey: 26, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Temporal Mid",                  varname:{Left:"Temporal_Mid_L",                       Right:"Temporal_Mid_R"                      }, weight: {Left:unitVolume["AAL3"]["Temporal_Mid"                ].Left, Right:unitVolume["AAL3"]["Temporal_Mid"                ].Right}},
        {static: true, primaryKey: 31,  identifier: 31, belongToForeignKey:  26,  volumeForeignKey: 27, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Temporal Pole Mid",             varname:{Left:"Temporal_Pole_Mid_L",                  Right:"Temporal_Pole_Mid_R"                 }, weight: {Left:unitVolume["AAL3"]["Temporal_Pole_Mid"           ].Left, Right:unitVolume["AAL3"]["Temporal_Pole_Mid"           ].Right}},
        {static: true, primaryKey: 32,  identifier: 32, belongToForeignKey:  26,  volumeForeignKey: 28, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Temporal Inf",                  varname:{Left:"Temporal_Inf_L",                       Right:"Temporal_Inf_R"                      }, weight: {Left:unitVolume["AAL3"]["Temporal_Inf"                ].Left, Right:unitVolume["AAL3"]["Temporal_Inf"                ].Right}},
        {static: true, primaryKey: 33,  identifier: 33, belongToForeignKey:  33,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Lateral parietal",              varname:{Left:"Lateral_parietal_L",                   Right:"Lateral_parietal_R"                  }},                      
        {static: true, primaryKey: 34,  identifier: 34, belongToForeignKey:  33,  volumeForeignKey: 29, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Postcentral",                   varname:{Left:"Postcentral_L",                        Right:"Postcentral_R"                       }, weight: {Left:unitVolume["AAL3"]["Postcentral"                 ].Left, Right:unitVolume["AAL3"]["Postcentral"                 ].Right}},
        {static: true, primaryKey: 35,  identifier: 35, belongToForeignKey:  33,  volumeForeignKey: 30, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Parietal Sup",                  varname:{Left:"Parietal_Sup_L",                       Right:"Parietal_Sup_R"                      }, weight: {Left:unitVolume["AAL3"]["Parietal_Sup"                ].Left, Right:unitVolume["AAL3"]["Parietal_Sup"                ].Right}},
        {static: true, primaryKey: 36,  identifier: 36, belongToForeignKey:  33,  volumeForeignKey: 31, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Parietal Inf",                  varname:{Left:"Parietal_Inf_L",                       Right:"Parietal_Inf_R"                      }, weight: {Left:unitVolume["AAL3"]["Parietal_Inf"                ].Left, Right:unitVolume["AAL3"]["Parietal_Inf"                ].Right}},
        {static: true, primaryKey: 37,  identifier: 37, belongToForeignKey:  33,  volumeForeignKey: 32, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Supramarginal",                 varname:{Left:"Supramarginal_L",                      Right:"Supramarginal_R"                     }, weight: {Left:unitVolume["AAL3"]["Supramarginal"               ].Left, Right:unitVolume["AAL3"]["Supramarginal"               ].Right}},
        {static: true, primaryKey: 38,  identifier: 38, belongToForeignKey:  33,  volumeForeignKey: 33, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Angular",                       varname:{Left:"Angular_L",                            Right:"Angular_R"                           }, weight: {Left:unitVolume["AAL3"]["Angular"                     ].Left, Right:unitVolume["AAL3"]["Angular"                     ].Right}},
        {static: true, primaryKey: 39,  identifier: 39, belongToForeignKey:  33,  volumeForeignKey: 34, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Paracentral Lobule",            varname:{Left:"Paracentral_Lobule_L",                 Right:"Paracentral_Lobule_R"                }, weight: {Left:unitVolume["AAL3"]["Paracentral_Lobule"          ].Left, Right:unitVolume["AAL3"]["Paracentral_Lobule"          ].Right}},
        {static: true, primaryKey: 40,  identifier: 40, belongToForeignKey:  40,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"AAL3",            belongTo: "Medial temporal",  fullname: "Medial temporal",               varname:{Left:"Medial_temporal_L",                    Right:"Medial_temporal_R"                   }},                      
        {static: true, primaryKey: 41,  identifier: 41, belongToForeignKey:  40,  volumeForeignKey: 35, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Medial temporal",  fullname: "Hippocampus",                   varname:{Left:"Hippocampus_L",                        Right:"Hippocampus_R"                       }, weight: {Left:unitVolume["AAL3"]["Hippocampus"                 ].Left, Right:unitVolume["AAL3"]["Hippocampus"                 ].Right}},
        {static: true, primaryKey: 42,  identifier: 42, belongToForeignKey:  40,  volumeForeignKey: 36, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Medial temporal",  fullname: "Parahippocampal",               varname:{Left:"Parahippocampal_L",                    Right:"Parahippocampal_R"                   }, weight: {Left:unitVolume["AAL3"]["Parahippocampal"             ].Left, Right:unitVolume["AAL3"]["Parahippocampal"             ].Right}},
        {static: true, primaryKey: 43,  identifier: 43, belongToForeignKey:  40,  volumeForeignKey: 37, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Medial temporal",  fullname: "Amygdala",                      varname:{Left:"Amygdala_L",                           Right:"Amygdala_R"                          }, weight: {Left:unitVolume["AAL3"]["Amygdala"                    ].Left, Right:unitVolume["AAL3"]["Amygdala"                    ].Right}},
        {static: true, primaryKey: 44,  identifier: 44, belongToForeignKey:  44,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"AAL3",            belongTo: "Occipital",        fullname: "Occipital",                     varname:{Left:"Occipital_L",                          Right:"Occipital_R"                         }},                      
        {static: true, primaryKey: 45,  identifier: 45, belongToForeignKey:  44,  volumeForeignKey: 38, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Occipital",        fullname: "Calcarine",                     varname:{Left:"Calcarine_L",                          Right:"Calcarine_R"                         }, weight: {Left:unitVolume["AAL3"]["Calcarine"                   ].Left, Right:unitVolume["AAL3"]["Calcarine"                   ].Right}},
        {static: true, primaryKey: 46,  identifier: 46, belongToForeignKey:  44,  volumeForeignKey: 39, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Occipital",        fullname: "Cuneus",                        varname:{Left:"Cuneus_L",                             Right:"Cuneus_R"                            }, weight: {Left:unitVolume["AAL3"]["Cuneus"                      ].Left, Right:unitVolume["AAL3"]["Cuneus"                      ].Right}},
        {static: true, primaryKey: 47,  identifier: 47, belongToForeignKey:  44,  volumeForeignKey: 40, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Occipital",        fullname: "Lingual",                       varname:{Left:"Lingual_L",                            Right:"Lingual_R"                           }, weight: {Left:unitVolume["AAL3"]["Lingual"                     ].Left, Right:unitVolume["AAL3"]["Lingual"                     ].Right}},
        {static: true, primaryKey: 48,  identifier: 48, belongToForeignKey:  44,  volumeForeignKey: 41, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Occipital",        fullname: "Occipital Sup",                 varname:{Left:"Occipital_Sup_L",                      Right:"Occipital_Sup_R"                     }, weight: {Left:unitVolume["AAL3"]["Occipital_Sup"               ].Left, Right:unitVolume["AAL3"]["Occipital_Sup"               ].Right}},
        {static: true, primaryKey: 49,  identifier: 49, belongToForeignKey:  44,  volumeForeignKey: 42, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Occipital",        fullname: "Occipital Mid",                 varname:{Left:"Occipital_Mid_L",                      Right:"Occipital_Mid_R"                     }, weight: {Left:unitVolume["AAL3"]["Occipital_Mid"               ].Left, Right:unitVolume["AAL3"]["Occipital_Mid"               ].Right}},
        {static: true, primaryKey: 50,  identifier: 50, belongToForeignKey:  44,  volumeForeignKey: 43, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Occipital",        fullname: "Occipital Inf",                 varname:{Left:"Occipital_Inf_L",                      Right:"Occipital_Inf_R"                     }, weight: {Left:unitVolume["AAL3"]["Occipital_Inf"               ].Left, Right:unitVolume["AAL3"]["Occipital_Inf"               ].Right}},
        {static: true, primaryKey: 51,  identifier: 51, belongToForeignKey:  44,  volumeForeignKey: 44, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Occipital",        fullname: "Fusiform",                      varname:{Left:"Fusiform_L",                           Right:"Fusiform_R"                          }, weight: {Left:unitVolume["AAL3"]["Fusiform"                    ].Left, Right:unitVolume["AAL3"]["Fusiform"                    ].Right}},
        {static: true, primaryKey: 52,  identifier: 52, belongToForeignKey:  52,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"AAL3",            belongTo: "Basal ganglia",    fullname: "Basal ganglia",                 varname:{Left:"Basal_ganglia_L",                      Right:"Basal_ganglia_R"                     }},                      
        {static: true, primaryKey: 53,  identifier: 53, belongToForeignKey:  52,  volumeForeignKey: 45, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Basal ganglia",    fullname: "Caudate",                       varname:{Left:"Caudate_L",                            Right:"Caudate_R"                           }, weight: {Left:unitVolume["AAL3"]["Caudate"                     ].Left, Right:unitVolume["AAL3"]["Caudate"                     ].Right}},
        {static: true, primaryKey: 54,  identifier: 54, belongToForeignKey:  52,  volumeForeignKey: 46, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Basal ganglia",    fullname: "Putamen",                       varname:{Left:"Putamen_L",                            Right:"Putamen_R"                           }, weight: {Left:unitVolume["AAL3"]["Putamen"                     ].Left, Right:unitVolume["AAL3"]["Putamen"                     ].Right}},
        {static: true, primaryKey: 55,  identifier: 55, belongToForeignKey:  52,  volumeForeignKey: 47, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Basal ganglia",    fullname: "Pallidum",                      varname:{Left:"Pallidum_L",                           Right:"Pallidum_R"                          }, weight: {Left:unitVolume["AAL3"]["Pallidum"                    ].Left, Right:unitVolume["AAL3"]["Pallidum"                    ].Right}},
        {static: true, primaryKey: 56,  identifier: 56, belongToForeignKey:  52,  volumeForeignKey: 48, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Basal ganglia",    fullname: "Thalamus",                      varname:{Left:"Thalamus_L",                           Right:"Thalamus_R"                          }, weight: {Left:unitVolume["AAL3"]["Thalamus"                    ].Left, Right:unitVolume["AAL3"]["Thalamus"                    ].Right}},
        {static: true, primaryKey: 57,  identifier: 57, belongToForeignKey:  52,  volumeForeignKey: 49, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Basal ganglia",    fullname: "N Acc",                         varname:{Left:"N_Acc_L",                              Right:"N_Acc_R"                             }, weight: {Left:unitVolume["AAL3"]["N_Acc"                       ].Left, Right:unitVolume["AAL3"]["N_Acc"                       ].Right}},
        {static: true, primaryKey: 58,  identifier: 58, belongToForeignKey:  58,  volumeForeignKey: -1, level: 0, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Brainstem",        fullname: "Brainstem",                     varname:{Left:"Brainstem_L",                          Right:"Brainstem_R"                         }},
        {static: true, primaryKey: 59,  identifier: 59, belongToForeignKey:  59,  volumeForeignKey:  0, level: 0, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Cerebellum gray",  fullname: "Cerebellum gray",               varname:{Left:"OutputAffineParamsY1",                 Right:"OutputAffineParamsX0"                }},

        {static: true, primaryKey: 60,  identifier: 60, belongToForeignKey:  60,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Global",           fullname: "Global",                        varname:{Left:"HO_Global",                            Right:"HO_Global"                           }},
        {static: true, primaryKey: 61,  identifier: 61, belongToForeignKey:  61,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal",                       varname:{Left:"HO_Frontal_L",                         Right:"HO_Frontal_R"                        }},
        {static: true, primaryKey: 62,  identifier: 62, belongToForeignKey:  61,  volumeForeignKey: 50, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Pole",                  varname:{Left:"HO_Frontal_Pole_L",                    Right:"HO_Frontal_Pole_R"                   }, weight: {Left:unitVolume["HO"]["Frontal_Pole"                  ].Left, Right:unitVolume["HO"]["Frontal_Pole"                  ].Right}},
        {static: true, primaryKey: 63,  identifier: 63, belongToForeignKey:  61,  volumeForeignKey: 51, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Insula",                        varname:{Left:"HO_Insula_L",                          Right:"HO_Insula_R"                         }, weight: {Left:unitVolume["HO"]["Insula"                        ].Left, Right:unitVolume["HO"]["Insula"                        ].Right}},
        {static: true, primaryKey: 64,  identifier: 64, belongToForeignKey:  61,  volumeForeignKey: 52, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Sup",                   varname:{Left:"HO_Frontal_Sup_L",                     Right:"HO_Frontal_Sup_R"                    }, weight: {Left:unitVolume["HO"]["Frontal_Sup"                   ].Left, Right:unitVolume["HO"]["Frontal_Sup"                   ].Right}},
        {static: true, primaryKey: 65,  identifier: 65, belongToForeignKey:  61,  volumeForeignKey: 53, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Mid",                   varname:{Left:"HO_Frontal_Mid_L",                     Right:"HO_Frontal_Mid_R"                    }, weight: {Left:unitVolume["HO"]["Frontal_Mid"                   ].Left, Right:unitVolume["HO"]["Frontal_Mid"                   ].Right}},
        {static: true, primaryKey: 66,  identifier: 66, belongToForeignKey:  61,  volumeForeignKey: 54, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Inf Tri",               varname:{Left:"HO_Frontal_Inf_Tri_L",                 Right:"HO_Frontal_Inf_Tri_R"                }, weight: {Left:unitVolume["HO"]["Frontal_Inf_Tri"               ].Left, Right:unitVolume["HO"]["Frontal_Inf_Tri"               ].Right}},
        {static: true, primaryKey: 67,  identifier: 67, belongToForeignKey:  61,  volumeForeignKey: 55, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Inf Oper",              varname:{Left:"HO_Frontal_Inf_Oper_L",                Right:"HO_Frontal_Inf_Oper_R"               }, weight: {Left:unitVolume["HO"]["Frontal_Inf_Oper"              ].Left, Right:unitVolume["HO"]["Frontal_Inf_Oper"              ].Right}},
        {static: true, primaryKey: 68,  identifier: 68, belongToForeignKey:  61,  volumeForeignKey: 56, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Precentral",                    varname:{Left:"HO_Precentral_L",                      Right:"HO_Precentral_R"                     }, weight: {Left:unitVolume["HO"]["Precentral"                    ].Left, Right:unitVolume["HO"]["Precentral"                    ].Right}},
        {static: true, primaryKey: 69,  identifier: 69, belongToForeignKey:  61,  volumeForeignKey: 57, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Med",                   varname:{Left:"HO_Frontal_Med_L",                     Right:"HO_Frontal_Med_R"                    }, weight: {Left:unitVolume["HO"]["Frontal_Med"                   ].Left, Right:unitVolume["HO"]["Frontal_Med"                   ].Right}},
        {static: true, primaryKey: 71,  identifier: 71, belongToForeignKey:  61,  volumeForeignKey: 58, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Supp Motor Area",               varname:{Left:"HO_Supp_Motor_Area_L",                 Right:"HO_Supp_Motor_Area_R"                }, weight: {Left:unitVolume["HO"]["Supp_Motor_Area"               ].Left, Right:unitVolume["HO"]["Supp_Motor_Area"               ].Right}},
        {static: true, primaryKey: 71,  identifier: 71, belongToForeignKey:  61,  volumeForeignKey: 59, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Cingulate Ant",                 varname:{Left:"HO_Cingulate_Ant_L",                   Right:"HO_Cingulate_Ant_R"                  }, weight: {Left:unitVolume["HO"]["Cingulate_Ant"                 ].Left, Right:unitVolume["HO"]["Cingulate_Ant"                 ].Right}},
        {static: true, primaryKey: 72,  identifier: 72, belongToForeignKey:  61,  volumeForeignKey: 60, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Orb",                   varname:{Left:"HO_Frontal_Orb_L",                     Right:"HO_Frontal_Orb_R"                    }, weight: {Left:unitVolume["HO"]["Frontal_Orb"                   ].Left, Right:unitVolume["HO"]["Frontal_Orb"                   ].Right}},
        {static: true, primaryKey: 73,  identifier: 73, belongToForeignKey:  61,  volumeForeignKey: 61, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Oper",                  varname:{Left:"HO_Frontal_Oper_L",                    Right:"HO_Frontal_Oper_R"                   }, weight: {Left:unitVolume["HO"]["Frontal_Oper"                  ].Left, Right:unitVolume["HO"]["Frontal_Oper"                  ].Right}},
        {static: true, primaryKey: 74,  identifier: 74, belongToForeignKey:  61,  volumeForeignKey: 62, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Central Oper",                  varname:{Left:"HO_Central_Oper_L",                    Right:"HO_Central_Oper_R"                   }, weight: {Left:unitVolume["HO"]["Central_Oper"                  ].Left, Right:unitVolume["HO"]["Central_Oper"                  ].Right}},
        {static: true, primaryKey: 75,  identifier: 75, belongToForeignKey:  61,  volumeForeignKey: 63, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Subcallosal",                   varname:{Left:"HO_Subcallosal_L",                     Right:"HO_Subcallosal_R"                    }, weight: {Left:unitVolume["HO"]["Subcallosal"                   ].Left, Right:unitVolume["HO"]["Subcallosal"                   ].Right}},
        {static: true, primaryKey: 76,  identifier: 76, belongToForeignKey:  61,  volumeForeignKey: 64, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Paracingulate",                 varname:{Left:"HO_Paracingulate_L",                   Right:"HO_Paracingulate_R"                  }, weight: {Left:unitVolume["HO"]["Paracingulate"                 ].Left, Right:unitVolume["HO"]["Paracingulate"                 ].Right}},
        {static: true, primaryKey: 77,  identifier: 77, belongToForeignKey:  77,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Retrosplenial",    fullname: "Retrosplenial",                 varname:{Left:"HO_PCC_L",                             Right:"HO_PCC_R"                            }},                      
        {static: true, primaryKey: 78,  identifier: 78, belongToForeignKey:  77,  volumeForeignKey: 65, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Retrosplenial",    fullname: "Cingulate Post",                varname:{Left:"HO_Cingulate_Post_L",                  Right:"HO_Cingulate_Post_R"                 }, weight: {Left:unitVolume["HO"]["Cingulate_Post"                ].Left, Right:unitVolume["HO"]["Cingulate_Post"                ].Right}},
        {static: true, primaryKey: 79,  identifier: 79, belongToForeignKey:  77,  volumeForeignKey: 66, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Retrosplenial",    fullname: "Precuneus",                     varname:{Left:"HO_Precuneus_L",                       Right:"HO_Precuneus_R"                      }, weight: {Left:unitVolume["HO"]["Precuneus"                     ].Left, Right:unitVolume["HO"]["Precuneus"                     ].Right}},
        {static: true, primaryKey: 80,  identifier: 80, belongToForeignKey:  80,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Lateral temporal",              varname:{Left:"HO_Lateral_temporal_L",                Right:"HO_Lateral_temporal_R"               }},
        {static: true, primaryKey: 81,  identifier: 81, belongToForeignKey:  80,  volumeForeignKey: 67, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Pole",                 varname:{Left:"HO_Temporal_Pole_L",                   Right:"HO_Temporal_Pole_R"                  }, weight: {Left:unitVolume["HO"]["Temporal_Pole"                 ].Left, Right:unitVolume["HO"]["Temporal_Pole"                 ].Right}},
        {static: true, primaryKey: 82,  identifier: 82, belongToForeignKey:  80,  volumeForeignKey: 68, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Sup Ant",              varname:{Left:"HO_Temporal_Sup_Ant_L",                Right:"HO_Temporal_Sup_Ant_R"               }, weight: {Left:unitVolume["HO"]["Temporal_Sup_Ant"              ].Left, Right:unitVolume["HO"]["Temporal_Sup_Ant"              ].Right}},
        {static: true, primaryKey: 83,  identifier: 83, belongToForeignKey:  80,  volumeForeignKey: 69, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Sup Post",             varname:{Left:"HO_Temporal_Sup_Post_L",               Right:"HO_Temporal_Sup_Post_R"              }, weight: {Left:unitVolume["HO"]["Temporal_Sup_Post"             ].Left, Right:unitVolume["HO"]["Temporal_Sup_Post"             ].Right}},
        {static: true, primaryKey: 84,  identifier: 84, belongToForeignKey:  80,  volumeForeignKey: 70, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Mid Ant",              varname:{Left:"HO_Temporal_Mid_Ant_L",                Right:"HO_Temporal_Mid_Ant_R"               }, weight: {Left:unitVolume["HO"]["Temporal_Mid_Ant"              ].Left, Right:unitVolume["HO"]["Temporal_Mid_Ant"              ].Right}},
        {static: true, primaryKey: 85,  identifier: 85, belongToForeignKey:  80,  volumeForeignKey: 71, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Mid Post",             varname:{Left:"HO_Temporal_Mid_Post_L",               Right:"HO_Temporal_Mid_Post_R"              }, weight: {Left:unitVolume["HO"]["Temporal_Mid_Post"             ].Left, Right:unitVolume["HO"]["Temporal_Mid_Post"             ].Right}},
        {static: true, primaryKey: 86,  identifier: 86, belongToForeignKey:  80,  volumeForeignKey: 72, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Mid Temporooccipital", varname:{Left:"HO_Temporal_Mid_Temporooccipital_L",   Right:"HO_Temporal_Mid_Temporooccipital_R"  }, weight: {Left:unitVolume["HO"]["Temporal_Mid_Temporooccipital" ].Left, Right:unitVolume["HO"]["Temporal_Mid_Temporooccipital" ].Right}},
        {static: true, primaryKey: 87,  identifier: 87, belongToForeignKey:  80,  volumeForeignKey: 73, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Inf Ant",              varname:{Left:"HO_Temporal_Inf_Ant_L",                Right:"HO_Temporal_Inf_Ant_R"               }, weight: {Left:unitVolume["HO"]["Temporal_Inf_Ant"              ].Left, Right:unitVolume["HO"]["Temporal_Inf_Ant"              ].Right}},
        {static: true, primaryKey: 88,  identifier: 88, belongToForeignKey:  80,  volumeForeignKey: 74, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Inf Post",             varname:{Left:"HO_Temporal_Inf_Post_L",               Right:"HO_Temporal_Inf_Post_R"              }, weight: {Left:unitVolume["HO"]["Temporal_Inf_Post"             ].Left, Right:unitVolume["HO"]["Temporal_Inf_Post"             ].Right}},
        {static: true, primaryKey: 89,  identifier: 89, belongToForeignKey:  80,  volumeForeignKey: 75, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Inf Temporooccipital", varname:{Left:"HO_Temporal_Inf_Temporooccipital_L",   Right:"HO_Temporal_Inf_Temporooccipital_R"  }, weight: {Left:unitVolume["HO"]["Temporal_Inf_Temporooccipital" ].Left, Right:unitVolume["HO"]["Temporal_Inf_Temporooccipital" ].Right}},
        {static: true, primaryKey: 90,  identifier: 90, belongToForeignKey:  80,  volumeForeignKey: 76, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Planum Polare",                 varname:{Left:"HO_Planum_Polare_L",                   Right:"HO_Planum_Polare_R"                  }, weight: {Left:unitVolume["HO"]["Planum_Polare"                 ].Left, Right:unitVolume["HO"]["Planum_Polare"                 ].Right}},
        {static: true, primaryKey: 91,  identifier: 91, belongToForeignKey:  80,  volumeForeignKey: 77, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Heschl",                        varname:{Left:"HO_Heschl_L",                          Right:"HO_Heschl_R"                         }, weight: {Left:unitVolume["HO"]["Heschl"                        ].Left, Right:unitVolume["HO"]["Heschl"                        ].Right}},
        {static: true, primaryKey: 92,  identifier: 92, belongToForeignKey:  80,  volumeForeignKey: 78, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Planum Temporale",              varname:{Left:"HO_Planum_Temporale_L",                Right:"HO_Planum_Temporale_R"               }, weight: {Left:unitVolume["HO"]["Planum_Temporale"              ].Left, Right:unitVolume["HO"]["Planum_Temporale"              ].Right}},
        {static: true, primaryKey: 93,  identifier: 93, belongToForeignKey:  80,  volumeForeignKey: 79, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal fusiform Ant",         varname:{Left:"HO_Temporal_fusiform_Ant_L",           Right:"HO_Temporal_fusiform_Ant_R"          }, weight: {Left:unitVolume["HO"]["Temporal_fusiform_Ant"         ].Left, Right:unitVolume["HO"]["Temporal_fusiform_Ant"         ].Right}},
        {static: true, primaryKey: 94,  identifier: 94, belongToForeignKey:  80,  volumeForeignKey: 80, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal fusiform Post",        varname:{Left:"HO_Temporal_fusiform_Post_L",          Right:"HO_Temporal_fusiform_Post_R"         }, weight: {Left:unitVolume["HO"]["Temporal_fusiform_Post"        ].Left, Right:unitVolume["HO"]["Temporal_fusiform_Post"        ].Right}},
        {static: true, primaryKey: 95,  identifier: 95, belongToForeignKey:  80,  volumeForeignKey: 81, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Occipital Fusiform",   varname:{Left:"HO_Temporal_Occipital_Fusiform_L",     Right:"HO_Temporal_Occipital_Fusiform_R"    }, weight: {Left:unitVolume["HO"]["Temporal_Occipital_Fusiform"   ].Left, Right:unitVolume["HO"]["Temporal_Occipital_Fusiform"   ].Right}},
        {static: true, primaryKey: 96,  identifier: 96, belongToForeignKey:  96,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Lateral parietal",              varname:{Left:"HO_Lateral_parietal_L",                Right:"HO_Lateral_parietal_R"               }},                    
        {static: true, primaryKey: 97,  identifier: 97, belongToForeignKey:  96,  volumeForeignKey: 82, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Postcentral",                   varname:{Left:"HO_Postcentral_L",                     Right:"HO_Postcentral_R"                    }, weight: {Left:unitVolume["HO"]["Postcentral"                   ].Left, Right:unitVolume["HO"]["Postcentral"                   ].Right}},
        {static: true, primaryKey: 98,  identifier: 98, belongToForeignKey:  96,  volumeForeignKey: 83, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Parietal Sup Lobule",           varname:{Left:"HO_Parietal_Sup_Lobule_L",             Right:"HO_Parietal_Sup_Lobule_R"            }, weight: {Left:unitVolume["HO"]["Parietal_Sup_Lobule"           ].Left, Right:unitVolume["HO"]["Parietal_Sup_Lobule"           ].Right}},
        {static: true, primaryKey: 99,  identifier: 99, belongToForeignKey:  96,  volumeForeignKey: 84, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Supramarginal Ant",             varname:{Left:"HO_Supramarginal_Ant_L",               Right:"HO_Supramarginal_Ant_R"              }, weight: {Left:unitVolume["HO"]["Supramarginal_Ant"             ].Left, Right:unitVolume["HO"]["Supramarginal_Ant"             ].Right}},
        {static: true, primaryKey:100,  identifier:100, belongToForeignKey:  96,  volumeForeignKey: 85, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Supramarginal Post",            varname:{Left:"HO_Supramarginal_Post_L",              Right:"HO_Supramarginal_Post_R"             }, weight: {Left:unitVolume["HO"]["Supramarginal_Post"            ].Left, Right:unitVolume["HO"]["Supramarginal_Post"            ].Right}},
        {static: true, primaryKey:101,  identifier:101, belongToForeignKey:  96,  volumeForeignKey: 86, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Angular",                       varname:{Left:"HO_Angular_L",                         Right:"HO_Angular_R"                        }, weight: {Left:unitVolume["HO"]["Angular"                       ].Left, Right:unitVolume["HO"]["Angular"                       ].Right}},
        {static: true, primaryKey:102,  identifier:102, belongToForeignKey:  96,  volumeForeignKey: 87, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Parietal Oper",                 varname:{Left:"HO_Parietal_Oper_L",                   Right:"HO_Parietal_Oper_R"                  }, weight: {Left:unitVolume["HO"]["Parietal_Oper"                 ].Left, Right:unitVolume["HO"]["Parietal_Oper"                 ].Right}},
        {static: true, primaryKey:103,  identifier:103, belongToForeignKey: 103,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Medial temporal",  fullname: "Medial temporal",               varname:{Left:"HO_Medial_temporal_L",                 Right:"HO_Medial_temporal_R"                }},                    
        {static: true, primaryKey:104,  identifier:104, belongToForeignKey: 103,  volumeForeignKey: 88, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Medial temporal",  fullname: "Parahippocampal Ant",           varname:{Left:"HO_Parahippocampal_Ant_L",             Right:"HO_Parahippocampal_Ant_R"            }, weight: {Left:unitVolume["HO"]["Parahippocampal_Ant"           ].Left, Right:unitVolume["HO"]["Parahippocampal_Ant"           ].Right}},
        {static: true, primaryKey:105,  identifier:105, belongToForeignKey: 103,  volumeForeignKey: 89, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Medial temporal",  fullname: "Parahippocampal Post",          varname:{Left:"HO_Parahippocampal_Post_L",            Right:"HO_Parahippocampal_Post_R"           }, weight: {Left:unitVolume["HO"]["Parahippocampal_Post"          ].Left, Right:unitVolume["HO"]["Parahippocampal_Post"          ].Right}},
        {static: true, primaryKey:106,  identifier:106, belongToForeignKey: 103,  volumeForeignKey: 90, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Medial temporal",  fullname: "Hippocampus",                   varname:{Left:"HO_Hippocampus_L",                     Right:"HO_Hippocampus_R"                    }, weight: {Left:unitVolume["HO"]["Hippocampus"                   ].Left, Right:unitVolume["HO"]["Hippocampus"                   ].Right}},
        {static: true, primaryKey:107,  identifier:107, belongToForeignKey: 103,  volumeForeignKey: 91, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Medial temporal",  fullname: "Amygdala",                      varname:{Left:"HO_Amygdala_L",                        Right:"HO_Amygdala_R"                       }, weight: {Left:unitVolume["HO"]["Amygdala"                      ].Left, Right:unitVolume["HO"]["Amygdala"                      ].Right}},
        {static: true, primaryKey:108,  identifier:108, belongToForeignKey: 108,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Occipital",                     varname:{Left:"HO_Occipital_L",                       Right:"HO_Occipital_R"                      }},                    
        {static: true, primaryKey:109,  identifier:109, belongToForeignKey: 108,  volumeForeignKey: 92, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Occipital Lat_Sup",             varname:{Left:"HO_Occipital_Lat_Sup_L",               Right:"HO_Occipital_Lat_Sup_R"              }, weight: {Left:unitVolume["HO"]["Occipital_Lat_Sup"             ].Left, Right:unitVolume["HO"]["Occipital_Lat_Sup"             ].Right}},
        {static: true, primaryKey:110,  identifier:110, belongToForeignKey: 108,  volumeForeignKey: 93, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Occipital Lat_Inf",             varname:{Left:"HO_Occipital_Lat_Inf_L",               Right:"HO_Occipital_Lat_Inf_R"              }, weight: {Left:unitVolume["HO"]["Occipital_Lat_Inf"             ].Left, Right:unitVolume["HO"]["Occipital_Lat_Inf"             ].Right}},
        {static: true, primaryKey:111,  identifier:111, belongToForeignKey: 108,  volumeForeignKey: 94, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Intracalcarine",                varname:{Left:"HO_Intracalcarine_L",                  Right:"HO_Intracalcarine_R"                 }, weight: {Left:unitVolume["HO"]["Intracalcarine"                ].Left, Right:unitVolume["HO"]["Intracalcarine"                ].Right}},
        {static: true, primaryKey:112,  identifier:112, belongToForeignKey: 108,  volumeForeignKey: 95, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Occipital Pole",                varname:{Left:"HO_Occipital_Pole_L",                  Right:"HO_Occipital_Pole_R"                 }, weight: {Left:unitVolume["HO"]["Occipital_Pole"                ].Left, Right:unitVolume["HO"]["Occipital_Pole"                ].Right}},
        {static: true, primaryKey:113,  identifier:113, belongToForeignKey: 108,  volumeForeignKey: 96, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Cuneal",                        varname:{Left:"HO_Cuneal_L",                          Right:"HO_Cuneal_R"                         }, weight: {Left:unitVolume["HO"]["Cuneal"                        ].Left, Right:unitVolume["HO"]["Cuneal"                        ].Right}},
        {static: true, primaryKey:114,  identifier:114, belongToForeignKey: 108,  volumeForeignKey: 97, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Lingual",                       varname:{Left:"HO_Lingual_L",                         Right:"HO_Lingual_R"                        }, weight: {Left:unitVolume["HO"]["Lingual"                       ].Left, Right:unitVolume["HO"]["Lingual"                       ].Right}},
        {static: true, primaryKey:115,  identifier:115, belongToForeignKey: 108,  volumeForeignKey: 98, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Occipital Fusiform",            varname:{Left:"HO_Occipital_Fusiform_L",              Right:"HO_Occipital_Fusiform_R"             }, weight: {Left:unitVolume["HO"]["Occipital_Fusiform"            ].Left, Right:unitVolume["HO"]["Occipital_Fusiform"            ].Right}},
        {static: true, primaryKey:116,  identifier:116, belongToForeignKey: 108,  volumeForeignKey: 99, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Supracalcarine",                varname:{Left:"HO_Supracalcarine_L",                  Right:"HO_Supracalcarine_R"                 }, weight: {Left:unitVolume["HO"]["Supracalcarine"                ].Left, Right:unitVolume["HO"]["Supracalcarine"                ].Right}},
        {static: true, primaryKey:117,  identifier:117, belongToForeignKey: 117,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Basal ganglia",    fullname: "Basal ganglia",                 varname:{Left:"HO_Basal_ganglia_L",                   Right:"HO_Basal_ganglia_R"                  }},                    
        {static: true, primaryKey:118,  identifier:118, belongToForeignKey: 117,  volumeForeignKey:100, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Basal ganglia",    fullname: "Caudate",                       varname:{Left:"HO_Caudate_L",                         Right:"HO_Caudate_R"                        }, weight: {Left:unitVolume["HO"]["Caudate"                       ].Left, Right:unitVolume["HO"]["Caudate"                       ].Right}},
        {static: true, primaryKey:119,  identifier:119, belongToForeignKey: 117,  volumeForeignKey:101, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Basal ganglia",    fullname: "Putamen",                       varname:{Left:"HO_Putamen_L",                         Right:"HO_Putamen_R"                        }, weight: {Left:unitVolume["HO"]["Putamen"                       ].Left, Right:unitVolume["HO"]["Putamen"                       ].Right}},
        {static: true, primaryKey:120,  identifier:120, belongToForeignKey: 117,  volumeForeignKey:102, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Basal ganglia",    fullname: "Pallidum",                      varname:{Left:"HO_Pallidum_L",                        Right:"HO_Pallidum_R"                       }, weight: {Left:unitVolume["HO"]["Pallidum"                      ].Left, Right:unitVolume["HO"]["Pallidum"                      ].Right}},
        {static: true, primaryKey:121,  identifier:121, belongToForeignKey: 117,  volumeForeignKey:103, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Basal ganglia",    fullname: "Thalamus",                      varname:{Left:"HO_Thalamus_L",                        Right:"HO_Thalamus_R"                       }, weight: {Left:unitVolume["HO"]["Thalamus"                      ].Left, Right:unitVolume["HO"]["Thalamus"                      ].Right}},
        {static: true, primaryKey:122,  identifier:122, belongToForeignKey: 117,  volumeForeignKey:104, level: 1, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Basal ganglia",    fullname: "Accumbens",                     varname:{Left:"HO_Accumbens_L",                       Right:"HO_Accumbens_R"                      }, weight: {Left:unitVolume["HO"]["Accumbens"                     ].Left, Right:unitVolume["HO"]["Accumbens"                     ].Right}},
        {static: true, primaryKey:123,  identifier:123, belongToForeignKey: 123,  volumeForeignKey: -1, level: 0, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Brainstem",        fullname: "Brainstem",                     varname:{Left:"HO_Brainstem_L",                       Right:"HO_Brainstem_R"                      }},
        {static: true, primaryKey:124,  identifier:124, belongToForeignKey: 124,  volumeForeignKey:  0, level: 0, report:false, type: 'suvr',      atlas:"Harvard-Oxford",  belongTo: "Cerebellum gray",  fullname: "Cerebellum gray",               varname:{Left:"OutputAffineParamsY1",                 Right:"OutputAffineParamsX0"                }},

        {static: true, primaryKey:125,  identifier:  0, belongToForeignKey: 125,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"AAL3",            belongTo: "Composite",        fullname: "Composite",                     varname:{Left:"Composite_C",                          Right:"Composite_C"                         }},
        {static: true, primaryKey:126,  identifier:  1, belongToForeignKey: 126,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal",                       varname:{Left:"Frontal_L_C",                          Right:"Frontal_R_C"                         }},
        {static: true, primaryKey:127,  identifier:  2, belongToForeignKey: 126,  volumeForeignKey:  0, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Precentral",                    varname:{Left:"Precentral_L_C",                       Right:"Precentral_R_C"                      }, weight: {Left:unitVolume["AAL3"]["Precentral"                  ].Left, Right:unitVolume["AAL3"]["Precentral"                  ].Right}},
        {static: true, primaryKey:128,  identifier:  3, belongToForeignKey: 126,  volumeForeignKey:  1, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Sup",                   varname:{Left:"Frontal_Sup_L_C",                      Right:"Frontal_Sup_R_C"                     }, weight: {Left:unitVolume["AAL3"]["Frontal_Sup"                 ].Left, Right:unitVolume["AAL3"]["Frontal_Sup"                 ].Right}},
        {static: true, primaryKey:129,  identifier:  4, belongToForeignKey: 126,  volumeForeignKey:  2, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Mid",                   varname:{Left:"Frontal_Mid_L_C",                      Right:"Frontal_Mid_R_C"                     }, weight: {Left:unitVolume["AAL3"]["Frontal_Mid"                 ].Left, Right:unitVolume["AAL3"]["Frontal_Mid"                 ].Right}},
        {static: true, primaryKey:130,  identifier:  5, belongToForeignKey: 126,  volumeForeignKey:  3, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Inf Oper",              varname:{Left:"Frontal_Inf_Oper_L_C",                 Right:"Frontal_Inf_Oper_R_C"                }, weight: {Left:unitVolume["AAL3"]["Frontal_Inf_Oper"            ].Left, Right:unitVolume["AAL3"]["Frontal_Inf_Oper"            ].Right}},
        {static: true, primaryKey:131,  identifier:  6, belongToForeignKey: 126,  volumeForeignKey:  4, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Inf Tri",               varname:{Left:"Frontal_Inf_Tri_L_C",                  Right:"Frontal_Inf_Tri_R_C"                 }, weight: {Left:unitVolume["AAL3"]["Frontal_Inf_Tri"             ].Left, Right:unitVolume["AAL3"]["Frontal_Inf_Tri"             ].Right}},
        {static: true, primaryKey:132,  identifier:  7, belongToForeignKey: 126,  volumeForeignKey:  5, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Inf Orb",               varname:{Left:"Frontal_Inf_Orb_L_C",                  Right:"Frontal_Inf_Orb_R_C"                 }, weight: {Left:unitVolume["AAL3"]["Frontal_Inf_Orb"             ].Left, Right:unitVolume["AAL3"]["Frontal_Inf_Orb"             ].Right}},
        {static: true, primaryKey:133,  identifier:  8, belongToForeignKey: 126,  volumeForeignKey:  6, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Rolandic Oper",                 varname:{Left:"Rolandic_Oper_L_C",                    Right:"Rolandic_Oper_R_C"                   }, weight: {Left:unitVolume["AAL3"]["Rolandic_Oper"               ].Left, Right:unitVolume["AAL3"]["Rolandic_Oper"               ].Right}},
        {static: true, primaryKey:134,  identifier:  9, belongToForeignKey: 126,  volumeForeignKey:  7, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Supp Motor Area",               varname:{Left:"Supp_Motor_Area_L_C",                  Right:"Supp_Motor_Area_R_C"                 }, weight: {Left:unitVolume["AAL3"]["Supp_Motor_Area"             ].Left, Right:unitVolume["AAL3"]["Supp_Motor_Area"             ].Right}},
        {static: true, primaryKey:135,  identifier: 10, belongToForeignKey: 126,  volumeForeignKey:  8, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Olfactory",                     varname:{Left:"Olfactory_L_C",                        Right:"Olfactory_R_C"                       }, weight: {Left:unitVolume["AAL3"]["Olfactory"                   ].Left, Right:unitVolume["AAL3"]["Olfactory"                   ].Right}},
        {static: true, primaryKey:136,  identifier: 11, belongToForeignKey: 126,  volumeForeignKey:  9, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Sup Med",               varname:{Left:"Frontal_Sup_Med_L_C",                  Right:"Frontal_Sup_Med_R_C"                 }, weight: {Left:unitVolume["AAL3"]["Frontal_Sup_Med"             ].Left, Right:unitVolume["AAL3"]["Frontal_Sup_Med"             ].Right}},
        {static: true, primaryKey:137,  identifier: 12, belongToForeignKey: 126,  volumeForeignKey: 10, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Med Orb",               varname:{Left:"Frontal_Med_Orb_L_C",                  Right:"Frontal_Med_Orb_R_C"                 }, weight: {Left:unitVolume["AAL3"]["Frontal_Med_Orb"             ].Left, Right:unitVolume["AAL3"]["Frontal_Med_Orb"             ].Right}},
        {static: true, primaryKey:138,  identifier: 13, belongToForeignKey: 126,  volumeForeignKey: 11, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Rectus",                        varname:{Left:"Rectus_L_C",                           Right:"Rectus_R_C"                          }, weight: {Left:unitVolume["AAL3"]["Rectus"                      ].Left, Right:unitVolume["AAL3"]["Rectus"                      ].Right}},
        {static: true, primaryKey:139,  identifier: 14, belongToForeignKey: 126,  volumeForeignKey: 12, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "OFCmed",                        varname:{Left:"OFCmed_L_C",                           Right:"OFCmed_R_C"                          }, weight: {Left:unitVolume["AAL3"]["OFCmed"                      ].Left, Right:unitVolume["AAL3"]["OFCmed"                      ].Right}},
        {static: true, primaryKey:140,  identifier: 15, belongToForeignKey: 126,  volumeForeignKey: 13, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "OFCant",                        varname:{Left:"OFCant_L_C",                           Right:"OFCant_R_C"                          }, weight: {Left:unitVolume["AAL3"]["OFCant"                      ].Left, Right:unitVolume["AAL3"]["OFCant"                      ].Right}},
        {static: true, primaryKey:141,  identifier: 16, belongToForeignKey: 126,  volumeForeignKey: 14, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "OFCpost",                       varname:{Left:"OFCpost_L_C",                          Right:"OFCpost_R_C"                         }, weight: {Left:unitVolume["AAL3"]["OFCpost"                     ].Left, Right:unitVolume["AAL3"]["OFCpost"                     ].Right}},
        {static: true, primaryKey:142,  identifier: 17, belongToForeignKey: 126,  volumeForeignKey: 15, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "OFClat",                        varname:{Left:"OFClat_L_C",                           Right:"OFClat_R_C"                          }, weight: {Left:unitVolume["AAL3"]["OFClat"                      ].Left, Right:unitVolume["AAL3"]["OFClat"                      ].Right}},
        {static: true, primaryKey:143,  identifier: 18, belongToForeignKey: 126,  volumeForeignKey: 16, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Insula",                        varname:{Left:"Insula_L_C",                           Right:"Insula_R_C"                          }, weight: {Left:unitVolume["AAL3"]["Insula"                      ].Left, Right:unitVolume["AAL3"]["Insula"                      ].Right}},
        {static: true, primaryKey:144,  identifier: 19, belongToForeignKey: 126,  volumeForeignKey: 17, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Cingulate Ant Sub",             varname:{Left:"Cingulate_Ant_Sub_L_C",                Right:"Cingulate_Ant_Sub_R_C"               }, weight: {Left:unitVolume["AAL3"]["Cingulate_Ant_Sub"           ].Left, Right:unitVolume["AAL3"]["Cingulate_Ant_Sub"           ].Right}},
        {static: true, primaryKey:145,  identifier: 20, belongToForeignKey: 126,  volumeForeignKey: 18, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Cingulate Ant Pre",             varname:{Left:"Cingulate_Ant_Pre_L_C",                Right:"Cingulate_Ant_Pre_R_C"               }, weight: {Left:unitVolume["AAL3"]["Cingulate_Ant_Pre"           ].Left, Right:unitVolume["AAL3"]["Cingulate_Ant_Pre"           ].Right}},
        {static: true, primaryKey:146,  identifier: 21, belongToForeignKey: 126,  volumeForeignKey: 19, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Cingulate Ant Sup",             varname:{Left:"Cingulate_Ant_Sup_L_C",                Right:"Cingulate_Ant_Sup_R_C"               }, weight: {Left:unitVolume["AAL3"]["Cingulate_Ant_Sup"           ].Left, Right:unitVolume["AAL3"]["Cingulate_Ant_Sup"           ].Right}},
        {static: true, primaryKey:147,  identifier: 22, belongToForeignKey: 126,  volumeForeignKey: 20, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Frontal",          fullname: "Cingulate Mid",                 varname:{Left:"Cingulate_Mid_L_C",                    Right:"Cingulate_Mid_R_C"                   }, weight: {Left:unitVolume["AAL3"]["Cingulate_Mid"               ].Left, Right:unitVolume["AAL3"]["Cingulate_Mid"               ].Right}},
        {static: true, primaryKey:148,  identifier: 23, belongToForeignKey: 148,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"AAL3",            belongTo: "PCC-Precuneus",    fullname: "PCC-Precuneus",                 varname:{Left:"PCC_L_C",                              Right:"PCC_R_C"                             }},                    
        {static: true, primaryKey:149,  identifier: 24, belongToForeignKey: 148,  volumeForeignKey: 21, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "PCC-Precuneus",    fullname: "Cingulate Post",                varname:{Left:"Cingulate_Post_L_C",                   Right:"Cingulate_Post_R_C"                  }, weight: {Left:unitVolume["AAL3"]["Cingulate_Post"              ].Left, Right:unitVolume["AAL3"]["Cingulate_Post"              ].Right}},
        {static: true, primaryKey:150,  identifier: 25, belongToForeignKey: 148,  volumeForeignKey: 22, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "PCC-Precuneus",    fullname: "Precuneus",                     varname:{Left:"Precuneus_L_C",                        Right:"Precuneus_R_C"                       }, weight: {Left:unitVolume["AAL3"]["Precuneus"                   ].Left, Right:unitVolume["AAL3"]["Precuneus"                   ].Right}},
        {static: true, primaryKey:151,  identifier: 26, belongToForeignKey: 151,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Lateral temporal",              varname:{Left:"Lateral_temporal_L_C",                 Right:"Lateral_temporal_R_C"                }},                    
        {static: true, primaryKey:152,  identifier: 27, belongToForeignKey: 151,  volumeForeignKey: 23, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Heschl",                        varname:{Left:"Heschl_L_C",                           Right:"Heschl_R_C"                          }, weight: {Left:unitVolume["AAL3"]["Heschl"                      ].Left, Right:unitVolume["AAL3"]["Heschl"                      ].Right}},
        {static: true, primaryKey:153,  identifier: 28, belongToForeignKey: 151,  volumeForeignKey: 24, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Temporal Sup",                  varname:{Left:"Temporal_Sup_L_C",                     Right:"Temporal_Sup_R_C"                    }, weight: {Left:unitVolume["AAL3"]["Temporal_Sup"                ].Left, Right:unitVolume["AAL3"]["Temporal_Sup"                ].Right}},
        {static: true, primaryKey:154,  identifier: 29, belongToForeignKey: 151,  volumeForeignKey: 25, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Temporal Pole Sup",             varname:{Left:"Temporal_Pole_Sup_L_C",                Right:"Temporal_Pole_Sup_R_C"               }, weight: {Left:unitVolume["AAL3"]["Temporal_Pole_Sup"           ].Left, Right:unitVolume["AAL3"]["Temporal_Pole_Sup"           ].Right}},
        {static: true, primaryKey:155,  identifier: 30, belongToForeignKey: 151,  volumeForeignKey: 26, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Temporal Mid",                  varname:{Left:"Temporal_Mid_L_C",                     Right:"Temporal_Mid_R_C"                    }, weight: {Left:unitVolume["AAL3"]["Temporal_Mid"                ].Left, Right:unitVolume["AAL3"]["Temporal_Mid"                ].Right}},
        {static: true, primaryKey:156,  identifier: 31, belongToForeignKey: 151,  volumeForeignKey: 27, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Temporal Pole Mid",             varname:{Left:"Temporal_Pole_Mid_L_C",                Right:"Temporal_Pole_Mid_R_C"               }, weight: {Left:unitVolume["AAL3"]["Temporal_Pole_Mid"           ].Left, Right:unitVolume["AAL3"]["Temporal_Pole_Mid"           ].Right}},
        {static: true, primaryKey:157,  identifier: 32, belongToForeignKey: 151,  volumeForeignKey: 28, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral temporal", fullname: "Temporal Inf",                  varname:{Left:"Temporal_Inf_L_C",                     Right:"Temporal_Inf_R_C"                    }, weight: {Left:unitVolume["AAL3"]["Temporal_Inf"                ].Left, Right:unitVolume["AAL3"]["Temporal_Inf"                ].Right}},
        {static: true, primaryKey:158,  identifier: 33, belongToForeignKey: 158,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Lateral parietal",              varname:{Left:"Lateral_parietal_L_C",                 Right:"Lateral_parietal_R_C"                }},                    
        {static: true, primaryKey:159,  identifier: 34, belongToForeignKey: 158,  volumeForeignKey: 29, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Postcentral",                   varname:{Left:"Postcentral_L_C",                      Right:"Postcentral_R_C"                     }, weight: {Left:unitVolume["AAL3"]["Postcentral"                 ].Left, Right:unitVolume["AAL3"]["Postcentral"                 ].Right}},
        {static: true, primaryKey:160,  identifier: 35, belongToForeignKey: 158,  volumeForeignKey: 30, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Parietal Sup",                  varname:{Left:"Parietal_Sup_L_C",                     Right:"Parietal_Sup_R_C"                    }, weight: {Left:unitVolume["AAL3"]["Parietal_Sup"                ].Left, Right:unitVolume["AAL3"]["Parietal_Sup"                ].Right}},
        {static: true, primaryKey:161,  identifier: 36, belongToForeignKey: 158,  volumeForeignKey: 31, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Parietal Inf",                  varname:{Left:"Parietal_Inf_L_C",                     Right:"Parietal_Inf_R_C"                    }, weight: {Left:unitVolume["AAL3"]["Parietal_Inf"                ].Left, Right:unitVolume["AAL3"]["Parietal_Inf"                ].Right}},
        {static: true, primaryKey:162,  identifier: 37, belongToForeignKey: 158,  volumeForeignKey: 32, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Supramarginal",                 varname:{Left:"Supramarginal_L_C",                    Right:"Supramarginal_R_C"                   }, weight: {Left:unitVolume["AAL3"]["Supramarginal"               ].Left, Right:unitVolume["AAL3"]["Supramarginal"               ].Right}},
        {static: true, primaryKey:163,  identifier: 38, belongToForeignKey: 158,  volumeForeignKey: 33, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Angular",                       varname:{Left:"Angular_L_C",                          Right:"Angular_R_C"                         }, weight: {Left:unitVolume["AAL3"]["Angular"                     ].Left, Right:unitVolume["AAL3"]["Angular"                     ].Right}},
        {static: true, primaryKey:164,  identifier: 39, belongToForeignKey: 158,  volumeForeignKey: 34, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Lateral parietal", fullname: "Paracentral Lobule",            varname:{Left:"Paracentral_Lobule_L_C",               Right:"Paracentral_Lobule_R_C"              }, weight: {Left:unitVolume["AAL3"]["Paracentral_Lobule"          ].Left, Right:unitVolume["AAL3"]["Paracentral_Lobule"          ].Right}},
        {static: true, primaryKey:165,  identifier: 40, belongToForeignKey: 165,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"AAL3",            belongTo: "Medial temporal",  fullname: "Medial temporal",               varname:{Left:"Medial_temporal_L_C",                  Right:"Medial_temporal_R_C"                 }},                    
        {static: true, primaryKey:166,  identifier: 41, belongToForeignKey: 165,  volumeForeignKey: 35, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Medial temporal",  fullname: "Hippocampus",                   varname:{Left:"Hippocampus_L_C",                      Right:"Hippocampus_R_C"                     }, weight: {Left:unitVolume["AAL3"]["Hippocampus"                 ].Left, Right:unitVolume["AAL3"]["Hippocampus"                 ].Right}},
        {static: true, primaryKey:167,  identifier: 42, belongToForeignKey: 165,  volumeForeignKey: 36, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Medial temporal",  fullname: "Parahippocampal",               varname:{Left:"Parahippocampal_L_C",                  Right:"Parahippocampal_R_C"                 }, weight: {Left:unitVolume["AAL3"]["Parahippocampal"             ].Left, Right:unitVolume["AAL3"]["Parahippocampal"             ].Right}},
        {static: true, primaryKey:168,  identifier: 43, belongToForeignKey: 165,  volumeForeignKey: 37, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Medial temporal",  fullname: "Amygdala",                      varname:{Left:"Amygdala_L_C",                         Right:"Amygdala_R_C"                        }, weight: {Left:unitVolume["AAL3"]["Amygdala"                    ].Left, Right:unitVolume["AAL3"]["Amygdala"                    ].Right}},
        {static: true, primaryKey:169,  identifier: 44, belongToForeignKey: 169,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"AAL3",            belongTo: "Occipital",        fullname: "Occipital",                     varname:{Left:"Occipital_L_C",                        Right:"Occipital_R_C"                       }},                    
        {static: true, primaryKey:170,  identifier: 45, belongToForeignKey: 169,  volumeForeignKey: 38, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Occipital",        fullname: "Calcarine",                     varname:{Left:"Calcarine_L_C",                        Right:"Calcarine_R_C"                       }, weight: {Left:unitVolume["AAL3"]["Calcarine"                   ].Left, Right:unitVolume["AAL3"]["Calcarine"                   ].Right}},
        {static: true, primaryKey:171,  identifier: 46, belongToForeignKey: 169,  volumeForeignKey: 39, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Occipital",        fullname: "Cuneus",                        varname:{Left:"Cuneus_L_C",                           Right:"Cuneus_R_C"                          }, weight: {Left:unitVolume["AAL3"]["Cuneus"                      ].Left, Right:unitVolume["AAL3"]["Cuneus"                      ].Right}},
        {static: true, primaryKey:172,  identifier: 47, belongToForeignKey: 169,  volumeForeignKey: 40, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Occipital",        fullname: "Lingual",                       varname:{Left:"Lingual_L_C",                          Right:"Lingual_R_C"                         }, weight: {Left:unitVolume["AAL3"]["Lingual"                     ].Left, Right:unitVolume["AAL3"]["Lingual"                     ].Right}},
        {static: true, primaryKey:173,  identifier: 48, belongToForeignKey: 169,  volumeForeignKey: 41, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Occipital",        fullname: "Occipital Sup",                 varname:{Left:"Occipital_Sup_L_C",                    Right:"Occipital_Sup_R_C"                   }, weight: {Left:unitVolume["AAL3"]["Occipital_Sup"               ].Left, Right:unitVolume["AAL3"]["Occipital_Sup"               ].Right}},
        {static: true, primaryKey:174,  identifier: 49, belongToForeignKey: 169,  volumeForeignKey: 42, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Occipital",        fullname: "Occipital Mid",                 varname:{Left:"Occipital_Mid_L_C",                    Right:"Occipital_Mid_R_C"                   }, weight: {Left:unitVolume["AAL3"]["Occipital_Mid"               ].Left, Right:unitVolume["AAL3"]["Occipital_Mid"               ].Right}},
        {static: true, primaryKey:175,  identifier: 50, belongToForeignKey: 169,  volumeForeignKey: 43, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Occipital",        fullname: "Occipital Inf",                 varname:{Left:"Occipital_Inf_L_C",                    Right:"Occipital_Inf_R_C"                   }, weight: {Left:unitVolume["AAL3"]["Occipital_Inf"               ].Left, Right:unitVolume["AAL3"]["Occipital_Inf"               ].Right}},
        {static: true, primaryKey:176,  identifier: 51, belongToForeignKey: 169,  volumeForeignKey: 44, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Occipital",        fullname: "Fusiform",                      varname:{Left:"Fusiform_L_C",                         Right:"Fusiform_R_C"                        }, weight: {Left:unitVolume["AAL3"]["Fusiform"                    ].Left, Right:unitVolume["AAL3"]["Fusiform"                    ].Right}},
        {static: true, primaryKey:177,  identifier: 52, belongToForeignKey: 177,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"AAL3",            belongTo: "Basal ganglia",    fullname: "Basal ganglia",                 varname:{Left:"Basal_ganglia_L_C",                    Right:"Basal_ganglia_R_C"                   }},
        {static: true, primaryKey:178,  identifier: 53, belongToForeignKey: 177,  volumeForeignKey: 45, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Basal ganglia",    fullname: "Caudate",                       varname:{Left:"Caudate_L_C",                          Right:"Caudate_R_C"                         }, weight: {Left:unitVolume["AAL3"]["Caudate"                     ].Left, Right:unitVolume["AAL3"]["Caudate"                     ].Right}},
        {static: true, primaryKey:179,  identifier: 54, belongToForeignKey: 177,  volumeForeignKey: 46, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Basal ganglia",    fullname: "Putamen",                       varname:{Left:"Putamen_L_C",                          Right:"Putamen_R_C"                         }, weight: {Left:unitVolume["AAL3"]["Putamen"                     ].Left, Right:unitVolume["AAL3"]["Putamen"                     ].Right}},
        {static: true, primaryKey:180,  identifier: 55, belongToForeignKey: 177,  volumeForeignKey: 47, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Basal ganglia",    fullname: "Pallidum",                      varname:{Left:"Pallidum_L_C",                         Right:"Pallidum_R_C"                        }, weight: {Left:unitVolume["AAL3"]["Pallidum"                    ].Left, Right:unitVolume["AAL3"]["Pallidum"                    ].Right}},
        {static: true, primaryKey:181,  identifier: 56, belongToForeignKey: 177,  volumeForeignKey: 48, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Basal ganglia",    fullname: "Thalamus",                      varname:{Left:"Thalamus_L_C",                         Right:"Thalamus_R_C"                        }, weight: {Left:unitVolume["AAL3"]["Thalamus"                    ].Left, Right:unitVolume["AAL3"]["Thalamus"                    ].Right}},
        {static: true, primaryKey:182,  identifier: 57, belongToForeignKey: 177,  volumeForeignKey: 49, level: 1, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Basal ganglia",    fullname: "N Acc",                         varname:{Left:"N_Acc_L_C",                            Right:"N_Acc_R_C"                           }, weight: {Left:unitVolume["AAL3"]["N_Acc"                       ].Left, Right:unitVolume["AAL3"]["N_Acc"                       ].Right}},
        {static: true, primaryKey:183,  identifier: 58, belongToForeignKey: 183,  volumeForeignKey: -1, level: 0, report:false, type: 'centiloid', atlas:"AAL3",            belongTo: "Brainstem",        fullname: "Brainstem",                     varname:{Left:"Brainstem_L_C",                        Right:"Brainstem_R_C"                       }},                    
        {static: true, primaryKey:184,  identifier: 59, belongToForeignKey: 184,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Composite",        fullname: "Composite",                     varname:{Left:"Composite_C",                          Right:"Composite_C"                         }},                    
        {static: true, primaryKey:185,  identifier: 60, belongToForeignKey: 185,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal",                       varname:{Left:"HO_Frontal_L_C",                       Right:"HO_Frontal_R_C"                      }},                    
        {static: true, primaryKey:186,  identifier: 61, belongToForeignKey: 185,  volumeForeignKey: 50, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Pole",                  varname:{Left:"HO_Frontal_Pole_L_C",                  Right:"HO_Frontal_Pole_R_C"                 }, weight: {Left:unitVolume["HO"]["Frontal_Pole"                  ].Left, Right:unitVolume["HO"]["Frontal_Pole"                  ].Right}},
        {static: true, primaryKey:187,  identifier: 62, belongToForeignKey: 185,  volumeForeignKey: 51, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Insula",                        varname:{Left:"HO_Insula_L_C",                        Right:"HO_Insula_R_C"                       }, weight: {Left:unitVolume["HO"]["Insula"                        ].Left, Right:unitVolume["HO"]["Insula"                        ].Right}},
        {static: true, primaryKey:188,  identifier: 63, belongToForeignKey: 185,  volumeForeignKey: 52, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Sup",                   varname:{Left:"HO_Frontal_Sup_L_C",                   Right:"HO_Frontal_Sup_R_C"                  }, weight: {Left:unitVolume["HO"]["Frontal_Sup"                   ].Left, Right:unitVolume["HO"]["Frontal_Sup"                   ].Right}},
        {static: true, primaryKey:189,  identifier: 64, belongToForeignKey: 185,  volumeForeignKey: 53, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Mid",                   varname:{Left:"HO_Frontal_Mid_L_C",                   Right:"HO_Frontal_Mid_R_C"                  }, weight: {Left:unitVolume["HO"]["Frontal_Mid"                   ].Left, Right:unitVolume["HO"]["Frontal_Mid"                   ].Right}},
        {static: true, primaryKey:190,  identifier: 65, belongToForeignKey: 185,  volumeForeignKey: 54, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Inf Tri",               varname:{Left:"HO_Frontal_Inf_Tri_L_C",               Right:"HO_Frontal_Inf_Tri_R_C"              }, weight: {Left:unitVolume["HO"]["Frontal_Inf_Tri"               ].Left, Right:unitVolume["HO"]["Frontal_Inf_Tri"               ].Right}},
        {static: true, primaryKey:191,  identifier: 66, belongToForeignKey: 185,  volumeForeignKey: 55, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Inf Oper",              varname:{Left:"HO_Frontal_Inf_Oper_L_C",              Right:"HO_Frontal_Inf_Oper_R_C"             }, weight: {Left:unitVolume["HO"]["Frontal_Inf_Oper"              ].Left, Right:unitVolume["HO"]["Frontal_Inf_Oper"              ].Right}},
        {static: true, primaryKey:192,  identifier: 67, belongToForeignKey: 185,  volumeForeignKey: 56, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Precentral",                    varname:{Left:"HO_Precentral_L_C",                    Right:"HO_Precentral_R_C"                   }, weight: {Left:unitVolume["HO"]["Precentral"                    ].Left, Right:unitVolume["HO"]["Precentral"                    ].Right}},
        {static: true, primaryKey:193,  identifier: 68, belongToForeignKey: 185,  volumeForeignKey: 57, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Med",                   varname:{Left:"HO_Frontal_Med_L_C",                   Right:"HO_Frontal_Med_R_C"                  }, weight: {Left:unitVolume["HO"]["Frontal_Med"                   ].Left, Right:unitVolume["HO"]["Frontal_Med"                   ].Right}},
        {static: true, primaryKey:194,  identifier: 69, belongToForeignKey: 185,  volumeForeignKey: 58, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Supp Motor Area",               varname:{Left:"HO_Supp_Motor_Area_L_C",               Right:"HO_Supp_Motor_Area_R_C"              }, weight: {Left:unitVolume["HO"]["Supp_Motor_Area"               ].Left, Right:unitVolume["HO"]["Supp_Motor_Area"               ].Right}},
        {static: true, primaryKey:195,  identifier: 71, belongToForeignKey: 185,  volumeForeignKey: 59, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Cingulate Ant",                 varname:{Left:"HO_Cingulate_Ant_L_C",                 Right:"HO_Cingulate_Ant_R_C"                }, weight: {Left:unitVolume["HO"]["Cingulate_Ant"                 ].Left, Right:unitVolume["HO"]["Cingulate_Ant"                 ].Right}},
        {static: true, primaryKey:196,  identifier: 71, belongToForeignKey: 185,  volumeForeignKey: 60, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Orb",                   varname:{Left:"HO_Frontal_Orb_L_C",                   Right:"HO_Frontal_Orb_R_C"                  }, weight: {Left:unitVolume["HO"]["Frontal_Orb"                   ].Left, Right:unitVolume["HO"]["Frontal_Orb"                   ].Right}},
        {static: true, primaryKey:197,  identifier: 72, belongToForeignKey: 185,  volumeForeignKey: 61, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Frontal Oper",                  varname:{Left:"HO_Frontal_Oper_L_C",                  Right:"HO_Frontal_Oper_R_C"                 }, weight: {Left:unitVolume["HO"]["Frontal_Oper"                  ].Left, Right:unitVolume["HO"]["Frontal_Oper"                  ].Right}},
        {static: true, primaryKey:198,  identifier: 73, belongToForeignKey: 185,  volumeForeignKey: 62, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Central Oper",                  varname:{Left:"HO_Central_Oper_L_C",                  Right:"HO_Central_Oper_R_C"                 }, weight: {Left:unitVolume["HO"]["Central_Oper"                  ].Left, Right:unitVolume["HO"]["Central_Oper"                  ].Right}},
        {static: true, primaryKey:199,  identifier: 74, belongToForeignKey: 185,  volumeForeignKey: 63, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Subcallosal",                   varname:{Left:"HO_Subcallosal_L_C",                   Right:"HO_Subcallosal_R_C"                  }, weight: {Left:unitVolume["HO"]["Subcallosal"                   ].Left, Right:unitVolume["HO"]["Subcallosal"                   ].Right}},
        {static: true, primaryKey:200,  identifier: 75, belongToForeignKey: 185,  volumeForeignKey: 64, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Frontal",          fullname: "Paracingulate",                 varname:{Left:"HO_Paracingulate_L_C",                 Right:"HO_Paracingulate_R_C"                }, weight: {Left:unitVolume["HO"]["Paracingulate"                 ].Left, Right:unitVolume["HO"]["Paracingulate"                 ].Right}},
        {static: true, primaryKey:201,  identifier: 76, belongToForeignKey: 201,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Retrosplenial",    fullname: "Retrosplenial",                 varname:{Left:"HO_PCC_L_C",                           Right:"HO_PCC_R_C"                          }},                    
        {static: true, primaryKey:202,  identifier: 77, belongToForeignKey: 201,  volumeForeignKey: 65, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Retrosplenial",    fullname: "Cingulate Post",                varname:{Left:"HO_Cingulate_Post_L_C",                Right:"HO_Cingulate_Post_R_C"               }, weight: {Left:unitVolume["HO"]["Cingulate_Post"                ].Left, Right:unitVolume["HO"]["Cingulate_Post"                ].Right}},
        {static: true, primaryKey:203,  identifier: 78, belongToForeignKey: 201,  volumeForeignKey: 66, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Retrosplenial",    fullname: "Precuneus",                     varname:{Left:"HO_Precuneus_L_C",                     Right:"HO_Precuneus_R_C"                    }, weight: {Left:unitVolume["HO"]["Precuneus"                     ].Left, Right:unitVolume["HO"]["Precuneus"                     ].Right}},
        {static: true, primaryKey:204,  identifier: 79, belongToForeignKey: 204,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Lateral temporal",              varname:{Left:"HO_Lateral_temporal_L_C",              Right:"HO_Lateral_temporal_R_C"             }},                    
        {static: true, primaryKey:205,  identifier: 80, belongToForeignKey: 204,  volumeForeignKey: 67, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Pole",                 varname:{Left:"HO_Temporal_Pole_L_C",                 Right:"HO_Temporal_Pole_R_C"                }, weight: {Left:unitVolume["HO"]["Temporal_Pole"                 ].Left, Right:unitVolume["HO"]["Temporal_Pole"                 ].Right}},
        {static: true, primaryKey:206,  identifier: 81, belongToForeignKey: 204,  volumeForeignKey: 68, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Sup Ant",              varname:{Left:"HO_Temporal_Sup_Ant_L_C",              Right:"HO_Temporal_Sup_Ant_R_C"             }, weight: {Left:unitVolume["HO"]["Temporal_Sup_Ant"              ].Left, Right:unitVolume["HO"]["Temporal_Sup_Ant"              ].Right}},
        {static: true, primaryKey:207,  identifier: 82, belongToForeignKey: 204,  volumeForeignKey: 69, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Sup Post",             varname:{Left:"HO_Temporal_Sup_Post_L_C",             Right:"HO_Temporal_Sup_Post_R_C"            }, weight: {Left:unitVolume["HO"]["Temporal_Sup_Post"             ].Left, Right:unitVolume["HO"]["Temporal_Sup_Post"             ].Right}},
        {static: true, primaryKey:208,  identifier: 83, belongToForeignKey: 204,  volumeForeignKey: 70, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Mid Ant",              varname:{Left:"HO_Temporal_Mid_Ant_L_C",              Right:"HO_Temporal_Mid_Ant_R_C"             }, weight: {Left:unitVolume["HO"]["Temporal_Mid_Ant"              ].Left, Right:unitVolume["HO"]["Temporal_Mid_Ant"              ].Right}},
        {static: true, primaryKey:209,  identifier: 84, belongToForeignKey: 204,  volumeForeignKey: 71, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Mid Post",             varname:{Left:"HO_Temporal_Mid_Post_L_C",             Right:"HO_Temporal_Mid_Post_R_C"            }, weight: {Left:unitVolume["HO"]["Temporal_Mid_Post"             ].Left, Right:unitVolume["HO"]["Temporal_Mid_Post"             ].Right}},
        {static: true, primaryKey:210,  identifier: 85, belongToForeignKey: 204,  volumeForeignKey: 72, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Mid Temporooccipital", varname:{Left:"HO_Temporal_Mid_Temporooccipital_L_C", Right:"HO_Temporal_Mid_Temporooccipital_R_C"}, weight: {Left:unitVolume["HO"]["Temporal_Mid_Temporooccipital" ].Left, Right:unitVolume["HO"]["Temporal_Mid_Temporooccipital" ].Right}},
        {static: true, primaryKey:211,  identifier: 86, belongToForeignKey: 204,  volumeForeignKey: 73, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Inf Ant",              varname:{Left:"HO_Temporal_Inf_Ant_L_C",              Right:"HO_Temporal_Inf_Ant_R_C"             }, weight: {Left:unitVolume["HO"]["Temporal_Inf_Ant"              ].Left, Right:unitVolume["HO"]["Temporal_Inf_Ant"              ].Right}},
        {static: true, primaryKey:212,  identifier: 87, belongToForeignKey: 204,  volumeForeignKey: 74, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Inf Post",             varname:{Left:"HO_Temporal_Inf_Post_L_C",             Right:"HO_Temporal_Inf_Post_R_C"            }, weight: {Left:unitVolume["HO"]["Temporal_Inf_Post"             ].Left, Right:unitVolume["HO"]["Temporal_Inf_Post"             ].Right}},
        {static: true, primaryKey:213,  identifier: 88, belongToForeignKey: 204,  volumeForeignKey: 75, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Inf Temporooccipital", varname:{Left:"HO_Temporal_Inf_Temporooccipital_L_C", Right:"HO_Temporal_Inf_Temporooccipital_R_C"}, weight: {Left:unitVolume["HO"]["Temporal_Inf_Temporooccipital" ].Left, Right:unitVolume["HO"]["Temporal_Inf_Temporooccipital" ].Right}},
        {static: true, primaryKey:214,  identifier: 89, belongToForeignKey: 204,  volumeForeignKey: 76, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Planum Polare",                 varname:{Left:"HO_Planum_Polare_L_C",                 Right:"HO_Planum_Polare_R_C"                }, weight: {Left:unitVolume["HO"]["Planum_Polare"                 ].Left, Right:unitVolume["HO"]["Planum_Polare"                 ].Right}},
        {static: true, primaryKey:215,  identifier: 90, belongToForeignKey: 204,  volumeForeignKey: 77, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Heschl",                        varname:{Left:"HO_Heschl_L_C",                        Right:"HO_Heschl_R_C"                       }, weight: {Left:unitVolume["HO"]["Heschl"                        ].Left, Right:unitVolume["HO"]["Heschl"                        ].Right}},
        {static: true, primaryKey:216,  identifier: 91, belongToForeignKey: 204,  volumeForeignKey: 78, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Planum Temporale",              varname:{Left:"HO_Planum_Temporale_L_C",              Right:"HO_Planum_Temporale_R_C"             }, weight: {Left:unitVolume["HO"]["Planum_Temporale"              ].Left, Right:unitVolume["HO"]["Planum_Temporale"              ].Right}},
        {static: true, primaryKey:217,  identifier: 92, belongToForeignKey: 204,  volumeForeignKey: 79, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal fusiform Ant",         varname:{Left:"HO_Temporal_fusiform_Ant_L_C",         Right:"HO_Temporal_fusiform_Ant_R_C"        }, weight: {Left:unitVolume["HO"]["Temporal_fusiform_Ant"         ].Left, Right:unitVolume["HO"]["Temporal_fusiform_Ant"         ].Right}},
        {static: true, primaryKey:218,  identifier: 93, belongToForeignKey: 204,  volumeForeignKey: 80, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal fusiform Post",        varname:{Left:"HO_Temporal_fusiform_Post_L_C",        Right:"HO_Temporal_fusiform_Post_R_C"       }, weight: {Left:unitVolume["HO"]["Temporal_fusiform_Post"        ].Left, Right:unitVolume["HO"]["Temporal_fusiform_Post"        ].Right}},
        {static: true, primaryKey:219,  identifier: 94, belongToForeignKey: 204,  volumeForeignKey: 81, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral temporal", fullname: "Temporal Occipital Fusiform",   varname:{Left:"HO_Temporal_Occipital_Fusiform_L_C",   Right:"HO_Temporal_Occipital_Fusiform_R_C"  }, weight: {Left:unitVolume["HO"]["Temporal_Occipital_Fusiform"   ].Left, Right:unitVolume["HO"]["Temporal_Occipital_Fusiform"   ].Right}},
        {static: true, primaryKey:220,  identifier: 95, belongToForeignKey: 220,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Lateral parietal",              varname:{Left:"HO_Lateral_parietal_L_C",              Right:"HO_Lateral_parietal_R_C"             }},                    
        {static: true, primaryKey:221,  identifier: 96, belongToForeignKey: 220,  volumeForeignKey: 82, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Postcentral",                   varname:{Left:"HO_Postcentral_L_C",                   Right:"HO_Postcentral_R_C"                  }, weight: {Left:unitVolume["HO"]["Postcentral"                   ].Left, Right:unitVolume["HO"]["Postcentral"                   ].Right}},
        {static: true, primaryKey:222,  identifier: 97, belongToForeignKey: 220,  volumeForeignKey: 83, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Parietal Sup Lobule",           varname:{Left:"HO_Parietal_Sup_Lobule_L_C",           Right:"HO_Parietal_Sup_Lobule_R_C"          }, weight: {Left:unitVolume["HO"]["Parietal_Sup_Lobule"           ].Left, Right:unitVolume["HO"]["Parietal_Sup_Lobule"           ].Right}},
        {static: true, primaryKey:223,  identifier: 98, belongToForeignKey: 220,  volumeForeignKey: 84, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Supramarginal Ant",             varname:{Left:"HO_Supramarginal_Ant_L_C",             Right:"HO_Supramarginal_Ant_R_C"            }, weight: {Left:unitVolume["HO"]["Supramarginal_Ant"             ].Left, Right:unitVolume["HO"]["Supramarginal_Ant"             ].Right}},
        {static: true, primaryKey:224,  identifier: 99, belongToForeignKey: 220,  volumeForeignKey: 85, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Supramarginal Post",            varname:{Left:"HO_Supramarginal_Post_L_C",            Right:"HO_Supramarginal_Post_R_C"           }, weight: {Left:unitVolume["HO"]["Supramarginal_Post"            ].Left, Right:unitVolume["HO"]["Supramarginal_Post"            ].Right}},
        {static: true, primaryKey:225,  identifier:100, belongToForeignKey: 220,  volumeForeignKey: 86, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Angular",                       varname:{Left:"HO_Angular_L_C",                       Right:"HO_Angular_R_C"                      }, weight: {Left:unitVolume["HO"]["Angular"                       ].Left, Right:unitVolume["HO"]["Angular"                       ].Right}},
        {static: true, primaryKey:226,  identifier:101, belongToForeignKey: 220,  volumeForeignKey: 87, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Lateral parietal", fullname: "Parietal Oper",                 varname:{Left:"HO_Parietal_Oper_L_C",                 Right:"HO_Parietal_Oper_R_C"                }, weight: {Left:unitVolume["HO"]["Parietal_Oper"                 ].Left, Right:unitVolume["HO"]["Parietal_Oper"                 ].Right}},
        {static: true, primaryKey:227,  identifier:102, belongToForeignKey: 227,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Medial temporal",  fullname: "Medial temporal",               varname:{Left:"HO_Medial_temporal_L_C",               Right:"HO_Medial_temporal_R_C"              }},                    
        {static: true, primaryKey:228,  identifier:103, belongToForeignKey: 227,  volumeForeignKey: 88, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Medial temporal",  fullname: "Parahippocampal Ant",           varname:{Left:"HO_Parahippocampal_Ant_L_C",           Right:"HO_Parahippocampal_Ant_R_C"          }, weight: {Left:unitVolume["HO"]["Parahippocampal_Ant"           ].Left, Right:unitVolume["HO"]["Parahippocampal_Ant"           ].Right}},
        {static: true, primaryKey:229,  identifier:104, belongToForeignKey: 227,  volumeForeignKey: 89, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Medial temporal",  fullname: "Parahippocampal Post",          varname:{Left:"HO_Parahippocampal_Post_L_C",          Right:"HO_Parahippocampal_Post_R_C"         }, weight: {Left:unitVolume["HO"]["Parahippocampal_Post"          ].Left, Right:unitVolume["HO"]["Parahippocampal_Post"          ].Right}},
        {static: true, primaryKey:230,  identifier:105, belongToForeignKey: 227,  volumeForeignKey: 90, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Medial temporal",  fullname: "Hippocampus",                   varname:{Left:"HO_Hippocampus_L_C",                   Right:"HO_Hippocampus_R_C"                  }, weight: {Left:unitVolume["HO"]["Hippocampus"                   ].Left, Right:unitVolume["HO"]["Hippocampus"                   ].Right}},
        {static: true, primaryKey:231,  identifier:106, belongToForeignKey: 227,  volumeForeignKey: 91, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Medial temporal",  fullname: "Amygdala",                      varname:{Left:"HO_Amygdala_L_C",                      Right:"HO_Amygdala_R_C"                     }, weight: {Left:unitVolume["HO"]["Amygdala"                      ].Left, Right:unitVolume["HO"]["Amygdala"                      ].Right}},
        {static: true, primaryKey:232,  identifier:107, belongToForeignKey: 232,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Occipital",                     varname:{Left:"HO_Occipital_L_C",                     Right:"HO_Occipital_R_C"                    }},                    
        {static: true, primaryKey:233,  identifier:108, belongToForeignKey: 232,  volumeForeignKey: 92, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Occipital Lat_Sup",             varname:{Left:"HO_Occipital_Lat_Sup_L_C",             Right:"HO_Occipital_Lat_Sup_R_C"            }, weight: {Left:unitVolume["HO"]["Occipital_Lat_Sup"             ].Left, Right:unitVolume["HO"]["Occipital_Lat_Sup"             ].Right}},
        {static: true, primaryKey:234,  identifier:109, belongToForeignKey: 232,  volumeForeignKey: 93, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Occipital Lat_Inf",             varname:{Left:"HO_Occipital_Lat_Inf_L_C",             Right:"HO_Occipital_Lat_Inf_R_C"            }, weight: {Left:unitVolume["HO"]["Occipital_Lat_Inf"             ].Left, Right:unitVolume["HO"]["Occipital_Lat_Inf"             ].Right}},
        {static: true, primaryKey:235,  identifier:110, belongToForeignKey: 232,  volumeForeignKey: 94, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Intracalcarine",                varname:{Left:"HO_Intracalcarine_L_C",                Right:"HO_Intracalcarine_R_C"               }, weight: {Left:unitVolume["HO"]["Intracalcarine"                ].Left, Right:unitVolume["HO"]["Intracalcarine"                ].Right}},
        {static: true, primaryKey:236,  identifier:111, belongToForeignKey: 232,  volumeForeignKey: 95, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Occipital Pole",                varname:{Left:"HO_Occipital_Pole_L_C",                Right:"HO_Occipital_Pole_R_C"               }, weight: {Left:unitVolume["HO"]["Occipital_Pole"                ].Left, Right:unitVolume["HO"]["Occipital_Pole"                ].Right}},
        {static: true, primaryKey:237,  identifier:112, belongToForeignKey: 232,  volumeForeignKey: 96, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Cuneal",                        varname:{Left:"HO_Cuneal_L_C",                        Right:"HO_Cuneal_R_C"                       }, weight: {Left:unitVolume["HO"]["Cuneal"                        ].Left, Right:unitVolume["HO"]["Cuneal"                        ].Right}},
        {static: true, primaryKey:238,  identifier:113, belongToForeignKey: 232,  volumeForeignKey: 97, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Lingual",                       varname:{Left:"HO_Lingual_L_C",                       Right:"HO_Lingual_R_C"                      }, weight: {Left:unitVolume["HO"]["Lingual"                       ].Left, Right:unitVolume["HO"]["Lingual"                       ].Right}},
        {static: true, primaryKey:239,  identifier:114, belongToForeignKey: 232,  volumeForeignKey: 98, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Occipital Fusiform",            varname:{Left:"HO_Occipital_Fusiform_L_C",            Right:"HO_Occipital_Fusiform_R_C"           }, weight: {Left:unitVolume["HO"]["Occipital_Fusiform"            ].Left, Right:unitVolume["HO"]["Occipital_Fusiform"            ].Right}},
        {static: true, primaryKey:240,  identifier:115, belongToForeignKey: 232,  volumeForeignKey: 99, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Occipital",        fullname: "Supracalcarine",                varname:{Left:"HO_Supracalcarine_L_C",                Right:"HO_Supracalcarine_R_C"               }, weight: {Left:unitVolume["HO"]["Supracalcarine"                ].Left, Right:unitVolume["HO"]["Supracalcarine"                ].Right}},
        {static: true, primaryKey:241,  identifier:116, belongToForeignKey: 241,  volumeForeignKey: -1, level: 0, report:true,  type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Basal ganglia",    fullname: "Basal ganglia",                 varname:{Left:"HO_Basal_ganglia_L_C",                 Right:"HO_Basal_ganglia_R_C"                }},                    
        {static: true, primaryKey:242,  identifier:117, belongToForeignKey: 241,  volumeForeignKey:100, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Basal ganglia",    fullname: "Caudate",                       varname:{Left:"HO_Caudate_L_C",                       Right:"HO_Caudate_R_C"                      }, weight: {Left:unitVolume["HO"]["Caudate"                       ].Left, Right:unitVolume["HO"]["Caudate"                       ].Right}},
        {static: true, primaryKey:243,  identifier:118, belongToForeignKey: 241,  volumeForeignKey:101, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Basal ganglia",    fullname: "Putamen",                       varname:{Left:"HO_Putamen_L_C",                       Right:"HO_Putamen_R_C"                      }, weight: {Left:unitVolume["HO"]["Putamen"                       ].Left, Right:unitVolume["HO"]["Putamen"                       ].Right}},
        {static: true, primaryKey:244,  identifier:119, belongToForeignKey: 241,  volumeForeignKey:102, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Basal ganglia",    fullname: "Pallidum",                      varname:{Left:"HO_Pallidum_L_C",                      Right:"HO_Pallidum_R_C"                     }, weight: {Left:unitVolume["HO"]["Pallidum"                      ].Left, Right:unitVolume["HO"]["Pallidum"                      ].Right}},
        {static: true, primaryKey:245,  identifier:120, belongToForeignKey: 241,  volumeForeignKey:103, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Basal ganglia",    fullname: "Thalamus",                      varname:{Left:"HO_Thalamus_L_C",                      Right:"HO_Thalamus_R_C"                     }, weight: {Left:unitVolume["HO"]["Thalamus"                      ].Left, Right:unitVolume["HO"]["Thalamus"                      ].Right}},
        {static: true, primaryKey:246,  identifier:121, belongToForeignKey: 241,  volumeForeignKey:104, level: 1, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Basal ganglia",    fullname: "Accumbens",                     varname:{Left:"HO_Accumbens_L_C",                     Right:"HO_Accumbens_R_C"                    }, weight: {Left:unitVolume["HO"]["Accumbens"                     ].Left, Right:unitVolume["HO"]["Accumbens"                     ].Right}},
        {static: true, primaryKey:247,  identifier:122, belongToForeignKey: 247,  volumeForeignKey: -1, level: 0, report:false, type: 'centiloid', atlas:"Harvard-Oxford",  belongTo: "Brainstem",        fullname: "Brainstem",                     varname:{Left:"HO_Brainstem_L_C",                     Right:"HO_Brainstem_R_C"                    }},
        // {primaryKey:246,  foreignKey: 246, level: 0, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal1",          fullname: "Frontal1",                     varname:{Left:"Cingulate_Ant_Sub_L", Right:"Cingulate_Ant_Sub_R"}},
        // {primaryKey:247,  foreignKey: 246, level: 1, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal1",          fullname: "subFrontal1",                  varname:{Left:"Cingulate_Ant_Pre_L", Right:"Cingulate_Ant_Pre_R"}},
        // {primaryKey:248,  foreignKey: 246, level: 1, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal1",          fullname: "subFrontal2",                  varname:{Left:"Cingulate_Ant_Sup_L", Right:"Cingulate_Ant_Sup_R"}},
        // {primaryKey:249,  foreignKey: 246, level: 1, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal1",          fullname: "subFrontal3",                  varname:{Left:"Cingulate_Mid_L", Right:"Cingulate_Mid_R"}},
      ]
      const primaryKeyArr = arr.map((el,idx)=>({...el, primaryKey:idx}))
      let belongToForeignKeyVar=0;
      const belongToForeignKeyArr = primaryKeyArr.map((el,idx)=>{
        if (el.level===0) belongToForeignKeyVar = el.primaryKey
        return {...el, belongToForeignKey:belongToForeignKeyVar}
      })
      const identifierSUVR = belongToForeignKeyArr.filter(el=>el.type==='suvr').map((el,idx)=>({...el, identifier:idx}))
      const identifierCentil = belongToForeignKeyArr.filter(el=>el.type==='centiloid').map((el,idx)=>({...el, identifier:idx}))
      const resultArr = [...identifierSUVR, ...identifierCentil]
      return resultArr
    })(),
  }
})()