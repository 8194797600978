import React from 'react';
import styles from './App.module.scss';
import { useSelector } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { coreItem } from '0_variables/coreItem';
import { Home } from './4_routers';
import { GlobalLayout } from '5_components';

function App() {
  const loginState = useSelector((state) => state.login);
  const productCoreItem = coreItem['amyloid'];
  const CTN_Mode = productCoreItem.CTN_Mode;
  const sessionUserName = sessionStorage.getItem('username');
  const isLogin = loginState.logged || !!sessionUserName;

  return (
    <>
      {isLogin && (
        <>
          {CTN_Mode && (
            <div
              className={`${
                sessionUserName
                  ? styles.menuCTNIndicator
                  : styles.homeCTNIndicator
              }`}
            >
              <div>CTN project viewer only</div>
              {/* <div style={{display:`${loginStateFromsessionStorage ?  "none": ""}`}}>기존 버전과 다른 username으로 계정 생성 및 로그인해주세요</div> */}
            </div>
          )}
          <Switch>
            <Route
              exact
              path={['/signin', '/signup', '/forgot', '/selectProduct']}
            >
              <Home />
            </Route>
            <Route path="/:product">
              <GlobalLayout />
            </Route>
            <Route path="/">
              <Redirect to={{ pathname: '/signin' }} />
            </Route>
          </Switch>
        </>
      )}
      {!isLogin && (
        <Switch>
          <Route exact path={['/signin', '/signup', '/forgot']}>
            <Home />
          </Route>
          <Route path="/">
            <Redirect to={{ pathname: '/signin' }} />
          </Route>
        </Switch>
      )}
    </>
  );
}

export default App;
