import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { registerUserThunk } from '1_reduxs/actions/loginAction';
import { HomePassButton, InputText } from '5_components';
import styles from './Signup.module.scss';

export default function Signup() {
  const history = useHistory();
  const dispatch = useDispatch();
  const signupValuesTemplate = {
    fullname: '',
    username: '',
    password1: '',
    password2: '',
    email: '',
    'phone number': '',
  };
  if (process.env.REACT_APP_IS_OCI === 'true') {
    signupValuesTemplate.institution = '';
  }
  const [signupValues, setSignupValues] = useState(signupValuesTemplate);
  const [isAgreeChecked, setIsAgreeChecked] = useState(false);
  const isValidPassword =
    signupValues.password1 === signupValues.password2 ? true : false;

  const processFunc = async () => {
    if (isValidPassword === false) {
      return;
    }

    if (isAgreeChecked === false) {
      alert('Please agree to the policy.');
      return;
    }

    if (isAgreeChecked) {
      const payload = {
        username: signupValues.username,
        password1: signupValues.password1,
        password2: signupValues.password2,
        email: signupValues.email,
        phone: signupValues['phone number'],
      };

      if (process.env.REACT_APP_IS_OCI === 'true') {
        payload.fullname = signupValues.fullname;
        payload.institution = signupValues.institution;
      } else {
        payload.first_name = signupValues.fullname;
        payload.last_name = signupValues.fullname;
      }

      const resObj = await dispatch(registerUserThunk(payload));
      if (resObj.response) {
        alert('Successfully signed up. Please sign in.');
        history.push(`/signin`);
      } else {
        if (resObj.code === undefined)
          alert('Network Error: Please check if Btxbrain-backend is running.');
        else alert(resObj.message);
      }
      // console.log(`${resObj.message} (${resObj.code}) `);
    }
  };

  const propsSignUp = {
    processFunc,
    btnClassName: 'signup-btn',
    title: 'SIGN UP',
  };

  useEffect(() => {
    return () => {
      setSignupValues({
        fullname: '',
        username: '',
        password1: '',
        password2: '',
        email: '',
        'phone number': '',
      });
    };
  }, []);

  return (
    <div className={styles['container']}>
      <div>
        <h1 className={`${styles['title']}`}>{'SIGN UP'}</h1>
        <InputText
          title="NAME"
          name="fullname"
          placeholder="Enter name"
          value={signupValues}
          setValue={setSignupValues}
        />
        <InputText
          title="USERNAME"
          name="username"
          placeholder="Enter username"
          value={signupValues}
          setValue={setSignupValues}
        />
        <InputText
          title="PASSWORD"
          type="password"
          name="password1"
          placeholder="Enter password"
          value={signupValues}
          setValue={setSignupValues}
        />
        <InputText
          title="CONFIRM PASSWORD"
          type="password"
          name="password2"
          placeholder="Confirm password"
          value={signupValues}
          setValue={setSignupValues}
        />
        <div
          className={`${styles['CheckPassword']}`}
          style={{ display: `${isValidPassword ? 'none' : ''}` }}
        >
          The password confirmation doses not match.
        </div>
        <InputText
          title="EMAIL"
          name="email"
          placeholder="Enter email"
          value={signupValues}
          setValue={setSignupValues}
        />
        <InputText
          title="PHONE NUMBER"
          name="phone number"
          placeholder="Enter phone number"
          value={signupValues}
          setValue={setSignupValues}
        />
        {process.env.REACT_APP_IS_OCI === 'true' && (
          <InputText
            title="INSTITUTION NAME"
            name="institution"
            placeholder="Enter institution name"
            value={signupValues}
            setValue={setSignupValues}
            inputClassName="signup-input"
          />
        )}
        <div className={`${styles['policy']}`}>
          <input
            type="checkbox"
            aria-label="agreePolicy"
            checked={isAgreeChecked}
            onChange={() => setIsAgreeChecked(!isAgreeChecked)}
          />
          <div>{'Agree to the policy'}</div>
        </div>
        <HomePassButton item={propsSignUp} />
      </div>
    </div>
  );
}
