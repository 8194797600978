import { createSlice } from '@reduxjs/toolkit';
import { removesessionStorage } from '0_variables/utils';

const initialState = {
  username: null,
  token: null,
  logged: false,
};

if (process.env.REACT_APP_USE_REFRESH_TOKEN === 'true') {
  initialState.refreshToken = null;
  initialState.expire = null;
}

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setLoginState: (state, action) => {
      const { username, token, logged } = action.payload;
      if (!logged) {
        removesessionStorage();
      }

      state.username = username;
      state.token = token;
      state.logged = logged;

      if (process.env.REACT_APP_USE_REFRESH_TOKEN === 'true') {
        const { expire, refreshToken } = action.payload;
        state.refreshToken = refreshToken;
        state.expire = expire;
      }
    },
  },
});

export const { setLoginState } = loginSlice.actions;
export default loginSlice.reducer;
