import { coreItem } from '0_variables/coreItem';
import { getLicenseInfo, removesessionStorage } from '0_variables/utils';
import { openSpinner, resetModalState } from '1_reduxs/reducers/modalReducer';
import { fetchFilesStore } from '1_reduxs/reducers/filesReducer';
import { initControl } from '1_reduxs/reducers/controllerReducer';
import { setLoginState } from '1_reduxs/reducers/loginReducer';
import {
  resetLicenseInfo,
  updateLicenseInfo,
} from '1_reduxs/reducers/licenseReducer';
import { resetLayoutState } from '1_reduxs/reducers/layoutReducer';
import {
  Signin as SigninAPI,
  RefreshToken as RefreshTokenAPI,
  Registration as RegistrationAPI,
  ResetPassword as ResetPasswordAPI,
} from '2_services/loginApi';
import BTXDB from '2_services/btxdb';
import { datadogRum } from '@datadog/browser-rum';

export function startLoginThunk(values) {
  return async (dispatch) => {
    try {
      dispatch(
        openSpinner({
          status: true,
          percent: 0,
          message: 'Log in...',
          target: '',
        }),
      );

      const res = await SigninAPI(values);
      const token = res.data.token || null;
      const expire = res.data?.expire || null;
      const refreshToken = res.data?.refresh_token || null;
      const is_Cloud_Mode = coreItem['amyloid'].Cloud_Mode;
      const loginThunkData = {
        username: values.username,
        token,
        logged: true,
      };

      sessionStorage.setItem('username', values.username);
      sessionStorage.setItem('token', token);

      if (process.env.REACT_APP_USE_REFRESH_TOKEN === 'true') {
        sessionStorage.setItem('expire', expire);
        sessionStorage.setItem('refreshToken', refreshToken);
        loginThunkData.expire = expire;
        loginThunkData.refreshToken = refreshToken;
      }

      // Cloud 모드(OCI 아님)일때, 서버로부터 user.is_active_mode 를 받아야함
      // Cloud 모드가 아닐때(On-premise, OCI), 서버로부터 user 객체를 받지 않아야함
      if (is_Cloud_Mode) {
        const is_active_email = res.data.user.is_active_email; // user 객체가 없을경우 예외발생후 로그인 실패

        if (is_active_email) {
          // is_active_email이 true일때, 로그인 진행
          dispatch(setLoginThunk(loginThunkData));

          return {
            response: true,
            message: 'login: Success',
            code: res.status,
            is_Cloud_Mode: is_Cloud_Mode,
            is_active_email: is_active_email,
          };
        } else {
          // is_active_email이 false 일때, 로그인 차단
          return {
            response: true,
            message: 'email is not verified',
            code: res.status,
            is_Cloud_Mode: is_Cloud_Mode,
            is_active_email: is_active_email,
          };
        }
      } else {
        // user 객체가 있으면 안됨

        if ('user' in res.data) {
          throw new Error('on-premise front is connected to cloud server.');
        }

        dispatch(setLoginThunk(loginThunkData));
        return {
          response: true,
          message: 'login: Success',
          code: res.status,
          is_Cloud_Mode: is_Cloud_Mode,
          is_active_email: null,
        };
      }
    } catch (err) {
      removesessionStorage();
      return {
        response: false,
        message: 'login: Failed',
        code: err?.response?.status,
        is_Cloud_Mode: null,
        is_active_email: null,
      };
    } finally {
      dispatch(resetLayoutState());
      dispatch(resetModalState());
      dispatch(openSpinner({ status: false, percent: 0, message: '' }));
    }
  };
}

// Not used
// export function verify_token_api(values) {
//   return async (dispatch) => {
//     try {
//       dispatch(
//         actionModal.open_spinner({
//           status: true,
//           percent: 0,
//           message: 'Verfiying user account...',
//         }),
//       );
//       const res = await loginApi.TokenVerification(values);
//       const token = res.data.token;
//       if (!token) throw new Error('token expired');
//       const username = sessionStorage.getItem('username');
//       dispatch(
//         setLoginThunk({ username: username, token: token, logged: true }),
//       );
//       return { response: true, message: 'token: Verified' };
//     } catch (err) {
//       removesessionStorage();
//       dispatch(setLoginThunk({ username: '', token: '', logged: false }));
//       return { response: false, message: 'token: Expired' };
//     } finally {
//       dispatch(
//         actionModal.open_spinner({ status: false, percent: 0, message: '' }),
//       );
//     }
//   };
// }

export function refreshTokensThunk(values) {
  if (process.env.REACT_APP_USE_REFRESH_TOKEN !== 'true') {
    return {
      response: false,
      message: 'refreshToken: Failed',
    };
  }

  return async (dispatch) => {
    try {
      const username = sessionStorage.getItem('username');
      const res = await RefreshTokenAPI(values);
      const newToken = res.data.token;
      const newExpire = res.data?.expire || null;
      const refreshToken = values.refresh_token;

      if (sessionStorage.getItem('username') === username) {
        // refreshToken 실행 중 로그아웃됐을 수도 있어서 다시 체크
        sessionStorage.setItem('token', newToken);
        sessionStorage.setItem('expire', newExpire);

        await dispatch(
          setLoginThunk({
            username: username,
            token: newToken,
            expire: newExpire,
            refreshToken: refreshToken,
            logged: true,
          }),
        );
        return {
          response: true,
          message: 'refreshToken: Success',
          code: res.status,
          token: newToken,
          expire: newExpire,
          refreshToken: refreshToken,
        };
      } else {
        // refreshToken 실행 중 로그아웃됐을 경우 무시
        return {
          response: true,
          message: 'refreshToken: Failed',
          code: res.status,
        };
      }
    } catch (err) {
      return {
        response: false,
        message: 'refreshToken: Failed',
        code: err?.response?.status,
      };
    } finally {
      dispatch(resetLayoutState());
      dispatch(resetModalState());
    }
  };
}

export function registerUserThunk(values) {
  return async (dispatch) => {
    try {
      dispatch(
        openSpinner({
          status: true,
          percent: 0,
          message: 'Signing up...',
        }),
      );
      const res = await RegistrationAPI(values);

      return {
        response: true,
        message: 'Registration: Success',
        code: res.status,
      };
    } catch (err) {
      removesessionStorage();

      const httpStatus = err?.response?.status;
      const responseMessage = err?.response?.data;
      return {
        response: false,
        message: responseMessage
          ? `Registration: Failed, ${responseMessage}`
          : `Network Error: Please check if Btxbrain-backend is running.`,
        code: httpStatus,
      };
    } finally {
      dispatch(openSpinner({ status: false, percent: 0, message: '' }));
    }
  };
}

export function resetPasswdThunk(values) {
  return async (dispatch) => {
    try {
      dispatch(
        openSpinner({
          status: true,
          percent: 0,
          message: 'Checkout account',
        }),
      );
      const res = await ResetPasswordAPI(
        { Authorization: 'JWT ' }, // token이 필요없으나 헤더가 필요함
        values,
      );
      return { response: true, message: 'Reset: Success', code: res.status };
    } catch (err) {
      const responseMessage = err?.response?.data;
      const httpStatus = err?.response?.status;
      return {
        response: false,
        message: responseMessage
          ? `Reset: ${responseMessage}`
          : `Network Error: Please check if Btxbrain-backend is running.`,
        code: httpStatus,
      };
    } finally {
      removesessionStorage();
      dispatch(openSpinner({ status: false, percent: 0, message: '' }));
    }
  };
}
export function setLoginThunk(obj) {
  return async (dispatch) => {
    const { username, token, expire, refreshToken, logged } = obj;

    dispatch(setLoginState({ username, token, expire, refreshToken, logged }));

    // Datadog RUM 설정
    if (process.env.REACT_APP_USE_DATADOG_RUM === 'true') {
      if (username) {
        datadogRum.setUser({
          name: username,
        });
      } else {
        datadogRum.removeUserProperty('name');
      }
    }

    // reset Database
    await BTXDB.transaction('rw', BTXDB.slices, async () => {
      await BTXDB.slices.clear();
    });

    if (!logged) {
      dispatch(fetchFilesStore({ items: [] }));
      dispatch(initControl());
      dispatch(resetLicenseInfo());
    }
  };
}

// 단일 프로덕트의 라이센스 정보를 업데이트
export function refreshLicenseInfo(productName) {
  return async (dispatch) => {
    const licenseResult = await getLicenseInfo([productName]);

    if (licenseResult[productName]) {
      dispatch(
        updateLicenseInfo({ productName, ...licenseResult[productName] }),
      );
    }
  };
}
