import { ANALYSIS_CARD } from './analysisTypes';

export const AnalysisLayout = {
  amyloid: {
    top: {
      item1: [
        { title: 'Tracer', var: 'Tracer' },
        { title: 'Patient name', var: 'PatientName' },
        { title: 'Patient ID', var: 'PatientID' },
        { title: 'DOB', var: 'Age' },
        { title: 'Sex', var: 'Sex' },
        { title: 'Study Date', var: 'AcquisitionDateTime' },
        { title: 'Desc', var: 'OriginalFileName' },
      ],
      item2: [
        { title: 'Atlas', var: 'defaultAtlas' },
        { title: 'Reference', var: 'defaultRef' },
      ],
      item3: [
        { title: 'Generate a report', var: 'openReport' },
        { title: 'Export to CSV', var: 'downloadCSV_Individual' },
        { title: 'Export to NIFTI (SUVR)', var: 'downloadNifitiSUVR_Individaul' },
        { title: 'Export to NIFTI (Raw)', var: 'downloadNifitiIntensity_Individaul' },
      ],
    },
    bottomCards: [
      [{ title: 'Lobar SUVR', componentName: ANALYSIS_CARD.BRAIN_LOBE }],
      [
        { title: 'Regional SUVR', componentName: ANALYSIS_CARD.ANALYSIS_TABLE_1 },
        { title: 'Regional Centiloid', componentName: ANALYSIS_CARD.ANALYSIS_TABLE_2 },
      ], // NOTE: tracer [18F]FPN은 제외
      [{ title: 'Surface Projection', componentName: ANALYSIS_CARD.BRAIN_SURFACE }],
    ],
  },
  dat: {
    top: {
      item1: [
        { title: 'Tracer', var: 'Tracer' },
        { title: 'Patient name', var: 'PatientName' },
        { title: 'Patient ID', var: 'PatientID' },
        { title: 'DOB', var: 'Age' },
        { title: 'Sex', var: 'Sex' },
        { title: 'Study Date', var: 'AcquisitionDateTime' },
        { title: 'Desc', var: 'OriginalFileName' },
      ],
      item2: [
        { title: 'Atlas', var: 'defaultAtlas' },
        { title: 'Reference', var: 'defaultRef' },
      ],
      item3: [
        { title: 'Generate a report', var: 'openReport' },
        { title: 'Export to CSV', var: 'downloadCSV_Individual' },
        { title: 'Export to NIFTI (SBR)', var: 'downloadNifitiSUVR_Individaul' },
        { title: 'Export to NIFTI (Raw)', var: 'downloadNifitiIntensity_Individaul' },
      ],
    },
    bottomCards: [
      [{ title: 'Striatal SBR', componentName: ANALYSIS_CARD.BRAIN_LOBE }],
      [
        { title: 'Regional SBR', componentName: ANALYSIS_CARD.ANALYSIS_TABLE_1 },
        { title: 'Semiquantification', componentName: ANALYSIS_CARD.ANALYSIS_TABLE_2 },
      ],
    ],
  },
  fdg: {
    top: {
      item1: [
        { title: 'Tracer', var: 'Tracer' },
        { title: 'Patient name', var: 'PatientName' },
        { title: 'Patient ID', var: 'PatientID' },
        { title: 'DOB', var: 'Age' },
        { title: 'Sex', var: 'Sex' },
        { title: 'Study Date', var: 'AcquisitionDateTime' },
        { title: 'Desc', var: 'OriginalFileName' },
      ],
      item2: [
        { title: 'Atlas', var: 'defaultAtlas' },
        { title: 'Reference', var: 'defaultRef' },
      ],
      item3: [
        { title: 'Generate a report', var: 'openReport' },
        { title: 'Export to CSV', var: 'downloadCSV_Individual' },
        { title: 'Export to NIFTI (SUVR)', var: 'downloadNifitiSUVR_Individaul' },
        { title: 'Export to NIFTI (Raw)', var: 'downloadNifitiIntensity_Individaul' },
      ],
    },
    bottomCards: [
      [{ title: 'Lobar SUVR', componentName: ANALYSIS_CARD.BRAIN_LOBE }],
      [{ title: 'Regional SUVR', componentName: ANALYSIS_CARD.ANALYSIS_TABLE_1 }],
      [{ title: 'Surface Projection SUVR', componentName: ANALYSIS_CARD.BRAIN_SURFACE }],
    ],
  },
  tau: {
    top: {
      item1: [
        { title: 'Tracer', var: 'Tracer' },
        { title: 'Patient name', var: 'PatientName' },
        { title: 'Patient ID', var: 'PatientID' },
        { title: 'DOB', var: 'Age' },
        { title: 'Sex', var: 'Sex' },
        { title: 'Study Date', var: 'AcquisitionDateTime' },
        { title: 'Desc', var: 'OriginalFileName' },
      ],
      item2: [
        { title: 'Atlas', var: 'defaultAtlas' },
        { title: 'Reference', var: 'defaultRef' },
      ],
      item3: [
        { title: 'Generate a report', var: 'openReport' },
        { title: 'Export to CSV', var: 'downloadCSV_Individual' },
        { title: 'Export to NIFTI (SUVR)', var: 'downloadNifitiSUVR_Individaul' },
        { title: 'Export to NIFTI (Raw)', var: 'downloadNifitiIntensity_Individaul' },
      ],
    },
    bottomCards: [
      [{ title: 'Lobar SUVR', componentName: ANALYSIS_CARD.BRAIN_LOBE }],

      [{ title: 'Regional SUVR', componentName: ANALYSIS_CARD.ANALYSIS_TABLE_1 }],

      [{ title: 'Surface Projection SUVR', componentName: ANALYSIS_CARD.BRAIN_SURFACE }],
    ],
  },
  perfusion: {
    top: {
      item1: [
        { title: 'Tracer', var: 'Tracer' },
        { title: 'Patient name', var: 'PatientName' },
        { title: 'Patient ID', var: 'PatientID' },
        { title: 'DOB', var: 'Age' },
        { title: 'Sex', var: 'Sex' },
        { title: 'Study Date', var: 'AcquisitionDateTime' },
        { title: 'Desc', var: 'OriginalFileName' },
      ],
      item2: [
        { title: 'Atlas', var: 'defaultAtlas' },
        { title: 'Reference', var: 'defaultRef' },
      ],
      item3: [
        { title: 'Generate a report', var: 'openReport' },
        { title: 'Export to CSV', var: 'downloadCSV_Individual' },
        { title: 'Export to NIFTI (SUVR)', var: 'downloadNifitiSUVR_Individaul' },
        { title: 'Export to NIFTI (Raw)', var: 'downloadNifitiIntensity_Individaul' },
      ],
    },
    bottomCards: [
      [{ title: 'Lobar SUVR', componentName: ANALYSIS_CARD.BRAIN_LOBE }],

      [{ title: 'Regional SUVR', componentName: ANALYSIS_CARD.ANALYSIS_TABLE_1 }],

      [{ title: 'Surface Projection SUVR', componentName: ANALYSIS_CARD.BRAIN_SURFACE }],
    ],
  },
};
