import html2canvas from "html2canvas";
import  { saveAs } from 'file-saver';
import axios from "axios";
import JSZip from 'jszip';
import { isEqual, pick } from "lodash";
import { coreItem} from "0_variables/coreItem";
import MNI_Axial from "0_variables/MNI152_T1_2mm_z";
import MNI_Coronal from "0_variables/MNI152_T1_2mm_y";
import MNI_Sagittal from "0_variables/MNI152_T1_2mm_x";
import * as actionsControl from "1_reduxs/actions/controllerAction";
import { getSlices } from "2_services/sliceApi";
import { getRemainCount } from "2_services/uploadApi";

import * as cornerstone from "cornerstone-core";
import * as cornerstoneMath from "cornerstone-math";
import * as cornerstoneTools from "cornerstone-tools";
import Hammer from "hammerjs";
import * as cornerstoneWebImageLoader from "cornerstone-web-image-loader";


cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
cornerstoneWebImageLoader.external.cornerstone = cornerstone;
cornerstoneTools.external.Hammer = Hammer;


export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  // console.log(k, i, bytes, Math.pow(k, i), (bytes / Math.pow(k, i) / 10))
  // return parseFloat((bytes / Math.pow(k, i) / 10).toFixed(1));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const ItemSizeKB = (arr) => {
  let totalSizeKB = 0
  arr.forEach((v, i) => { totalSizeKB = totalSizeKB + Math.floor(v.size / 1024) })
  // return formatBytes(totalSize)
  return totalSizeKB
}
export const ItemSizeB = (arr) => {
  let totalSizeB = 0
  arr.forEach((v, i) => { totalSizeB = totalSizeB + v.size })
  // return formatBytes(totalSize)
  console.log(totalSizeB)
  return totalSizeB
}

export const ItemLength = (findResult) => {
  let count = 0
  findResult.forEach((v, i) => { count = count + v.seriesItem.length })
  return count
}

export const limitNum = {
  'amyloid': 1,
  'dat': 1,
  'fdg': 30,
  'tau': 30,
  'perfusion': 30,
}


export const ratio2Width = ({ width, height }) => height / width;
export const ratio2Height = ({ width, height }) => width / height;
export const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

// TODO: 나중에 selectedFile의 Ref Factor를 가져오는 방법 고민 필요
export const RefFactor = ({ productName, refName, ponsRF, crblRF, wmRF, cgRF, global }) => {
  // if (global){
  //   debugger;
  // }
  if (productName !== 'dat') {
    if (refName === "Pons") return ponsRF;
    else if (refName === "Whole cerebellum") return crblRF;
    //TODO: amyloid용 refFactor 받아와야함
    else if (refName === "Cerebral white matter") return wmRF;
    else if (refName === "Global cortex") return global; // for FDG
    else return 1; // Cerebellar gray matter
  } else {
    if (refName === "Pons") return ponsRF;
    else if (refName === "Cerebellar gray matter") return cgRF;
    else if (refName === "Cerebral white matter") return wmRF;
    else if (refName === "Whole cerebellum") return crblRF;
    else return 1; // occipital cortex
  }
}

export function getRFParamsByFileId(selectedFile) {
  if (selectedFile) {
    return pick(selectedFile, [
      'Tracer',
      'ratio_gry2pons',
      'ratio_gry2crbl',
      'ratio_gry2wm',
      'ratio_gry2cg',
      'Global',
      'out_suvr_min',
      'out_suvr_max',
      'in_suvr_min',
      'in_suvr_max',
    ]);
  }
  return null;
}

export function calculatePixelInfo({selectedFile, productName, refName, CTN_Mode, showNormalized}) {
  const RF = RefFactor({
    productName,
    refName,
    ponsRF: selectedFile['ratio_gry2pons'],
    crblRF: selectedFile['ratio_gry2crbl'],
    wmRF: selectedFile['ratio_gry2wm'],
    cgRF: selectedFile['ratio_gry2cg'],
    global: 1 / selectedFile['Global'],
  });

  const prefix = showNormalized ? 'out' : 'in';
  const suvr_min = selectedFile[`${prefix}_suvr_min`] * RF;
  const suvr_max = selectedFile[`${prefix}_suvr_max`] * RF;

  return {
    regularizedMax: 32767,
    min: suvr_min,
    max: suvr_max,
    width: CTN_Mode ? 32767 : suvr_max - suvr_min,
    center: (suvr_max + suvr_min) / 2,
  };
}

export const isChangedObjects = (prevObj, currObj) => {
  // debugger;
  const result = !isEqual(prevObj, currObj)
  return result;
}


  export const dataFormatForBrainTable = ({
    productName,
    columnList,
    selectedFile,
    settingOfProduct,
    RF,
  }) => {
    let valuePrefix = '';
    let filteredColumnList = [];
    const selectedAtlas = settingOfProduct.defaultAtlas;
    if (productName === 'dat') {
      filteredColumnList = columnList.filter(
        (obj) => obj.atlas === selectedAtlas,
      );
    } else {
      valuePrefix = selectedAtlas === 'AAL3' ? '' : 'HO_';
      filteredColumnList = columnList.filter((obj) => obj.atlas === 'all');
    }

    const format = filteredColumnList.map((item) => {
      // TODO: DW 나중에 확인 필요: DAT일 때에만 item.Lvar이 string이 아닌 경우가 발생하므로 "사실상 DAT 제품을 위한 로직임"
      if (productName === 'dat' && Array.isArray(item.Lvar)) {
        const getWeightedSum = (valueNameList, direction /* Left | Right */) => {
          return valueNameList.reduce(
            (acc, valueName) => {
              const targetRegion = settingOfProduct.list.find(
                (region) =>
                  region.atlas === selectedAtlas &&
                  region.varname[direction] === valueName,
              );
              acc.sum += selectedFile[valueName] * targetRegion.weight[direction];
              acc.weight += targetRegion.weight[direction];
              return acc;
            },
            { sum: 0, weight: 0 },
          );
        };

        const ObjL = getWeightedSum(item.Lvar, 'Left');
        const ObjR = getWeightedSum(item.Rvar, 'Right');

        const MeanOfWeightedSumLeft = ObjL.sum / ObjL.weight;
        const MeanOfWeightedSumRight = ObjR.sum / ObjR.weight;
        return {
          ...item,
          Lval: MeanOfWeightedSumLeft * RF,
          Rval: MeanOfWeightedSumRight * RF,
        };
      } else {
        return {
          ...item,
          Lval: selectedFile[`${valuePrefix}${item.Lvar}`] * RF,
          Rval: selectedFile[`${valuePrefix}${item.Rvar}`] * RF,
        };
      }
    });

    return format;
  };



export const metaDataLoader = async () => {


  function metaDataProvider(type, imageId) {
    const { direction, sliceID } = parseSliceImageId(imageId);    
    const scale = 1;
    const cX = 1;
    const cY = 1;
    const cZ = 1;

    let width = 91;
    let height = 91;
    if (direction === "coronal") { width = 91 + 40; height = 91 }
    else if (direction === "sagittal") { width = 109 + 20; height = 91 }
    else if (direction === "axial") { width = 91 + 40; height = 109 + 20 }
    else if (direction === "mip") { width = 109 + 20; height = 91 }

    if (type === 'imagePlaneModule') {
      if (direction === 'coronal') {
        return {
          frameOfReferenceUID: '1.2.3.4.5',
          rows: 91,
          columns: 91 + 40,
          rowCosines: [0, 1, 0],
          columnCosines: [0, 0, 1],
          imagePositionPatient: [scale * sliceID + cY, cX, cZ], //coronal plane에서 [xxx, sagittal line, axial line]
          columnPixelSpacing: 1,
          rowPixelSpacing: 1,
        }
      }
      else if (direction === 'sagittal') {
        return {
          frameOfReferenceUID: '1.2.3.4.5',
          rows: 91,
          columns: 109 + 20,
          rowCosines: [1, 0, 0],
          columnCosines: [0, 0, 1],
          imagePositionPatient: [cY, scale * sliceID + cX, cZ], //sagittal plane에서 [coroanl line, xxx, axial line]
          columnPixelSpacing: 1,
          rowPixelSpacing: 1,
        }
      }
      else if (direction === 'axial') {
        return {
          frameOfReferenceUID: '1.2.3.4.5',
          rows: 109 + 20,
          columns: 91 + 40,
          rowCosines: [0, 1, 0],
          columnCosines: [-1, 0, 0],
          imagePositionPatient: [cY + 109 + 20, cX, -scale * sliceID + cZ + 91], //axial plane에서  [coronal line, sagittal line, xxx]
          columnPixelSpacing: 1,
          rowPixelSpacing: 1,
        }
      }
    }
  }
  cornerstone.metaData.addProvider(metaDataProvider);
};

export const reportImageLoader = async (productName, pageName, fileList) => {
  
  // debugger;
  // const DB = fileList;
  // const DBarray = fileList.map(el=>el.reportItems)
  const DB = fileList.reduce((obj, el) => {
    obj['reportItems'] = {
      ...obj['reportItems'],
      ...el.reportItems,
    }
    return obj;
  }, {}).reportItems

  function getReportImage(imageId) {
    // debugger;
    const identifier = imageId.split(/[:,/]+/)
    const ImageLoaderName = identifier[0]
    const FileID = identifier[1]
    const Direction = identifier[2]
    const SliceID = Number(identifier[3])

    const size = getSizebyProduct(Direction);
    const width = size.width;
    const height = size.height;

    const getPixelData = () => {
      // const keyPairJSON = sessionStorage.getItem(FileID);
      // const data = JSON.parse(keyPairJSON)[imageId];
      // debugger;
      const data = DB[imageId];
      // console.log(data);
      try {
        if (data !== null && data !== undefined) {
          return getPixelDataFromBase64(data)
        } else {
          throw "unknown imageId"
        }
      } catch (e) {
        // debugger;
      }
      // if (data !== null && data !== undefined) {
      //   debugger;
      //   return getPixelDataFromBase64(data)
      // } else {
      //   throw "unknowm imageId"
      // }
    }

    var image = {
      imageId: imageId,
      minPixelValue: 0,
      maxPixelValue: 32767,
      slope: 1.0,
      intercept: 0,
      windowCenter: 16384,
      windowWidth: 32767,
      getPixelData: getPixelData,
      rows: height,
      columns: width,
      height: height,
      width: width,
      color: false,
      columnPixelSpacing: 2,
      rowPixelSpacing: 2,
      sizeInBytes: width * height * 2,
    };
    return {
      promise: new Promise((resolve) => {
        resolve(image);
      }),
      cancelFn: undefined
    };
  }
  if (pageName === 'report') {
    cornerstone.registerImageLoader(productName + pageName, getReportImage);
  }
}

export const getMNIImageID = (direction, index) => {
  return `mni_brtnx://${direction}/${index}`;
};

export const getSliceImageID = (options) => {
  const { productName, pageName, imageType, fileID, direction, sliceIndex } = options;
  // TODO imageType is 'input' | 'output' | 'input_subtraction' | 'output_subtraction'

  return `${productName}${pageName}://${imageType}/${fileID}/${direction}/${sliceIndex}`;
};

function parseSliceImageId(imageId) {
  const identifier = imageId.split(/[:,/]+/);
  const imageType = identifier[1]; // 'input' | 'output' | 'input_subtraction' | 'output_subtraction'
  const fileID = identifier[2];
  const direction = identifier[3]; // coronal, sagittal, axial
  const sliceID = Number(identifier[4]);

  return {
    imageType,
    fileID,
    direction,
    sliceID,
  };
}

export const initImageLoader = async (productName, pageName, baseFileID, targetFileID = null) => {
  const baseFileId = baseFileID;
  const originalSlice = await getSlices(baseFileID);
  // const targetFileId = targetFileID;
  const targetSlice = targetFileID ? await getSlices(targetFileID) : null;
  const Cloud_Mode = coreItem[productName].Cloud_Mode;
  const mniDB = {
    'axial': MNI_Axial,
    'coronal': MNI_Coronal,
    'sagittal': MNI_Sagittal,
  }
  
  function getMNIImage(imageId) {
    // mni_brtnx://${direction}/${index}
    // mni_brtnx://coronal/75
    const [, Direction, SliceID] = imageId.split(/[:,/]+/);
    
    const size = getSizebyProduct(Direction);
    const width = size.width;
    const height = size.height;

    function getPixelData() {
      const data = mniDB[Direction][SliceID];

      if (data !== null)
        return getPixelDataFromBase64(data);
      else
        throw "unknowm imageId"
    }

    var image = {
      imageId: imageId,
      minPixelValue: 0,
      maxPixelValue: 32767,
      suvrSlope: 1,
      slope: 1.0,
      intercept: 0,
      windowCenter: 16384,
      windowWidth: 32767,
      getPixelData: getPixelData,
      rows: height,
      columns: width,
      height: height,
      width: width,
      color: false,
      columnPixelSpacing: 2,
      rowPixelSpacing: 2,
      sizeInBytes: width * height * 2,
    };

    return {
      promise: new Promise((resolve) => {
        resolve(image);
      }),
      cancelFn: undefined
    };
  }
  function getSliceImage(imageId) {
    const { imageType, direction, sliceID, fileID } = parseSliceImageId(imageId);

    const findType = imageType.includes('output') ? 'output' : 'input';
    
    const size = getSizebyProduct(direction);
    const width = size.width;
    const height = size.height;

    function getPixelData() {
      const findSlice = fileID === baseFileId ? originalSlice : targetSlice;
      const baseData = findSlice.find(el => el.Type === findType && el.Direction === direction && el.ImageID === sliceID).B64Data;

      let targetData = null;
      if(targetSlice && imageType.includes('subtraction')) {
         targetData = targetSlice.find(el => el.Type === findType && el.Direction === direction && el.ImageID === sliceID).B64Data;
      }

      if (baseData !== null)
        if (Cloud_Mode) {
          // NOTE CLOUDE 모드의 경우 다르게 동작하는가?
          if (direction === 'mip') return getPixelDataFromBase64(baseData);
          else if (direction === 'sagittal') return getPixelDataFromBase64PETsagittal(baseData);
          else return getPixelDataFromBase64PET(baseData);
        } else {
          return getPixelDataFromBase64(baseData, targetData);
        }
      else
        throw "unknowm imageId"
    }

    const image = {
      imageId: imageId,
      minPixelValue: 0,
      maxPixelValue: 32767,
      // suvrSlope: suvrSlope, // not used
      slope: 1,
      intercept: 0,
      windowCenter: 16384,
      windowWidth: 32767,
      getPixelData: getPixelData,
      rows: height,
      columns: width,
      height: height,
      width: width,
      color: false,
      columnPixelSpacing: 2,
      rowPixelSpacing: 2,
      sizeInBytes: width * height * 2,
    };

    return {
      promise: new Promise((resolve) => {
        resolve(image);
      }),
      cancelFn: undefined
    };
  }

  // view에서만 쓰고 있음
  if (pageName === 'view') {
    // Removes all images from cache
    cornerstone.imageCache.purgeCache();

    cornerstone.registerImageLoader('mni_brtnx', getMNIImage);
    cornerstone.registerImageLoader(`${productName}${pageName}`, getSliceImage);
  };
}

export const getSizebyProduct = (Direction) => {
  if (Direction === 'coronal') return { width: 91 + 40, height: 91 }
  else if (Direction === 'sagittal') return { width: 109 + 20, height: 91 }
  else if (Direction === 'axial') return { width: 91 + 40, height: 109 + 20 }
  else if (Direction === "mip") return { width: 109 + 20, height: 91 }
  else return { width: 91, height: 91 }
}

export const reportImageIdsbyProduct = (productName, fileID) => {
  if (productName === 'amyloid') {
    return [
      'amyloidreport://' + fileID + '/axial/' + 60,
      'amyloidreport://' + fileID + '/axial/' + 55,
      'amyloidreport://' + fileID + '/axial/' + 50,
      'amyloidreport://' + fileID + '/axial/' + 45,
      'amyloidreport://' + fileID + '/axial/' + 40,
      'amyloidreport://' + fileID + '/axial/' + 35,
      'amyloidreport://' + fileID + '/axial/' + 30,
      'amyloidreport://' + fileID + '/axial/' + 25,
    ]
  } else if (productName === 'dat') {
    return [
      'datreport://' + fileID + '/axial/' + 40,
      'datreport://' + fileID + '/axial/' + 37,
      'datreport://' + fileID + '/axial/' + 34,
      'datreport://' + fileID + '/axial/' + 31,
      'datreport://' + fileID + '/axial/' + 28,
      'datreport://' + fileID + '/mip/' + 5,
      'datreport://' + fileID + '/mip/' + 10,
      'datreport://' + fileID + '/mip/' + 15,
      'datreport://' + fileID + '/mip/' + 20,
      'datreport://' + fileID + '/mip/' + 25,
      'datreport://' + fileID + '/mip/' + 30,
      'datreport://' + fileID + '/mip/' + 35,
      'datreport://' + fileID + '/mip/' + 40,
    ]
  } else if (productName === 'fdg') {
    // TODO: FDG와 TAU 제품에 대한 데이터 룰 필요함
    return [
      'fdgreport://' + fileID + '/axial/' + 60,
      'fdgreport://' + fileID + '/axial/' + 55,
      'fdgreport://' + fileID + '/axial/' + 50,
      'fdgreport://' + fileID + '/axial/' + 45,
      'fdgreport://' + fileID + '/axial/' + 40,
      'fdgreport://' + fileID + '/axial/' + 35,
      'fdgreport://' + fileID + '/axial/' + 30,
      'fdgreport://' + fileID + '/axial/' + 25,
    ]
  } else if (productName === 'tau') {
    return [
      'taureport://' + fileID + '/axial/' + 60,
      'taureport://' + fileID + '/axial/' + 55,
      'taureport://' + fileID + '/axial/' + 50,
      'taureport://' + fileID + '/axial/' + 45,
      'taureport://' + fileID + '/axial/' + 40,
      'taureport://' + fileID + '/axial/' + 35,
      'taureport://' + fileID + '/axial/' + 30,
      'taureport://' + fileID + '/axial/' + 25,
    ]
  }
}

export const str2pixelData = (str) => {
  var buf = new ArrayBuffer(str.length * 2); // 2 bytes for each char
  var bufView = new Int16Array(buf);
  var index = 0;
  for (var i = 0, strLen = str.length; i < strLen; i += 2) {
    var lower = str.charCodeAt(i);
    var upper = str.charCodeAt(i + 1);
    // debugger;
    bufView[index] = lower + (upper << 8);
    index++;
  }
  return bufView;
}


const subtractionTwoImage = (base64PixelData1, base64PixelData2) => {
  const data1 = window.atob(base64PixelData1);
  const data2 = window.atob(base64PixelData2);

  const bytes1 = str2pixelData(data1);
  const bytes2 = str2pixelData(data2);

  // 이미지 크기가 같은지 확인
  if (bytes1.length !== bytes2.length) {
    throw new Error("Images must have the same dimensions");
  }

  // 픽셀 단위로 뺄셈 수행
  const resultBytes = new Int16Array(bytes1.length);
  
  for (let i = 0; i < bytes1.length; i++) {
    // 픽셀 값이 0 미만이 되지 않도록 처리
    
    resultBytes[i] = clamp(bytes1[i] - bytes2[i] + 16384, 0, 32767) ;
  }
  
  return resultBytes;
}

export const getPixelDataFromBase64 = (base64PixelData, secondData) => {
  if(secondData) {
    return subtractionTwoImage(base64PixelData, secondData);
  }

  var pixelDataAsString = window.atob(base64PixelData);
  var pixelData = str2pixelData(pixelDataAsString);
  return pixelData;
}

export const getPixelDataFromBase64PET = (base64PixelData) => {
  var pixelDataAsString = window.atob(base64PixelData);
  var pixelData = str2pixelDataPET(pixelDataAsString);
  return pixelData;
}

export const getPixelDataFromBase64PETsagittal = (base64PixelData) => {
  var pixelDataAsString = window.atob(base64PixelData);
  var pixelData = str2pixelDataPETsagittal(pixelDataAsString);
  return pixelData;
}

export const str2pixelDataPETsagittal = (str) => {
  var buf = new ArrayBuffer(str.length); // 2 bytes for each char
  var bufView = new Int16Array(buf);
  var index = 0;
  for (var i = 0, strLen = str.length; i < strLen; i += 2) {
    var lower = str.charCodeAt(i);
    var upper = str.charCodeAt(i + 1);
    bufView[index] = lower + (upper << 8);
    index++;
  }
  const originArr = Array.from(bufView)
  let Arr2D = []
  while (originArr.length) Arr2D.push(originArr.splice(0, 65));
  // const newArr = scaleConcat(Arr2D, 2);
  // const newArr = LinearInterp2D(Arr2D, 2);
  const newArr = CubicInterp2D(Arr2D);
  // debugger;
  // const newArr = interpolateArray(Arr2D, Arr2D.length *2);
  bufView = Int16Array.from(newArr.flat())
  return bufView;
}

export const str2pixelDataPET = (str) => {
  var buf = new ArrayBuffer(str.length); // 2 bytes for each char
  var bufView = new Int16Array(buf);
  // var bufView2 = new Int16Array(buf);
  var index = 0;
  for (var i = 0, strLen = str.length; i < strLen; i += 2) {
    var lower = str.charCodeAt(i);
    var upper = str.charCodeAt(i + 1);
    bufView[index] = lower + (upper << 8);
    index++;
  }

  const originArr = Array.from(bufView)
  let Arr2D = []
  while (originArr.length) Arr2D.push(originArr.splice(0, 66));
  // while(newData.length) newArr2D.push(newData.splice(0,66*2-1));


  // const newArr = scaleConcat(Arr2D, 2);
  // const newArr = LinearInterp2D(Arr2D, 2);
  const newArr = CubicInterp2D(Arr2D);
  bufView = Int16Array.from(newArr.flat())
  return bufView;
}

export const closeSingleFile = (dispatch, singleFile, productName) => {
  dispatch(actionsControl.closeFileThunk(productName, singleFile));
}

export const getTargetPathOnWorklist = (currentPage) => {
  if (currentPage === 'dashboard' || currentPage === 'upload' || currentPage === 'setting') {
    return 'view';
  } else {
    return currentPage;
  }
}

// export const amyloidCSVFormatFromWorklist = (settingOfProduct, filteredFileList, productCoreItem, defaultAtlas,godThanksSUVR, godThanksCentiloid) => {
//   const selectedAll = filteredFileList.filter(({Group, Select})=>Select===true && Group !== 0);
//   if (selectedAll.length === 0) {
//     alert('No file selected on the worklist, please select file');
//   } else {
//     const bottomCards = productCoreItem["analysis"].bottom;
//     const srcSUVR = bottomCards[1].cards[0].content.filter(({atlas})=>atlas===defaultAtlas);
//     const srcCentiloid = bottomCards[1].cards[1].content.filter(({atlas})=>atlas===defaultAtlas);
//     const structuredData = selectedAll.reduce((arr, srcData, idx)=>{

//       const tracerName = srcData['Tracer'];
//       const refName = settingOfProduct.defaultRef[tracerName];
//       const shortRefName = getShortName(refName);
//       const ref = {fullName:refName, shortName:shortRefName};
//       const structData = structAmyloidCSV({ref, atlas:defaultAtlas, srcData:srcData, srcSUVR:srcSUVR, srcCentiloid:srcCentiloid, godThanksSUVR, godThanksCentiloid}); 
//       return idx === 0 ? arr=[...structData.headRows, ...structData.bodyRows]:arr=[...arr, ...structData.bodyRows];
//     },[])
//     const fname = 'fnameForWorklist.csv'
//     generateCSV({data:structuredData, transpose:true, fname})
//   }
// }

export const getShortName = (refName) => {
  if (refName === "Whole cerebellum") {
    const shortName = "wcrbll"
    return shortName;
  } else if (refName === "Cerebellar gray matter") {
    const shortName = "crbllGM"
    return shortName;
  } else if (refName === "Pons") {
    const shortName = "pons"
    return shortName;
  } else if (refName === "Cerebral white matter") {
    const shortName = "cerebralWM"
    return shortName;
  } else if (refName === "Occipital cortex") {
    const shortName = "occi"
    return shortName;
  } else if (refName === "Global cortex") {
    const shortName = "global"
    return shortName;
  } else {
    const shortName = "wcrbll"
    return shortName;
  }
}

export const datCSVFormatFromWorklist = (settingOfProduct, filteredFileList, productCoreItem, defaultAtlas, godThanksSBR, godThanksSemiquatification) => {
  const selectedAll = filteredFileList.filter(({ Group, Select }) => Select === true && Group !== 0);
  if (selectedAll.length === 0) {
    alert('No file is seleted. ');
  } else {
    const bottomCards = productCoreItem["analysis"].bottom;
    const srcSBR = bottomCards[1].cards[0].content.filter(({ atlas }) => atlas === defaultAtlas);
    const srcSemiquant = bottomCards[1].cards[1].content.filter(({ atlas }) => atlas === defaultAtlas);
    const structuredData = selectedAll.reduce((arr, srcData, idx) => {
      const tracerName = srcData['Tracer'];
      const refName = settingOfProduct.defaultRef[tracerName];
      const shortRefName = getShortName(refName);
      const ref = { fullName: refName, shortName: shortRefName };
      const structData = structDATCSV({ ref, atlas: defaultAtlas, srcData: srcData, srcSBR: srcSBR, srcSemiquant: srcSemiquant, godThanksSBR, godThanksSemiquatification });
      return idx === 0 ? arr = [...structData.headRows, ...structData.bodyRows] : arr = [...arr, ...structData.bodyRows];
    }, [])
    const fname = 'brtnx_quantified.csv'
    generateCSV({ data: structuredData, transpose: true, fname })
  }
}

export const fdgCSVFormatFromWorklist = (filteredFileList, productCoreItem, defaultAtlas) => {
  alert('fdgCSVFormat')
}

export const tauCSVFormatFromWorklist = (filteredFileList, productCoreItem, defaultAtlas) => {
  alert('tauCSVFormat')
}

export const openGroupFiles = ({ targetPage, completedFiles, selectedFileID, productName, dispatch, history }) => {
  dispatch(actionsControl.openFilesWithFileSelectThunk(productName, completedFiles, selectedFileID));
  history.push(`/${productName}/${targetPage}/${selectedFileID}`);
}

export const isAnySelected = (selectedFiles) => {  
  return selectedFiles.length > 0 && selectedFiles.some(el => el.Complete);
}
 
export const isAllCompleteandSelected = (selectedFiles, lengthCheckFn = (length) => length > 0) => {  
  const isAllComplete = selectedFiles.every(el => el.Complete === true);

  return lengthCheckFn(selectedFiles.length) && isAllComplete;
}

export const removesessionStorage = () => {
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("username");
  
  if (process.env.REACT_APP_USE_REFRESH_TOKEN === 'true') {
    sessionStorage.removeItem("refreshToken");
    sessionStorage.removeItem("expire");
  }
}

export const getCanvasBlob = (canvas) => {
  return new Promise(function (resolve, reject) {
    canvas.toBlob(function (blob) {
      resolve(blob)
    }, 'image/jpeg')
  })
}

export const generateJPEG = async (elRefs, func, func2, func3) => {

  func(true)
  // debugger;
  //* worklist 에서 보낸 elrefs랑 받는 elrefs가 다름 확인필요 ㅠㅠ..
  const zip = new JSZip();
  const totalFiles = elRefs.length;
  const eachPages = elRefs[0].length
  const totalPages = totalFiles * eachPages;
  let count = 0;
  const promiseAll = Promise.all(elRefs.map(async (elRef, fileNum) => {
    const PatientID = elRef[0].current.childNodes[0].childNodes[2].childNodes[0].childNodes[1].childNodes[0].childNodes[1].childNodes[1].childNodes[0].textContent
    const PatientName = elRef[0].current.childNodes[0].childNodes[2].childNodes[0].childNodes[1].childNodes[0].childNodes[0].childNodes[1].childNodes[0].textContent
    const tracerName = (elRef[0].current.childNodes[0].childNodes[2].childNodes[0].childNodes[1].childNodes[0].childNodes[5].childNodes[1].childNodes[0].textContent).replace('\u00B9\u2078', '18').replace('\u2079\u2079\u1d50Tc', '99mtc')
    const AcquisitionDateTime = elRef[0].current.childNodes[0].childNodes[2].childNodes[0].childNodes[1].childNodes[0].childNodes[4].childNodes[1].childNodes[0].textContent
    const refName = (elRef[0].current.childNodes[0].childNodes[3].childNodes[1].textContent).split(':').at(-1).trim()



    const handleTask = async (pageNum, ref, func3) => {
      const fname = fileNum + "_" + PatientID + "_" + PatientName + "_" + tracerName + "_" + AcquisitionDateTime + "_" + refName + "_" + pageNum + ".jpg"
      console.log('jpg:', fname)

      // const currentPage = fileNum * eachPages + (pageNum+1)
      count += 1
      console.log(count, totalPages, (count / totalPages * 100).toFixed(0))
      const currentPercent = (count / totalPages * 100).toFixed(0)
      func3({ message: "Downloading...", percent: currentPercent, target: "JPEG" })
      // func3(currentPercent+"%")

      const canvasItem = await html2canvas(ref.current, { allowTaint: true, useCORS: true });
      const blob = getCanvasBlob(canvasItem);
      zip.file(fname, blob);
    }

    for (const [pageNum, ref] of elRef.entries()) {
      await handleTask(pageNum, ref, func3)
    }
    return true;


    // const finalResult = Promise.all(elRef.map( async (ref,pageNum)=>{

    //   const fname = fileNum+"_"+PatientID +"_"+PatientName+"_"+tracerName+"_"+AcquisitionDateTime+"_"+refName+"_"+pageNum+".jpg"
    //   console.log('jpg:', fname)

    //   const currentPage = fileNum * eachPages + (pageNum+1)
    //   const currentPercent = (currentPage/totalPages*100).toFixed(0)
    //   console.log(currentPercent, currentPage, totalPages)
    //   setTimeout(()=>{
    //     func3(currentPercent+"%")
    //   },0)

    //   const canvasItem = await html2canvas(ref.current, {allowTaint:true, useCORS:true});
    //   const blob = getCanvasBlob(canvasItem);
    //   zip.file(fname, blob);
    // }))

    // return finalResult;


  }))

  promiseAll.then(() => {
    console.log("complete: ", promiseAll);
    const currentPercent = 100
    setTimeout(() => {
      func3({ message: "Downloading...", percent: currentPercent, target: "JPEG" })
    }, 0)
    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, 'brtnx(jpg).zip');
      func(false)
      func2(false)
    });
  })
}

export const structAmyloidCSV = ({ ref, atlas, srcData, godThanksSUVR, godThanksCentiloid, defaultCSVFormat } = {}) => {
  let godThanksFormating = godThanksSUVR.items.reduce((arr0, v0) => {
    arr0.region.push(v0.Region)
    arr0.subRegion.push(v0.Region)
    arr0["Total SUVR"].push(v0.Total)
    arr0["Left SUVR"].push(v0.Left)
    arr0["Right SUVR"].push(v0.Right)

    v0.subItem.forEach(v1 => {
      arr0.region.push(v0.Region)
      arr0.subRegion.push(v1.Region)
      arr0["Total SUVR"].push(v1.Total)
      arr0["Left SUVR"].push(v1.Left)
      arr0["Right SUVR"].push(v1.Right)
    })

    return arr0
  }, { region: [], subRegion: [], "Total SUVR": [], "Left SUVR": [], "Right SUVR": [] })



  let godThanksCentiloidValues = godThanksCentiloid.items.reduce((arr0, v0) => {
    arr0["Total Centiloid"].push(v0.Total)
    arr0["Left Centiloid"].push(v0.Left)
    arr0["Right Centiloid"].push(v0.Right)
    v0.subItem.forEach(v1 => {
      arr0["Total Centiloid"].push(v1.Total)
      arr0["Left Centiloid"].push(v1.Left)
      arr0["Right Centiloid"].push(v1.Right)
    })
    return arr0
  }, { "Total Centiloid": [], "Left Centiloid": [], "Right Centiloid": [] })


  const SUVR = defaultCSVFormat[1].state
  const Centil = defaultCSVFormat[2].state
  const Total = defaultCSVFormat[0].subOption[0].state;
  const LR = defaultCSVFormat[0].subOption[1].state;

  const statusTableObj = {
    "ST": { state: SUVR && Total, obj: godThanksFormating, targetArr: ['Total SUVR'] },
    "SLR": { state: SUVR && LR, obj: godThanksFormating, targetArr: ['Left SUVR', 'Right SUVR'] },
    "CT": { state: Centil && Total, obj: godThanksCentiloidValues, targetArr: ['Total Centiloid'] },
    "CLR": { state: Centil && LR, obj: godThanksCentiloidValues, targetArr: ['Left Centiloid', 'Right Centiloid'] },
  }

  Object.values(statusTableObj).forEach(val => {
    const state = val.state;
    const obj = val.obj; //str
    const targetArr = val.targetArr; // arr
    targetArr.forEach(target => {
      if (!state) delete obj[target]
    })
  })


  // console.log("after SUVR",godThanksFormating)
  // console.log("after Centiloid", godThanksCentiloidValues)




  // const columnsSUVR = srcSUVR.reduce((columnArr, item)=>{
  //   columnArr.push({'Patient ID':srcData.PatientID, Type: "Left SUVR", var: item.varname.Left, lobe:item.fullname, fullname: item.fullname})
  //   columnArr.push({'Patient ID':srcData.PatientID, Type: "Right SUVR", var: item.varname.Right, lobe:item.fullname, fullname: item.fullname})
  //   const subItem = item.subItem.reduce((columnArr2, subitem)=>{
  //     columnArr2.push({'Patient ID':srcData.PatientID, Type: "Left SUVR", var: subitem.varname.Left, lobe: item.fullname, fullname: subitem.fullname})
  //     columnArr2.push({'Patient ID':srcData.PatientID, Type: "Right SUVR", var: subitem.varname.Right, lobe: item.fullname, fullname: subitem.fullname})
  //     return columnArr2
  //   },[])
  //   columnArr=[...columnArr, ...subItem]
  //   return columnArr
  // },[])


  // const columnsCentiloid = srcCentiloid.reduce((columnArr, item)=>{
  //   columnArr.push({'Patient ID':srcData.PatientID, Type: "Left Centiloid", var: item.varname.Left, lobe:item.fullname, fullname: item.fullname})
  //   columnArr.push({'Patient ID':srcData.PatientID, Type: "Right Centiloid", var: item.varname.Right, lobe:item.fullname, fullname: item.fullname})
  //   const subItem = item.subItem.reduce((columnArr2, subitem)=>{
  //     columnArr2.push({'Patient ID':srcData.PatientID, Type: "Left Centiloid", var: subitem.varname.Left, lobe: item.fullname, fullname: subitem.fullname})
  //     columnArr2.push({'Patient ID':srcData.PatientID, Type: "Right Centiloid", var: subitem.varname.Right, lobe: item.fullname, fullname: subitem.fullname})
  //     return columnArr2
  //   },[])
  //   columnArr=[...columnArr, ...subItem]
  //   return columnArr
  // },[])

  // const columnIndex = columnsSUVR.filter(({Type})=>Type==='Left SUVR').reduce((columnArr, item)=>{
  //   columnArr[0].push(item.lobe)
  //   columnArr[1].push(item.fullname)
  //   return columnArr
  // },[[],[]])

  const columnAllIndex = [
    ["fileID", "Patient ID","Study date","Patient Name", "Atlas", "Reference", "Region", ...godThanksFormating.region],
    ["fileID", "Patient ID","Study date","Patient Name", "Atlas", "Reference", "Subregion", ...godThanksFormating.subRegion],
  ]



  // const columnAllIndex = [
  //   ["fileID", "Patient ID", "Patient Name", "Atlas", "Reference", "Region",   ...columnIndex[0]],
  //   ["fileID", "Patient ID", "Patient Name", "Atlas", "Reference", "Subregion",...columnIndex[1]],
  // ]
  // const columns = [...columnsSUVR, ...columnsCentiloid]

  const CSV_SUVRKeys = Object.keys(godThanksFormating)
  const rowIndexSUVR_source = ['Total SUVR', 'Left SUVR', 'Right SUVR']
  const rowIndexSUVR = CSV_SUVRKeys.filter(x => rowIndexSUVR_source.includes(x))



  const CSV_CentiloidKeys = Object.keys(godThanksCentiloidValues)
  const rowIndexCentiloid_source = ['Total Centiloid', 'Left Centiloid', 'Right Centiloid']
  const rowIndexCentiloid = CSV_CentiloidKeys.filter(x => rowIndexCentiloid_source.includes(x))

  // const rowIndex = ['Total SUVR', 'Left SUVR', 'Right SUVR', 'Total Centiloid', 'Left Centiloid','Right Centiloid']
  const rowsSUVR = rowIndexSUVR.map(item1 => {
    const rowSUVR = godThanksFormating[item1]
    return [srcData.fileID, srcData.PatientID,srcData.AcquisitionDateTime ,srcData.PatientName, atlas, ref.fullName, item1, ...rowSUVR]
  })
  const rowsCentiloid = rowIndexCentiloid.map(item1 => {
    const rowCentiloid = godThanksCentiloidValues[item1]
    return [srcData.fileID, srcData.PatientID,srcData.AcquisitionDateTime ,srcData.PatientName, atlas, ref.fullName, item1, ...rowCentiloid]
  })

  const finalRowsBody = [
    // ...[srcData.fileID, srcData.PatientID, srcData.PatientName, atlas, ref.fullName,...rowsSUVR], 
    // ...[srcData.fileID, srcData.PatientID, srcData.PatientName, atlas, ref.fullName,...rowsCentiloid]
    ...rowsSUVR,
    ...rowsCentiloid
  ]

  return {
    headRows: columnAllIndex,
    bodyRows: finalRowsBody,
  }
}

export const structDATCSV = ({ ref, atlas, srcData, godThanksSBR, godThanksSemiquatification, defaultCSVFormat } = {}) => {

  const godThanksFormating = godThanksSBR.items.reduce((arr0, v0) => {
    arr0.region.push(v0.Region)
    arr0.subRegion.push(v0.Region)
    arr0["Total"].push(v0.Total)
    arr0["Left"].push(v0.Left)
    arr0["Right"].push(v0.Right)
    v0.subItem.forEach(v1 => {
      arr0.region.push(v0.Region)
      arr0.subRegion.push(v1.Region)
      arr0["Total"].push(v1.Total)
      arr0["Left"].push(v1.Left)
      arr0["Right"].push(v1.Right)
    })
    return arr0
  }, { region: [], subRegion: [], "Total": [], "Left": [], "Right": [] })

  const godThanksSemiquatificationValues = godThanksSemiquatification.items.reduce((arr0, v0) => {
    arr0.region.push(v0.Measure)
    arr0.subRegion.push(v0.Region)
    arr0["Total"].push(v0.Total)
    arr0["Left"].push(v0.Left)
    arr0["Right"].push(v0.Right)
    v0.subItem.forEach(v1 => {
      arr0.region.push(v0.Region)
      arr0.subRegion.push(v1.Region)
      arr0["Total"].push(v1.Total)
      arr0["Left"].push(v1.Left)
      arr0["Right"].push(v1.Right)
    })
    return arr0
  }, { region: [], subRegion: [], "Total": [], "Left": [], "Right": [] })




  const SBR = defaultCSVFormat[1].state
  const Semiq = defaultCSVFormat[2].state
  const Total = defaultCSVFormat[0].subOption[0].state;
  const LR = defaultCSVFormat[0].subOption[1].state;

  const statusTableObj = {
    "ST": { state: SBR && Total, obj: godThanksFormating, targetArr: ["Total"] },
    "SLR": { state: SBR && LR, obj: godThanksFormating, targetArr: ["Left", 'Right'] },
    "CT": { state: Semiq && Total, obj: godThanksSemiquatificationValues, targetArr: ["Total"] },
    "CLR": { state: Semiq && LR, obj: godThanksSemiquatificationValues, targetArr: ["Left", "Right"] },
  }

  Object.values(statusTableObj).forEach(val => {
    const state = val.state;
    const obj = val.obj; //str
    const targetArr = val.targetArr; // arr
    targetArr.forEach(target => {
      if (!state) delete obj[target]
    })
  })


  // const srcSBR_Semiquant = [

  //   ...srcSBR,
  //   ...srcSemiquant
  // ]


  // const columnsSBR_Semiquant = srcSBR_Semiquant.reduce((columnArr, item)=>{
  //   columnArr.push({'Patient ID':srcData.PatientID, Type: "Left", var: item.varname.Left, lobe:item.fullname, fullname: item.fullname})
  //   columnArr.push({'Patient ID':srcData.PatientID, Type: "Right", var: item.varname.Right, lobe:item.fullname, fullname: item.fullname})
  //   const subItem = item.subItem.reduce((columnArr2, subitem)=>{
  //     columnArr2.push({'Patient ID':srcData.PatientID, Type: "Left", var: subitem.varname.Left, lobe: item.fullname, fullname: subitem.fullname})
  //     columnArr2.push({'Patient ID':srcData.PatientID, Type: "Right", var: subitem.varname.Right, lobe: item.fullname, fullname: subitem.fullname})
  //     return columnArr2
  //   },[])
  //   columnArr=[...columnArr, ...subItem]
  //   return columnArr
  // },[])

  // const columnIndex = columnsSBR_Semiquant.filter(({Type})=>Type==='Left').reduce((columnArr, item)=>{
  //   columnArr[0].push(item.lobe)
  //   columnArr[1].push(item.fullname)
  //   return columnArr
  // },[[],[]])

  const columnAllIndex = [
    ["fileID", "Patient ID", "Study date","Patient Name", "Atlas", "Reference", "Region", ...godThanksFormating.region, ...godThanksSemiquatificationValues.region],
    ["fileID", "Patient ID", "Study date","Patient Name", "Atlas", "Reference", "Subregion", ...godThanksFormating.subRegion],
  ]



  // const columns = [...columnsSBR_Semiquant]
  const CSV_Keys = Object.keys(godThanksFormating)
  const rowIndex_source = ['Total', 'Left', 'Right']
  const rowIndex = CSV_Keys.filter(x => rowIndex_source.includes(x))

  const rows = rowIndex.map(item1 => {
    // const row = columns.filter(({Type})=>Type===item1).map(item2=>{
    //   return srcData[item2.var]
    // })
    const rowSBR = godThanksFormating[item1]
    const rowSemiquatification = godThanksSemiquatificationValues[item1]
    return [srcData.fileID, srcData.PatientID, srcData.AcquisitionDateTime, srcData.PatientName, atlas, ref.fullName, item1, ...rowSBR, ...rowSemiquatification]
  })

  return {
    headRows: columnAllIndex,
    bodyRows: rows,
  }
}

export const structFDGTauCSV = ({ ref, atlas, srcData, godThanksSUVR, defaultCSVFormat } = {}) => {
  const godThanksFormating = godThanksSUVR.items.reduce((arr0, v0) => {
    arr0.region.push(v0.Region)
    arr0.subRegion.push(v0.Region)
    arr0["Total SUVR"].push(v0.Total)
    arr0["Left SUVR"].push(v0.Left)
    arr0["Right SUVR"].push(v0.Right)
    v0.subItem.forEach(v1 => {
      arr0.region.push(v0.Region)
      arr0.subRegion.push(v1.Region)
      arr0["Total SUVR"].push(v1.Total)
      arr0["Left SUVR"].push(v1.Left)
      arr0["Right SUVR"].push(v1.Right)
    })
    return arr0
  }, { region: [], subRegion: [], "Total SUVR": [], "Left SUVR": [], "Right SUVR": [] })

  const SUVR = defaultCSVFormat[1].state
  const Total = defaultCSVFormat[0].subOption[0].state;
  const LR = defaultCSVFormat[0].subOption[1].state;

  const statusTableObj = {
    "ST": { state: SUVR && Total, obj: godThanksFormating, targetArr: ['Total SUVR'] },
    "SLR": { state: SUVR && LR, obj: godThanksFormating, targetArr: ['Left SUVR', 'Right SUVR'] },
  }

  Object.values(statusTableObj).forEach(val => {
    const state = val.state;
    const obj = val.obj; //str
    const targetArr = val.targetArr; // arr
    targetArr.forEach(target => {
      if (!state) delete obj[target]
    })
  })

  console.log(godThanksFormating)
  // const refFactor = (()=>{
  //   if (ref.shortName == "pons" || ref.shortName == "cerebralWM") return srcData["ratio_gry2pons"];
  //   else if (ref.shortName == "wcrbll") return srcData["ratio_gry2crbl"];
  //   else return 1;
  // })();

  // const columnsSUVR = srcSUVR.reduce((columnArr, item)=>{
  //   columnArr.push({'Patient ID':srcData.PatientID, Type: "Left SUVR", var: item.varname.Left, lobe:item.fullname, fullname: item.fullname})
  //   columnArr.push({'Patient ID':srcData.PatientID, Type: "Right SUVR", var: item.varname.Right, lobe:item.fullname, fullname: item.fullname})
  //   const subItem = item.subItem.reduce((columnArr2, subitem)=>{
  //     columnArr2.push({'Patient ID':srcData.PatientID, Type: "Left SUVR", var: subitem.varname.Left, lobe: item.fullname, fullname: subitem.fullname})
  //     columnArr2.push({'Patient ID':srcData.PatientID, Type: "Right SUVR", var: subitem.varname.Right, lobe: item.fullname, fullname: subitem.fullname})
  //     return columnArr2
  //   },[])
  //   columnArr=[...columnArr, ...subItem]
  //   return columnArr
  // },[])

  // const columnIndex = columnsSUVR.filter(({Type})=>Type==='Left SUVR').reduce((columnArr, item)=>{
  //   columnArr[0].push(item.lobe)
  //   columnArr[1].push(item.fullname)
  //   return columnArr
  // },[[],[]])


  const columnAllIndex = [
    ["fileID", "Patient ID", "Study date","Patient Name", "Atlas", "Reference", "Region", ...godThanksFormating.region],
    ["fileID", "Patient ID", "Study date","Patient Name", "Atlas", "Reference", "Subregion", ...godThanksFormating.subRegion],
  ]
  // const columnAllIndex = [
  //   ["fileID", "Patient ID", "Patient Name", "Atlas", "Reference", "Region",   ...columnIndex[0]],
  //   ["fileID", "Patient ID", "Patient Name", "Atlas", "Reference", "Subregion",...columnIndex[1]],
  // ]
  // const columns = [...columnsSUVR, ...columnsCentiloid]

  const CSV_Keys = Object.keys(godThanksFormating)
  const rowIndex_source = ['Total SUVR', 'Left SUVR', 'Right SUVR']
  const rowIndex = CSV_Keys.filter(x => rowIndex_source.includes(x))

  // const rowIndexSUVR = ['Total SUVR', 'Left SUVR', 'Right SUVR']
  // const rowIndex = ['Total SUVR', 'Left SUVR', 'Right SUVR', 'Total Centiloid', 'Left Centiloid','Right Centiloid']
  const rowsSUVR = rowIndex.map(item1 => {
    const rowSUVR = godThanksFormating[item1]
    return [srcData.fileID, srcData.PatientID, srcData.AcquisitionDateTime, srcData.PatientName, atlas, ref.fullName, item1, ...rowSUVR]
  })
  //  debugger;
  const finalRowsBody = [
    // ...[srcData.fileID, srcData.PatientID, srcData.PatientName, atlas, ref.fullName,...rowsSUVR], 
    // ...[srcData.fileID, srcData.PatientID, srcData.PatientName, atlas, ref.fullName,...rowsCentiloid]
    ...rowsSUVR,
  ]

  return {
    headRows: columnAllIndex,
    bodyRows: finalRowsBody,
  }
}

export const generateCSV = async ({ data, transpose = false, fname } = {}) => {
  const transData = transpose ? data[0].map((_, colIndex) => data.map(row => row[colIndex])) : data;
  // debugger;
  // const csvContent = transData.map(e => {return e.join(";")})
  // const textEncoder = new TextEncoder('windows-1252');
  // const csvContentEncoded = textEncoder.encode(csvContent)
  // var blob = new Blob([csvContentEncoded], {type: 'text/csv;charset=windows-1252;'});
  // saveAs(blob, 'some-data.csv');

  // debugger;
  const csvContent = "data:text/csv;charset=utf-8," + transData.map(e => {
    return e.join(",")
  })
    .join("\r\n");
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", fname);
  // link.setAttribute("download", "brtnx_quantified.csv");
  document.body.appendChild(link);
  link.click();

}

// analysisAmyloidtable1, analysisDATtable1 merge
export const analysisTable1 = ({
    settingOfProduct,
    typeSelect,
    tableItems,
    selectedFile,
    defaultAtlas,
    RF,
    isSelectMultiAtlas,
  } = {}) => {
    const calculateWeightedAverage = (varNames, weights) =>
      varNames.reduce(
        (acc, el, idx) => acc + selectedFile[el] * weights[idx],
        0,
      );

    const selectedAtlas = (atlas, multiAtlas) => {
      return isSelectMultiAtlas
        ? multiAtlas === defaultAtlas
        : atlas === defaultAtlas;
    };

    const filteredList = settingOfProduct.list.filter(
      ({ level, type, atlas, isFolded, multiAtlas }) =>
        selectedAtlas(atlas, multiAtlas) &&
        level === 0 &&
        type === typeSelect &&
        !isFolded,
    );

    const result = filteredList.map((item, idx) => {
      const tableItem = tableItems.tableHead.reduce((obj, head) => {
        if (typeof item.varname.Left === 'string') {
          // 서버에서 직접 받는 값들
          switch (head) {
            case 'Region':
              obj[head] = item.fullname;
              break;
            case 'Total':
              const oldValue =
                (selectedFile[item.varname.Left] +
                  selectedFile[item.varname.Right]) /
                2;
              obj[head] = oldValue * RF;
              break;
            case 'Left':
              obj[head] = selectedFile[item.varname.Left] * RF;
              break;
            case 'Right':
              obj[head] = selectedFile[item.varname.Right] * RF;
              break;
            default:
              break;
          }
          return obj;
        } else {
          // 프론트에서 조합하는 값들
          const RightWeightAcc = item.weight.Right.reduce(
            (acc, val) => acc + val,
            0,
          );
          const LeftWeightAcc = item.weight.Left.reduce(
            (acc, val) => acc + val,
            0,
          );
          const LeftAvg = calculateWeightedAverage(
            item.varname.Left,
            item.weight.Left,
          );

          const RightAvg = calculateWeightedAverage(
            item.varname.Right,
            item.weight.Right,
          );

          switch (head) {
            case 'Region':
              obj[head] = item.fullname;
              break;
            case 'Total':
              const weightedMean2 =
                (RightAvg + LeftAvg) / (RightWeightAcc + LeftWeightAcc);
              obj[head] = weightedMean2 * RF;
              break;
            case 'Left':
              obj[head] = (LeftAvg / LeftWeightAcc) * RF;
              break;
            case 'Right':
              obj[head] = (RightAvg / RightWeightAcc) * RF;
              break;
            default:
              break;
          }

          return obj;
        }
      }, {});

      const subItemList = settingOfProduct.list.filter(
        ({ id, belongToForeignKey, level }) =>
          level === 1 &&
          id !== item.primaryKey &&
          belongToForeignKey === item.primaryKey,
      );

      if (subItemList.length !== 0) {
        tableItem['subItem'] = subItemList.map((subItem) => {
          return tableItems.tableHead.reduce((obj, head) => {
            switch (head) {
              case 'Region':
                obj[head] = subItem.fullname;
                break;
              case 'Total':
                const newTotal =
                  ((selectedFile[subItem.varname.Left] * subItem.weight.Left +
                    selectedFile[subItem.varname.Right] *
                      subItem.weight.Right) /
                    (subItem.weight.Left + subItem.weight.Right)) *
                  RF;
                obj[head] = newTotal;
                break;
              case 'Left':
                obj[head] = selectedFile[subItem.varname.Left] * RF;
                break;
              case 'Right':
                obj[head] = selectedFile[subItem.varname.Right] * RF;
                break;
              default:
                break;
            }
            return obj;
          }, {});
        });
      } else {
        tableItem['subItem'] = [];
      }
      tableItem['id'] = idx;
      return tableItem;
    });

    return { tableHead: tableItems.tableHead, items: result };
  };

export const analysisAmyloidDATcard2_2 = ({ settingOfProduct, typeSelect, tableItems, selectedFile, defaultAtlas, } = {}) => {  
  const result = settingOfProduct.list.filter(({ level, type, atlas, isFolded }) => atlas === defaultAtlas && level === 0 && type === typeSelect && !isFolded).map((item, idx) => {
    const tableItem = tableItems.tableHead.reduce((obj, head, idx) => {
      if (typeof (item.varname.Left) === 'string') {
        if (head === 'Region')
          obj[head] = item.fullname
        else if (head === 'Measure')
          obj[head] = item.fullname
        else if (head === 'Total')
          obj[head] = (selectedFile[item.varname.Left] + selectedFile[item.varname.Right]) / 2
        else if (head === 'Left')
          obj[head] = selectedFile[item.varname.Left]
        else if (head === 'Right')
          obj[head] = selectedFile[item.varname.Right]
        else if (head === 'Centiloid')
          obj[head] = (selectedFile[item.varname.Left] + selectedFile[item.varname.Right]) / 2
        return obj
      } else {
        const RightWeightAcc = item.weight.Right.reduce((sum, val) => {
          return sum + val
        }, 0)
        const LeftWeightAcc = item.weight.Left.reduce((sum, val) => {
          return sum + val
        }, 0)
        // debugger;
        if (head === 'Region')
          obj[head] = item.fullname
        else if (head === 'Total' || head === 'Centiloid')
          //TODO : 펼쳐진 코드 정리
          obj[head] = (() => {
            const RightAvg = item.varname.Right.reduce((acc, el, idx) => {
              const weightRight = item.weight.Right[idx]
              acc += selectedFile[el] * weightRight
              return acc
            }, 0)
            const LeftAvg = item.varname.Left.reduce((acc, el, idx) => {
              const weightLeft = item.weight.Left[idx]
              acc += selectedFile[el] * weightLeft
              return acc
            }, 0)
            const weightedMean2 = (RightAvg + LeftAvg) / (RightWeightAcc + LeftWeightAcc)
            return (weightedMean2)
          })()
        else if (head === 'Left')
          obj[head] = item.varname.Left.reduce((acc, el, idx) => {
            const weightLeft = item.weight.Left[idx]
            acc += selectedFile[el] * weightLeft
            return acc
          }, 0) / LeftWeightAcc
        else if (head === 'Right')
          obj[head] = item.varname.Right.reduce((acc, el, idx) => {
            const weightRight = item.weight.Right[idx]
            acc += selectedFile[el] * weightRight
            return acc
          }, 0) / RightWeightAcc
        return obj

      }
    }, {})

    const subItem = settingOfProduct.list.filter(({ id, belongToForeignKey, level }) => level === 1 && id !== item.primaryKey && belongToForeignKey === item.primaryKey)
    if (subItem.length !== 0) {
      tableItem['subItem'] = subItem.map(item2 => {
        return tableItems.tableHead.reduce((obj, head, idx) => {
          if (head === 'Region')
            obj[head] = item2.fullname
          else if (head === 'Measure')
            obj[head] = item.fullname
          else if (head === 'Total') {
            // const oldTotal = (selectedFile[item2.varname.Left] + selectedFile[item2.varname.Right]) / 2
            let newTotal
            try {
              newTotal = (selectedFile[item2.varname.Left] * item2.weight.Left + selectedFile[item2.varname.Right] * item2.weight.Right) / (item2.weight.Left + item2.weight.Right)
            } catch (e) {
              console.log(e)
            }
            // if (Math.abs(oldTotal - newTotal) > 1.0) console.error('please check weighted sum process')
            obj[head] = newTotal
          } else if (head === 'Left')
            obj[head] = selectedFile[item2.varname.Left]
          else if (head === 'Right')
            obj[head] = selectedFile[item2.varname.Right]
          else if (head === 'Centiloid') {
            let newTotal
            try {
              newTotal = (selectedFile[item2.varname.Left] * item2.weight.Left + selectedFile[item2.varname.Right] * item2.weight.Right) / (item2.weight.Left + item2.weight.Right)
            } catch (e) {
              console.log(e)
            }
            obj[head] = newTotal
          }
          return obj
        }, {})
      })
    } else {
      tableItem['subItem'] = []
    }

    tableItem['id'] = idx
    return tableItem
  })
  return { tableHead: tableItems.tableHead, items: result };
}

export const analysisDATcard2_2 = ({ settingOfProduct, typeSelect, tableItems, selectedFile, defaultAtlas, } = {}) => {
  // if (settingOfProduct.list.filter(el=>el.isFolded).length !== 0){

  //   // TODO: setting.list 에 isFolded가 centiloid에 대해서는 반영되지 않음
  // }
  const result = settingOfProduct.list.filter(({ level, type, atlas, isFolded, multiAtlas }) => multiAtlas === defaultAtlas && level === 0 && type === typeSelect && !isFolded).map((item, idx) => {
    // debugger;
    const tableItem = tableItems.tableHead.reduce((obj, head, idx) => {
      if (typeof (item.varname.Left) === 'string') {
        if (head === 'Region')
          obj[head] = item.fullname
        else if (head === 'Measure')
          obj[head] = item.fullname
        else if (head === 'Total')
          obj[head] = (selectedFile[item.varname.Left] + selectedFile[item.varname.Right]) / 2
        else if (head === 'Left')
          obj[head] = selectedFile[item.varname.Left]
        else if (head === 'Right')
          obj[head] = selectedFile[item.varname.Right]
        else if (head === 'Centiloid')
          obj[head] = (selectedFile[item.varname.Left] + selectedFile[item.varname.Right]) / 2
        return obj
      } else {
        const RightWeightAcc = item.weight.Right.reduce((sum, val) => {
          return sum + val
        }, 0)
        const LeftWeightAcc = item.weight.Left.reduce((sum, val) => {
          return sum + val
        }, 0)
        // debugger;
        if (head === 'Region')
          obj[head] = item.fullname
        else if (head === 'Total' || head === 'Centiloid')
          //TODO : 펼쳐진 코드 정리
          obj[head] = (() => {
            const RightAvg = item.varname.Right.reduce((acc, el, idx) => {
              const weightRight = item.weight.Right[idx]
              acc += selectedFile[el] * weightRight
              return acc
            }, 0)
            const LeftAvg = item.varname.Left.reduce((acc, el, idx) => {
              const weightLeft = item.weight.Left[idx]
              acc += selectedFile[el] * weightLeft
              return acc
            }, 0)
            const weightedMean2 = (RightAvg + LeftAvg) / (RightWeightAcc + LeftWeightAcc)
            return (weightedMean2)
          })()
        else if (head === 'Left')
          obj[head] = item.varname.Left.reduce((acc, el, idx) => {
            const weightLeft = item.weight.Left[idx]
            acc += selectedFile[el] * weightLeft
            return acc
          }, 0) / LeftWeightAcc
        else if (head === 'Right')
          obj[head] = item.varname.Right.reduce((acc, el, idx) => {
            const weightRight = item.weight.Right[idx]
            acc += selectedFile[el] * weightRight
            return acc
          }, 0) / RightWeightAcc
        return obj

      }
    }, {})

    const subItem = settingOfProduct.list.filter(({ id, belongToForeignKey, level }) => level === 1 && id !== item.primaryKey && belongToForeignKey === item.primaryKey)
    if (subItem.length !== 0) {
      tableItem['subItem'] = subItem.map(item2 => {
        return tableItems.tableHead.reduce((obj, head, idx) => {
          if (head === 'Region')
            obj[head] = item2.fullname
          else if (head === 'Measure')
            obj[head] = item.fullname
          else if (head === 'Total') {
            // const oldTotal = (selectedFile[item2.varname.Left] + selectedFile[item2.varname.Right]) / 2
            let newTotal
            try {
              newTotal = (selectedFile[item2.varname.Left] * item2.weight.Left + selectedFile[item2.varname.Right] * item2.weight.Right) / (item2.weight.Left + item2.weight.Right)
            } catch (e) {
              console.log(e)
            }
            // if (Math.abs(oldTotal - newTotal) > 1.0) console.error('please check weighted sum process')
            obj[head] = newTotal
          } else if (head === 'Left')
            obj[head] = selectedFile[item2.varname.Left]
          else if (head === 'Right')
            obj[head] = selectedFile[item2.varname.Right]
          else if (head === 'Centiloid') {
            let newTotal
            try {
              newTotal = (selectedFile[item2.varname.Left] * item2.weight.Left + selectedFile[item2.varname.Right] * item2.weight.Right) / (item2.weight.Left + item2.weight.Right)
            } catch (e) {
              console.log(e)
            }
            obj[head] = newTotal
          }
          return obj
        }, {})
      })
    } else {
      tableItem['subItem'] = []
    }

    tableItem['id'] = idx
    return tableItem
  })
  return { tableHead: tableItems.tableHead, items: result };
}


// NOTE reportAmyloidtable1, reportDATtable1 merge & refactoring - report, pdf 에서 사용
export const createReportTableData1 = (productName, options) => {
  const { settingOfProduct, typeSelect, defaultAtlas } = options;

  const filterCondition = (item) => {
    const commonConditions = item.atlas === defaultAtlas && item.report === true && item.type === typeSelect;

    if (productName === 'dat') {
      return commonConditions && item.multiAtlas === defaultAtlas;
    } else {
      return commonConditions && !item.isFolded;
    }
  };

  const filteredList = settingOfProduct.list.filter(filterCondition);

  return createRegionalSuvrTableData(filteredList, options);
};

const createRegionalSuvrTableData = (filteredList, {
  tableItems,
  selectedFile,
  RF,
} = {}) => {
  const calculateWeightedAverage = (varNames, weights) =>
    varNames.reduce((acc, el, idx) => acc + selectedFile[el] * weights[idx], 0);

  const result = filteredList.map((item, idx) => {
    const tableItem = tableItems.tableHead.reduce((obj, head) => {
      if (typeof item.varname.Left === 'string') {
        // 서버에서 직접 받는 값들
        switch (head) {
          case 'Region':
            obj[head] = item.fullname;
            break;
          case 'Total':
            const oldValue =
              (selectedFile[item.varname.Left] +
                selectedFile[item.varname.Right]) /
              2;
            obj[head] = oldValue * RF;
            break;
          case 'Left':
            obj[head] = selectedFile[item.varname.Left] * RF;
            break;
          case 'Right':
            obj[head] = selectedFile[item.varname.Right] * RF;
            break;
          default:
            break;
        }
        return obj
      } else {
        // 프론트에서 조합하는 값들
        const RightWeightAcc = item.weight.Right.reduce((sum, val) => {
          return sum + val;
        }, 0);
        const LeftWeightAcc = item.weight.Left.reduce((sum, val) => {
          return sum + val;
        }, 0);

        const LeftAvg = calculateWeightedAverage(
          item.varname.Left,
          item.weight.Left,
        );

        const RightAvg = calculateWeightedAverage(
          item.varname.Right,
          item.weight.Right,
        );

        switch (head) {
          case 'Region':
            obj[head] = item.fullname;
            break;
          case 'Total':
            const weightedMean2 =
              (RightAvg + LeftAvg) / (RightWeightAcc + LeftWeightAcc);
            obj[head] = weightedMean2 * RF;
            break;
          case 'Left':
            obj[head] = (LeftAvg / LeftWeightAcc) * RF;
            break;
          case 'Right':
            obj[head] = (RightAvg / RightWeightAcc) * RF;
            break;
          default:
            break;
        }

        return obj;
      }
    }, {});
    tableItem['subItem'] = [];
    tableItem['id'] = idx;
    return tableItem;
  });
  return { tableHead: tableItems.tableHead, items: result };
};



export const reportAmyloidDATtable2 = ({ settingOfProduct, typeSelect, tableItems, selectedFile, defaultAtlas, RF } = {}) => {
  // if (defaultAtlas.includes("Mal")){
    // debugger;
  //   console.log(subRegions)
  // }
  const newTableHead = tableItems.tableHead.filter(el => el !== 'Centiloid');
  const result = settingOfProduct.list.filter(({ level, type, atlas, report, isFolded }) => atlas === defaultAtlas && report && level === 0 && type === typeSelect && !isFolded).map((item, idx) => {
    const tableItem = newTableHead.reduce((obj, head, idx) => {
      if (typeof (item.varname.Left) === 'string') { // 서버에서 받은 값을 그대로 출력시키는 부분
        if (head === 'Region')
          obj[head] = item.fullname
        else if (head === 'Measure')
          obj[head] = item.fullname
        else if (head === 'Total')
          obj[head] = (selectedFile[item.varname.Left] + selectedFile[item.varname.Right]) / 2
        else if (head === 'Left')
          obj[head] = selectedFile[item.varname.Left]
        else if (head === 'Right')
          obj[head] = selectedFile[item.varname.Right]
        // else if (head==='Centiloid')
        //   obj[head]=(selectedFile[item.varname.Left] + selectedFile[item.varname.Right])/2
        return obj
      } else { // custom ROI 에 대해서만 동작하는 부분
        // if (head==='Region')
        //   obj[head]=item.fullname
        // else if (head==='Measure')
        //   obj[head]=item.fullname
        // else if (head==='Total')
        //   obj[head]=25
        // else if (head==='Left')
        //   obj[head]=26
        // else if (head==='Right')
        //   obj[head]=27
        // // else if (head==='Centiloid')
        // //   obj[head]=(selectedFile[item.varname.Left] + selectedFile[item.varname.Right])/2
        // return obj
        // debugger;
        const RightWeightAcc = item.weight.Right.reduce((sum, val) => {
          return sum + val
        }, 0)
        const LeftWeightAcc = item.weight.Left.reduce((sum, val) => {
          return sum + val
        }, 0)
        if (head === 'Region') {
          obj[head] = item.fullname
        } else if (head === 'Measure') {
          obj[head] = item.fullname
        }
        else if (head === 'Total') {
          //TODO : 펼쳐진 코드 정리
          obj[head] = (() => {
            const RightAvg = item.varname.Right.reduce((acc, el, idx) => {
              const weightRight = item.weight.Right[idx]
              acc += selectedFile[el] * weightRight
              return acc
            }, 0)
            const LeftAvg = item.varname.Left.reduce((acc, el, idx) => {
              const weightLeft = item.weight.Left[idx]
              acc += selectedFile[el] * weightLeft
              return acc
            }, 0)
            const weightedMean2 = (RightAvg + LeftAvg) / (RightWeightAcc + LeftWeightAcc)
            return weightedMean2
          })()
        } else if (head === 'Left')
          obj[head] = item.varname.Left.reduce((acc, el, idx) => {
            const weightLeft = item.weight.Left[idx]
            acc += selectedFile[el] * weightLeft
            return acc
          }, 0) / LeftWeightAcc
        else if (head === 'Right')
          obj[head] = item.varname.Right.reduce((acc, el, idx) => {
            const weightRight = item.weight.Right[idx]
            acc += selectedFile[el] * weightRight
            return acc
          }, 0) / RightWeightAcc
        return obj

      }
    }, {})

    const subItem = settingOfProduct.list.filter(({ id, belongToForeignKey, level }) => level === 1 && id !== item.primaryKey && belongToForeignKey === item.primaryKey)
    if (subItem.length !== 0) {
      tableItem['subItem'] = subItem.map(item2 => {
        return newTableHead.reduce((obj, head, idx) => {
          if (head === 'Region')
            obj[head] = item2.fullname
          else if (head === 'Measure')
            obj[head] = item.fullname
          else if (head === 'Total') {
            // const oldTotal = (selectedFile[item2.varname.Left] + selectedFile[item2.varname.Right]) / 2
            let newTotal
            try {
              newTotal = (selectedFile[item2.varname.Left] * item2.weight.Left + selectedFile[item2.varname.Right] * item2.weight.Right) / (item2.weight.Left + item2.weight.Right)
            } catch (e) {
              console.log(e)
            }
            // if (Math.abs(oldTotal - newTotal) > 1.0) console.error('please check weighted sum process')
            obj[head] = newTotal
          } else if (head === 'Left')
            obj[head] = selectedFile[item2.varname.Left]
          else if (head === 'Right')
            obj[head] = selectedFile[item2.varname.Right]
          // else if (head==='Centiloid')
          //   obj[head]=(selectedFile[item2.varname.Left] + selectedFile[item2.varname.Right])/2
          return obj
        }, {})
      })
    } else {
      tableItem['subItem'] = []
    }

    tableItem['id'] = idx
    return tableItem
  })
  return { tableHead: newTableHead, items: result };
}

export const applyReferenceFactor = (temp, selectedFile, settingOfProduct) => {
  // debugger;
  const tracerName = selectedFile['Tracer'];
  const refName = settingOfProduct.defaultRef[tracerName]
  const RF = RefFactor({ refName, ponsRF: selectedFile["ratio_gry2pons"], crblRF: selectedFile["ratio_gry2crbl"] })
  // const RF = (() => {
  //   if (refName === "Pons") return selectedFile["ratio_gry2pons"];
  //   else if (refName === "Whole cerebellum") return selectedFile["ratio_gry2crbl"];
  //   else if (refName === "Cerebellar gray matter") return 1;
  //   else if (refName === "Cerebral white matter") return selectedFile["ratio_gry2pons"];
  //   else return 1;
  // })()

  const apply_ref_temp = temp.items.map(v => {
    return {
      ...v,
      Total: v.Total * RF,
      Left: v.Left * RF,
      Right: v.Right * RF,
      subItem: v.subItem.map(vv => {
        return {
          ...vv,
          Total: vv.Total * RF,
          Left: vv.Left * RF,
          Right: vv.Right * RF,
        }
      })
    }
  })

  return { ...temp, items: apply_ref_temp }
}
export const getVersionInfo = async () => {
  try {
    const versionInfo = await axios.get(process.env.REACT_APP_BASE_URL + `result/download/version_info.txt`).then(res => res).catch(e => null)
    return versionInfo
  } catch (err) {
    // sessionStorage.removeItem('username')
    // sessionStorage.removeItem('token')
    console.log(err);
    return null
  }
}

export const getLicenseInfo = async (productNames) => {
  try {
    const results = await Promise.all(
      productNames.map(async (productName) => {
        try {
          const Cloud_Mode = coreItem[productName].Cloud_Mode;
          let endDate, remainCount;
          if (process.env.REACT_APP_IS_OCI !== 'true') { // OCI가 아니면 라이선스 파일을 조회함
            const licenseInfoRes = await axios.get(`${process.env.REACT_APP_BASE_URL}result/download/licenseinfo-${productName}.txt`);
            const productLicenseInfo = licenseInfoRes.data;
            [endDate, remainCount] = productLicenseInfo.split(/\s+/);
          } else { // OCI면 라이선스 파일을 조회하지 않고 무제한 처리
            endDate = 'inf';
          }

          if (process.env.REACT_APP_IS_OCI === 'true' || Cloud_Mode) { // 클라우드는 남은 업로드 수를 조회함
            const countRes = await getRemainCount(productName);
            remainCount = countRes.data;
          }

          const today = new Date();
          const licenseEndDate = endDate === 'inf' ? 'inf' : new Date(endDate);
          
          return {
            productName,
            valid: licenseEndDate === 'inf' || today < licenseEndDate,
            until: typeof licenseEndDate === 'string' ? licenseEndDate : licenseEndDate.toISOString().split('T')[0],
            count: remainCount, // 'inf' or remain count 
          };
        } catch (e) {
          return null;
        }
      })
    );

    let licenseInfo = {};
    results.forEach((license) => {
      if (license) {
         const { productName, valid, until, count } = license;
        licenseInfo[productName] = { valid, until, count };
      }
    });

    return licenseInfo;
  } catch (err) {
    console.log(err);
  }
};


export function CubicInterp2D(orig2D) {
  // dims = arr.shape
  const origDim = [orig2D.length, orig2D[0].length];
  let pad2D = new Array(origDim[0] + 3).fill(0).map(() => new Array(origDim[1] + 3).fill(0));

  for (var i = 0; i < origDim[0]; i++) {
    for (var j = 0; j < origDim[1]; j++) {
      pad2D[i + 1][j + 1] = orig2D[i][j]
    }
  }
  // 2D matrix slicing
  // new_arr = np.zeros([2*dims[0]-1, 2*dims[1]-1])
  const targDim = [origDim[0] * 2 - 1, origDim[1] * 2 - 1];
  let targ2D = new Array(targDim[0]).fill(0).map(() => new Array(targDim[1]).fill(0));

  for (let i = 0; i < origDim[0] - 1; i++) {
    for (let j = 0; j < origDim[1] - 1; j++) {
      // tmp4x4 = pad2D[i:i+4][ j:j+4]
      // if (i == 25 && j == 25){
      //   debugger
      // }
      let tmp4x4 = pad2D.slice(i, i + 4).map(i => i.slice(j, j + 4))
      targ2D[2 * i][2 * j] = pad2D[i + 1][j + 1]
      targ2D[2 * i + 1][2 * j] = Math.max(_cubic(tmp4x4, 0.5, 0), 0)
      targ2D[2 * i][2 * j + 1] = Math.max(_cubic(tmp4x4, 0, 0.5), 0)
      targ2D[2 * i + 1][2 * j + 1] = Math.max(_cubic(tmp4x4, 0.5, 0.5), 0)

      // # Boundary condition
      if (i == origDim[0] - 2 || j == origDim[1] - 2) {
        targ2D[2 * i + 2][2 * j] = pad2D[i + 2][j + 1]
        targ2D[2 * i][2 * j + 2] = pad2D[i + 1][j + 2]
        targ2D[2 * i + 2][2 * j + 1] = Math.max(_cubic(tmp4x4, 0.5, 0), 0)
        targ2D[2 * i + 1][2 * j + 2] = Math.max(_cubic(tmp4x4, 0, 0.5), 0)
        targ2D[2 * i + 2][2 * j + 2] = pad2D[i + 2][j + 2]
      } else {
        continue;
      }

    }
  }
  return targ2D
}
export function _cubic(p, x, y) {
  let a00 = p[1][1]
  let a01 = -.5 * p[1][0] + .5 * p[1][2]
  let a02 = p[1][0] - 2.5 * p[1][1] + 2 * p[1][2] - .5 * p[1][3]
  let a03 = -.5 * p[1][0] + 1.5 * p[1][1] - 1.5 * p[1][2] + .5 * p[1][3]
  let a10 = -.5 * p[0][1] + .5 * p[2][1]
  let a11 = .25 * p[0][0] - .25 * p[0][2] - .25 * p[2][0] + .25 * p[2][2]
  let a12 = -.5 * p[0][0] + 1.25 * p[0][1] - p[0][2] + .25 * p[0][3] + .5 * p[2][0] - 1.25 * p[2][1] + p[2][2] - .25 * p[2][3]
  let a13 = .25 * p[0][0] - .75 * p[0][1] + .75 * p[0][2] - .25 * p[0][3] - .25 * p[2][0] + .75 * p[2][1] - .75 * p[2][2] + .25 * p[2][3]
  let a20 = p[0][1] - 2.5 * p[1][1] + 2 * p[2][1] - .5 * p[3][1]
  let a21 = -.5 * p[0][0] + .5 * p[0][2] + 1.25 * p[1][0] - 1.25 * p[1][2] - p[2][0] + p[2][2] + .25 * p[3][0] - .25 * p[3][2]
  let a22 = p[0][0] - 2.5 * p[0][1] + 2 * p[0][2] - .5 * p[0][3] - 2.5 * p[1][0] + 6.25 * p[1][1] - 5 * p[1][2] + 1.25 * p[1][3] + 2 * p[2][0] - 5 * p[2][1] + 4 * p[2][2] - p[2][3] - .5 * p[3][0] + 1.25 * p[3][1] - p[3][2] + .25 * p[3][3]
  let a23 = -.5 * p[0][0] + 1.5 * p[0][1] - 1.5 * p[0][2] + .5 * p[0][3] + 1.25 * p[1][0] - 3.75 * p[1][1] + 3.75 * p[1][2] - 1.25 * p[1][3] - p[2][0] + 3 * p[2][1] - 3 * p[2][2] + p[2][3] + .25 * p[3][0] - .75 * p[3][1] + .75 * p[3][2] - .25 * p[3][3]
  let a30 = -.5 * p[0][1] + 1.5 * p[1][1] - 1.5 * p[2][1] + .5 * p[3][1]
  let a31 = .25 * p[0][0] - .25 * p[0][2] - .75 * p[1][0] + .75 * p[1][2] + .75 * p[2][0] - .75 * p[2][2] - .25 * p[3][0] + .25 * p[3][2]
  let a32 = -.5 * p[0][0] + 1.25 * p[0][1] - p[0][2] + .25 * p[0][3] + 1.5 * p[1][0] - 3.75 * p[1][1] + 3 * p[1][2] - .75 * p[1][3] - 1.5 * p[2][0] + 3.75 * p[2][1] - 3 * p[2][2] + .75 * p[2][3] + .5 * p[3][0] - 1.25 * p[3][1] + p[3][2] - .25 * p[3][3]
  let a33 = .25 * p[0][0] - .75 * p[0][1] + .75 * p[0][2] - .25 * p[0][3] - .75 * p[1][0] + 2.25 * p[1][1] - 2.25 * p[1][2] + .75 * p[1][3] + .75 * p[2][0] - 2.25 * p[2][1] + 2.25 * p[2][2] - .75 * p[2][3] - .25 * p[3][0] + .75 * p[3][1] - .75 * p[3][2] + .25 * p[3][3]
  let x2 = x * x
  let x3 = x2 * x
  return a00 + (a01 + (a02 + a03 * y) * y) * y + (a10 + (a11 + (a12 + a13 * y) * y) * y) * x + (a20 + (a21 + (a22 + a23 * y) * y) * y) * x2 + (a30 + (a31 + (a32 + a33 * y) * y) * y) * x3
}

export function isThisAFile(maybeFile) {
  return new Promise((resolve, reject) => {
    if (maybeFile.type !== '') return resolve(maybeFile)

    const reader = new FileReader()
    reader.onloadend = () => {
      if (reader.error && (reader.error.name === 'NotFoundError' || reader.error.name === 'NotReadableError')) return reject(reader.error.name)
      resolve(maybeFile)
    }
    reader.readAsBinaryString(maybeFile)
  })
}

// 특정 파일 ID의 특정한 방향에 대해서만 슬라이스 인덱스를 업데이트하는 함수
export function updateSliceIndexForFile(fileId, direction, newIndex) {
  const existingIndexes = JSON.parse(sessionStorage.getItem('fileSliceIndexes')) || {};
    const axial =  45 - 8;
  const coronal = 128 / 2 + 11;
  const sagittal =  130 / 2;
  const mip =  0;

  const currentFileIndexes = existingIndexes[fileId] || { axial, coronal, sagittal, mip };
  currentFileIndexes[direction] = newIndex;
  existingIndexes[fileId] = currentFileIndexes;
  sessionStorage.setItem('fileSliceIndexes', JSON.stringify(existingIndexes));
}

// 세션 스토리지에서 특정 파일의 슬라이스 인덱스를 읽어오는 함수
export function getSliceIndexForFile(fileId) {
  const allIndexes = JSON.parse(sessionStorage.getItem('fileSliceIndexes')) || {};
  const axial =  45 - 8;
  const coronal = 128 / 2 + 11;
  const sagittal =  130 / 2;
  const mip =  0;
  return allIndexes[fileId] || { axial, coronal, sagittal, mip };
}
