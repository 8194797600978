import React from 'react';
import styles from './Home.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { setLoginThunk } from '1_reduxs/actions/loginAction';
import { useHistory, useLocation } from 'react-router-dom';
import { Signin, Signup, Forgot, SelectProduct } from './components';
import { SpinnerModal } from '5_components';

import BrtnxLogo from '6_media/svg/BrtnxLogo';

export default function Home() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const modalState = useSelector((state) => state.modal);
  const leftBtn = {
    '/signin': { title: 'SIGN UP', push: '/signup' },
    '/signup': { title: 'SIGN IN', push: '/signin' },
    '/forgot': { title: 'SIGN IN', push: '/signin' },
    '/selectProduct': { title: 'LOG OUT', push: '/signin' },
  };
  const logoutData = {
    username: null,
    token: null,
    logged: false,
  };
  if (process.env.REACT_APP_USE_REFRESH_TOKEN === 'true') {
    logoutData.refreshToken = null;
    logoutData.expire = null;
  }

  return (
    <>
      <div className={`${styles['container']}`}>
        <div className={styles['leftfloat']}>
          <div style={{ width: '30%' }}>
            <BrtnxLogo />
          </div>
          {/* <div><img src={media.logoMain} alt=""/></div> */}
          <div
            role="button"
            onClick={() => {
              dispatch(setLoginThunk(logoutData));
              history.push(leftBtn[location.pathname].push);
            }}
          >
            {leftBtn[location.pathname].title}
          </div>
        </div>

        {location.pathname === '/signin' && (
          <div id="signin" className={styles['rightfloat']}>
            <Signin />
          </div>
        )}
        {location.pathname === '/signup' && (
          <div id="signup" className={styles['rightfloat']}>
            <Signup />
          </div>
        )}
        {location.pathname === '/forgot' && (
          <div id="forgot" className={styles['rightfloat']}>
            <Forgot />
          </div>
        )}
        {location.pathname === '/selectProduct' && (
          <div id="selectproduct" className={styles['rightfloat']}>
            <SelectProduct />
          </div>
        )}
      </div>
      {modalState.spinner.status && <SpinnerModal />}
    </>
  );
}
